import { Injectable } from '@angular/core';
import { GeoapiApiModel } from '@core/models/tapp-order/api-model/geoapi/geoapi.api.model';

@Injectable({
  providedIn: 'root',
})
export class AddressAutocompleteService {
  public addressInput: GeoapiApiModel; //address in input
  public selectedAddress: GeoapiApiModel; //selected address from dropdown
  public initializedAddress: GeoapiApiModel; //initialized address to restore if cancel clicked

  public searchResults: GeoapiApiModel[] = [];
}

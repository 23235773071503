<div fxFlex fxLayout="column" class="container-max-width">
  <div class="row" [ngClass]="isDesktop ? 'height-width-container' : ''">
    <div
      id="product-list-desktop"
      [ngClass]="isDesktop == true ? 'col-9' : 'col-12'"
      style="height: calc(100vh - 88px); overflow: auto"
    >
      <div class="container">
        <div class="row">
          <div class="col-12">
            <header class="title-container d-flex text-center flex-wrap">
              <h1 class="title w-100">
                {{ 'tapp-order.pages.order.order-success.title' | translate }}
              </h1>
              <h2 class="subtitle w-100">
                {{ 'tapp-order.pages.order.order-success.subtitle' | translate }}
              </h2>
            </header>
          </div>
          <div class="col-12">
            <div class="row info-container d-flex text-center flex-wrap align-items-center justify-content-center">
              <div class="box col-lg-3 col-md-5 ml-lg-3 align-self-stretch">
                <img src="../../../../assets/icons/tpizza-place.png" />
                <div class="title">
                  {{ 'tapp-order.pages.order.order-success.title-2' | translate }}
                </div>
                <div class="place">
                  <p class="city">{{ restaurant.city }}</p>
                  <p class="street">
                    ul. {{ restaurant.street }}
                    {{ restaurant.buildingNo }}
                  </p>
                </div>
              </div>
              <div class="box col-lg-3 col-md-5 mr-lg-3 align-self-stretch">
                <img src="../../../../assets/icons/tpizza-menu-icon.png" alt="" />
                <div class="title">
                  <ng-container *ngIf="orderType === orderTypeEnum.delivery; else other">
                    {{ 'tapp-order.pages.order.order-success.estimated-delivery-time' | translate }}
                  </ng-container>
                  <ng-template #other>
                    {{ 'tapp-order.pages.order.order-success.estimated-realization-time' | translate }}
                  </ng-template>
                </div>
                <div class="delivery-time">{{ deliveryTime }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="sidebar-parent-container"
      [ngClass]="isDesktop == true ? 'col-3' : 'col-12'"
      style="height: calc(100vh - 88px); overflow: auto; z-index: 999"
    >
      <app-sidebar
        [basketItemsAmount]="basketService.calculateAllItemsInBasket()"
        [basketItems]="this.basketService.basketItems"
        [basketItemsPrice]="basketService.calculatePrice()"
        [paymentMode]="true"
        [isOrderButtonActive]="!!this.orderService.getCustomerEmail()"
        [isSummaryMode]="this.isSummaryMode"
        [sessionStorageNewOrder]="this.sessionStorageNewOrder"
      ></app-sidebar>
    </div>
  </div>
</div>

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DeviceDetectorService {
  private desktopWidth: number = 880;

  private _isMobileMessage = new BehaviorSubject<boolean>(true);
  private _isDesktopMessage = new BehaviorSubject<boolean>(false);

  public isMobile = this._isMobileMessage.asObservable();
  public isDesktop = this._isDesktopMessage.asObservable();

  constructor() {
    this.onViewportChange();

    window.addEventListener('resize', () => {
      this.onViewportChange();
    });

    window.addEventListener('orientationchange', () => {
      this.onViewportChange();
    });
  }

  onViewportChange(): void {
    if (window.innerWidth >= this.desktopWidth) {
      this._isDesktopMessage.next(true);
      this._isMobileMessage.next(false);
    } else {
      this._isDesktopMessage.next(false);
      this._isMobileMessage.next(true);
    }
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { plainToClass } from 'class-transformer';
import { map } from 'rxjs/operators';
import { environment } from '@env/environment';
import { GeoapiApiModel } from '@core/models/tapp-order/api-model/geoapi/geoapi.api.model';

@Injectable({
  providedIn: 'root',
})
export class GeoapiHttpService {
  constructor(private http: HttpClient) {}

  search(query, limit = 1): Observable<GeoapiApiModel[]> {
    return this.http
      .get<GeoapiApiModel[]>(environment.geoApiUrl + '/api/search', {
        headers: { authToken: environment.geoApiAuthToken },
        params: { query },
      })
      .pipe(map((result) => plainToClass(GeoapiApiModel, result as object[])));
  }

  reverseSearch(lat, lon): Observable<GeoapiApiModel[]> {
    return this.http
      .get<GeoapiApiModel[]>(environment.geoApiUrl + '/api/search/coords', {
        headers: { authToken: environment.geoApiAuthToken },
        params: { lat: lat, lon: lon },
      })
      .pipe(map((result) => plainToClass(GeoapiApiModel, result as object[])));
  }
}

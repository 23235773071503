<div class="custom-error-message">
  <div class="order-success-logo">
    <img [src]="getLogo()" />
  </div>

  <div class="order-failed-icon">
    <img src="../../../../../assets/images/failed-payment-icon.png" />
  </div>

  <div class="order-failed-primary-text">
    <ng-template [ngIf]="!primaryText">
      {{ 'tapp-order.error-default-primary-text' | translate }}
    </ng-template>

    <ng-template [ngIf]="primaryText">
      {{ primaryText }}
    </ng-template>
  </div>

  <div class="order-failed-secondary-text">
    <ng-template [ngIf]="!secondaryText">
      {{ 'tapp-order.error-default-secondary-text' | translate }}
    </ng-template>

    <ng-template [ngIf]="secondaryText">
      {{ secondaryText }}
    </ng-template>
  </div>

  <div class="bottom-button">
    <app-bottom-button
      *ngIf="place"
      centerText="{{ 'tapp-order.order-failed-button-text' | translate }}"
      [routerLinkParam]="['', isDesktop ? place.publicId : 'order']"
      [buttonPosition]="buttonPosition"
    ></app-bottom-button>
  </div>
</div>

import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { environment } from '@env/environment';
import { AppHelper } from 'src/app/shared/helpers/app-helper';
import { LoadingService } from 'src/app/shared/loading/loading.service';
import { PaymentService } from '../../services/payment.service';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss'],
})
export class PaymentComponent implements OnInit {
  @ViewChild('iframe', { static: false }) iframe: ElementRef;
  @Input() placeId: string;
  @Input() orderId: string;
  @Input() embeddedContent: string = null;

  public env = environment;
  public form: FormGroup = null;

  constructor(private paymentService: PaymentService, private loadingService: LoadingService) {}

  ngOnInit(): void {
    this.loadingService.showLoader();

    this.paymentService.embeddedContent$.subscribe((url) => {
      this.onOpenIFrame(url);
    });

    if (this.env.payment.provider === 'fake') {
      this.form = new FormGroup(
        {
          creditCard: new FormControl('4556775268595771', [Validators.required]),
          creditCardExpireDate: new FormControl('0224', [Validators.required]),
          creditCardSecure: new FormControl('715', [Validators.required]),
        },
        {},
      );

      // fake data disabled
      this.form.get('creditCard').disable();
      this.form.get('creditCardExpireDate').disable();
      this.form.get('creditCardSecure').disable();
      this.loadingService.hideLoader();
    }
  }

  onOpenIFrame(url: string) {
    this.embeddedContent = url ? url : this.paymentService.getEmbeddedContentUrl();
  }

  onLoadIFrame() {
    this.loadingService.hideLoader();
    if (this.iframe) {
      const doc = this.iframe.nativeElement.contentDocument || this.iframe.nativeElement.contentWindow;
      if (doc) {
        const divProgress = doc.getElementById('divProgress') as HTMLDivElement;
        // divProgress.style.display = "none";
        const progImage = doc.getElementById('progImage') as HTMLImageElement;
        // progImage.style.display = "none";
        progImage.src = AppHelper.progressImgUrl;
      }
    }
  }
}

import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class NavigationService {
  private history: string[] = [];

  constructor(private location: Location, private router: Router, private route: ActivatedRoute) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.history.push(event.urlAfterRedirects);
      }
    });
  }

  /**
   * @description Prevents navigation away from the root path when the browser's native back button is used.
   * This method subscribes to location changes and checks if the navigation event is a 'popstate' type,
   * which occurs when the back button is clicked. If the current path is the root ('/'),
   * it navigates back to the root, effectively preventing any backward navigation from the root.
   */
  private preventBackNavigationFromRoot(): void {
    this.location.subscribe((event) => {
      if (event.type === 'popstate') {
        if (this.location.isCurrentPathEqualTo('/')) {
          this.location.go('/');
        }
      }
    });
    if (this.location.isCurrentPathEqualTo('/')) {
      this.location.go('/');
    }
  }

  public init(): void {
    this.preventBackNavigationFromRoot();
  }

  public back(): void {
    this.history.pop();
    if (this.history.length > 0) {
      this.location.back();
    } else {
      this.router.navigateByUrl('/');
    }
  }

  /**
   * @description Rewrites browser history by appending specified URLs between the root path and the current path.
   *
   * @param urls - Array of URL strings.
   * These URLs are inserted sequentially into the history stack, starting right after the root path and before the current path.
   */
  public rewriteHistory(urls: string[]): void {
    const rootPath = this.location.normalize('/');
    const currentPath = this.location.path();
    this.history = [];

    [rootPath, ...urls, currentPath].forEach((url) => {
      this.location.go(url);
      this.history.push(url);
    });
  }

  /**
   * Updates the current query parameters while preserving existing ones.
   *
   * This method merges the new query parameters with the current ones. If a parameter
   * in the new query parameters already exists, it will be updated with the new value.
   * Other existing query parameters will remain unchanged.
   *
   * @param newQueryParams - An object containing the query parameters to be updated or added.
   */
  public updateCurrentQueryParams(newQueryParams: { [paramName: string]: string }): void {
    // Get the current query parameters from the snapshot
    const currentQueryParams = this.route.snapshot.queryParams;

    // Merge the new query parameters with the current ones
    const mergedQueryParams = { ...currentQueryParams, ...newQueryParams };

    // Navigate with the merged query parameters
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: mergedQueryParams,
      replaceUrl: true,
    });
  }
}

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AttributeViewModel } from '@core/models/tapp-order/view-model/product/attribute.view.model';
import { AllergenViewModel } from '@core/models/tapp-order/view-model/product/allergen.view.model';
import { NutritionValueViewModel } from '@core/models/tapp-order/view-model/product/nutrition-value.view.model';

interface DialogData {
  attributes: AttributeViewModel[];
  allergens: AllergenViewModel[];
  nutritionValues: NutritionValueViewModel[];
}

@Component({
  selector: 'app-product-allergens-dialog',
  templateUrl: './product-allergens-dialog.component.html',
  styleUrls: ['./product-allergens-dialog.component.scss'],
})
export class ProductAllergensDialogComponent implements OnInit {
  public attributes: AttributeViewModel[] = [];
  public allergens: AllergenViewModel[] = [];
  public nutritionValues: NutritionValueViewModel[] = [];

  constructor(
    public dialogRef: MatDialogRef<ProductAllergensDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) {
    this.attributes = data.attributes;
    this.allergens = data.allergens;
    this.nutritionValues = data.nutritionValues;
  }

  ngOnInit() {}
  close(): void {
    this.dialogRef.close();
  }
}

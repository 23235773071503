<div class="form-group">
  <ng-container *ngIf="icon !== null">
    <div class="input-group-icon">
      <ng-container *ngTemplateOutlet="inputContent"></ng-container>
      <label class="form-control-label" *ngIf="label; else inputLabel">
        {{ label | translate }}
      </label>
      <span class="fi flaticon-{{ icon }} form-control-icon form-control-icon-append"></span>
    </div>
  </ng-container>

  <ng-container *ngIf="icon === null">
    <ng-container *ngTemplateOutlet="inputContent"></ng-container>
    <label class="form-control-label" *ngIf="label; else inputLabel">
      {{ label | translate }}
    </label>
  </ng-container>

  <ng-container *ngIf="hint !== null">
    <span class="form-text text-muted">{{ hint | translate }}</span>
  </ng-container>
</div>

<ng-template #inputContent>
  <ng-content></ng-content>
</ng-template>

<ng-template #inputLabel>
  <label *ngIf="labelText" class="form-control-label">
    {{ labelText }}
  </label>
</ng-template>

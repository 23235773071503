import { DefaultExtraFeeMembershipPriceApiModel } from './default-extra-fee-membership-price.api.model';

export class DefaultExtraFeeApiModel {
  basketItemId: string;

  defaultExtraFeeId: string;

  defaultExtraFeeName: string;

  defaultExtraFeePrice: number;

  defaultExtraFeeEarnedPoints: number;

  defaultExtraFeeRedemptionPoints: number;

  membershipPrices: DefaultExtraFeeMembershipPriceApiModel[] = [];
}

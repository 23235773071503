import { CalculateDiscountNotUsedResponseApiModel } from '@api/model/discount/calculate-discount-not-used-response.api.model';
import { AbstractViewModel } from '@core/models/abstract.view.model';

export class CalculateDiscountNotUsedResponseViewModel extends AbstractViewModel<CalculateDiscountNotUsedResponseApiModel> {
  public id: number = null;
  public name: string = null;

  public constructor(
    protected apiModel: CalculateDiscountNotUsedResponseApiModel = new CalculateDiscountNotUsedResponseApiModel(),
  ) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.id = this.apiModel.id || null;
    this.name = this.apiModel.name || null;
  }

  public apply(value: any): CalculateDiscountNotUsedResponseViewModel {
    Object.assign(this, value);
    return this;
  }

  toApiModel(): CalculateDiscountNotUsedResponseApiModel {
    return Object.assign(this.apiModel, {
      id: this.id,
      name: this.name,
    });
  }
}

import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { WorkingHoursApiModel } from '@core/models/tapp-order/api-model/working-hours/working-hours.api.model';
import { PlaceViewModel } from '@core/models/tapp-order/view-model/place/place.view.model';
import { ProductViewModel } from '@core/models/tapp-order/view-model/product/product.view.model';
import { ModifierTypeEnum } from '@shared/enum/modifier-type.enum';
import { LoadingStatus } from '@shared/loading/model/loading-status.enum';
import { MenuItem } from 'primeng/api';
import { BasketService } from 'src/app/services/basket.service';
import { PlaceService } from 'src/app/services/place.service';
import { ProductService } from 'src/app/services/product.service';
import * as uuid from 'uuid';
import { DiscountDataProvider } from '../../data-provider/discount.data-provider';
import { ConditionTypeEnum } from '../../enum/condition/condition-type.enum';
import { CalculateDiscountResponseViewModel } from '../../model/calculate-discount-response.view.model';
import { DiscountViewModel } from '../../model/discount.view.model';
import { MenuTypeEnum } from '@shared/enum/menu-type.enum';
import { OrderService } from '@core/services/order.service';

class Step {
  index: number;
  products: ProductViewModel[];
  value: number;
}

@Component({
  selector: 'app-discount-configurator',
  templateUrl: './discount-configurator.component.html',
  styleUrls: ['./discount-configurator.component.scss'],
  providers: [ProductService],
})
export class DiscountConfiguratorComponent implements OnInit, OnChanges {
  public steps: Step[] = [];
  public products: ProductViewModel[] = [];
  public selectedProducts: any[] = [];
  public conditionSizeIds: number[] = [];
  public conditionDoughIds: (number | string)[] = [];
  public activeIndex = 0;
  public isOpen = true;
  public loadingStatus: LoadingStatus = LoadingStatus.pending;
  public totalItemsPrice: string;

  private items: MenuItem[] = [];
  private workingHours: WorkingHoursApiModel[];

  @Input() discount: DiscountViewModel;
  @Input() display = true;

  @Output() public onNextStep: EventEmitter<void> = new EventEmitter<void>();
  @Output() public onAddToBasket: EventEmitter<void> = new EventEmitter<void>();
  @Output() public onClose: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    public basketService: BasketService,
    public productService: ProductService,
    private router: Router,
    private discountDataProvider: DiscountDataProvider,
    private placeService: PlaceService,
    public orderService: OrderService,
  ) {}

  ngOnChanges(): void {
    this.clear();
  }

  ngOnInit(): void {
    const orderType =
      sessionStorage.getItem('qrMenuMode') == 'true' ? MenuTypeEnum.ONSITE : this.orderService.getMenuType();

    this.discountDataProvider.findById(this.discount.id.toString(), orderType).subscribe((result) => {
      this.discount = result;
      this.prepareConditions();
      this.prepareMenuItems();
      this.addSummaryStep();
      this.loadingStatus = LoadingStatus.success;
    });

    this.placeService.getPlace().subscribe(
      (place: PlaceViewModel) => {
        this.workingHours = place.workingHours;
        if (this.workingHours && this.workingHours.length > 0 && !this.isPlaceOpen()) {
          this.isOpen = false;
        }
      },
      () => {
        this.router.navigate(['/']);
      },
    );
  }

  public isPlaceOpen(): boolean {
    let isOpen = false;
    const currDate: Date = new Date();
    const currTime: string = currDate.toLocaleTimeString('pl-Pl', {
      hour: '2-digit',
      minute: '2-digit',
    });
    const currWeekDay: string = currDate.toLocaleDateString('en-Us', { weekday: 'long' }).toLocaleLowerCase();
    this.workingHours
      .filter((wh) => wh.weekDay === currWeekDay)
      .forEach((wh) => {
        if (wh.startTime <= currTime && wh.endTime >= currTime) {
          isOpen = true;
        }
      });
    return isOpen;
  }

  private prepareMenuItems() {
    this.steps.forEach((step) => {
      this.items.push({
        command: () => {
          this.activeIndex = step.index;
        },
      });
    });
  }

  private addSummaryStep() {
    this.items.push({
      command: () => {
        this.activeIndex = this.activeIndex + 1;
      },
    });
  }

  private prepareConditions(): void {
    let index = 0;
    this.discount.conditions.forEach((condition) => {
      switch (condition.type) {
        case ConditionTypeEnum.productGroup:
          condition.groups.forEach((group) => {
            for (let i = 0; i < group.value; i++) {
              const tempStep = new Step();
              tempStep.index = index;
              tempStep.value = 1;
              tempStep.products = [];

              group.products.forEach((product) => {
                product.originalExternalGroupIdFromDiscount = group.originalExternalGroupId;
                tempStep.products.push(product);
              });

              this.steps.push(tempStep);
              index++;
            }
          });
          break;
        case ConditionTypeEnum.product:
          condition.products.forEach((product) => {
            for (let i = 0; i < product.value; i++) {
              const tempStep = new Step();
              tempStep.index = index;
              tempStep.value = 1;
              tempStep.products = [];
              tempStep.products.push(product.product);

              this.steps.push(tempStep);
              index++;
            }
          });
          break;
        case ConditionTypeEnum.pizzaSize:
          this.conditionSizeIds = condition.pizzaSizeIds;
          break;
        case ConditionTypeEnum.pizzaDough:
          this.conditionDoughIds = condition.pizzaDoughIds;
          break;
        default:
          break;
      }
    });
  }

  add(addToBasket: boolean = true) {
    const discoundUid = this.generateUuid();

    this.selectedProducts.forEach((product) => {
      product.apiModel = null;
      product.modifiers = null;
      product.discountGroupUid = discoundUid;
      product.discountId = this.discount.id;
      product.discountName = this.discount.name;
      product.internalId = uuid.v4();

      product.SelectedModifiers.forEach((modifier) => {
        modifier.internalId = uuid.v4();
      });

      product.unselectedModifiers.forEach((modifier) => {
        modifier.internalId = uuid.v4();
      });
    });

    this.discountDataProvider.calculate(this.selectedProducts).subscribe((discount) => {
      const uids = this.generateUidArray();

      let x = 0;
      this.selectedProducts.forEach((product) => {
        product.internalUid = uids[x];
        x++;
      });

      x = 0;
      discount.items.forEach((discountProduct) => {
        discountProduct.internalUid = uids[x];
        x++;
      });

      this.selectedProducts.forEach((product) => {
        discount.items.forEach((discountProduct) => {
          if (
            discountProduct.productId == product.originalExternalId &&
            discountProduct.internalUid == product.internalUid
          ) {
            product.price = discountProduct.unitPrice;
            let totalPrice = discountProduct.totalPrice;

            discountProduct.modifiers.forEach((modifier) => {
              totalPrice += modifier.totalPrice;
            });

            discountProduct.additionalIngredients.forEach((ingredient) => {
              totalPrice += ingredient.totalPrice;
            });

            product.totalPriceWithDiscounts = totalPrice;
          }
        });
      });

      if (addToBasket === true) {
        this.selectedProducts.forEach((product) => {
          this.productService.product = product;
          this.basketService.add(product, product.SelectedModifiers);
        });
      }

      this.totalItemsPrice = this.calculateTotalPrice(discount);
    });
    if (addToBasket === true) {
      this.onAddToBasket.emit();
    }
  }

  private calculateTotalPrice(discount: CalculateDiscountResponseViewModel): string {
    let totalPrice: Number = Number(discount.totalItemsPrice) - Number(discount.totalItemsDiscount);

    return totalPrice.toFixed(2).toString();
  }

  generateUidArray() {
    const uids = [];
    this.selectedProducts.forEach((product) => {
      uids.push(this.generateUuid());
    });
    return uids;
  }

  generateUuid() {
    return uuid.v4();
  }

  clear() {
    this.activeIndex = 0;
    this.selectedProducts = [];
  }

  public backToPreviousStep(): void {
    const selectedProducts = [...this.selectedProducts];
    const prevStepIndex = this.activeIndex - 1;
    selectedProducts.splice(prevStepIndex, 1);
    this.activeIndex = prevStepIndex;
    this.selectedProducts = [...selectedProducts];
    this.onNextStep.emit();
  }

  getProduct($event) {
    this.selectedProducts.push($event);
    this.activeIndex++;
    /* Wyliczanie ceny w ostatnim kroku */
    if (this.activeIndex === this.steps.length) {
      this.add(false);
    }
    this.onNextStep.emit();
  }
}

import { Injectable } from '@angular/core';
import { BaseDataProvider } from '@core/data-providers/base.data-provider';
import { Observable, of, throwError } from 'rxjs';
import { first, flatMap, map, switchMap, tap } from 'rxjs/operators';
import { MenuHttpService } from '../http/tapp-order/menu/menu.http.service';
import { MenuViewModel } from '../models/tapp-order/view-model/menu/menu.view.model';
import { MenuTypeEnum } from '@shared/enum/menu-type.enum';

@Injectable({
  providedIn: 'root',
})
export class MenuDataProvider {
  constructor(private menuHttpService: MenuHttpService, private baseDataProvider: BaseDataProvider) {}

  getMenu(id: string): Observable<MenuViewModel> {
    return this.menuHttpService.getMenu(id).pipe(map((res) => new MenuViewModel(res)));
  }

  getMenuWithModifiers(id: string): Observable<any> {
    return this.menuHttpService.getMenu(id).pipe(
      flatMap((res) => {
        const menuViewModel = new MenuViewModel(res);
        return menuViewModel.groups
          .map((group) => {
            return group.products.map((product) => {
              return { modifiers: this.baseDataProvider.getProductModifiers(product.publicId), menu: menuViewModel };
            });
          })
          .flat();
      }),
      switchMap((value) => {
        return value.modifiers;
      }),
      // map((a) => a),
      tap((modifiers) => console.log('Modifiers: ', modifiers)),
    );
  }

  public getMenuByPlaceId(placeId: string, menuType: MenuTypeEnum): Observable<MenuViewModel | null> {
    return this.baseDataProvider.getPlace(placeId).pipe(
      first(),
      switchMap((place) => {
        if (place.menus.length === 0) {
          console.error('#092ASDFASDFDUFJ: Menu is not defined');
          return throwError(() => new Error('Menu is not defined'));
        }

        const menuId = place.getMenuByType(menuType)?.publicId;

        if (!menuId) {
          return throwError(() => new Error('Menu is not defined'));
        }

        return this.getMenu(menuId).pipe(first());
      }),
    );
  }
}

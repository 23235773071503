<app-dialog [(visible)]="visible" [closable]="true" [size]="'sm'">
  <ng-template #header>
    <div class="unexpected-issue-dialog__header">
      {{ 'tapp-order.shared.unexpected-issue-dialog.title' | translate }}
    </div>
  </ng-template>
  <div class="unexpected-issue-dialog__content">
    <div class="main-info">
      <i class="pi pi-exclamation-circle"></i>
      {{ 'tapp-order.shared.unexpected-issue-dialog.message' | translate }}
    </div>
    <div *ngIf="place?.phone" class="additional-info">
      <p> {{ 'tapp-order.shared.unexpected-issue-dialog.or-call' | translate }}: </p>
      <p>
        {{ 'tapp-order.shared.unexpected-issue-dialog.phone-number' | translate }}
        <a href="tel:{{ place.phone }}">
          {{ place.phone }}
        </a>
      </p>
      <p>
        {{ place.city }}
      </p>
      <p>
        {{ place.street + ' ' + place.buildingNo + (place.localNo ? '/' + place.localNo : '') }}
      </p>
    </div>
  </div>
  <ng-template #footer>
    <div class="unexpected-issue-dialog__footer">
      <div pRipple class="btn-cancel btn-cancel--short btn-cancel--visible" (click)="close()">
        {{ 'buttons.close' | translate }}
      </div>
    </div>
  </ng-template>
</app-dialog>

import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { AddFormEvent } from 'src/app/core/components/article-builder/form/event/add-form.event';
import { FormEvent } from 'src/app/core/components/article-builder/form/event/form.event';
import {
  CateringAggregationArticleViewModel,
  CateringAggregationViewModel,
} from 'src/app/core/models/catering-aggregation.view.model';
import { CartService } from 'src/app/core/services/cart.service';
import { LoadingService } from 'src/app/shared/loading/loading.service';
import { OrderStateModel } from '../../models/order.state.model';
import { FbItemViewModel } from '../../models/view-model/order/fb-item/fb-item.view.model';
import { OrderViewModel } from '../../models/view-model/order/order.view.model';
import { CateringService } from '../../services/catering.service';
import { OrderCateringService, SelectedModifierItemMap } from '../../services/order-catering.service';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-product-details-page',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.scss'],
})
export class ProductDetailsPageComponent implements OnInit, AfterViewInit {
  public article: CateringAggregationArticleViewModel = null;

  public orderState: OrderStateModel = null;
  public order: OrderViewModel = new OrderViewModel();

  public availableCatering: CateringAggregationViewModel = null;
  public selectedArticleCombinationList: CateringAggregationArticleViewModel[] = null;

  constructor(
    public cartService: CartService,
    private cateringService: CateringService,
    private orderCateringService: OrderCateringService,
    private loadingService: LoadingService,
    public bsModalRef: BsModalRef,
  ) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {}

  navigateBack() {
    this.bsModalRef.hide();
  }

  addToOrder(article) {
    this.cartService.addCart(article);
  }

  public onAddedArticle(formEvent: FormEvent): void {
    if (formEvent instanceof AddFormEvent) {
      const selectedModifierItemMap: Map<string, Map<string, boolean>> = formEvent.selectedModifierItemMap;
      const selectedSubArticleMap: Map<string, Map<string, boolean>> = formEvent.selectedSubArticleMap;
      const article: CateringAggregationArticleViewModel | undefined = this.availableCatering.articles.find(
        (x) => x.id === formEvent.articleId,
      );

      if (article) {
        const articleCombination: CateringAggregationArticleViewModel = this.cateringService.buildSelectedArticle(
          article,
          formEvent.quantity,
          selectedModifierItemMap,
          selectedSubArticleMap,
        );

        article.subArticleList
          .filter((replacer) => replacer.replacementList.length === 0)
          .forEach((ingredient) => {
            articleCombination.subArticleList.push(ingredient);
          });

        this.cateringService.recalculateArticleCombinationHash(articleCombination);

        const articleCombinationList: Array<CateringAggregationArticleViewModel> =
          this.selectedArticleCombinationList || [];
        const existingArticleCombination: CateringAggregationArticleViewModel | undefined = articleCombinationList.find(
          (element) => element.selectedCombinationHash === articleCombination.selectedCombinationHash,
        );

        if (existingArticleCombination) {
          existingArticleCombination.selectedQuantity += articleCombination.selectedQuantity;
          this.modifyItemInBasket(existingArticleCombination);
        } else {
          articleCombinationList.push(articleCombination);
          this.addItemToBasket(articleCombination);
        }
      }
    }
  }

  private addItemToBasket(item: CateringAggregationArticleViewModel): void {
    this.loadingService.showLoader();
    //this.messageService.clear();

    this.orderCateringService.post(item).subscribe(
      (order) => {
        this.order = order;
        this.recalculateItems();

        this.loadingService.hideLoader();
        this.navigateBack();
      },
      (err: HttpErrorResponse) => {
        if (err.status === 400) {
          this.recalculateItems();
          //this.messageService.add(new MessageModel(MessageType.danger, this.translate.instant('error.220')));
        }

        this.loadingService.hideLoader();
      },
    );
  }

  private modifyItemInBasket(item: CateringAggregationArticleViewModel): void {
    this.loadingService.showLoader();
    //this.messageService.clear();

    const calculateQuantity = (arr: FbItemViewModel[], articleId: string) => {
      return arr
        .filter((orderedItem) => {
          return orderedItem.articleId === articleId;
        })
        .reduce((prev, curr) => {
          prev += curr.quantity;
          return prev;
        }, 0);
    };
    const sumOfQuantityFromRequest = calculateQuantity(this.order.fbItems, item.id);

    this.orderCateringService.patchQuantityItem(item).subscribe(
      (order) => {
        if (sumOfQuantityFromRequest === calculateQuantity(order.fbItems, item.id)) {
          //this.messageService.add(new MessageModel(MessageType.danger, this.translate.instant('error.220')));
        }

        this.order = order;
        this.recalculateItems();

        this.loadingService.hideLoader();
        this.navigateBack();
      },
      (err: HttpErrorResponse) => {},
    );
  }

  private recalculateItems(): void {
    this.buildSelectedArticleCombinationList();
  }

  private buildSelectedArticleCombinationList(): void {
    const selectedMap: SelectedModifierItemMap = this.orderCateringService.buildSelectedMap(
      this.availableCatering,
      this.orderState.order.fbItems,
    );

    if (Object.keys(selectedMap).length > 0) {
      const articleCombinationList: Array<CateringAggregationArticleViewModel> = [];

      for (const articleId of Object.keys(selectedMap)) {
        for (const mapping of selectedMap[articleId]) {
          const articleCombination: CateringAggregationArticleViewModel = this.cateringService.buildSelectedArticle(
            mapping.article,
            mapping.selectedQuantity,
            mapping.selectedModifierItemMap,
            mapping.selectedSubArticleMap,
          );

          if (articleCombination) {
            articleCombinationList.push(articleCombination);
          }
        }
      }

      this.selectedArticleCombinationList = this.orderCateringService.selectedOrderCatering =
        articleCombinationList.map((x) => x);
    } else {
      this.selectedArticleCombinationList = this.orderCateringService.selectedOrderCatering = [];
    }
  }
}

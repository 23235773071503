import { AbstractViewModel } from '../../../abstract.view.model';
import { RoomApiModel } from '../../api-model/place/room.api.model';
import { TableViewModel } from './table.view.model';

export class RoomViewModel extends AbstractViewModel<RoomApiModel> {
  public name: string;
  public tables: TableViewModel[];

  constructor(protected apiModel: RoomApiModel = new RoomApiModel()) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.name = this.apiModel.name;
    this.tables = this.apiModel.tables ? this.apiModel.tables.map((table) => new TableViewModel(table)) : [];
  }

  toApiModel(): RoomApiModel {
    return undefined;
  }

  toString() {
    return this.name;
  }
}

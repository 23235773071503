import { Component, Input, OnInit } from '@angular/core';
import { OrderApiModel } from '@api/model/user/order.api.model';
import { OrderTypeEnum } from '@core/models/tapp-order/api-model/order/OrderTypeEnum';
import { OrderStatusEnum } from '@core/models/tapp-order/order-status.enum';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { OrderHistoryModalComponent } from '@core/pages/logged-in-user/components/order-history-modal/order-history-modal.component';
import { BasketService } from '../../../../../services/basket.service';
import { BaseDataProvider } from '@core/data-providers/base.data-provider';
import { forkJoin, Observable } from 'rxjs';
import { ModifierTypeEnum } from '@shared/enum/modifier-type.enum';
import { ProductViewModel } from '@core/models/tapp-order/view-model/product/product.view.model';
import { ProductTypeEnum } from '@shared/enum/product-type.enum';
import { BasketItemViewModel } from '@core/models/tapp-order/view-model/basketItem/basket-item.view.model';
import { map, take } from 'rxjs/operators';
import * as _ from 'lodash';
import { OrderViewModel } from '@core/pages/logged-in-user/model/order.view.model';
import { Router } from '@angular/router';
import { v4 as uuidv4 } from 'uuid';
import { ModifierViewModel } from '@core/models/tapp-order/view-model/product/modifier.view.model';
import { MessageService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { SurveyModalComponent } from '@shared/survey-modal/survey-modal.component';
import { LoadingService } from '@shared/loading/loading.service';
import { appUtils } from '../../../../../utils/app.utils';
import { OrderItemApiModel } from '@core/models/tapp-order/api-model/order/order-item.api.model';

interface BasketItem {
  product: ProductViewModel;
  selectedModifiers: ProductViewModel[];
  missingProducts: ProductViewModel[];
}

@Component({
  selector: 'app-order-item',
  templateUrl: './order-item.component.html',
  styleUrls: ['./order-item.component.scss'],
})
export class OrderItemComponent implements OnInit {
  @Input() public order: OrderApiModel;
  public OrderTypeEnum = OrderTypeEnum;
  public OrderStatusEnum = OrderStatusEnum;
  public dialogRef: MatDialogRef<SurveyModalComponent>;
  public timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  constructor(
    public dialog: MatDialog,
    private basketService: BasketService,
    private baseDataProvider: BaseDataProvider,
    private router: Router,
    private messageService: MessageService,
    private translateService: TranslateService,
    private loadingService: LoadingService,
  ) {}

  ngOnInit(): void {}

  public openDialog(): void {
    const order = new OrderViewModel(this.order);

    order.orderItems = appUtils.transformOrderItemApiModelsToBasketItemViewModels(this.order.orderItems);

    this.dialog.open(OrderHistoryModalComponent, {
      panelClass: 'order-history-modal',
      data: order,
    });

    // this.getProductViewModelsFromOrderHisotry().subscribe((value) => {
    //   order.orderItems = value.map(({ product, selectedModifiers, missingProducts }) => {
    //     const productItem = this.order.orderItems.find((item) => product.publicId === item.productPublicId);
    //     const basketItem = _.cloneDeep(product) as BasketItemViewModel;
    //     basketItem.BasketItemID = uuidv4();
    //     const itemSelectedModifiers = _.cloneDeep(productItem.selectedModifiers) ? _.cloneDeep(selectedModifiers) : [];
    //     if (productItem.selectedModifiers.length === 0) {
    //       basketItem.price = Number(productItem.totalPrice);
    //     }
    //     basketItem.SelectedModifiers = itemSelectedModifiers;
    //     basketItem.CurrentQuantity = product.CurrentQuantity;
    //     basketItem.unselectedModifiers = missingProducts;
    //     basketItem.modifiersToShow = itemSelectedModifiers.filter(
    //       (modifierProduct) =>
    //         modifierProduct.modifierType !== ModifierTypeEnum.PIZZA_DOUGH &&
    //         modifierProduct.modifierType !== ModifierTypeEnum.PIZZA_SIZE &&
    //         modifierProduct.modifierType !== ModifierTypeEnum.PIZZA_FREE_INGREDIENT,
    //     );
    //     return basketItem;
    //   });
    //   this.dialog.open(OrderHistoryModalComponent, {
    //     panelClass: 'order-history-modal',
    //     data: order,
    //   });
    // });
  }

  public repeatOrder(): void {
    this.basketService.removeBasket();
    this.getProductViewModelsFromOrderHisotry(true)
      .pipe(take(1))
      .subscribe(() => {
        this.router.navigate([this.order.place.publicId]);
      });
  }

  public getProductViewModelsFromOrderHisotry(addToBasket = false): Observable<BasketItem[]> {
    const basketItem: BasketItem[] = [];
    const observables: Observable<ProductViewModel>[] = [];

    this.order.orderItems.forEach((item) => {
      observables.push(this.baseDataProvider.getProduct(item.productPublicId));
    });

    return forkJoin(observables).pipe(
      map((products: ProductViewModel[]) => {
        products.forEach((product, index) => {
          const item = this.order.orderItems[index];
          const selectedModifiers: ProductViewModel[] = [];
          let missingProducts: ProductViewModel[] = null;
          let requiredModifiers: ModifierViewModel[] = [];
          let freeModifiersGroup: ModifierViewModel = null;

          product.modifiers.forEach((modifier) => {
            modifier.products.forEach((productModifier) => {
              if (productModifier.productType !== ProductTypeEnum.PRODUCT_PIZZA) {
                if (modifier.minQuantity > 0) {
                  requiredModifiers.push(modifier);
                }
              } else {
                product.modifiers.forEach((pizzaModifier) => {
                  if (pizzaModifier.minQuantity > 0) {
                    requiredModifiers.push(pizzaModifier);
                  }
                });
              }
            });
          });

          item.selectedModifiers.forEach((selectedModifier) => {
            const modifierGroup = product.modifiers.find(
              (modGroup) => modGroup.publicId === selectedModifier.modifierGroupPublicId,
            );
            if (!modifierGroup) {
              return;
            }

            requiredModifiers = requiredModifiers.filter((value) => {
              return value.publicId !== modifierGroup.publicId;
            });

            const selectedModifierProduct = modifierGroup.products.find(
              (product) => product.publicId === selectedModifier.productPublicId,
            );
            if (!selectedModifierProduct) {
              return;
            }

            selectedModifierProduct.CurrentQuantity = selectedModifier.quantity;
            selectedModifierProduct.groupId = selectedModifier.modifierGroupPublicId;
            selectedModifiers.push(selectedModifierProduct);

            if (product.productType !== ProductTypeEnum.PRODUCT_PIZZA) {
              return;
            }

            const pizzaSizeGroup = product.modifiers.find(
              (modGroup) => modGroup.publicId === selectedModifierProduct.groupId,
            );
            if (!pizzaSizeGroup) {
              return;
            }

            const selectedPizzaSize = pizzaSizeGroup.products.find(
              (product) => product.publicId === selectedModifierProduct.publicId,
            );
            if (!selectedPizzaSize) {
              return;
            }

            selectedPizzaSize.CurrentQuantity = selectedModifierProduct.CurrentQuantity;

            item.selectedModifiers.forEach((modifier) => {
              const pizzaModifierGroup = selectedPizzaSize.modifiers.find(
                (modGroup) => modGroup.publicId === modifier.modifierGroupPublicId,
              );
              if (pizzaModifierGroup?.modifierType === ModifierTypeEnum.PIZZA_FREE_INGREDIENT) {
                freeModifiersGroup = pizzaModifierGroup;
              }
              if (!pizzaModifierGroup) {
                return;
              }

              requiredModifiers = requiredModifiers.filter((value) => {
                return value.publicId !== pizzaModifierGroup.publicId;
              });

              const selectedPizzaModifier = pizzaModifierGroup.products.find(
                (product) => product.publicId === modifier.productPublicId,
              );
              if (!selectedPizzaModifier) {
                return;
              }

              selectedPizzaModifier.CurrentQuantity = modifier.quantity;
              selectedPizzaModifier.groupId = modifier.modifierGroupPublicId;
              selectedModifiers.push(selectedPizzaModifier);
            });
          });

          if (freeModifiersGroup) {
            missingProducts = freeModifiersGroup.products.filter((product) => {
              return !selectedModifiers.includes(product);
            });
            missingProducts.forEach((product) => {
              product.CurrentQuantity = 0;
              product.groupId = freeModifiersGroup.publicId;
            });
          }

          product.CurrentQuantity = item.quantity;
          if (addToBasket) {
            if (requiredModifiers.length > 0) {
              this.messageService.add({
                severity: 'error',
                summary: this.translateService.instant(
                  'tapp-order.pages.logged-in-user.components.order-item.error-title',
                ),
                detail: this.translateService.instant(
                  'tapp-order.pages.logged-in-user.components.order-item.error-body',
                  { product: product.name },
                ),
              });
              return;
            }
            this.basketService.add(product, selectedModifiers, missingProducts);
          }
          basketItem.push({ product, selectedModifiers, missingProducts });
        });

        return basketItem;
      }),
    );
  }

  public openSurveyModal(order): void {
    this.loadingService.showLoader();
    this.dialogRef = this.dialog.open(SurveyModalComponent, {
      panelClass: 'survey-modal',
      data: order,
    });
  }
}

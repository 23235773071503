import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { OrderHttpService } from '../http/order.http.service';
import { OrderFbItemHttpService } from '../http/order-fbitem.http.service';
import { OrderViewModel } from '../models/view-model/order/order.view.model';
import { FbItemViewModel } from '../models/view-model/order/fb-item/fb-item.view.model';
import { CateringModel } from '../models/catering.model';
import { forkJoin } from 'rxjs';
import { OrderPaymentRequestModel } from '../models/order-payment.request.model';

@Injectable({
  providedIn: 'root',
})
export class OrderDataProvider {
  constructor(private httpService: OrderHttpService, private orderFbItemHttpService: OrderFbItemHttpService) {}

  findById(placeId: string, orderId: string): Observable<OrderViewModel> {
    return this.httpService.findById(placeId, orderId).pipe(map((apiModel) => new OrderViewModel(apiModel)));
  }

  closeBasket(cinemaId: string, orderId: string, forcePaymentConfirmed: boolean) {
    return this.httpService.closeBasket(cinemaId, orderId, forcePaymentConfirmed);
  }

  create(cinemaId: string, sourceOrderId: string = null): Observable<OrderViewModel> {
    return this.httpService.create(cinemaId, sourceOrderId).pipe(map((apiModel) => new OrderViewModel(apiModel)));
  }

  update(cinemaId: string, order: OrderViewModel): Observable<OrderViewModel> {
    return this.httpService.update(cinemaId, order.toApiModel()).pipe(map((apiModel) => new OrderViewModel(apiModel)));
  }

  delete(cinemaId: string, orderId: string): Observable<void> {
    return this.httpService.delete(cinemaId, orderId).pipe(map(() => null));
  }

  silentDelete(cinemaId: string, order: OrderViewModel): boolean {
    return this.httpService.silentDelete(cinemaId, order.id);
  }

  /**
   * Patches catering items into order
   */
  patchCateringItems(
    cinemaId: string,
    items: Array<FbItemViewModel>,
    order: OrderViewModel | null = null,
  ): Observable<OrderViewModel> {
    if (order === null) {
      return this.create(cinemaId).pipe(
        mergeMap((o) => {
          return this.orderFbItemHttpService
            .put(
              cinemaId,
              o.id,
              items.map((item) => item.toApiModel()),
            )
            .pipe(map((res) => new OrderViewModel(res)));
        }),
      );
    }

    return this.orderFbItemHttpService
      .put(
        cinemaId,
        order.id,
        items.map((item) => item.toApiModel()),
      )
      .pipe(map((res) => new OrderViewModel(res)));
  }

  /**
   * Post catering item into order
   */
  postCateringItem(cinemaId: string, orderId: string, item: FbItemViewModel): Observable<OrderViewModel> {
    return this.orderFbItemHttpService
      .post(cinemaId, orderId, item.toApiModel())
      .pipe(map((res) => new OrderViewModel(res)));
  }

  /**
   * Patch catering item into order
   */
  patchCateringItem(
    cinemaId: string,
    orderId: string,
    basketItemId: string,
    item: FbItemViewModel,
  ): Observable<OrderViewModel> {
    return this.orderFbItemHttpService
      .patchItem(cinemaId, orderId, basketItemId, item.toApiModel())
      .pipe(map((res) => new OrderViewModel(res)));
  }

  patchQuantityCateringItem(
    cinemaId: string,
    orderId: string,
    basketItemId: string,
    quantity: number,
  ): Observable<OrderViewModel> {
    return this.orderFbItemHttpService
      .patchQuantityItem(cinemaId, orderId, basketItemId, quantity)
      .pipe(map((res) => new OrderViewModel(res)));
  }

  deleteCateringItem(cinemaId: string, orderId: string, itemId: string) {
    return this.orderFbItemHttpService.delete(cinemaId, orderId, itemId).pipe(map((res) => new OrderViewModel(res)));
  }

  getPaymentMethodList(cinemaId: string, orderId: string) {
    return this.httpService
      .getPaymentMethodList(cinemaId, orderId)
      .pipe
      // map((paymentMethodResponseModel: Array<PaymentMethodResponseModel>) => {
      //   return automapper.map('PaymentMethodResponseModel', 'PaymentMethod', paymentMethodResponseModel);
      // })
      ();
  }

  getPayment(orderPaymentRequest: OrderPaymentRequestModel) {
    return this.httpService.getPayment(orderPaymentRequest);
  }

  registerPayment(cinemaId: string, orderId: string, token: string = null) {
    return this.httpService.registerPayment(cinemaId, orderId, token);
  }

  /**
   * Gets available catering for order
   */
  getCatering(cinemaId: string, orderId: string = null, screenGroupId: string = null): Observable<CateringModel> {
    return forkJoin({
      nutritionals: this.httpService.getNutritionalInfo(cinemaId),
      catering: this.httpService.getCatering(cinemaId, orderId, screenGroupId),
    }).pipe(map((x) => new CateringModel(x.catering, x.nutritionals)));
  }

  public removeItem(cinemaId: string, orderId: string, itemId: string): Observable<OrderViewModel> {
    return this.httpService.removeItem(cinemaId, orderId, itemId).pipe(map((x) => new OrderViewModel(x)));
  }
}

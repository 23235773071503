import { Component, Input, OnInit, Renderer2 } from '@angular/core';

import { ProductViewModel } from '@core/models/tapp-order/view-model/product/product.view.model';
import { ModifierViewModel } from '@core/models/tapp-order/view-model/product/modifier.view.model';
import { ProductService } from 'src/app/services/product.service';

@Component({
  selector: 'app-product-modifiers',
  templateUrl: './product-modifiers.component.html',
  styleUrls: ['./product-modifiers.component.scss'],
})
export class ProductModifiersComponent implements OnInit {
  @Input() modifierGroups: ModifierViewModel[];
  @Input() public isToggleMode: boolean;

  constructor(private renderer2: Renderer2) {}

  ngOnInit() {}

  public toggleProducts(div: HTMLDivElement): void {
    if (this.isToggleMode) {
      if (div.classList.contains('visible')) {
        this.renderer2.removeClass(div, 'visible');
        return;
      }
      this.renderer2.addClass(div, 'visible');
    }
  }

  public isOpen(div: HTMLDivElement): boolean {
    return div.classList.contains('visible');
  }

  decreaseModifierQuantity(modifier: ProductViewModel, modifierGroup: ModifierViewModel): void {
    if (modifier.CurrentQuantity > 0) {
      modifier.CurrentQuantity--;
      modifier.totalPrice = modifier.price * modifier.CurrentQuantity;
    }
  }

  increaseModifierQuantity(modifier: ProductViewModel, modifierGroup: ModifierViewModel): void {
    let modifiersInGroupCount = modifierGroup.products.reduce(
      (accumulator: number, current: ProductViewModel) => accumulator + current.CurrentQuantity,
      0,
    );

    if (modifier.CurrentQuantity < modifier.maxQuantity && modifiersInGroupCount < modifierGroup.maxQuantity) {
      modifier.CurrentQuantity++;
      modifier.totalPrice = modifier.price * modifier.CurrentQuantity;
    }
  }

  radioChange(modifierProduct: ProductViewModel, modifierGroup: ModifierViewModel): void {
    modifierGroup.products.forEach((modifier) => {
      modifier.CurrentQuantity = 0;
    });

    modifierProduct.CurrentQuantity = 1;
  }

  checkboxChange(modifierProduct: ProductViewModel, modifierGroup: ModifierViewModel): void {
    if (modifierProduct.minQuantity > 0) {
      modifierProduct.isChecked = true;
      modifierProduct.CurrentQuantity = 1;
    } else {
      modifierProduct.isChecked = !modifierProduct.isChecked;
      if (modifierProduct.isChecked) {
        modifierProduct.CurrentQuantity = 1;
      } else {
        modifierProduct.CurrentQuantity = 0;
      }
    }
  }

  countSelectedModifiersFromGroup(group: ModifierViewModel): number {
    let toReturn = 0;
    group.products.forEach((thisProduct) => {
      toReturn += thisProduct.CurrentQuantity;
    });
    return toReturn;
  }

  public isValid(): boolean {
    let isValid = true;
    let invalidGroupId = null;

    this.modifierGroups.forEach((modifierGroup) => {
      let groupQuantity = 0;
      modifierGroup.hasError = false;

      modifierGroup.products.forEach((modifierProduct) => {
        groupQuantity += modifierProduct.CurrentQuantity;
        if (
          modifierProduct.CurrentQuantity < modifierProduct.minQuantity ||
          modifierProduct.CurrentQuantity > modifierProduct.maxQuantity
        ) {
          isValid = false;
          modifierGroup.hasError = true;

          if (!invalidGroupId) {
            invalidGroupId = modifierGroup.publicId;
          }
        }
      });

      if (groupQuantity < modifierGroup.minQuantity || groupQuantity > modifierGroup.maxQuantity) {
        isValid = false;
        modifierGroup.hasError = true;

        if (!invalidGroupId) {
          invalidGroupId = modifierGroup.publicId;
        }
      }
    });

    if (invalidGroupId) {
      document.getElementById(`ingredient-dialog-group-${invalidGroupId}`).scrollIntoView({ behavior: 'smooth' });
    }

    return isValid;
  }
}

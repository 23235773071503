import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandlerService } from '../error-handler/error-handler.service';
import { MessageService } from 'src/app/shared/message/service/message.service';
import { MessageModel, MessageType } from 'src/app/shared/message/model/message.model';
import { indexOf } from 'lodash';
import { TranslateService } from '@ngx-translate/core';

export interface ResponseErrorInterface {
  code: string;
  message: string;
}

@Injectable()
export class ResponseValidatorService {
  constructor(
    private router: Router,
    private translate: TranslateService,
    private messageService: MessageService,
    private errorHandlerService: ErrorHandlerService,
  ) {}

  handleGlobalErrors(errorResponse: HttpErrorResponse): void {
    if (this.errorHandlerService.handle(errorResponse)) {
      return;
    }

    const messages = this.prepareGlobalErrors(errorResponse);
    const dangerMessage = messages.find((m: MessageModel) => indexOf(['20000', '503'], m.code) !== -1);
    if (dangerMessage) {
      this.router.navigate(['error', dangerMessage.code]);
      return;
    }

    messages.forEach((message) => this.messageService.add(message));
  }

  private prepareGlobalErrors(errorResponse: HttpErrorResponse) {
    let messages = [];

    switch (errorResponse.status) {
      case 403:
        break;
      case 404:
        break;
      case 409:
        break;
      case 503:
        messages.push(new MessageModel(MessageType.warning, this.translate.instant('error.503'), '503'));
        break;
      case 401:
        break;
      case 400:
        if (errorResponse.error.errors && errorResponse.error.error.errors !== undefined) {
          messages = errorResponse.error.error.errors.map((error: ResponseErrorInterface) => {
            console.log('ERROR 400', messages);
            return new MessageModel(MessageType.warning, this.translate.instant(`error.${error.code}`), error.code);
          });
        }
        break;
      default:
        messages.push(new MessageModel(MessageType.warning, this.translate.instant('error.20000'), '20000'));
        console.log('ERROR default', errorResponse);
        break;
    }

    return messages;
  }
}

import {
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  ElementRef,
  Host,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  ViewContainerRef,
} from '@angular/core';
import { AbstractControl, FormGroup, NgControl, ValidationErrors } from '@angular/forms';
import { FORM_ERRORS } from './model/form-errors';
import { ControlErrorComponent } from './component/control-error/control-error.component';
import { ControlErrorsContainerDirective } from './control-errors-container.directive';
import { EMPTY, merge, Observable } from 'rxjs';
import { FormSubmitDirective } from './form-submit.directive';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { TranslateService } from '@ngx-translate/core';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[formControl], [formControlName]',
})
export class ControlErrorsDirective implements OnInit, OnDestroy {
  ref: ComponentRef<ControlErrorComponent>;
  container: ViewContainerRef;
  submit$: Observable<Event>;
  @Input() customErrors = {};
  @Input() formControlName: string;

  @HostListener('focus', ['$event'])
  onClick(): void {
    if (this.form) {
      this.markAsTouched(this.form.formGroup);
    }
  }

  constructor(
    private vcr: ViewContainerRef,
    private host: ElementRef<HTMLFormElement>,
    private resolver: ComponentFactoryResolver,
    @Optional() formErrorsContainer: ControlErrorsContainerDirective,
    @Inject(FORM_ERRORS) private errors,
    @Optional() @Host() private form: FormSubmitDirective,
    private controlDir: NgControl,
    private translateService: TranslateService,
  ) {
    this.container = formErrorsContainer ? formErrorsContainer.vcr : vcr;
    this.submit$ = this.form ? this.form.submit$ : EMPTY;
  }

  ngOnInit(): void {
    merge(this.submit$, this.control.valueChanges, this.control.statusChanges)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        const controlErrors: ValidationErrors = this.control.errors;
        if (controlErrors && (this.control.touched || this.control.value)) {
          this.assignTranslatedError(controlErrors);
        } else if (this.ref) {
          this.setError(null);
        }
      });
  }

  private assignTranslatedError(controlErrors: ValidationErrors): void {
    this.control.markAsTouched();
    const firstKey = Object.keys(controlErrors)[0];
    const errorByKey = this.errors[firstKey];
    const translatedText = this.getTranslatedError(firstKey, errorByKey, controlErrors[firstKey]);
    this.setError(translatedText);
  }

  get control(): AbstractControl {
    return this.controlDir.control;
  }

  get element(): HTMLFormElement {
    return this.host.nativeElement;
  }

  private getTranslatedError(key, errorByKey, controlError): string {
    switch (key) {
      case 'min':
        return this.translateService.instant(errorByKey(controlError), {
          value: controlError.min,
        });
      case 'max':
        return this.translateService.instant(errorByKey(controlError), {
          value: controlError.max,
        });
      case 'minlength':
        return this.translateService.instant(errorByKey(controlError), {
          value: controlError.requiredLength,
        });
      case 'maxlength':
        return this.translateService.instant(errorByKey(controlError), {
          value: controlError.requiredLength,
        });
      default:
        if (this.translateService.instant(errorByKey(controlError)) === '') {
          return controlError;
        }
        return this.translateService.instant(errorByKey(controlError));
    }
  }

  private setError(text: string) {
    if (!this.ref) {
      const factory = this.resolver.resolveComponentFactory(ControlErrorComponent);
      this.ref = this.container.createComponent(factory);
    }
    this.ref.instance.text = text;
    if (text) {
      this.element.classList.add('is-invalid');
    } else {
      this.element.classList.remove('is-invalid');
    }
  }

  ngOnDestroy() {}

  private markAsTouched(formGroup: FormGroup) {
    (<any>Object).entries(formGroup.controls).some(([key, value]) => {
      if (value.controls) {
        this.markAsTouched(value);
      }
      if (key === this.formControlName) {
        value.markAsTouched();
        return true;
      }
    });
  }
}

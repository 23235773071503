<div class="modal-group">
  <div class="col-12 d-flex align-items-center modal-navbar" (click)="cancelChanges()">
    <i class="ic ic-back mr-3 primary-color"></i>
    {{ 'tapp-order.pages.product.common.back' | translate }}
  </div>

  <div class="modal-group-scroll-container">
    <div class="col-12 modal-header">
      {{ 'tapp-order.pages.product.common.change-ingredients' | translate }}
    </div>
    <app-product-modifiers #modifiersComponent [modifierGroups]="modifierGroups"> </app-product-modifiers>
  </div>
  <ng-container *ngIf="isMobile === true">
    <div class="modal-group-save-button-container">
      <div (click)="saveChanges()" class="modal-group-save-button ui-clickable">
        <span>
          {{ 'tapp-order.pages.product.common.save' | translate }}
        </span>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="isMobile !== true">
    <div class="col-12 d-flex justify-content-center modal-group-footer">
      <div
        (click)="saveChanges()"
        class="col-11 d-flex justify-content-center modal-group-save-button-desktop ui-clickable"
      >
        <span>
          {{ 'tapp-order.pages.product.common.save' | translate }}
        </span>
      </div>
    </div>
  </ng-container>

  <!-- <button (click)="cancelChanges()" class="cancel-btn">Anuluj</button> -->
</div>

<div class="places-container" *ngIf="isLoaded">
  <div class="places-container__content">
    <app-button-back *ngIf="isDesktop" class="button-back"></app-button-back>
    <div #productListDesktop id="product-list-desktop" class="places-container__content__list">
      <div class="container">
        <div class="row">
          <div class="left-side col-lg-6 col-md-6 col-12">
            <p class="main-title">
              {{ 'tapp-order.places-component.select-local' | translate }}
            </p>
            <!--
            <div class="d-flex col-12 select-adress-input">
              <div class="container p-0">
                <div class="col-12 d-flex p-0">
                  <input
                    [(ngModel)]="placeInputValue"
                    class="col-12"
                    placeholder="Adres, np. Przykładowa 1"
                    type="text"
                  />
                  <div class="col select-adress-find-me-button">
                    <div (click)="getUserLocation()" class="ui-clickable">
                      {{
                        placeInputValue.length > 0
                          ? null
                          : ("tapp-order.select-adress-component.card-localize-me-button"
                            | translate)
                      }}
                      <img src="../../../../assets/icons/map-pin.png" />
                    </div>
                  </div>
                </div>
                <ng-template [ngIf]="results.length > 0">
                  <div class="col-12 select-adress-autocomplete-container">
                    <div
                      (click)="selectPickupLocation(autocompleteitem)"
                      *ngFor="let autocompleteitem of results; index as i"
                      class="col-12 ui-clickable select-adress-autocomplete-item"
                    >
                      {{ autocompleteitem.fullAddress }}
                    </div>
                  </div>
                </ng-template>
                <div class="place-input-error-container mt-1 mb-2">
                  <ng-template [ngIf]="placeInputError != ''">
                    <div
                      class="col-12 d-flex align-items-center justify-content-center place-input-error"
                    >
                      {{ placeInputError }}
                    </div>
                  </ng-template>
                </div>
              </div>
            </div>
            -->
            <app-place-list [cities]="cities" (cityEvent)="setCity($event)"></app-place-list>
          </div>
          <div #placesWrapper class="right-side col-lg-6 col-md-6 col-12" *ngIf="city">
            <p class="main-title">
              {{ 'tapp-order.places-component.main-title' | translate }}
            </p>
            <p class="subtitle">{{ city }}</p>
            <app-place [placesAndHolidays]="[places, holidays]" [deliveryZoneList]="deliveryZoneList"></app-place>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

import { PaymentStatusEnum } from '@core/models/tapp-order/api-model/order/PaymentStatusEnum';
import { PaymentMethodEnum } from '@core/models/tapp-order/api-model/order/PaymentMethodEnum';
import { OrderPaymentApiModel } from '@core/models/tapp-order/api-model/order/order-payment.api.model';
import { PaymentProviderEnum } from '@core/models/tapp-order/api-model/order/PaymentProviderEnum';

export class OrderPaymentViewModel {
  public createdAt: Date;
  public totalPrice: number;
  public foodTotalPrice: number;
  public packageCost: number;
  public deliveryCost: number;
  public otherCost: number;
  public currency: string;
  public paymentMethod: string;
  public paymentProvider: string;
  public paymentStatus: string;
  public paymentContinueUrl: string;
  public providerPaymentUrl: string;
  public onlinePaymentMethodType: string;

  constructor(apiModel: OrderPaymentApiModel) {
    this.createdAt = apiModel.createdAt;
    this.totalPrice = Number(apiModel.totalPrice);
    this.foodTotalPrice = Number(apiModel.foodTotalPrice);
    this.packageCost = Number(apiModel.packageCost);
    this.deliveryCost = Number(apiModel.deliveryCost);
    this.otherCost = Number(apiModel.otherCost);
    this.currency = apiModel.currency;
    this.paymentMethod = PaymentMethodEnum[apiModel.paymentMethod];
    this.paymentProvider = PaymentProviderEnum[apiModel.paymentProvider];
    this.paymentStatus = PaymentStatusEnum[apiModel.paymentStatus];
    this.paymentContinueUrl = apiModel.paymentContinueUrl;
    this.providerPaymentUrl = apiModel.providerPaymentUrl;
    this.onlinePaymentMethodType = apiModel.onlinePaymentMethodType;
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { environment } from '@env/environment';
import { TipType } from './tip-type.enum';
import { Tip } from './tip.model';

@Component({
  selector: 'app-tip',
  templateUrl: './tip.component.html',
  styleUrls: ['./tip.component.scss'],
})
export class TipComponent implements OnInit {
  @Input() mode = 'input';
  @Input() min = 1;
  @Input() max = 100;
  @Input() disabled = false;

  tips: Tip[] = environment.tips.map((t) => new Tip(TipType[t.type], t.value));
  public tipType: typeof TipType = TipType;

  selectedTip: Tip = null;

  constructor() {}

  ngOnInit(): void {}

  handleChange(event) {
    this.selectedTip.value = event.target.value;
  }

  onMove(event) {
    console.log(event);
    this.selectedTip.value = event.value;
  }
}

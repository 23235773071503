import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UserDataApiModel } from '@api/model/user/user-data.api.model';
import { UserRestService } from '@api/rest/user/user.rest.service';
import { GeoapiHttpService } from '@core/http/tapp-order/geoapi/geoapi.http.service';
import { GeoapiApiModel } from '@core/models/tapp-order/api-model/geoapi/geoapi.api.model';
import { OrderService } from '@core/services/order.service';
import { AddressAutocompleteService } from '@ui/address-finder/service/address-autocomplete.service';
import { Subject, Subscription } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-address-autocomplete',
  templateUrl: './address-autocomplete.component.html',
  styleUrls: ['./address-autocomplete.component.scss'],
})
export class AddressAutocompleteComponent implements OnInit, OnDestroy {
  private _user: UserDataApiModel | null = null;
  private _searchTimeout: ReturnType<typeof setTimeout> | null = null;
  private _searchSubscriber: Subscription | null = null;

  private destroyed$ = new Subject<void>();

  public localNo: string = '';

  @Input() errorMessage: string;
  @Input() hasError: boolean = false;
  @Input() context: 'account' | 'other' = 'other';

  @Output() selectAddressEvent: EventEmitter<GeoapiApiModel> = new EventEmitter<GeoapiApiModel>();
  @Output() selectLocalNo: EventEmitter<string> = new EventEmitter<string>();
  @Output() onAddressInitEvent: EventEmitter<GeoapiApiModel | null> = new EventEmitter<GeoapiApiModel | null>();

  constructor(
    private geoapiHttpService: GeoapiHttpService,
    private userRestService: UserRestService,
    private orderService: OrderService,
    public addressAutocompleteService: AddressAutocompleteService,
  ) {}

  ngOnInit(): void {
    switch (this.context) {
      case 'account':
        this.fetchUser();
        break;
      case 'other':
        this.reinitGeoApiModel();
        break;
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  private reinitGeoApiModelForUser(): void {
    let flag = true;
    if (this.getUserFullAddress() !== '') {
      this.geoapiHttpService
        .search(this.getUserFullAddress())
        .pipe(first(), takeUntil(this.destroyed$))
        .subscribe({
          next: (searchResult) => {
            searchResult.forEach((result) => {
              if (flag) {
                flag = false;

                // sprawdzenie miasta
                if (result.city.toLowerCase() !== this._user.deliveryAddress.city.toLowerCase()) {
                  flag = true;
                }
                // sprawdzenie ulicy
                if (result.street.toLowerCase() !== this._user.deliveryAddress.street.toLowerCase()) {
                  flag = true;
                }
                // sprawdzenie numeru ulicy
                if (result.houseNumber.toLowerCase() !== this._user.deliveryAddress.buildingNo.toLowerCase()) {
                  flag = true;
                }
                // sprawdzenie czy mamy numer kodu pocztowego
                if (result.postCode) {
                  if (result.postCode !== this._user.deliveryAddress.zipCode) {
                    flag = true;
                  }
                }
                if (!flag) {
                  if (this._user.deliveryAddress.localNo && this._user.deliveryAddress.localNo != 'null') {
                    this.localNo = this._user.deliveryAddress.localNo;
                    this.onLocalNoChange();
                  }

                  this.addressAutocompleteService.addressInput = result;
                  this.addressAutocompleteService.initializedAddress = result;
                  this.selectLocation(result);

                  this.onAddressInitEvent.emit(result);
                }
              }
            });
          },
          error: () => {},
        });
    }
  }

  private reinitGeoApiModel(): void {
    let flag = true;
    if (this.getAddressString() !== '') {
      this.geoapiHttpService
        .search(this.getAddressString())
        .pipe(first(), takeUntil(this.destroyed$))
        .subscribe({
          next: (searchResult) => {
            searchResult.forEach((result) => {
              if (flag) {
                flag = false;

                //sprawdzenie miasta
                if (result.city.toLowerCase() !== this.orderService.getDeliveryAddressCity().toLowerCase()) {
                  flag = true;
                }
                //sprawdzenie ulicy
                if (result.street.toLowerCase() !== this.orderService.getDeliveryAddressStreet().toLowerCase()) {
                  flag = true;
                }
                //sprawdzenie numeru ulicy
                if (
                  result.houseNumber.toLowerCase() !== this.orderService.getDeliveryAddressBuildingNo().toLowerCase()
                ) {
                  flag = true;
                }
                //sprawdzenie czy mamy numer kodu pocztowego
                if (result.postCode) {
                  if (result.postCode !== this.orderService.getDeliveryAddressZipCode()) {
                    flag = true;
                  }
                }
                if (!flag) {
                  if (
                    this.orderService.getDeliveryAddressLocalNo() &&
                    this.orderService.getDeliveryAddressLocalNo() != 'null'
                  ) {
                    this.localNo = this.orderService.getDeliveryAddressLocalNo();
                    this.onLocalNoChange();
                  }

                  this.addressAutocompleteService.addressInput = result;
                  this.addressAutocompleteService.initializedAddress = result;
                  this.selectLocation(result);

                  this.onAddressInitEvent.emit(result);
                }
              }
            });
          },
          error: () => {},
        });
    }
  }

  private fetchUser(): void {
    this.userRestService
      .getUser()
      .pipe(first(), takeUntil(this.destroyed$))
      .subscribe({
        next: (user) => {
          this._user = user;
          this.reinitGeoApiModelForUser();
        },
        error: () => {},
      });
  }

  private getUserFullAddress(): string {
    let addressString: string = '';
    this._user.deliveryAddress.street && this._user.deliveryAddress.street != 'null'
      ? (addressString += this._user.deliveryAddress.street)
      : null;
    this._user.deliveryAddress.buildingNo && this._user.deliveryAddress.buildingNo != 'null'
      ? (addressString += ' ' + this._user.deliveryAddress.buildingNo + ',')
      : null;
    this._user.deliveryAddress.zipCode && this._user.deliveryAddress.zipCode != 'null'
      ? (addressString += ' ' + this._user.deliveryAddress.zipCode + ',')
      : null;
    this._user.deliveryAddress.city && this._user.deliveryAddress.city != 'null'
      ? (addressString += ' ' + this._user.deliveryAddress.city)
      : null;
    return addressString;
  }

  private getAddressString(): string {
    let addressString: string = '';
    this.orderService.getDeliveryAddressStreet() && this.orderService.getDeliveryAddressStreet() != 'null'
      ? (addressString += this.orderService.getDeliveryAddressStreet())
      : null;
    this.orderService.getDeliveryAddressBuildingNo() && this.orderService.getDeliveryAddressBuildingNo() != 'null'
      ? (addressString += ' ' + this.orderService.getDeliveryAddressBuildingNo() + ',')
      : null;
    this.orderService.getDeliveryAddressZipCode() && this.orderService.getDeliveryAddressZipCode() != 'null'
      ? (addressString += ' ' + this.orderService.getDeliveryAddressZipCode() + ',')
      : null;
    this.orderService.getDeliveryAddressCity() && this.orderService.getDeliveryAddressCity() != 'null'
      ? (addressString += ' ' + this.orderService.getDeliveryAddressCity())
      : null;
    return addressString;
  }

  public search(event: { query: string }): void {
    this.addressAutocompleteService.selectedAddress = null;
    this.selectAddressEvent.emit(null);

    if (event.query !== '') {
      if (this._searchTimeout) {
        clearTimeout(this._searchTimeout);
      }

      this._searchSubscriber?.unsubscribe();

      this._searchTimeout = setTimeout(() => {
        this._searchSubscriber = this.geoapiHttpService
          .search(event.query)
          .pipe(first(), takeUntil(this.destroyed$))
          .subscribe((searchResult) => {
            this.addressAutocompleteService.searchResults = searchResult;
          });
      }, 100);
    }
  }

  public clear(): void {
    this.addressAutocompleteService.addressInput = null;
    this.selectLocation(null);
  }

  public onCancel(): void {
    this.addressAutocompleteService.addressInput = this.addressAutocompleteService.initializedAddress;
    this.selectLocation(this.addressAutocompleteService.initializedAddress);
  }

  public getUserLocation(): void {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const longitude = position.coords.longitude;
          const latitude = position.coords.latitude;
          this.geoapiHttpService.reverseSearch(latitude, longitude).subscribe((response) => {
            if (response && response.length > 0) {
              this.addressAutocompleteService.addressInput = response[0];
              this.selectLocation(response[0]);
            }
          });
        },
        () => {
          this.addressAutocompleteService.searchResults = [];
          this.addressAutocompleteService.addressInput = null;
          this.addressAutocompleteService.selectedAddress = null;
          this.addressAutocompleteService.initializedAddress = null;

          this.selectAddressEvent.emit(null);
        },
        { enableHighAccuracy: true },
      );
    }
  }

  public selectLocation(item: GeoapiApiModel | null): void {
    this.addressAutocompleteService.searchResults = [];
    this.addressAutocompleteService.selectedAddress = item;
    this.errorMessage = null;

    this.selectAddressEvent.emit(item);
  }

  public onLocalNoChange(): void {
    this.selectLocalNo.emit(this.localNo);
  }
}

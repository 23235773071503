import { Component, Input, Output, OnInit, EventEmitter, HostListener, ViewChild, ElementRef } from '@angular/core';
import { GroupViewModel } from '../../../models/tapp-order/view-model/menu/group.view.model';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
  @Input() set groups(value: GroupViewModel[]) {
    this._groups = value;
    if (value.length > 0) {
      this.activeGroupId = value[0].publicId;
    }
  }

  @Output() onSearchEvent = new EventEmitter<string>();

  @ViewChild('toolbarMenu') toolbarMenu: ElementRef;

  public searchBarVisible = false;
  public activeGroupId: string;
  private _groups: GroupViewModel[] = [];
  private blockOnScroll: boolean = false;
  private toolbarMouseDown = false;
  private toolbarStartX: any;
  private toolbarScrollLeft: any;
  private blockOnScrollTimeout = null;

  ngOnInit(): void {}

  @HostListener('window:scroll', ['$event'])
  onScroll(event: any): void {
    let scrollPosition =
      (document.documentElement.scrollTop || document.body.scrollTop) -
      document.getElementById('app-navbar').clientHeight;
    this.groups.forEach((group, key) => {
      let elementTopPosition = document.getElementById(group.publicId).offsetTop;
      const viewportHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

      if (this.blockOnScrollTimeout) {
        clearTimeout(this.blockOnScrollTimeout);
        this.blockOnScrollTimeout = null;
      }

      this.blockOnScrollTimeout = setTimeout(() => {
        this.blockOnScroll = false;
      }, 200);

      if (scrollPosition == elementTopPosition && !this.blockOnScroll) {
        this.setActiveGroup(group.publicId);
      } else if (
        window.innerHeight + scrollPosition >= document.body.offsetHeight &&
        key + 1 == this.groups.length &&
        !this.blockOnScroll
      ) {
        this.setActiveGroup(group.publicId);
      } else if (scrollPosition >= elementTopPosition - viewportHeight * 0.25 && !this.blockOnScroll) {
        this.setActiveGroup(group.publicId);
      }
    });
  }

  toggleSearchBar(): void {
    this.searchBarVisible = !this.searchBarVisible;
  }

  scroll(id): void {
    this.blockOnScroll = true;
    this.activeGroupId = id;
    const groupElement = document.getElementById(id);
    if (groupElement) {
      let topPosition = groupElement.offsetTop;

      window.scrollTo({
        top: topPosition,
        behavior: 'smooth',
      });
    }
  }

  onSearch(event: any): void {
    this.onSearchEvent.emit(event.target.value);
  }

  get groups() {
    return this._groups;
  }

  toolbarStartDragging(e, flag, el) {
    this.toolbarMouseDown = true;
    this.toolbarStartX = e.pageX - el.offsetLeft;
    this.toolbarScrollLeft = el.scrollLeft;
  }

  toolbarStopDragging(e, flag) {
    this.toolbarMouseDown = false;
  }

  toolbarMoveEvent(e, el) {
    e.preventDefault();
    if (!this.toolbarMouseDown) {
      return;
    }
    const x = e.pageX - el.offsetLeft;
    const scroll = x - this.toolbarStartX;
    el.scrollLeft = this.toolbarScrollLeft - scroll;
  }

  private setActiveGroup(groupId: string) {
    this.activeGroupId = groupId;
    let element = document.getElementById('menu-item' + groupId);
    if (element != null) {
      element.scrollIntoView({
        behavior: 'smooth',
        inline: 'center',
      });
    }
  }
}

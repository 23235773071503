<div *ngIf="order" class="mb-3">
  <div class="col-12">
    <header class="title-container d-flex text-center flex-wrap">
      <h1 class="title w-100">
        {{ 'tapp-order.pages.order.order-processing.order-finished.title-container.title' | translate }}
      </h1>
      <h2 class="subtitle w-100">
        {{ 'tapp-order.pages.order.order-processing.order-finished.title-container.subtitle.pre' | translate }}:
        {{ localOrderIdentifier }}
        {{ 'tapp-order.pages.order.order-processing.order-finished.title-container.subtitle.suf' | translate }}
      </h2>
    </header>
  </div>
  <div class="col-12">
    <div class="row info-container d-flex text-center flex-wrap align-items-center justify-content-center">
      <div class="box col-lg-3 col-md-5 ml-lg-3 align-self-stretch" *ngIf="order.type != orderTypeEnum.at_location">
        <i class="ic ic-pizzeria"></i>
        <div class="title">
          {{ 'tapp-order.pages.order.order-processing.order-finished.info-container.title' | translate }}:
        </div>
        <div *ngIf="restaurant" class="place">
          <p class="city">{{ restaurant.city }}</p>
          <p class="street">
            ul. {{ restaurant.street }}
            {{ restaurant.buildingNo }}
          </p>
        </div>
      </div>
      <div
        class="box col-lg-3 col-md-5 align-self-stretch"
        *ngIf="order.type == orderTypeEnum.at_location && orderService.tableName"
      >
        <div class="place">
          <p class="city">
            {{ 'tapp-order.order-summary-room' | translate }}:
            {{ orderService.roomName }}
          </p>
          <p class="street">
            {{ 'tapp-order.order-summary-table' | translate }}:
            {{ orderService.tableName }}
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

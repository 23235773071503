import { Component, HostListener, Input, OnInit } from '@angular/core';
import { PlaceViewModel } from '@core/models/tapp-order/view-model/place/place.view.model';
import { DeviceDetectorService } from '../../../../services/device-detector.service';
import { PlaceService } from '../../../../services/place.service';
import { environment } from '@env/environment';

@Component({
  selector: 'app-custom-error-message',
  templateUrl: './custom-error-message.component.html',
  styleUrls: ['./custom-error-message.component.scss', './../../order/order-success/order-success.component.scss'],
})
export class CustomErrorMessageComponent implements OnInit {
  public logo: string;
  public isDesktop: boolean;
  public place: PlaceViewModel;

  public buttonPosition: 'fixed' | 'static' = this.getButtonPosition(window.innerWidth);

  @Input() primaryText: string;
  @Input() secondaryText: string;

  constructor(private placeService: PlaceService, private deviceService: DeviceDetectorService) {
    this.deviceService.isDesktop.subscribe((isDesktop) => {
      this.isDesktop = isDesktop;
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.buttonPosition = this.getButtonPosition((event.target as Window).innerWidth);
  }

  ngOnInit(): void {
    /* Ustawianie logo */
    this.placeService.getPlace().subscribe((placeResponse) => {
      this.place = placeResponse;

      if (placeResponse.image && placeResponse.image.url) {
        this.logo = placeResponse.image.url;
      } else {
        this.logo = '../../../assets/images/' + environment.defaultLogoName;
      }
    });
  }

  getLogo(): string {
    return this.logo;
  }

  private getButtonPosition(windowInnerWidth: number): 'fixed' | 'static' {
    return windowInnerWidth < 768 ? 'fixed' : 'static';
  }
}

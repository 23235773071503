import { AbstractViewModel } from '../../../abstract.view.model';
import { PaymentMethodApiModel } from '../../../api-model/order/payment-method/payment-method.api.model';

export class PaymentMethodViewModel extends AbstractViewModel<PaymentMethodApiModel> {
  id: string;
  name: string;
  identifier: string;
  cardId: string;
  value: number;

  constructor(protected apiModel: PaymentMethodApiModel = new PaymentMethodApiModel()) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.id = this.apiModel.id;
    this.name = this.apiModel.name;
    this.identifier = this.apiModel.identifier;
    this.cardId = this.apiModel.cardId;
    this.value = this.apiModel.value;
  }

  toApiModel(): PaymentMethodApiModel {
    return undefined;
  }
}

import { CardActionTypeEnum } from './card-action-type.enum';
import { CateringAggregationArticleViewModel } from './catering-aggregation.view.model';

export class ArticleCard {
  constructor(
    public item: CateringAggregationArticleViewModel,
    public actionType: CardActionTypeEnum,
    public actionValue?: number,
  ) {}
}

import { Injectable } from '@angular/core';
import { BasketItemViewModel } from '@core/models/tapp-order/view-model/basketItem/basket-item.view.model';
import { OrderService } from '@core/services/order.service';
import { ModifierTypeEnum } from '@shared/enum/modifier-type.enum';
import { LoadingService } from '@shared/loading/loading.service';
import * as _ from 'lodash';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { BehaviorSubject, Subject } from 'rxjs';
import { BasketService } from 'src/app/services/basket.service';
import { DiscountDeliveryTypeInfoComponent } from './../component/dialog/discount-delivery-type-info/discount-delivery-type-info.component';
import { DiscountDataProvider } from './../data-provider/discount.data-provider';
import { CalculateDiscountResponseViewModel } from './../model/calculate-discount-response.view.model';
import { CalculateDiscountItemResponseViewModel } from '@core/pages/discounts/model/calculate-discount-item-response.view.model';

@Injectable({
  providedIn: 'root',
})
export class DiscountService {
  public basketItems: BasketItemViewModel[] = [];
  public readonly promoCode: string = '';

  public ref: DynamicDialogRef;
  public calculateDiscountResponse: CalculateDiscountResponseViewModel;
  public isBasketIsCalculating: Subject<boolean> = new Subject();
  public data$: BehaviorSubject<CalculateDiscountResponseViewModel> =
    new BehaviorSubject<CalculateDiscountResponseViewModel>(null);

  public isEqualBeforeAndAfter: boolean = false;
  private basketBeforeUpdateDiscount: BasketItemViewModel[] = [];
  private basketAfterUpdateDiscount: BasketItemViewModel[] = [];

  constructor(
    public dialogService: DialogService,
    private basketService: BasketService,
    private discountDataProvider: DiscountDataProvider,
    private loadingService: LoadingService,
    private orderService: OrderService,
  ) {}

  ngOnInit(): void {}

  checkChanges() {
    this.calculate();
  }

  calculate(promoCode: string = this.promoCode) {
    if (this.basketService.basketItems.length > 0) {
      if (this.checkIsOrder()) {
        return;
      }
      this.loadingService.showLoader();
      this.isBasketIsCalculating.next(true);
      this.discountDataProvider.calculateBasket(this.basketService.basketItems, promoCode).subscribe(
        (result) => {
          this.basketService.setFoodTotalPriceBackendCalculation(result.totalItemsPrice);
          this.calculateDiscountResponse = result;
          this.updateAllDiscounts();
          this.isEqualBeforeAndAfter = this.checkIsHaveAnyChanges();
          this.loadingService.hideLoader();
          this.isBasketIsCalculating.next(false);
          (<string>this.promoCode) = promoCode;
          this.data$.next(result);
        },
        (error) => {
          this.loadingService.hideLoader();
          this.isBasketIsCalculating.next(false);
          (<string>this.promoCode) = promoCode;
        },
      );
    } else {
      this.data$.next(null);
    }
  }

  checkIsOrder() {
    if (
      this.orderService.getOrderId() ||
      window.location.pathname.includes('/order/') ||
      window.location.pathname.includes('/order-success/') ||
      window.location.pathname.includes('/order-failed/') ||
      window.location.pathname.includes('/error')
    ) {
      return true;
    } else {
      return false;
    }
  }

  private updateAllDiscounts() {
    this.basketBeforeUpdateDiscount = _.cloneDeep(this.basketService.basketItems);

    this.basketService.basketItems.forEach((basketItem) => {
      let discountProduct = this.calculateDiscountResponse.items.find(
        (item) => item.internalId == basketItem.internalId,
      );

      basketItem.discountId = discountProduct.discountId;
      basketItem.totalPrice = discountProduct.totalPrice;
      basketItem.totalDiscount = discountProduct.totalDiscount;

      if (basketItem.discountId) {
        let discount = this.calculateDiscountResponse.discounts.find(
          (discount) => discount.id == basketItem.discountId,
        );
        if (discount) {
          basketItem.discountName = discount.name;
        }
      }

      basketItem.price = discountProduct.unitPrice;

      discountProduct.modifiers.forEach((modifier) => {
        basketItem.totalPrice += modifier.totalPrice;
      });

      discountProduct.additionalIngredients.forEach((ingredient) => {
        basketItem.totalPrice += ingredient.totalPrice;
      });

      basketItem.totalPriceWithDiscounts = basketItem.totalPrice;

      if (basketItem.product2) {
        let discountProduct2 = this.calculateDiscountResponse.items.find(
          (item) => item.internalId == basketItem.product2.internalId,
        );

        basketItem.product2.discountId = discountProduct2.discountId;
        basketItem.product2.totalPrice = discountProduct2.totalPrice;
        basketItem.product2.totalDiscount = discountProduct2.totalDiscount;

        if (basketItem.product2.discountId) {
          let discount = this.calculateDiscountResponse.discounts.find(
            (discount) => discount.id == basketItem.product2.discountId,
          );
          if (discount) {
            basketItem.product2.discountName = discount.name;
          }
        }

        basketItem.product2.price = discountProduct2.unitPrice;

        discountProduct2.modifiers.forEach((modifier) => {
          basketItem.product2.totalPrice += modifier.totalPrice;
        });

        discountProduct2.additionalIngredients.forEach((ingredient) => {
          basketItem.product2.totalPrice += ingredient.totalPrice;
        });

        basketItem.product2.totalPriceWithDiscounts = basketItem.product2.totalPrice;
      }
    });

    this.basketService.saveBasket();

    this.basketAfterUpdateDiscount = _.cloneDeep(this.basketService.basketItems);
  }

  public checkIsHaveAnyChanges(): boolean {
    return _.isEqual(this.basketBeforeUpdateDiscount, this.basketAfterUpdateDiscount);
  }

  checkIsNewDiscounts() {
    let result = false;
    this.basketService.basketItems.forEach((basketItem) => {
      this.calculateDiscountResponse.items.forEach((discountProduct) => {
        if (basketItem.internalId == discountProduct.internalId) {
          if (basketItem.discountId != discountProduct.discountId) {
            result = true;
          }
        }
      });
    });

    return result;
  }

  private displayDialog(): void {
    if (!this.isEqualBeforeAndAfter && window.location.pathname.includes('/payments')) {
      this.ref = this.dialogService.open(DiscountDeliveryTypeInfoComponent, {
        width: '550px',
        // contentStyle: { overflow: 'auto' },
        baseZIndex: 100000,
        autoZIndex: true,
        dismissableMask: true,
        modal: true,
        data: {
          calculateDiscountResponse: this.calculateDiscountResponse,
        },
      });
    }
  }

  ngOnDestroy() {
    if (this.ref) {
      this.ref.close();
    }
  }
}

import { AbstractViewModel } from '../../abstract.view.model';
import { OrderMembershipPriceApiModel } from '../../api-model/order/order-membership-price.api.model';

export class OrderMembershipPricesViewModel extends AbstractViewModel<OrderMembershipPriceApiModel> {
  isAppliedOnOrder: boolean;
  membershipLevel: number;
  membershipCardTypeId: string;
  membershipTotalValue: number;

  constructor(protected apiModel: OrderMembershipPriceApiModel = new OrderMembershipPriceApiModel()) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.isAppliedOnOrder = this.apiModel.isAppliedOnOrder;
    this.membershipLevel = this.apiModel.membershipLevel;
    this.membershipCardTypeId = this.apiModel.membershipCardTypeId;
    this.membershipTotalValue = this.apiModel.membershipTotalValue;
  }

  toApiModel(): OrderMembershipPriceApiModel {
    return undefined;
  }
}

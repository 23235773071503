import { Exclude, Expose, Type } from 'class-transformer';
import { GroupDiscountApiModel } from './group-discount.api.model';
import { ProductDiscountApiModel } from './product-discount.api.model';

@Exclude()
export class ConditionApiModel {
  @Expose()
  public id: number;

  @Expose()
  public type: string;

  @Expose()
  public monday: string;

  @Expose()
  public tuesday: string;

  @Expose()
  public wednesday: string;

  @Expose()
  public thursday: string;

  @Expose()
  public friday: string;

  @Expose()
  public saturday: string;

  @Expose()
  public sunday: string;

  @Expose()
  public hourFrom: string;

  @Expose()
  public hourTo: string;

  @Expose()
  public selectAll: boolean;

  @Expose()
  public value: string;

  @Expose()
  public anyItems: boolean;

  @Expose()
  public pizzaSizeIds: number[];

  @Expose()
  public pizzaDoughIds: number[];

  @Expose()
  public deliveryTypes: string[];

  @Expose()
  @Type(() => ProductDiscountApiModel)
  public products: ProductDiscountApiModel[];

  @Expose()
  @Type(() => GroupDiscountApiModel)
  public groups: GroupDiscountApiModel[];
}

import { PlaceViewModel } from '@core/models/tapp-order/view-model/place/place.view.model';
import { InvoiceViewModel } from '@core/pages/payment/model/invoice.view.model';
import { ProductViewModel } from '@core/models/tapp-order/view-model/product/product.view.model';
import { OrderApiModel } from '@api/model/user/order.api.model';
import { OrderPaymentViewModel } from '@core/pages/logged-in-user/model/order-payment.view.model';
import { DeliveryAddressViewModel } from '@core/pages/logged-in-user/model/order-address.view.model';

export class OrderViewModel {
  public tablePublicId?: string;
  public publicId: string;
  public customerName: string;
  public customerEmail: string;
  public customerPhone: string;
  public notes: string;
  public createdAt: Date;
  public pickupTime: Date;
  public deliveryTime: Date;
  public type: string;
  public status: string;
  public reason: string;
  public localOrderIdentifier?: string;
  public place: PlaceViewModel;
  public orderItems: ProductViewModel[];
  public orderPayment: OrderPaymentViewModel;
  public deliveryAddress: DeliveryAddressViewModel;
  public invoice: InvoiceViewModel;

  constructor(order: OrderApiModel) {
    this.tablePublicId = order.tablePublicId;
    this.publicId = order.publicId;
    this.customerName = order.customerName;
    this.customerEmail = order.customerEmail;
    this.customerPhone = order.customerPhone;
    this.notes = order.notes;
    this.createdAt = order.createdAt;
    this.pickupTime = order.pickupTime;
    this.deliveryTime = order.deliveryTime;
    this.type = order.type;
    this.status = order.status;
    this.reason = order.reason;
    this.localOrderIdentifier = order.localOrderIdentifier;
    this.place = new PlaceViewModel(order.place);
    this.orderPayment = new OrderPaymentViewModel(order.orderPayment);
    this.deliveryAddress = order.deliveryAddress ? new DeliveryAddressViewModel(order.deliveryAddress) : null;
  }
}

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { DeliveryZonesHttpService } from '@core/http/tapp-order/delivery-zones/delivery-zones.http.service';
import { DeliveryZoneApiModel } from '@core/models/tapp-order/api-model/delivery-zones/delivery-zone.api.model';
import { OrderTypeEnum } from '@core/models/tapp-order/api-model/order/OrderTypeEnum';
import { DeliveryZoneViewModel } from '@core/models/tapp-order/view-model/delivery-zones/delivery-zone.view.model';
import { PlaceViewModel } from '@core/models/tapp-order/view-model/place/place.view.model';
import { OrderService } from '@core/services/order.service';
import { BasketService } from '../../services/basket.service';
import { PlaceService } from '../../services/place.service';

@Injectable()
export class AddressFinderService {
  private _foundDeliveryZones: DeliveryZoneApiModel[] = [];
  private _deliveryZoneList: DeliveryZoneViewModel[] = [];
  private deliveryZones: DeliveryZoneApiModel[] = [];
  private pickupOrderType: string = OrderTypeEnum.pick_up;

  public deliveryOrderType: string = OrderTypeEnum.delivery;
  public selectedOrderType: OrderTypeEnum;

  constructor(
    private router: Router,
    private deliveryZonesHttpService: DeliveryZonesHttpService,
    private orderService: OrderService,
    private placeService: PlaceService,
    private basketService: BasketService,
  ) {
    this.deliveryZoneList();
    this.selectedOrderType = this.orderService.getOrderType();
    this.orderService.orderTypeSubject$.subscribe((res: OrderTypeEnum) => {
      if (res !== null) {
        this.selectedOrderType = res;
      }
    });
  }

  private deliveryZoneList(): void {
    this.deliveryZonesHttpService.getDeliveryZoneList().subscribe((deliveryZoneList) => {
      this._deliveryZoneList = deliveryZoneList;
    });
  }

  public setDeliveryZoneIdToLocalStorage(): void {
    this.orderService.setDeliveryZoneId(this.getFoundDeliveryZones()[0].id);
  }

  public setDeliveryZoneToLocalStorage(): void {
    this.orderService.setDeliveryZone(this.getFoundDeliveryZones()[0]);
  }

  private navigateToMenu(): void {
    let isDeliveryZone = false;
    this._deliveryZoneList.forEach((deliveryZone) => {
      if (deliveryZone.placePublicId === sessionStorage.getItem('placeId')) {
        isDeliveryZone = true;
      }
    });

    if (isDeliveryZone) {
      this.orderService.setOrderType(this.deliveryOrderType);

      this.orderService.setDeliveryZoneId(this.getFoundDeliveryZones()[0].id);
      this.orderService.setDeliveryZone(this.getFoundDeliveryZones()[0]);
    } else {
      this.orderService.setOrderType(this.pickupOrderType);
    }

    this.router.navigate([sessionStorage.getItem('placeId')]);
  }

  public setFoundDeliveryZones(deliveryZone: DeliveryZoneApiModel): void {
    this._foundDeliveryZones.push(deliveryZone);
  }

  public unsetFoundDeliveryZones(): void {
    this._foundDeliveryZones = [];
  }

  public getFoundDeliveryZones(): DeliveryZoneApiModel[] {
    return this._foundDeliveryZones;
  }

  public sortLocalByLowestDeliveryPrice(): void {
    this.getFoundDeliveryZones().sort((a, b) => (a.deliveryPrice < b.deliveryPrice ? -1 : 1));
  }

  public setPlaceIdToSessionStorage(): void {
    if (
      sessionStorage.getItem('placeId') &&
      this.getFoundDeliveryZones()[0].placePublicId != sessionStorage.getItem('placeId')
    ) {
      this.basketService.removeBasket();
    }

    this.placeService.use(this.getFoundDeliveryZones()[0].placePublicId);
  }

  public getDeliveryZone(): DeliveryZoneApiModel | null {
    if (this.getFoundDeliveryZones().length === 0) {
      return null;
    }

    return this.getFoundDeliveryZones()[0];
  }

  public saveDataToService(): void {
    this.orderService.setOrderType(this.deliveryOrderType);
  }

  public searchDefaultLocal(): void {
    const placeId = this.placeService.getPlaceId();
    this._foundDeliveryZones = [];
    this.deliveryZones = [];

    this._deliveryZoneList.forEach((deliveryZone) => {
      if (deliveryZone.placePublicId === placeId) {
        this.deliveryZones.push(deliveryZone);
        this.setFoundDeliveryZones(deliveryZone);
      }
    });
    this.sortLocalByLowestDeliveryPrice();
    this.setPlaceIdToSessionStorage();
    this.setDeliveryZoneIdToLocalStorage();
    this.setDeliveryZoneToLocalStorage();
    this.saveDataToService();
    this.navigateToMenu();
  }

  public searchLocal(place: PlaceViewModel): void {
    this.placeService.use(place.publicId);
    this.saveDataToService();
    this.navigateToMenu();
  }
}

import { CreateOrderItemModifierRequestApiModel } from '@core/models/tapp-order/api-model/order/create-order-item-modifier-request.api.model';
import { CreateOrderItemRequestApiModel } from '@core/models/tapp-order/api-model/order/create-order-item-request.api.model';
import { BasketItemViewModel } from '@core/models/tapp-order/view-model/basketItem/basket-item.view.model';
import * as _ from 'lodash';

export const _transformBasketItemViewModelsToCreateOrderItemRequestApiModels = (
  basketItems: BasketItemViewModel[],
): CreateOrderItemRequestApiModel[] => {
  let pizzaGroupId: number = 1;

  const preparedOrderItems: CreateOrderItemRequestApiModel[] = [];
  basketItems.forEach((basketItem) => {
    const isDividedPizza: boolean = !!basketItem.product2;
    const preparedBasketItem: CreateOrderItemRequestApiModel = new CreateOrderItemRequestApiModel();

    preparedBasketItem.productPublicId = basketItem.publicId;
    preparedBasketItem.name = basketItem.name;
    preparedBasketItem.quantity = basketItem.CurrentQuantity * (isDividedPizza ? 0.5 : 1);
    preparedBasketItem.notes = basketItem.notes;

    if (basketItem.discountId) {
      preparedBasketItem.discountId = String(basketItem.discountId);
    }

    if (basketItem.internalId) {
      preparedBasketItem.internalId = basketItem.internalId;
    }

    if (basketItem.originalExternalGroupIdFromDiscount) {
      preparedBasketItem.originalExternalGroupId = String(basketItem.originalExternalGroupIdFromDiscount);
    }

    const preparedModifiers: CreateOrderItemModifierRequestApiModel[] = [];
    basketItem.SelectedModifiers.forEach((selectedModifier) => {
      const tmpModifier: CreateOrderItemModifierRequestApiModel = new CreateOrderItemModifierRequestApiModel();

      tmpModifier.productPublicId = selectedModifier.publicId;
      tmpModifier.modifierPublicId = selectedModifier.groupId;
      tmpModifier.internalId = selectedModifier.internalId;
      tmpModifier.name = selectedModifier.name;
      tmpModifier.quantity = selectedModifier.CurrentQuantity;

      preparedModifiers.push(tmpModifier);
    });
    preparedBasketItem.selectedModifiers = preparedModifiers;

    const preparedModifiersUnselected: CreateOrderItemModifierRequestApiModel[] = [];

    basketItem.unselectedModifiers?.forEach((unselectedModifier) => {
      const tmpModifier: CreateOrderItemModifierRequestApiModel = new CreateOrderItemModifierRequestApiModel();

      tmpModifier.productPublicId = unselectedModifier.publicId;
      tmpModifier.modifierPublicId = unselectedModifier.groupId;
      tmpModifier.internalId = unselectedModifier.internalId;
      tmpModifier.name = unselectedModifier.name;
      tmpModifier.quantity = 1;

      preparedModifiersUnselected.push(tmpModifier);
    });

    preparedBasketItem.unselectedModifiers = preparedModifiersUnselected;

    if (isDividedPizza) {
      preparedBasketItem.pizzaGroupId = pizzaGroupId;
      preparedBasketItem.parentPizzaPublicId = null;
    }

    preparedOrderItems.push(_.cloneDeep(preparedBasketItem));

    if (isDividedPizza) {
      const preparedBasketItem2: CreateOrderItemRequestApiModel = new CreateOrderItemRequestApiModel();

      preparedBasketItem2.pizzaGroupId = pizzaGroupId;
      preparedBasketItem2.parentPizzaPublicId = preparedBasketItem.productPublicId;
      preparedBasketItem2.productPublicId = basketItem.product2.publicId;
      preparedBasketItem2.name = basketItem.product2.name;
      preparedBasketItem2.quantity = preparedBasketItem.quantity;
      preparedBasketItem2.notes = basketItem.product2.notes;

      if (basketItem.product2.discountId) {
        preparedBasketItem2.discountId = String(basketItem.product2.discountId);
      }

      if (basketItem.product2.internalId) {
        preparedBasketItem2.internalId = String(basketItem.product2.internalId);
      }

      if (basketItem.product2.originalExternalGroupIdFromDiscount) {
        preparedBasketItem2.originalExternalGroupId = _.cloneDeep(
          basketItem.product2.originalExternalGroupIdFromDiscount,
        );
      }

      const preparedModifiers2: CreateOrderItemModifierRequestApiModel[] = [];

      basketItem.SelectedModifiers2.forEach((selectedModifier) => {
        const tmpModifier: CreateOrderItemModifierRequestApiModel = new CreateOrderItemModifierRequestApiModel();

        tmpModifier.productPublicId = selectedModifier.publicId;
        tmpModifier.modifierPublicId = selectedModifier.groupId;
        tmpModifier.internalId = selectedModifier.internalId;
        tmpModifier.name = selectedModifier.name;
        tmpModifier.quantity = selectedModifier.CurrentQuantity;

        preparedModifiers2.push(tmpModifier);
      });
      preparedBasketItem2.selectedModifiers = preparedModifiers2;

      const preparedModifiers2Unselected: CreateOrderItemModifierRequestApiModel[] = [];

      basketItem.unselectedModifiers2.forEach((unselectedModifier) => {
        const tmpModifier: CreateOrderItemModifierRequestApiModel = new CreateOrderItemModifierRequestApiModel();

        tmpModifier.productPublicId = unselectedModifier.publicId;
        tmpModifier.modifierPublicId = unselectedModifier.groupId;
        tmpModifier.internalId = unselectedModifier.internalId;
        tmpModifier.name = unselectedModifier.name;
        tmpModifier.quantity = 1;

        preparedModifiers2Unselected.push(tmpModifier);
      });

      preparedBasketItem2.unselectedModifiers = preparedModifiers2Unselected;

      preparedOrderItems.push(_.cloneDeep(preparedBasketItem2));

      pizzaGroupId++;
    }
  });

  return preparedOrderItems;
};

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TermsAndPrivacyPolicyItemApiModel } from '@core/models/tapp-order/api-model/terms-and-conditions/terms-and-privacy-policy-item.api.model';
import { plainToClass } from 'class-transformer';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class TermsAndPrivacyPolicyHttpService {
  constructor(private http: HttpClient) {}

  public getList(): Observable<TermsAndPrivacyPolicyItemApiModel[]> {
    return this.http
      .get<TermsAndPrivacyPolicyItemApiModel[]>(`/termsAndPrivacyPolicy`)
      .pipe(map((result) => plainToClass(TermsAndPrivacyPolicyItemApiModel, result as object[])));
  }

  public get(placePublicId: string): Observable<TermsAndPrivacyPolicyItemApiModel[]> {
    return this.http
      .get<TermsAndPrivacyPolicyItemApiModel[]>(`/termsAndPrivacyPolicy/${placePublicId}`)
      .pipe(map((result) => plainToClass(TermsAndPrivacyPolicyItemApiModel, result as object[])));
  }

  // public create(placePublicId: string, content: string) {
  //   return this.http
  //     .post<TermApiModel>(`/termsAndPrivacyPolicy/create`, {
  //       placePublicId,
  //       content,
  //     })
  //     .pipe(map((result) => plainToClass(TermApiModel, result as object)));
  // }

  // public update(placePublicId: string, termId: number, content: string) {
  //   return this.http
  //     .put<TermApiModel>(`/termsAndPrivacyPolicy/update/${termId}`, {
  //       placePublicId,
  //       content,
  //     })
  //     .pipe(map((result) => plainToClass(TermApiModel, result as object)));
  // }

  // public delete(termId: number) {
  //   return this.http.delete<void>(
  //     `/termsAndPrivacyPolicy/delete/${termId}`
  //   );
  // }
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-register-message-nav-bar',
  templateUrl: './register-message-nav-bar.component.html',
  styleUrls: ['./register-message-nav-bar.component.scss'],
})
export class RegisterMessageNavBarComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}

import { ProductApiModel } from '@core/models/tapp-order/api-model/product/product.api.model';
import { Exclude, Expose, Type } from 'class-transformer';

@Exclude()
export class GroupDiscountApiModel {
  @Expose()
  public value: number;

  @Expose()
  public originalExternalGroupId: number;

  @Expose()
  @Type(() => ProductApiModel)
  public products: ProductApiModel[];
}

import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CompanyApiModel } from '@api/model/user/company.api.model';
import { GusHttpService } from '@core/http/tapp-order/gus/gus.http.service';
import { OrderService } from '@core/services/order.service';

@Component({
  selector: 'app-invoice-form',
  templateUrl: './invoice-form.component.html',
  styleUrls: ['./invoice-form.component.scss'],
})
export class InvoiceFormComponent implements OnInit {
  public invoiceForm: FormGroup;

  @ViewChild('nipInput') private nipInput: ElementRef<HTMLInputElement>;

  @Output() closeDialogEmmiter: EventEmitter<any> = new EventEmitter<any>();
  @Output() invoiceFormEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Input() userForm: boolean = false;
  @Input() companyForm: CompanyApiModel;

  constructor(public orderService: OrderService, private gusHttpService: GusHttpService) {}

  ngOnInit(): void {
    if (this.userForm && this.companyForm) {
      this.createCompanyFormFromModel();
    } else {
      this.createInvoiceForm();
    }
  }

  public createInvoiceForm(): void {
    this.invoiceForm = new FormGroup({
      nip: new FormControl(this.orderService.getInvoiceNip() ? this.orderService.getInvoiceNip() : null, [
        Validators.required,
        Validators.maxLength(15),
      ]),
      name: new FormControl(
        this.orderService.getInvoiceCompanyName() ? this.orderService.getInvoiceCompanyName() : null,
        [Validators.required, Validators.maxLength(100)],
      ),
      city: new FormControl(
        this.orderService.getInvoiceCompanyCity() ? this.orderService.getInvoiceCompanyCity() : null,
        [Validators.required],
      ),
      street: new FormControl(this.orderService.getInvoiceCompanyStreet()),
      streetNo: new FormControl(
        this.orderService.getInvoiceCompanyStreetNo() ? this.orderService.getInvoiceCompanyStreetNo() : null,
        [Validators.required],
      ),
      localNo: new FormControl(this.orderService.getInvoiceCompanyLocalNo()),
      postalCode: new FormControl(
        this.orderService.getInvoicePostalCode() ? this.orderService.getInvoicePostalCode() : null,
        [Validators.required],
      ),
      country: new FormControl(this.orderService.getInvoiceCountry() ? this.orderService.getInvoiceCountry() : null, [
        Validators.required,
      ]),
    });
  }

  private createCompanyFormFromModel(): void {
    this.invoiceForm = new FormGroup({
      nip: new FormControl(this.companyForm.taxNumber ? this.companyForm.taxNumber : null, [Validators.required]),
      name: new FormControl(this.companyForm.companyName ? this.companyForm.companyName : null, [Validators.required]),
      city: new FormControl(this.companyForm.city ? this.companyForm.city : null, [Validators.required]),
      street: new FormControl(this.companyForm.street),
      streetNo: new FormControl(this.companyForm.buildingNo ? this.companyForm.buildingNo : null, [
        Validators.required,
      ]),
      localNo: new FormControl(this.companyForm.localNo),
      postalCode: new FormControl(this.companyForm.zipCode ? this.companyForm.zipCode : null, [Validators.required]),
      country: new FormControl(this.companyForm.country ? this.companyForm.country : null, [Validators.required]),
    });
  }

  public focusOnNip(): void {
    this.nipInput.nativeElement.focus();
  }

  public fillInvoiceDataByTaxNumber() {
    this.gusHttpService.search(this.invoiceForm.controls['nip'].value).subscribe((result) => {
      this.invoiceForm.controls['name'].setValue(result.companyName);
      this.invoiceForm.controls['city'].setValue(result.city);
      this.invoiceForm.controls['street'].setValue(result.street);
      this.invoiceForm.controls['streetNo'].setValue(result.buildingNo);
      this.invoiceForm.controls['localNo'].setValue(result.localNo);
      this.invoiceForm.controls['postalCode'].setValue(result.zipCode);
      this.invoiceForm.controls['country'].setValue(result.country);
    });
  }

  public saveInvoiceData(): void {
    this.invoiceForm.controls['nip'].markAsTouched();
    this.invoiceForm.controls['nip'].markAsDirty();
    this.invoiceForm.controls['nip'].updateValueAndValidity();

    this.invoiceForm.controls['name'].markAsTouched();
    this.invoiceForm.controls['name'].markAsDirty();
    this.invoiceForm.controls['name'].updateValueAndValidity();

    this.invoiceForm.controls['city'].markAsTouched();
    this.invoiceForm.controls['city'].markAsDirty();
    this.invoiceForm.controls['city'].updateValueAndValidity();

    this.invoiceForm.controls['street'].markAsTouched();
    this.invoiceForm.controls['street'].markAsDirty();
    this.invoiceForm.controls['street'].updateValueAndValidity();

    this.invoiceForm.controls['streetNo'].markAsTouched();
    this.invoiceForm.controls['streetNo'].markAsDirty();
    this.invoiceForm.controls['streetNo'].updateValueAndValidity();

    this.invoiceForm.controls['localNo'].markAsTouched();
    this.invoiceForm.controls['localNo'].markAsDirty();
    this.invoiceForm.controls['localNo'].updateValueAndValidity();

    this.invoiceForm.controls['postalCode'].markAsTouched();
    this.invoiceForm.controls['postalCode'].markAsDirty();
    this.invoiceForm.controls['postalCode'].updateValueAndValidity();

    this.invoiceForm.controls['country'].markAsTouched();
    this.invoiceForm.controls['country'].markAsDirty();
    this.invoiceForm.controls['country'].updateValueAndValidity();

    this.invoiceForm.markAsTouched();
    this.invoiceForm.updateValueAndValidity();
    if (this.invoiceForm.valid) {
      if (this.userForm) {
        this.closeDialogEmmiter.emit();
        this.invoiceFormEmitter.emit(this.invoiceForm);
      } else {
        this.orderService.setInvoiceNip(this.invoiceForm.controls['nip'].value);
        this.orderService.setInvoiceCompanyName(this.invoiceForm.controls['name'].value);
        this.orderService.setInvoiceCompanyCity(this.invoiceForm.controls['city'].value);
        this.orderService.setInvoiceCompanyStreet(this.invoiceForm.controls['street'].value);
        this.orderService.setInvoiceCompanyStreetNo(this.invoiceForm.controls['streetNo'].value);
        this.orderService.setInvoiceCompanyLocalNo(this.invoiceForm.controls['localNo'].value);
        this.orderService.setInvoicePostalCode(this.invoiceForm.controls['postalCode'].value);
        this.orderService.setInvoiceCountry(this.invoiceForm.controls['country'].value);
        this.closeDialogEmmiter.emit();
      }
    } else {
      console.warn(this.invoiceForm.controls['nip'].errors);
    }
  }
}

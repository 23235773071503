import { WeekDay } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { PlaceHttpService } from '@core/http/tapp-order/place/place.http.service';
import { OrderTypeEnum } from '@core/models/tapp-order/api-model/order/OrderTypeEnum';
import { WorkingHoursApiModel } from '@core/models/tapp-order/api-model/working-hours/working-hours.api.model';
import { SelectedDeliveryHoursTypeEnum } from '@core/pages/order/enum/selected-delivery-hours-type.enum';
import { OrderService } from '@core/services/order.service';
import { WeekDayType } from '@core/types/week-day.type';
import { Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { DeliveryHourModel } from './model/delivery-hour.model';
import { DeliveryTypeModel } from './model/delivery-type.model';
import { DiscountService } from '@core/pages/discounts/service/discount.service';
import { DeviceDetectorService } from 'src/app/services/device-detector.service';
import { AddressFinderService } from '@core/services/address-finder.service';
import { PlaceService } from '../../../services/place.service';
import { PlaceViewModel } from '@core/models/tapp-order/view-model/place/place.view.model';
import { WorkingHoursViewModel } from '@core/models/tapp-order/view-model/place/working-hours.view.model';

interface DropdownOption {
  value: string;
  label: string;
}

@Component({
  selector: 'app-payment-delivery',
  templateUrl: './payment-delivery.component.html',
  styleUrls: ['./payment-delivery.component.scss'],
  providers: [AddressFinderService],
})
export class PaymentDeliveryComponent implements OnInit, OnDestroy {
  public deliveryTypes: DeliveryTypeModel[] = [];
  public selectedDeliveryType: DeliveryTypeModel = new DeliveryTypeModel();
  public deliveryHours: DeliveryHourModel[] = [];
  public deliveryTypeEnum = OrderTypeEnum;
  public _isDeliveryZone: boolean = null;

  deliveryHoursType: any;
  selectedDeliveryHoursType = null;
  asapDeliveryHours: SelectedDeliveryHoursTypeEnum = SelectedDeliveryHoursTypeEnum.ASAP;
  specifyDeliveryHours: SelectedDeliveryHoursTypeEnum = SelectedDeliveryHoursTypeEnum.SPECIFY;

  public displayDialog: boolean = false;

  @Input() set isDeliveryZone(value: boolean) {
    if (value !== null) {
      this._isDeliveryZone = value;

      this.selectedDeliveryHoursType = this.asapDeliveryHours;
      this.setDeliveryTypes();
      this.setDefaultDeliveryType();
    }
  }

  private _destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private orderService: OrderService,
    private placeHttpService: PlaceHttpService,
    private discountService: DiscountService,
    private deviceService: DeviceDetectorService,
    private addressFinderService: AddressFinderService,
    private placeService: PlaceService,
  ) {
    this._destroy$.next(false);
  }

  ngOnInit(): void {
    this.setSelectedDeliveryHoursType(this.asapDeliveryHours);
    this.initWorkEndTime();
  }

  ngOnDestroy(): void {
    this._destroy$.next(true);
    this._destroy$.complete();
  }

  private setDefaultDeliveryType(): void {
    if (this._isDeliveryZone) {
      switch (this.orderService.getOrderType()) {
        case OrderTypeEnum.delivery:
          this.selectedDeliveryType = this.deliveryTypes[0];

          break;
        case OrderTypeEnum.pick_up:
          this.selectedDeliveryType = this.deliveryTypes[1];
          break;

        default:
          this.selectedDeliveryType = this.deliveryTypes[0];
      }
    } else {
      this.selectedDeliveryType = this.deliveryTypes[0];
    }

    this.orderService.setOrderType(this.selectedDeliveryType.value);
  }

  private setDeliveryTypes(): void {
    this.deliveryTypes = [];

    if (this._isDeliveryZone) {
      this.deliveryTypes.push({
        name: 'tapp-order.delivery-type.delivery',
        value: OrderTypeEnum.delivery,
        price: 12,
      });
    }

    this.deliveryTypes.push({
      name: 'tapp-order.delivery-type.pick-up',
      value: OrderTypeEnum.pick_up,
      price: 0,
    });
  }

  public changeDeliveryType() {
    let changeDeliveryType: boolean = false;
    if (this.selectedDeliveryType.value !== this.orderService.getOrderType()) {
      changeDeliveryType = true;
    }
    this.orderService.setOrderType(this.selectedDeliveryType.value);
    this.addressFinderService.deliveryOrderType = this.selectedDeliveryType.value;
    this.addressFinderService.saveDataToService();

    if (changeDeliveryType) {
      this.discountService.calculate();
      this.deviceService.isMobile.subscribe((isMobile) => {
        if (isMobile) {
          this.displayDialog = !this.discountService.isEqualBeforeAndAfter;
        } else {
          this.displayDialog = this.discountService.checkIsHaveAnyChanges();
        }
      });
      this.initWorkEndTime();
    }
  }

  changeDeliveryHours($event) {
    this.orderService.setDeliveryHours($event.value.value);
  }

  changeDeliveryHoursType($event: any) {
    this.deliveryHoursType = $event.value.value;
  }

  setSelectedDeliveryHoursType(type: string) {
    this.selectedDeliveryHoursType = type;
    this.orderService.setSelectedDeliveryHoursType(type);
  }

  private initWorkEndTime(): void {
    const placeId = sessionStorage.getItem('placeId');

    this.placeService
      .useAsObservable(placeId)
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: (place: PlaceViewModel) => {
          let workingHours: WorkingHoursViewModel[] = [];

          if (this.orderService.getOrderType() == OrderTypeEnum.delivery) {
            workingHours = place.deliveryWorkingHours;
          } else if (this.orderService.getOrderType() == OrderTypeEnum.pick_up) {
            workingHours = place.pickupWorkingHours;
          } else {
            workingHours = place.workingHours;
          }

          this.deliveryHours = this.getDeliveryTimesDropdownOptions(
            workingHours.filter((workingHour) => workingHour.weekDay === this.getCurrentWeekDay()),
          );
          this.orderService.removeDeliveryHours();
          if (this.deliveryHours.length) {
            this.orderService.setDeliveryHours(this.deliveryHours[0].value);
          }
        },
      });
  }

  /**
   * Generates an options array with 30-minute intervals for the PrimeNG p-dropdown component.
   * The options are based on the given delivery times.
   *
   * @param deliveryTimes An array of WorkingHoursApiModel objects representing the delivery times.
   * @returns An array of DropdownOption objects with 30-minute intervals.
   */
  private getDeliveryTimesDropdownOptions(deliveryTimes: WorkingHoursApiModel[]): DropdownOption[] {
    const options: DropdownOption[] = [];
    const currentTime = this.getCurrentTime();

    deliveryTimes.forEach((workingHour) => {
      const start = this.timeToMinutes(workingHour.startTime) > this.timeToMinutes(currentTime) ? this.timeToMinutes(workingHour.startTime) : this.timeToMinutes(currentTime);
      const end = this.timeToMinutes(workingHour.endTime);

      for (let time = start; time <= end; time += 30) {
        const hours = Math.floor(time / 60);
        const minutes = time % 60;
        const timeString = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;

        options.push({ value: timeString, label: timeString });
      }
    });

    // Remove duplicates and sort the options
    const uniqueOptions = Array.from(new Set(options.map((option) => option.value)))
      .sort()
      .map((value) => ({ value, label: value }));

    return uniqueOptions;
  }

  private timeToMinutes(time: string): number {
    const [hours, minutes] = time.split(':').map(Number);
    return hours * 60 + minutes;
  }

  //get current time rounded up to half hour to check available delivery times dropdown options
  private getCurrentTime(): string {
    const now = new Date();
    let hours = now.getHours();
    let minutes = now.getMinutes();

    if (minutes > 0 && minutes <= 30) {
      minutes = 30;
    } else if (minutes > 30) {
      minutes = 0;
      hours = (hours + 1) % 24; // Increment hour and handle the 24-hour wrap-around
    }

    const formattedHours = hours.toString().padStart(2, '0');
    const formattedMinutes = minutes.toString().padStart(2, '0');

    return `${formattedHours}:${formattedMinutes}`;
  }

  /**
   * Returns the day of the week as a string for the given date.
   *
   * @param date The date to get the day of the week for (defaults to the current date).
   * @returns The day of the week as a string, or null if the input date is invalid.
   */
  private getCurrentWeekDay(): WeekDayType | null {
    const day = new Date().getDay();
    switch (day) {
      case WeekDay.Monday:
        return 'monday';
      case WeekDay.Tuesday:
        return 'tuesday';
      case WeekDay.Wednesday:
        return 'wednesday';
      case WeekDay.Thursday:
        return 'thursday';
      case WeekDay.Friday:
        return 'friday';
      case WeekDay.Saturday:
        return 'saturday';
      case WeekDay.Sunday:
        return 'sunday';
      default:
        return null;
    }
  }

  public close() {
    this.displayDialog = false;
  }
}

import { AbstractViewModel } from '../../../abstract.view.model';
import { NutritionValueApiModel } from '@core/models/tapp-order/api-model/product/nutrition-value.api.model';
import { NutritionViewModel } from '@core/models/tapp-order/view-model/product/nutrition.view.model';

export class NutritionValueViewModel extends AbstractViewModel<NutritionValueApiModel> {
  public value: string;
  public nutrition: NutritionViewModel = null;

  constructor(public apiModel: NutritionValueApiModel = new NutritionValueApiModel()) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.value = this.apiModel.value;
    this.nutrition = this.apiModel.nutrition ? new NutritionViewModel(this.apiModel.nutrition) : null;
  }

  toApiModel(): NutritionValueApiModel {
    return undefined;
  }
}

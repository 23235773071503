<ul class="list-of-place-details">
  <ng-container *ngFor="let place of places">
    <div class="place">
      <div class="row">
        <div class="col d-flex align-items-center flex-wrap">
          <div class="address">
            <span class="street">{{ place.street }}</span>
            <span class="buildingNo">{{ place.buildingNo }}</span>
            <span class="localNo" *ngIf="place.localNo">/{{ place.localNo }}</span>
          </div>
          <div class="phone" *ngIf="place.phone">
            <i class="ic ic-phone primary-color"></i>
            <a href="tel:{{ place.phone }}" class="phone-number">{{ place.phone }}</a>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12" *ngIf="place.workingHours && place.workingHours.length">
          <p class="main-label">
            {{ 'tapp-order.place-component.opening-hours-label' | translate }}
          </p>
          <ul class="list-of-days">
            <ng-container *ngFor="let day of place.workingHours; let i = index">
              <li class="day">
                <span class="range">{{ 'app.weekDays.' + day.weekDay | translate }}</span
                >:
                <span class="hours">{{ day.workingHourString }}</span>
              </li>
            </ng-container>
          </ul>
        </div>

        <div class="col-12">
          <div class="row mt-3">
            <div class="col-6">
              <ng-container *ngIf="place.company">
                <div class="company-data w-100">
                  <div>{{ place.company.name }}</div>
                  <div>
                    {{ 'tapp-order.place-component.taxNumber' | translate }}:
                    {{ place.company.taxIdNumber }}
                  </div>
                  <div>{{ place.company.getAddress() }}</div>
                </div>
              </ng-container>
            </div>
            <div class="col-6 d-flex flex-row align-items-end align-content-end" *ngIf="place.publicId">
              <button
                pButton
                class="btn-show-menu ml-auto"
                type="button"
                label="{{ 'tapp-order.place-component.check-out-menu-button' | translate }}"
                (click)="checkPosStatus(place)"
              ></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</ul>

<app-unexpected-issue-dialog
  [visible]="displayPosStatusDialog"
  [place]="displayPosStatusDialogPlace"
  (visibleChange)="!$event && closeDialog()"
></app-unexpected-issue-dialog>

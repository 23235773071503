import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AuthService } from '@core/services/auth.service';
import { filter, first, map, startWith, takeWhile, tap } from 'rxjs/operators';
import { concat, Observable, of, timer } from 'rxjs';
import { ActivateEmailModalService } from '@core/services/activate-email-modal.service';
import { PlaceService } from '../../services/place.service';

@Component({
  selector: 'app-verify-email-modal',
  templateUrl: './verify-email-modal.component.html',
  styleUrls: ['./verify-email-modal.component.scss'],
})
export class VerifyEmailModalComponent implements OnInit {
  public email: string;
  public resendLoading: boolean = false;
  public isResent: string = false.toString();
  public secondsRemaining$: Observable<any>;

  constructor(
    public dialogRef: MatDialogRef<VerifyEmailModalComponent>,
    public authService: AuthService,
    public activateEmailModalService: ActivateEmailModalService,
    public placeService: PlaceService,
  ) {
    this.secondsRemaining$ = timer(0, 1000).pipe(
      map((n) => (60 - n) * 1000),
      takeWhile((n) => n >= 0),
    );
  }

  ngOnInit(): void {
    this.email = this.authService.getEmail();
    this.isResent = this.authService.getIsResented();
  }

  resend(): void {
    this.resendLoading = true;
    this.authService
      .resendMail(this.placeService.getPlaceId() ?? null)
      .pipe(first())
      .subscribe(
        () => {
          this.authService.setIsResent(true.toString());
          this.resendLoading = false;
          this.isResent = this.authService.getIsResented();
        },
        () => {
          this.resendLoading = false;
        },
        () => {},
      );
  }

  public closeDialog(): void {
    this.authService.logout();
    this.activateEmailModalService.closeDialog();
  }
}

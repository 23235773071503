import { Component, OnInit } from '@angular/core';
import { AuthRestService } from '@api/rest/auth/auth.rest.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { PasswordChangeModalComponent } from '@shared/password-change-modal/password-change-modal.component';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss'],
})
export class PasswordResetComponent implements OnInit {
  constructor(
    private authRestService: AuthRestService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      const passwordResetToken = params.resetPasswordToken;
      this.dialog.open(PasswordChangeModalComponent, {
        panelClass: 'password-change-modal-container',
        data: {
          passwordResetToken,
        },
      });
      this.router.navigate(['']);
    });
  }
}

import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { formatCurrency, getCurrencySymbol } from '@angular/common';
import { environment } from '@env/environment';

@Pipe({
  name: 'money',
})
export class MoneyPipe implements PipeTransform {
  constructor(@Inject(LOCALE_ID) private _localeId: string) {}

  transform(value: number, currencyCode: string = environment.globalize.currency, digitsInfo?: string): string | null {
    return formatCurrency(
      value,
      this._localeId,
      getCurrencySymbol(currencyCode, 'wide') + ' ',
      currencyCode,
      digitsInfo,
    );
  }
}

<!-- Navigation by product groups -->
<app-product-group
  id="app-navbar"
  class="navigator"
  [groups]="menu.groups"
  [page]="'discounts'"
  [qrMenuMode]="qrMenuMode"
  *ngIf="menu"
></app-product-group>

<div id="products-container" class="content">
  <div class="navigator-empty-area" *ngIf="menu"></div>
  <ng-container *ngTemplateOutlet="closeAlert"></ng-container>

  <div class="group">
    <span class="group__title">
      <div class="group__title__background-text">
        {{ 'tapp-order.pages.discount.common.discounts' | translate | uppercase }}
      </div>
      <div class="group__title__front">
        <h2 class="group__title__front__text">
          {{ 'tapp-order.pages.discount.common.discounts' | translate | uppercase }}
        </h2>
      </div>
    </span>
    <div class="group__products">
      <app-discount-card
        *ngFor="let discount of discounts"
        [discount]="discount"
        (onAdd)="handleAddDiscount(discount)"
        [buttonVisible]="isOpen && !placeIsPreviewOnly && !qrMenuMode"
      ></app-discount-card>
    </div>
  </div>

  <div class="buttom-button-empty-area" *ngIf="menu"></div>
</div>

<!-- Close alert -->
<ng-template #closeAlert>
  <div class="close-alert" *ngIf="!isOpen">
    <span *ngIf="!nextWorkingHours">{{ 'products.local-closed-message' | translate }}</span>
    <span *ngIf="nextWorkingHours">
      {{ 'products.local-closed-message-2' | translate }}
      <b>
        {{ 'app.weekDays.' + nextWorkingHours.weekDay | translate }}
        {{ nextWorkingHours.startTime }}</b
      >.
    </span>
    <span> {{ 'products.local-closed-message-3' | translate }}</span>
  </div>
</ng-template>

<!-- Basket -->
<app-sidebar
  *ngIf="place && (place.onlineOrderActive || orderService.tableId) && !qrMenuMode"
  class="basket"
  [basketItemsAmount]="basketService.calculateAllItemsInBasket()"
  [basketItems]="basketService.basketItems$ | async"
  [basketItemsPrice]="basketService.calculatePrice()"
  [nextWorkingHours]="nextWorkingHours"
  [isOpen]="isOpen"
  [productsCrossSell]="productsCrossSell.length"
  (openCrossSellDialogEvent)="showCrossSellProducts()"
></app-sidebar>

<!-- Button show basket -->
<app-bottom-button
  *ngIf="
    bottomNotificationComponent.active == false &&
    placeIsPreviewOnly == false &&
    !qrMenuMode &&
    filteredMenu &&
    isOpen &&
    hasMenu
  "
  class="button-show-basket"
  [centerText]="generateButtonText()[0]"
  [rightText]="generateButtonText()[1] | currency: 'PLN':'zł':'1.2-2':'pl-PL'"
  [display]="2"
  (click)="navigateToPayment()"
></app-bottom-button>

<!-- Dialog configurator -->
<app-discount-dialog-configurator #appDiscountDialogConfigurator></app-discount-dialog-configurator>

<!-- Dialog cross-sell -->
<app-dialog [(visible)]="displayCrossSellDialog" [closable]="false" (onHide)="closeCrossSellProducts()">
  <ng-template #header>
    <div class="dialog-cross-sell__header">{{ 'tapp-order.pages.product.dialog.cross-sell.header' | translate }}</div>
  </ng-template>

  <div class="dialog-cross-sell__content">
    <app-product-item
      *ngFor="let product of productsCrossSell"
      [data]="product"
      [buttonVisible]="true"
      (onAdd)="handleAddProductToBasket(product)"
    ></app-product-item>
  </div>

  <ng-template #footer>
    <div class="dialog-cross-sell__footer">
      <button
        pButton
        class="p-button"
        type="button"
        [label]="'tapp-order.pages.product.dialog.cross-sell.button-label' | translate"
        (click)="displayCrossSellDialog = false"
      ></button>
    </div>
  </ng-template>
</app-dialog>

import { ConditionApiModel } from '@api/model/discount/condition.api.model';
import { AbstractViewModel } from '@core/models/abstract.view.model';
import { GroupDiscountViewModel } from './group-discount.view.model';
import { ProductDiscountViewModel } from './product-discount.view.model';

export class ConditionViewModel extends AbstractViewModel<ConditionApiModel> {
  public id: number = null;
  public type: string = null;
  public monday: string = null;
  public wednesday: string = null;
  public thursday: string = null;
  public friday: string = null;
  public saturday: string = null;
  public sunday: string = null;
  public hourFrom: string = null;
  public hourTo: string = null;
  public selectAll: boolean = false;
  public value: string = null;
  public anyItems: boolean = false;
  public pizzaSizeIds: number[] = [];
  public pizzaDoughIds: number[] = [];
  public deliveryTypes: string[] = [];
  public products: ProductDiscountViewModel[] = [];
  public groups: GroupDiscountViewModel[] = [];

  public constructor(protected apiModel: ConditionApiModel = new ConditionApiModel()) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.id = this.apiModel.id || null;
    this.type = this.apiModel.type || null;
    this.monday = this.apiModel.monday || null;
    this.wednesday = this.apiModel.wednesday || null;
    this.thursday = this.apiModel.thursday || null;
    this.friday = this.apiModel.friday || null;
    this.saturday = this.apiModel.saturday || null;
    this.sunday = this.apiModel.sunday || null;
    this.hourFrom = this.apiModel.hourFrom || null;
    this.hourTo = this.apiModel.hourTo || null;
    this.selectAll = this.apiModel.selectAll || false;
    this.value = this.apiModel.value || null;
    this.anyItems = this.apiModel.anyItems || false;
    this.pizzaSizeIds = this.apiModel.pizzaSizeIds || [];
    this.pizzaDoughIds = this.apiModel.pizzaDoughIds || [];
    this.deliveryTypes = this.apiModel.deliveryTypes || [];
    this.products = this.apiModel.products
      ? this.apiModel.products.map((value) => new ProductDiscountViewModel(value))
      : [];
    this.groups = this.apiModel.groups ? this.apiModel.groups.map((value) => new GroupDiscountViewModel(value)) : [];
  }

  public apply(value: any): ConditionViewModel {
    Object.assign(this, value);
    return this;
  }

  toApiModel(): ConditionApiModel {
    return Object.assign(this.apiModel, {
      id: this.id,
      type: this.type,
      monday: this.monday,
      wednesday: this.wednesday,
      thursday: this.thursday,
      friday: this.friday,
      saturday: this.saturday,
      sunday: this.sunday,
      hourFrom: this.hourFrom,
      hourTo: this.hourTo,
      selectAll: this.selectAll,
      value: this.value,
      anyItems: this.anyItems,
      pizzaSizeIds: this.pizzaSizeIds.map((i) => i),
      pizzaDoughsId: this.pizzaDoughIds.map((i) => i),
      deliveryTypes: this.deliveryTypes.map((i) => i),
      products: this.products.map((i) => i.toApiModel()),
      groups: this.groups.map((i) => i.toApiModel()),
    });
  }
}

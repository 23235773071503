import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CalculateDiscountRequestApiModel } from '@api/model/discount/calculate-discount-request.api.model';
import { CalculateDiscountResponseApiModel } from '@api/model/discount/calculate-discount-response.api.model';
import { DiscountApiModel } from '@api/model/discount/discount.api.model';
import { BasketItemViewModel } from '@core/models/tapp-order/view-model/basketItem/basket-item.view.model';
import { OrderService } from '@core/services/order.service';
import { plainToClass } from 'class-transformer';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { appUtils } from 'src/app/utils/app.utils';
import { TermsAndConditionsItemApiModel } from '@core/models/tapp-order/api-model/terms-and-conditions/terms-and-conditions-item.api.model';

@Injectable({
  providedIn: 'root',
})
export class DiscountRestService {
  constructor(private http: HttpClient, private orderService: OrderService) {}

  public findAll(orderType: string): Observable<DiscountApiModel[]> {
    let publicId = sessionStorage.getItem('placeId');

    return this.http
      .get<DiscountApiModel[]>('/discount/' + publicId + '?orderType=' + orderType.toLowerCase())
      .pipe(map((result) => plainToClass(DiscountApiModel, result as object[])));
  }

  public findById(id: string, orderType: string): Observable<DiscountApiModel> {
    let publicId = sessionStorage.getItem('placeId');

    return this.http
      .get<DiscountApiModel[]>('/discount/' + publicId + '/' + id + '?orderType=' + orderType.toLowerCase())
      .pipe(map((result) => plainToClass(DiscountApiModel, result as object)));
  }

  public calculate(basketItems: BasketItemViewModel[]): Observable<CalculateDiscountResponseApiModel> {
    const publicId = sessionStorage.getItem('placeId');

    const preparedOrderRequest: CalculateDiscountRequestApiModel = new CalculateDiscountRequestApiModel();

    preparedOrderRequest.type = this.orderService.getOrderType();
    preparedOrderRequest.orderItems = appUtils
      .transformBasketItemViewModelsToCreateOrderItemRequestApiModels(basketItems)
      .map((item) => ({ ...item, discountId: null }));

    return this.http
      .post<CalculateDiscountResponseApiModel[]>('/discount/' + publicId + '/calculate', preparedOrderRequest)
      .pipe(map((result) => plainToClass(CalculateDiscountResponseApiModel, result as object)));
  }

  public calculateBasket(
    basketItems: BasketItemViewModel[],
    promoCode: string,
  ): Observable<CalculateDiscountResponseApiModel> {
    const publicId = sessionStorage.getItem('placeId');

    const preparedOrderRequest: CalculateDiscountRequestApiModel = new CalculateDiscountRequestApiModel();

    preparedOrderRequest.type = this.orderService.getOrderType();
    preparedOrderRequest.orderItems = appUtils
      .transformBasketItemViewModelsToCreateOrderItemRequestApiModels(basketItems)
      .map((item) => ({ ...item, discountId: null }));
    preparedOrderRequest.promoCode = promoCode;

    return this.http
      .post<CalculateDiscountResponseApiModel[]>('/discount/' + publicId + '/calculateBasket', preparedOrderRequest)
      .pipe(map((result) => plainToClass(CalculateDiscountResponseApiModel, result as object)));
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { plainToClass, plainToClassFromExist } from 'class-transformer';
import { map } from 'rxjs/operators';
import { Cacheable, LocalStorageStrategy } from 'ts-cacheable';
import { GusApiModel } from '@core/models/tapp-order/api-model/gus/gus.api.model';

const menuCacheBuster$ = new Subject<void>();

@Injectable({
  providedIn: 'root',
})
export class GusHttpService {
  constructor(private http: HttpClient) {}

  @Cacheable({
    storageStrategy: LocalStorageStrategy,
    cacheBusterObserver: menuCacheBuster$,
  })
  search(taxNumber): Observable<GusApiModel> {
    return this.http
      .get<GusApiModel>('/core/taxNumber/search/' + taxNumber)
      .pipe(map((result) => plainToClass(GusApiModel, result as object)));
  }
}

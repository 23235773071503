import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { environment } from '@env/environment';
import { Observable, Subscriber, Subscription, of, Subject } from 'rxjs';
import { first, tap } from 'rxjs/operators';
import { BaseDataProvider } from '../core/data-providers/base.data-provider';
import { PlaceViewModel } from '../core/models/tapp-order/view-model/place/place.view.model';
import { BasketService } from './basket.service';
import { NavigationService } from './navigation.service';

@Injectable({
  providedIn: 'root',
})
export class PlaceService {
  public place: PlaceViewModel;
  public places: PlaceViewModel[] = [];
  public isOnline: boolean = true;

  public updatePlaceSubject$ = new Subject<void>();

  constructor(
    private title: Title,
    private navigationService: NavigationService,
    private baseDataProvider: BaseDataProvider,
    private basketService: BasketService,
  ) {
    this.checkConnection();
  }

  /**
   * @deprecated Templrary solution for push notifications; Can be refactored after 07.08.2023
   */
  private trySaveSessionStorage(): void {
    const placeId = sessionStorage.getItem('placeId');

    if (placeId) {
      localStorage.setItem('placeId', placeId);
    } else {
      localStorage.removeItem('placeId');
    }
  }

  public init(): void {
    const sessionStoragePlaceId = this.getPlaceId();

    if (sessionStoragePlaceId) {
      this.use(sessionStoragePlaceId);
    } else {
      const localStoragePlaceId = localStorage.getItem('placeId');

      if (localStoragePlaceId) {
        this.use(localStoragePlaceId);
      }
    }

    /* Templrary solution for push notifications; Can be refactored after 07.08.2023 */
    setInterval(() => {
      this.trySaveSessionStorage();
    }, 500);
  }

  public use(placeId: string): Subscription {
    return this.useAsObservable(placeId).subscribe();
  }

  public useAsObservable(placeId: string): Observable<PlaceViewModel> {
    const currentPlaceId = this.getPlaceId();

    if (currentPlaceId === placeId && this.place?.publicId === placeId) {
      this.title.setTitle(`${this.place.name} - ${environment.title}`);
      return of(this.place);
    }

    sessionStorage.setItem('placeId', placeId);
    this.navigationService.rewriteHistory([]);
    this.updatePlaceSubject$.next();

    return this.getPlace({ bypassCache: true })
      .pipe(tap((place) => this.title.setTitle(`${place.name} - ${environment.title}`)))
      .pipe(first());
  }

  public getPlaceId(): string {
    return sessionStorage.getItem('placeId');
  }

  public getPlace(opts?: { bypassCache?: boolean }): Observable<PlaceViewModel> {
    return new Observable((observer: Subscriber<PlaceViewModel>) => {
      if (this.place && this.place.publicId == this.getPlaceId() && !opts?.bypassCache) {
        if (!this.isOnline) {
          this.place.isPreviewOnly = true;
        }
        if (this.place.isPreviewOnly) {
          this.basketService.removeBasket();
        }
        observer.next(this.place);
      } else {
        if (this.getPlaceId()) {
          this.baseDataProvider.getPlace(this.getPlaceId(), { bypassCache: opts?.bypassCache }).subscribe(
            (place) => {
              this.place = place;
              if (!this.isOnline) {
                this.place.isPreviewOnly = true;
              }
              if (this.place.isPreviewOnly) {
                this.basketService.removeBasket();
              }
              observer.next(this.place);
            },
            (error) => {
              observer.error(error ?? 'Place not found');
            },
          );
        } else {
          observer.error('PlaceId is not set');
        }
      }
    });
  }

  public getPlaces(): Observable<PlaceViewModel[]> {
    return this.baseDataProvider.getPlaces().pipe(
      tap((places: PlaceViewModel[]) => {
        this.places = places;
      }),
    );
  }

  public getIsPreviewOnly(): Observable<boolean> {
    return new Observable((observer: Subscriber<boolean>) => {
      observer.next(this.place.isPreviewOnly);
    });
  }

  private checkConnection() {
    //TODO: warto sprawdzać w inny sposób czy jest połączenie internetowe
    //navigator.onLine nie działa poprawnie w PWA na iOS
    // try {
    //   var httpRequest = new XMLHttpRequest();
    //   httpRequest.open('GET', environment.ctlUrl, false);
    //   httpRequest.send();
    // } catch (error) {
    //   this.isOnline = false;
    // }
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { BasketService } from '../../../../services/basket.service';
import { NewOrderService } from '../../../http/tapp-order/order/new-order.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-order-summary',
  templateUrl: './order-summary.component.html',
  styleUrls: ['./order-summary.component.scss'],
})
export class OrderSummaryComponent implements OnInit {
  @Input() buttonText: string = 'Continue';
  @Input() buttonLink: string[];
  public tableId;
  public tableName;
  public roomName;

  constructor(public basketService: BasketService, private newOrderService: NewOrderService, private router: Router) {}

  ngOnInit(): void {
    this.tableId = sessionStorage.getItem('tableId');
    this.tableName = sessionStorage.getItem('tableName');
    this.roomName = sessionStorage.getItem('roomName');
  }

  createOrder() {
    this.router.navigate(['/payments']);
    // this.newOrderService
    //   .create(this.basketService.basketItems)
    //   .subscribe((newOrderCreateResponse) => {
    //     sessionStorage.setItem(
    //       'NewOrderApiModel',
    //       JSON.stringify(newOrderCreateResponse)
    //     );
    //     window.location.href =
    //       newOrderCreateResponse.orderPayment.providerPaymentUrl;
    //   },
    //     (error) => {
    //     this.router.navigate(['error', 'payu']);
    //     });
  }
}

import { AbstractViewModel } from '../../../abstract.view.model';
import { LinkApiModel } from '@core/models/tapp-order/api-model/place/link.api.model';

export class LinkViewModel extends AbstractViewModel<LinkApiModel> {
  public name: string;
  public url: string;

  constructor(protected apiModel: LinkApiModel = new LinkApiModel()) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.name = this.apiModel.name;
    this.url = this.apiModel.url;
  }

  toApiModel(): LinkApiModel {
    return undefined;
  }

  toString() {
    return this.name;
  }
}

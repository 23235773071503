import { Exclude, Expose, Type } from 'class-transformer';
import { DateTime } from 'luxon';
import { ConditionApiModel } from './condition.api.model';

@Exclude()
export class DiscountApiModel {
  @Expose()
  public id: number;

  @Expose()
  public name: string;

  @Expose()
  public description: string;

  @Expose()
  public dateFrom: DateTime;

  @Expose()
  public dateTo: DateTime;

  @Expose()
  public active: boolean;

  @Expose()
  public imageUrl: string;

  @Expose()
  @Type(() => ConditionApiModel)
  public conditions: ConditionApiModel[];
}

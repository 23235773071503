<div class="area">
  <div>
    <p>
      {{ article.description }}
    </p>
  </div>
  <div *ngIf="allergens">
    <h5 class="group-label">{{ 'labels.allergens' | translate }}</h5>
    <p>
      {{ allergens }}
    </p>
  </div>
  <div *ngIf="nutritionals">
    <h5 class="group-label">{{ 'labels.nutritionals' | translate }}</h5>
    <p>
      {{ nutritionals }}
    </p>
  </div>

  <div class="hrline" *ngIf="article.subArticleList.length > 0 || article.modifierArticleList.length > 0"></div>

  <div
    class="article-area pt-3"
    #articleArea
    [ngClass]="{
      'pb-4': article.subArticleList.length > 0 || article.modifierArticleList.length > 0
    }"
  >
    <form [formGroup]="form">
      <div
        class="modifier-area"
        id="{{ subArticle.id }}"
        *ngFor="let subArticle of article.subArticleList; let i = index"
      >
        <div class="modifier">
          <h5 class="group-label" *ngIf="subArticle.replacementList.length === 0; else replacementGroup">
            {{ subArticle.name }}
          </h5>
          <ng-template #replacementGroup>
            <mat-radio-group class="radio-group" [formControl]="getFormControl(subArticle.id, 0)">
              <mat-radio-button
                (change)="onSelectSubArticleReplacer(subArticle, subArticle)"
                [name]="subArticle.id"
                [id]="getSubArticleText(subArticle, subArticle)"
                [value]="getSubArticleText(subArticle, subArticle)"
                [checked]="true"
                class="rcb"
                color="primary"
              >
                <span class="row label">
                  <span class="col-12 modifier-item-name">
                    {{ subArticle.name }}
                  </span>
                  <!-- <span *ngIf="subArticle.description" class="col-12 description">
                                {{ subArticle.description }}
                            </span> -->
                </span>
              </mat-radio-button>

              <mat-radio-button
                *ngFor="let subArticleReplacer of subArticle.replacementList; let ind = index"
                (change)="onSelectSubArticleReplacer(subArticle, subArticleReplacer)"
                [name]="subArticle.id"
                [id]="getSubArticleText(subArticle, subArticleReplacer)"
                [value]="getSubArticleText(subArticle, subArticleReplacer)"
                class="rcb"
                color="primary"
              >
                <span class="row label">
                  <span class="col-12 modifier-item-name">
                    {{ subArticleReplacer.name }}
                  </span>
                  <!-- <span *ngIf="subArticleReplacer.description" class="col-12  description">
                                {{ subArticleReplacer.description }}
                            </span> -->
                </span>
              </mat-radio-button>
            </mat-radio-group>
          </ng-template>
        </div>

        <!-- <p *ngIf="formSubmitAttempt === true && form.get(subArticle.id)?.errors?.required" class="with-errors" err="error.10030">
            Select product from the list.
        </p> -->

        <hr *ngIf="i < article.subArticleList.length - 1 || article.modifierArticleList.length > 0" />
      </div>

      <div
        class="modifier-area"
        id="{{ modifier.id }}"
        *ngFor="let modifier of article.modifierArticleList; let i = index"
      >
        <ng-container *ngIf="isSomethingToDisplay(modifier)">
          <div class="modifier">
            <div
              class="text-left glabel"
              [class.has-error]="formSubmitAttempt === true && form.get(modifier.id)?.errors?.required"
            >
              <h5 class="d-inline-block group-label">{{ modifier.name }}</h5>
              <span
                class="required"
                *ngIf="
                  modifier.isRequired ||
                  ((modifier.multiChoiceMin > 0 || modifier.multiChoiceMax > 0) && modifier.multiChoice)
                "
              >
                (
                <span
                  *ngIf="modifier.isRequired"
                  [class.error]="formSubmitAttempt === true && form.get(modifier.id)?.errors?.required"
                >
                  required
                </span>
                <span *ngIf="modifier.multiChoice">
                  <span *ngIf="modifier.multiChoiceMin > 0">
                    <span *ngIf="modifier.isRequired">&nbsp;</span>
                    min
                    {{ modifier.multiChoiceMin }}
                  </span>
                  <span *ngIf="modifier.multiChoiceMax > 0">
                    <span *ngIf="modifier.isRequired">&nbsp;</span>
                    max
                    {{ modifier.multiChoiceMax }}
                  </span>
                </span>
                )
              </span>
            </div>

            <ng-container *ngIf="!modifier.multiChoice">
              <mat-radio-group class="radio-group" [formControl]="getFormControl(modifier.id, 0)">
                <ng-container *ngFor="let modifierItem of modifier.itemCollection; let ind = index">
                  <ng-container *ngIf="couldBePrinted(modifierItem.relatedModifiers)">
                    <mat-radio-button
                      (change)="onSelectModifierItem(modifierItem, modifier)"
                      [name]="modifier.id"
                      [id]="modifierItem.id"
                      [value]="modifierItem.id"
                      class="rcb"
                      color="primary"
                    >
                      <span class="row justify-content-end label">
                        <span class="col-9 modifier-item-name">
                          {{ modifierItem.name }}
                          <span *ngIf="modifierItem.quantity > 1" class="pl-1 quantity"
                            >(x{{ modifierItem.quantity }})
                          </span>
                          <!-- <span class="description d-block">
                                                {{ modifierItem.description }}
                                            </span> -->
                        </span>
                        <span class="col-3 text-right" *ngIf="modifierItem.price">
                          {{ currency }}
                          {{ modifierItem.price | number: '1.2-2' }}
                        </span>
                      </span>
                    </mat-radio-button>
                  </ng-container>
                </ng-container>
              </mat-radio-group>
            </ng-container>

            <ng-container *ngIf="modifier.multiChoice">
              <div *ngFor="let modifierItem of modifier.itemCollection; let ind = index">
                <ng-container *ngIf="couldBePrinted(modifierItem.relatedModifiers)">
                  <div [formArrayName]="modifier.id">
                    <mat-checkbox
                      [formControl]="getFormControl(modifier.id, ind)"
                      [name]="modifier.id"
                      [id]="modifierItem.id"
                      [value]="modifierItem.id"
                      (change)="onSelectModifierItem(modifierItem, modifier)"
                      class="rcb"
                      color="primary"
                    >
                      <span class="row justify-content-end label">
                        <span class="col-9 modifier-item-name">
                          {{ modifierItem.name }}
                          <span *ngIf="modifierItem.quantity > 1" class="pl-1 quantity"
                            >(x{{ modifierItem.quantity }})
                          </span>
                          <!-- <span class="description d-block">
                                        {{ modifierItem.description }}
                                    </span> -->
                        </span>
                        <span class="col-3 text-right" *ngIf="modifierItem.price">
                          {{ currency }}
                          {{ modifierItem.price | number: '1.2-2' }}
                        </span>
                      </span>
                    </mat-checkbox>

                    <!-- <div class="description pl-5">
                                {{ modifierItem.description }}
                            </div> -->
                  </div>
                </ng-container>
              </div>
            </ng-container>
          </div>

          <!-- <p *ngIf="formSubmitAttempt === true && form.get(modifier.id)?.errors?.required" class="with-errors" err="error.10030">
                Select product from the list.
            </p> -->

          <hr *ngIf="i < article.modifierArticleList.length - 1" />
        </ng-container>
      </div>
    </form>
  </div>
</div>

<div class="">
  <div class="row">
    <div class="col-12 text-center">
      <div class="row selector-counter">
        <div class="offset-sm-3 offset-3 col-2 px-0 col-decrement" (click)="onDecrement()">
          <span class="increment" [ngClass]="{ disabled: this.selectedQuantity <= 1 }"
            ><i class="fa fa-minus"></i
          ></span>
        </div>
        <div class="col-2 sel-amount">
          <span class="count font-weight-bold">{{ selectedQuantity }}</span>
        </div>
        <div class="col-2" (click)="onIncrement()">
          <span class="increment"><i class="fa fa-plus"></i></span>
        </div>
      </div>
    </div>
    <div class="col-12 text-center text-md-right pt-3">
      <button class="action-button mx-0" mat-raised-button color="primary" (click)="onClickedAdd()">
        <div class="row justify-content-end">
          <div class="col-4">
            {{ 'buttons.add-to-order' | translate }}
          </div>
          <div class="col-4 title-price">
            {{ totalValue * selectedQuantity | money }}
          </div>
        </div>
      </button>
    </div>
  </div>
</div>

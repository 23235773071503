import { Exclude, Expose } from 'class-transformer';

@Exclude()
export class DeliveryAddressApiModel {
  @Expose()
  public street: string;

  @Expose()
  public buildingNo: string;

  @Expose()
  public localNo: string;

  @Expose()
  public zipCode: string;

  @Expose()
  public city: string;
}

import {
  CateringAggregationArticleModifierItemViewModel,
  CateringAggregationArticleModifierViewModel,
  CateringAggregationArticleViewModel,
} from 'src/app/core/models/catering-aggregation.view.model';

export class ArticlePriceCalculator {
  private article: CateringAggregationArticleViewModel;

  public constructor(article: CateringAggregationArticleViewModel) {
    this.article = article;
  }

  private _selectedSubArticleList: Map<string, CateringAggregationArticleViewModel> = new Map<
    string,
    CateringAggregationArticleViewModel
  >();

  /**
   * The map which contains the selected modifier items
   *
   * The key points to ID of modifier
   */
  private _selectedModifierItemList: Map<string, Array<CateringAggregationArticleModifierItemViewModel>> = new Map<
    string,
    Array<CateringAggregationArticleModifierItemViewModel>
  >();

  get selectedModifierItemList(): Map<string, Array<CateringAggregationArticleModifierItemViewModel>> {
    return this._selectedModifierItemList;
  }

  /**
   * Adds modifier item into container
   */
  public addModifierItem(
    modifierItem: CateringAggregationArticleModifierItemViewModel,
    modifier: CateringAggregationArticleModifierViewModel,
  ): ArticlePriceCalculator {
    if (this._selectedModifierItemList.has(modifier.id)) {
      if (this.isRadioButton(modifier)) {
        this._selectedModifierItemList.set(
          modifier.id,
          this.isChecked(modifierItem, modifier) && !modifier.isRequired ? [] : [modifierItem],
        );
      } else {
        if (this.isChecked(modifierItem, modifier)) {
          this._selectedModifierItemList.set(
            modifier.id,
            this._selectedModifierItemList.get(modifier.id).filter((element) => {
              return element.id !== modifierItem.id;
            }),
          );
        } else {
          if (this._selectedModifierItemList.get(modifier.id).length < modifier.multiChoiceMax) {
            this._selectedModifierItemList.get(modifier.id).push(modifierItem);
          }
        }
      }
    } else {
      this._selectedModifierItemList.set(modifier.id, [modifierItem]);
    }
    return this;
  }

  /**
   * Adds sub article replacer item into container
   */
  public addSubArticleReplacer(
    modifierItem: CateringAggregationArticleViewModel,
    modifier: CateringAggregationArticleViewModel,
  ): ArticlePriceCalculator {
    this._selectedSubArticleList.set(modifier.id, modifierItem);

    return this;
  }

  /**
   * Drops modifier item from container
   */
  public dropModifierItem(
    modifierItem: CateringAggregationArticleModifierItemViewModel,
    modifier: CateringAggregationArticleModifierViewModel,
  ): ArticlePriceCalculator {
    if (!this._selectedModifierItemList.has(modifier.id)) {
      return this;
    }

    this._selectedModifierItemList.set(
      modifier.id,
      this._selectedModifierItemList.get(modifier.id).filter((element) => {
        return element.id !== modifierItem.id;
      }),
    );

    return this;
  }

  public resetModifierItems(modifier: CateringAggregationArticleModifierViewModel): ArticlePriceCalculator {
    if (!this._selectedModifierItemList.has(modifier.id)) {
      return this;
    }

    this._selectedModifierItemList.set(modifier.id, new Array<CateringAggregationArticleModifierItemViewModel>());

    return this;
  }

  /**
   * Gets price to pay (includes article price, modifiers and related modifiers)
   */
  public getPriceToPay(): number {
    let priceToPay: number = this.article.price;

    this._selectedModifierItemList.forEach((list, modifierId) => {
      list.forEach((modifierItem) => {
        if (!modifierItem.isNoTypeModifierItem()) {
          priceToPay += modifierItem.price;
        }
      });
    });

    return priceToPay;
  }

  private isChecked(
    modifierItem: CateringAggregationArticleModifierItemViewModel,
    modifier: CateringAggregationArticleModifierViewModel,
  ): boolean {
    return (
      this._selectedModifierItemList.get(modifier.id).filter((element) => {
        return element.id === modifierItem.id;
      }).length > 0
    );
  }

  private isRadioButton(modifier: CateringAggregationArticleModifierViewModel): boolean {
    return modifier.multiChoice === false;
  }
}

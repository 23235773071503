export class FbItemSubArticleApiModel {
  articleId: string;

  quantity: number;

  name: string;

  taxRate: number;

  price: number;

  defaultPriceLevelPrice: number;
}

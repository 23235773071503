import {
  AfterContentInit,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ElementRef,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Dropdown } from 'primeng/dropdown';
import { InputSwitch } from 'primeng/inputswitch';
import { MultiSelect } from 'primeng/multiselect';
import { AutoComplete } from 'primeng/autocomplete';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { InputDirective } from '../input/input.directive';
import { Calendar } from 'primeng/calendar';
import { SelectButton } from 'primeng/selectbutton';

type controlType = InputDirective | Dropdown | MultiSelect | InputSwitch | AutoComplete | SelectButton | Calendar;

@Component({
  selector: 'app-form-field',
  exportAs: 'appFormField',
  templateUrl: './form-field.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormFieldComponent implements OnInit, AfterContentInit, AfterViewInit, OnDestroy {
  get required(): boolean {
    return this._required;
  }

  @Input()
  set required(value: boolean) {
    this._required = value;
  }

  private _destroyed = new Subject<void>();

  @Input() icon: string = null;

  @Input() hint: string = null;
  public hintText: string = null;

  @Input() label: string = null;
  @Input() labelText: string = null;

  @ViewChild('label', { static: false }) private _label: ElementRef;

  @ContentChild(Dropdown, { static: false }) _controlPrimeNgDropdown: Dropdown;
  @ContentChild(Calendar, { static: false }) _controlPrimeNgCalendar: Calendar;
  @ContentChild(InputSwitch, { static: false })
  _controlPrimeNgInputSwitch: InputSwitch;
  @ContentChild(MultiSelect, { static: false })
  _controlPrimeNgMultiselect: MultiSelect;
  @ContentChild(AutoComplete, { static: false })
  _controlPrimeNgAutocomplete: AutoComplete;
  @ContentChild(SelectButton, { static: false })
  _controlPrimeNgSelectButton: SelectButton;
  @ContentChild(InputDirective, { static: false })
  _controlNonStatic: InputDirective;
  @ContentChild(InputDirective, { static: true })
  _controlStatic: InputDirective;

  get _control() {
    return (
      this._explicitFormFieldControl ||
      this._controlNonStatic ||
      this._controlStatic ||
      this._controlPrimeNgDropdown ||
      this._controlPrimeNgInputSwitch ||
      this._controlPrimeNgMultiselect ||
      this._controlPrimeNgAutocomplete ||
      this._controlPrimeNgCalendar ||
      this._controlPrimeNgSelectButton
    );
  }

  set _control(value: controlType) {
    this._explicitFormFieldControl = value;
  }

  private _explicitFormFieldControl: controlType;
  private _required: boolean;

  @HostBinding('class.is-focused') get focused() {
    if (this.isInputControl(this._control)) {
      return this._control.focused;
    } else if (this.isAutocompleteControl(this._control)) {
      return this._controlPrimeNgAutocomplete.focus;
    } else if (this.isCalendarControl(this._control)) {
      return this._controlPrimeNgCalendar.focus;
    }

    return true;
  }

  @HostBinding('class.is-filled') get filled() {
    if (this.isInputControl(this._control)) {
      return !this._control.empty;
    } else if (this.isAutocompleteControl(this._control)) {
      return this._controlPrimeNgAutocomplete.filled !== undefined;
    } else if (this.isCalendarControl(this._control)) {
      return this._controlPrimeNgCalendar.filled;
    }
    return true;
  }

  @HostBinding('class.is-required') get classRequired() {
    return this.required;
  }

  constructor(
    public _elementRef: ElementRef,
    private _changeDetectorRef: ChangeDetectorRef,
    private translateService: TranslateService,
  ) {}

  ngOnInit() {
    if (this.hint !== null) {
      this.hintText = this.translateService.instant(this.hint);
    }

    if (this.label !== null) {
      this.labelText = this.translateService.instant(this.label);
    }
  }

  ngAfterViewInit(): void {
    this._changeDetectorRef.detectChanges();
  }

  ngAfterContentInit(): void {
    const control = this._control;

    if (this.isInputControl(control)) {
      control.stateChanges.subscribe(() => {
        this._changeDetectorRef.markForCheck();
      });
    }

    if (this.isInputControl(control) && control.ngControl && control.ngControl.valueChanges) {
      control.ngControl.valueChanges.pipe(takeUntil(this._destroyed)).subscribe((value) => {
        if (value === '') {
          control.clear();
        }
        this._changeDetectorRef.markForCheck();
      });
    }
  }

  ngOnDestroy(): void {
    this._destroyed.next();
    this._destroyed.complete();
  }

  private isInputControl(obj: any): obj is InputDirective {
    return obj instanceof InputDirective;
  }

  private isAutocompleteControl(obj: any): obj is AutoComplete {
    return obj instanceof AutoComplete;
  }

  private isCalendarControl(obj: any): obj is Calendar {
    return obj instanceof Calendar;
  }
}

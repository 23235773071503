import { AbstractViewModel } from '../../../abstract.view.model';
import { NutritionApiModel } from '@core/models/tapp-order/api-model/product/nutrition.api.model';

export class NutritionViewModel extends AbstractViewModel<NutritionApiModel> {
  public name: string;
  public publicId: string;
  public unitName: string;
  public itemType: string;

  public minValue: number = null;
  public maxValue: number = null;
  public valuesRange: number[] = [];

  constructor(public apiModel: NutritionApiModel = new NutritionApiModel()) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.name = this.apiModel.name;
    this.publicId = this.apiModel.publicId;
    this.unitName = this.apiModel.unitName;
    this.itemType = this.apiModel.itemType;
  }

  toApiModel(): NutritionApiModel {
    return undefined;
  }

  toString() {
    return this.name;
  }
}

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { UserRestService } from '@api/rest/user/user.rest.service';
import { PasswordChangeApiModel } from '@api/model/user/password-change.api.model';

@Component({
  selector: 'app-password-change-modal',
  templateUrl: './password-change-modal.component.html',
  styleUrls: ['./password-change-modal.component.scss'],
})
export class PasswordChangeModalComponent implements OnInit {
  private passwordResetToken: string;
  public passwordResetForm: FormGroup;
  public loginLoading: boolean = false;
  public isFormCorrect: boolean = false;
  public showError: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<PasswordChangeModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private userRestService: UserRestService,
  ) {}

  ngOnInit(): void {
    this.passwordResetToken = this.data.passwordResetToken;
    this.passwordResetForm = this.fb.group(
      {
        password: new FormControl(null, [
          Validators.required,
          Validators.pattern(/\d/),
          Validators.pattern(/[A-Z]/),
          Validators.pattern(/[a-z]/),
          Validators.pattern(/[!@#$%^&*()_+\-=\[\]{};':"|,.<>\/?]/),
          Validators.minLength(8),
        ]),
        repeatPassword: new FormControl(null, [Validators.required]),
      },
      { validator: this.passwordMatchValidator },
    );

    this.passwordResetForm.valueChanges.subscribe(() => {
      this.isFormCorrect = this.passwordResetForm.valid;
    });
  }

  public passwordMatchValidator(formGroup: FormGroup): void {
    const passwordControl = formGroup.get('password');
    const repeatPasswordControl = formGroup.get('repeatPassword');

    if (passwordControl.value === repeatPasswordControl.value) {
      repeatPasswordControl.setErrors(null);
    } else {
      repeatPasswordControl.setErrors({ mismatch: true });
    }
  }

  public onSubmit(): void {
    if (this.passwordResetForm.valid) {
      this.showError = false;
      this.loginLoading = true;
      const apiModel = new PasswordChangeApiModel();
      apiModel.password = this.passwordResetForm.get('password').value;
      apiModel.passwordRepeat = this.passwordResetForm.get('repeatPassword').value;
      apiModel.resetPasswordToken = this.passwordResetToken;
      this.userRestService.postResetPasswordChange(apiModel).subscribe(
        (x) => {
          this.loginLoading = false;
          this.dialogRef.close();
        },
        (e) => {
          this.showError = true;
          this.loginLoading = false;
        },
      );
    }
  }
}

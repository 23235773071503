import { Exclude, Expose } from 'class-transformer';

@Exclude()
export class TermsAndPrivacyPolicyItemApiModel {
  @Expose()
  public id: number;

  @Expose()
  public content: string;
}

<div class="col-12 your-order-summary">
  <div class="row">
    <div class="col-12 your-order-summary-items-count">
      {{ 'tapp-order.order-summary-items' | translate }}:
      {{ basketService.calculateAllItemsInBasket() }}
    </div>
  </div>
  <!-- <div class="row" style="margin-top: 10px">
    <div class="col-6 d-flex flex-row your-order-summary-table-id">
      {{ "tapp-order.order-summary-room" | translate }}: {{ roomName }} <br />
      {{ "tapp-order.order-summary-table" | translate }}: {{ tableName }}
    </div>
    <div class="col-6 d-flex flex-row-reverse your-order-summary-price">
      {{
        basketService.calculatePrice() | currency: "PLN":"zł":"1.2-2":"pl-PL"
      }}
    </div>
  </div> -->
  <div class="row">
    <div class="col-12" style="padding: 0">
      <button
        class="your-order-summary-continue-button"
        [disabled]="!basketService.calculateAllItemsInBasket() || basketService.calculateAllItemsInBasket() === 0"
        (click)="createOrder()"
      >
        <span style="display: flex; justify-content: flex-start; align-content: center; align-items: center">
          <img
            src="../../../../../../../../assets/icons/shopping-cart.png"
            class="fa fa-cart-shopping"
            style="padding-right: 1rem"
          />
          {{ 'tapp-order.order-summary-order' | translate }}</span
        >
        <span
          class="right"
          style="display: flex; justify-content: flex-end; align-content: center; align-items: center"
          >{{ basketService.calculatePrice() | currency: 'PLN':'zł':'1.2-2':'pl-PL' }}</span
        >
      </button>
    </div>
  </div>
</div>

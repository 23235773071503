import { ProductDiscountApiModel } from '@api/model/discount/product-discount.api.model';
import { AbstractViewModel } from '@core/models/abstract.view.model';
import { ProductViewModel } from '@core/models/tapp-order/view-model/product/product.view.model';

export class ProductDiscountViewModel extends AbstractViewModel<ProductDiscountApiModel> {
  public value: number = null;
  public originalExternalProductId: number = null;
  public product: ProductViewModel = null;

  public constructor(protected apiModel: ProductDiscountApiModel = new ProductDiscountApiModel()) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.value = this.apiModel.value || null;
    this.originalExternalProductId = this.apiModel.originalExternalProductId || null;
    this.product = this.apiModel.product ? new ProductViewModel(this.apiModel.product) : null;
  }

  public apply(value: any): ProductDiscountViewModel {
    Object.assign(this, value);
    return this;
  }

  toApiModel(): ProductDiscountApiModel {
    return Object.assign(this.apiModel, {
      value: this.value,
      originalExternalProductId: this.originalExternalProductId,
      product: this.product,
    });
  }
}

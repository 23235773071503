import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-pizza-divide-switch',
  templateUrl: './pizza-divide-switch.component.html',
  styleUrls: ['./pizza-divide-switch.component.scss'],
})
export class PizzaDivideSwitchComponent implements OnInit {
  @Input() checked: boolean = false;

  @Output() checkedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() {}

  ngOnInit(): void {}
}

import { AbstractViewModel } from '../../../abstract.view.model';
import { SurveyAnswersApiModel } from '@core/models/tapp-order/api-model/survey/survey-answers.api.model';
import { SurveyAnswerItemViewModel } from '@core/models/tapp-order/view-model/survey/survey-answer-item.view.model';

export class SurveyAnswersViewModel extends AbstractViewModel<SurveyAnswersApiModel> {
  public surveys: SurveyAnswerItemViewModel[] = [];

  constructor(protected apiModel: SurveyAnswersApiModel = new SurveyAnswersApiModel()) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {}

  toApiModel(): SurveyAnswersApiModel {
    return Object.assign(this.apiModel, {
      surveys: this.surveys.map((survey) => survey.toApiModel()),
    });
  }

  public apply(formData: any) {
    Object.assign(this, formData, {
      surveys: formData.surveys.map((surveyFormData) => {
        let viewModel = new SurveyAnswerItemViewModel();
        viewModel.apply(surveyFormData);

        return viewModel;
      }),
    });
  }
}

import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-order-refused',
  templateUrl: './order-refused.component.html',
  styleUrls: ['./order-refused.component.scss'],
})
export class OrderRefusedComponent implements OnInit {
  @Output() public newOrderEvent: EventEmitter<null> = new EventEmitter<null>();

  constructor() {}

  ngOnInit(): void {}
}

import { AbstractViewModel } from '../../../abstract.view.model';
import { FbItemSubArticleApiModel } from '../../../api-model/order/fb-item/fb-item-sub-article.api.model';

export class FbItemSubArticleViewModel extends AbstractViewModel<FbItemSubArticleApiModel> {
  articleId: string;
  quantity: number;
  name: string;
  taxRate: number;
  price: number;
  defaultPriceLevelPrice: number;

  constructor(protected apiModel: FbItemSubArticleApiModel = new FbItemSubArticleApiModel()) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.articleId = this.apiModel.articleId;
    this.quantity = this.apiModel.quantity;
    this.name = this.apiModel.name;
    this.taxRate = this.apiModel.taxRate;
    this.price = this.apiModel.price;
    this.defaultPriceLevelPrice = this.apiModel.defaultPriceLevelPrice;
  }

  toApiModel(): FbItemSubArticleApiModel {
    return Object.assign(this.apiModel, {
      articleId: this.articleId,
    });
  }
}

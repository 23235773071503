import { Component, NgModule, OnInit } from '@angular/core';
import { Router, RouterModule, Routes } from '@angular/router';
import { MainLayoutComponent } from '@core/layout/main/main-layout.component';
import { ActivationComponent } from '@core/pages/activation/activation.component';
import { FoundLocalComponent } from '@core/pages/found-local/found-local.component';
import { InitTableComponent } from '@core/pages/init-table/init-table.component';
import { LoggedInUserComponent } from '@core/pages/logged-in-user/logged-in-user.component';
import { OrderHistoryComponent } from '@core/pages/logged-in-user/order-history/order-history.component';
import { UserSettngsComponent } from '@core/pages/logged-in-user/user-settings/user-settngs.component';
import { OrderProcessingComponent } from '@core/pages/order/order-processing/order-processing.component';
import { PasswordResetComponent } from '@core/pages/password-reset/password-reset.component';
import { PlacesComponent } from '@core/pages/places/places.component';
import { RegisterPageComponent } from '@core/pages/register/register.component';
import { ParamKeys } from '@shared/enum/param-kyes.enum';
import { InstagramLoginButtonComponent } from '@shared/login-modal/social-media-buttons/instagram-login-button/instagram-login-button.component';
import { RedirectToParentRouteComponent } from '@shared/redirect-to-parent-route/redirect-to-parent-route.component';
import { CanComponentDeactivateGuard } from './guards/can-component-deactivate.guard';
import { DiscountsComponent } from './pages/discounts/discounts.component';
import { MessagePageComponent } from './pages/message/message.component';
import { OrderFailedComponent } from './pages/order/order-failed/order-failed.component';
import { OrderSuccessComponent } from './pages/order/order-success/order-success.component';
import { OrderComponent } from './pages/order/order.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { PaymentPageComponent } from './pages/payment/payment.component';
import { ProductListPizzaSecondPartComponent } from './pages/products/page/product-list-pizza-second-part/product-list-pizza-second-part.component';
import { ProductModifyComponent } from './pages/products/page/product-modify/product-modify.component';
import { ProductsPageComponent } from './pages/products/products.component';
import { ScannerComponent } from './pages/scanner/scanner.component';
import { SelectAdressComponent } from './pages/select-adress/select-adress.component';
import { SelectPaymentMethodComponent } from './pages/select-payment-method/select-payment-method.component';
import { StartPageComponent } from './pages/start/start.component';
import { TermsAndConditionsComponent } from './pages/terms-and-conditions/terms-and-conditions.component';
import { BaseDataProvider } from '@core/data-providers/base.data-provider';
import { takeUntil } from 'rxjs/operators';
import { TableViewModel } from '@core/models/tapp-order/view-model/place/table.view.model';

// This is temporary component to handle old links
@Component({
  template: '',
})
class HandleOldLinkComponent implements OnInit {
  constructor(private router: Router, private baseDataProvider: BaseDataProvider) {}

  ngOnInit(): void {
    const segments = this.router.url.split('/');

    // hadle /discounts
    if (segments[1] === 'discounts' && segments.length === 2) {
      console.warn('HandleOldLinkComponent', 'discounts');

      const placeId = sessionStorage.getItem(ParamKeys.PlaceId);

      if (placeId) {
        this.router.navigate([placeId, 'discounts']);
        return;
      } else {
        this.router.navigate(['']);
        return;
      }
    }

    // hadle /products
    if (segments[1] === 'products' && segments.length === 2) {
      console.warn('HandleOldLinkComponent', 'products');
      const placeId = sessionStorage.getItem(ParamKeys.PlaceId);

      if (!placeId) {
        this.router.navigate(['']);
        return;
      }

      this.router.navigate([placeId, 'products']);
      return;
    }

    // hadle /products/:type/:id
    if (segments[1] === 'products' && segments[2] && segments[3] && segments.length === 4) {
      console.warn('HandleOldLinkComponent', 'products/:type/:id');
      const placeId = sessionStorage.getItem(ParamKeys.PlaceId);

      if (!placeId) {
        this.router.navigate(['']);
        return;
      }

      const type = segments[2];

      if (type === 'new') {
        const productId = segments[3];

        this.router.navigate([placeId, 'products', productId]);
        return;
      } else if (type === 'modify') {
        const basketItemId = segments[3];

        this.router.navigate([placeId, 'basket', 'items', basketItemId]);
        return;
      }
    }

    // hadle /products-modify/:id/:basketId
    if (segments[1] === 'products-modify' && (segments.length === 3 || segments.length === 4)) {
      console.warn('HandleOldLinkComponent', 'products-modify/:id/:basketId');
      const placeId = sessionStorage.getItem(ParamKeys.PlaceId);
      const productId = segments[2];
      const basketItemId = segments[3];

      if (!placeId) {
        this.router.navigate(['']);
        return;
      }

      if (basketItemId) {
        this.router.navigate([placeId, 'basket', 'items', basketItemId]);
        return;
      } else {
        this.router.navigate([placeId, 'products', productId]);
        return;
      }
    }

    //handle old url of table order /:tableId
    if (segments[1] && segments.length == 2) {
      if (/[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}$/.test(segments[1])) {
        console.warn('HandleOldLinkComponent', ':tableId');

        const tableId = segments[1];
        this.baseDataProvider.getTable(tableId).subscribe(
          (table: TableViewModel) => {
            this.router.navigate(['/', table.placePublicId, 'table', table.publicId]);
          },
          () => {
            this.router.navigate(['/']);
          },
        );
        return;
      } else {
        const placeId = segments[1];
        this.router.navigate([placeId, 'products']);
        return;
      }
    }

    // handle /:placeId/:type/:id
    if (segments[1] && segments[2] && segments[3] && segments.length === 4) {
      console.warn('HandleOldLinkComponent', ':placeId/:type/:id');

      const placeId = segments[1];
      const type = segments[2];

      if (type == 'new') {
        const productId = segments[3];

        this.router.navigate([placeId, 'products', productId]);
        return;
      } else if (type == 'modify') {
        const basketItemId = segments[3];

        this.router.navigate([placeId, 'basket', 'items', basketItemId]);
        return;
      }
    }

    // handle /:placeId/:type/:id/pizza-second-part
    if (
      segments[1] &&
      segments[2] &&
      segments[3] &&
      segments[4] &&
      segments[4] === 'pizza-second-part' &&
      segments.length === 5
    ) {
      console.warn('HandleOldLinkComponent', ':placeId/:type/:id/pizza-second-part');

      const placeId = segments[1];
      const type = segments[2];
      const productId = segments[3];

      if (type == 'new') {
        this.router.navigate([placeId, 'products', productId, 'choose-product']);
        return;
      }
    }

    // handle /:placeId/:type/:id/:id2
    if (
      segments[1] &&
      segments[2] &&
      segments[2] === 'new-divided-pizza' &&
      segments[3] &&
      segments[4] &&
      segments.length === 5
    ) {
      console.warn('HandleOldLinkComponent', ':placeId/:type/:id/:id2');

      const placeId = segments[1];
      const productId = segments[3];
      const productId2 = segments[4];

      this.router.navigate([placeId, 'products', productId, productId2]);
      return;
    }

    console.error('HandleOldLinkComponent -> Handler for route not found: ', this.router.url);
    this.router.navigate(['/']);
  }
}

const routes: Routes = [
  {
    path: '',
    component: MainLayoutComponent,
    children: [
      { path: '', component: SelectAdressComponent, pathMatch: 'full' },
      {
        path: 'reset-password/:resetPasswordToken',
        component: PasswordResetComponent,
      },
      {
        path: 'activation/:activationToken',
        component: ActivationComponent,
      },
      { path: 'instagram', component: InstagramLoginButtonComponent },
      { path: 'register', component: RegisterPageComponent },
      {
        path: 'user',
        component: LoggedInUserComponent,
        children: [
          {
            path: 'order-history',
            component: OrderHistoryComponent,
          },
          { path: 'user-settings', component: UserSettngsComponent, canDeactivate: [CanComponentDeactivateGuard] },
        ],
      },

      { path: 'date/:currentDate', component: SelectAdressComponent },
      { path: 'payment/:status', component: PaymentPageComponent },
      {
        path: 'order',
        component: OrderComponent,
        data: { animation: 'isRight' },
      },
      {
        path: 'select-payment-method',
        component: SelectPaymentMethodComponent,
        data: { animation: 'isRight' },
      },
      {
        path: 'order-success/:id',
        component: OrderSuccessComponent,
        data: { animation: 'isRight' },
      },
      {
        path: 'order-failed',
        component: OrderFailedComponent,
        data: { animation: 'isRight' },
      },
      { path: 'order/:id', component: OrderProcessingComponent },

      { path: 'error', component: OrderProcessingComponent },
      { path: 'error/:id', component: MessagePageComponent },
      { path: '404', component: PageNotFoundComponent },

      { path: 'found-local', component: FoundLocalComponent },
      { path: 'payments', component: PaymentPageComponent },
      { path: 'start', component: StartPageComponent },
      { path: 'scanner', component: ScannerComponent },

      /* OLD LINKS */
      { path: 'products', component: HandleOldLinkComponent },
      { path: 'products/:type/:id', component: HandleOldLinkComponent },
      {
        path: 'products-modify/:id/:basketId',
        component: HandleOldLinkComponent,
      },
      {
        path: 'discounts',
        component: HandleOldLinkComponent,
      },
      /* ----------------- */

      { path: 'places', component: PlacesComponent },
      {
        path: `:${ParamKeys.PlaceId}`,
        children: [
          { path: '', component: HandleOldLinkComponent },
          {
            path: 'products',
            children: [
              {
                path: '',
                component: ProductsPageComponent,
                pathMatch: 'full',
                data: { qrmenu: false },
              },
              {
                path: 'qrmenu',
                component: ProductsPageComponent,
                pathMatch: 'full',
                data: { qrmenu: true },
              },
              {
                path: `:${ParamKeys.ProductId}`,
                component: ProductModifyComponent,
                canDeactivate: [CanComponentDeactivateGuard],
              },
              {
                path: `:${ParamKeys.ProductId}/choose-product`,
                component: ProductListPizzaSecondPartComponent,
              },
              {
                path: `:${ParamKeys.ProductId}/:${ParamKeys.ProductId2}`,
                component: ProductModifyComponent,
                canDeactivate: [CanComponentDeactivateGuard],
              },
            ],
          },
          {
            path: 'discounts',
            children: [
              {
                path: '',
                component: DiscountsComponent,
                canDeactivate: [CanComponentDeactivateGuard],
                pathMatch: 'full',
                data: { qrmenu: false },
              },
              {
                path: 'qrmenu',
                component: DiscountsComponent,
                canDeactivate: [CanComponentDeactivateGuard],
                pathMatch: 'full',
                data: { qrmenu: true },
              },
            ],
          },
          { path: 'table/:tableId', component: InitTableComponent },
          {
            path: 'terms-and-conditions',
            component: TermsAndConditionsComponent,
          },
          {
            path: 'basket',
            children: [
              {
                path: '',
                component: RedirectToParentRouteComponent,
                pathMatch: 'full',
              },
              {
                path: 'items',
                children: [
                  {
                    path: '',
                    component: RedirectToParentRouteComponent,
                    pathMatch: 'full',
                  },
                  {
                    path: `:${ParamKeys.BasketItemId}`,
                    component: ProductModifyComponent,
                    canDeactivate: [CanComponentDeactivateGuard],
                  },
                  {
                    path: `:${ParamKeys.BasketItemId}/choose-product`,
                    component: ProductListPizzaSecondPartComponent,
                  },
                ],
              },
            ],
          },
        ],
      },

      /* OLD LINKS */
      {
        path: ':placeId/:type/:id',
        component: HandleOldLinkComponent,
      },
      {
        path: ':placeId/:type/:id/pizza-second-part',
        component: HandleOldLinkComponent,
      },
      {
        path: ':placeId/:type/:id/:id2',
        component: HandleOldLinkComponent,
      },
      /* ----------------- */

      { path: '**', redirectTo: '/404' },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CoreRoutingModule {}

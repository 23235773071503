import { OrderReasonEnum } from '@core/models/tapp-order/api-model/order/OrderReasonEnum';
import { OrderDeliveryAdressApiModel } from '@core/models/tapp-order/api-model/order/order-delivery-adress.api.model';
import { Exclude, Expose, Transform, Type } from 'class-transformer';
import { OrderPaymentApiModel } from '@core/models/tapp-order/api-model/order/order-payment.api.model';
import { OrderItemApiModel } from '@core/models/tapp-order/api-model/order/order-item.api.model';
import { OrderTypeEnum } from '@core/models/tapp-order/api-model/order/OrderTypeEnum';
import { OrderStatusEnum } from '@core/models/tapp-order/order-status.enum';
import { PlaceApiModel } from '@core/models/tapp-order/api-model/place/place.api.model';
import { InvoiceApiModel } from '@api/model/payment/invoice.api.model';
import { DeliveryAddressApiModel } from '@api/model/user/delivery-address.api.model';

@Exclude()
export class OrderApiModel {
  @Expose()
  public tablePublicId?: string;

  @Expose()
  public publicId: string;

  @Expose()
  public customerName: string;

  @Expose()
  public customerEmail: string;

  @Expose()
  public customerPhone: string;

  @Expose()
  public notes: string;

  @Expose()
  @Transform(({ value }) => {
    if (value) {
      value = (value as string) + 'Z';
      return new Date(value).toISOString();
    } else {
      return null;
    }
  })
  public createdAt: Date;

  @Expose()
  @Transform(({ value }) => {
    if (value) {
      value = (value as string) + 'Z';
      return new Date(value).toISOString();
    } else {
      return null;
    }
  })
  public pickupTime: Date;

  @Expose()
  @Transform(({ value }) => {
    if (value) {
      value = (value as string) + 'Z';
      return new Date(value).toISOString();
    } else {
      return null;
    }
  })
  public deliveryTime: Date;

  @Expose()
  public type: OrderTypeEnum;

  @Expose()
  public status: OrderStatusEnum;

  @Expose()
  public reason: OrderReasonEnum;

  @Expose()
  public localOrderIdentifier?: string;

  @Expose()
  public place: PlaceApiModel;

  @Expose()
  @Type(() => OrderItemApiModel)
  public orderItems: OrderItemApiModel[];

  @Expose()
  public orderPayment: OrderPaymentApiModel;

  @Expose()
  public hasSurveyToComplete: boolean;

  @Expose()
  public deliveryAddress: DeliveryAddressApiModel;

  @Expose()
  public invoice: InvoiceApiModel;
}

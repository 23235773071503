import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { AddressFinderComponent } from '@ui/address-finder/address-finder/address-finder.component';

@Component({
  selector: 'app-dialog-address-finder',
  templateUrl: './dialog-address-finder.component.html',
  styleUrls: ['./dialog-address-finder.component.scss'],
})
export class DialogAddressFinderComponent {
  @Input() message1: string = null;
  @Input() message2: string = null;
  @Input() displayDialog: boolean = false;
  @Input() redirectToPlace: boolean = false;
  @Input() checkCurrentPlace: boolean = false;
  @Input() context: 'account' | 'other' = 'other';

  @Output() onCloseDialog: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('appAddressFinder') appAddressFinder: AddressFinderComponent;

  constructor() {}

  public submit(): void {
    this.appAddressFinder?.findPlace();
  }

  public cancel(): void {
    this.appAddressFinder?.onCancel();
    this.onCloseDialog.emit();
  }

  public onAddressFound(): void {
    this.onCloseDialog.emit();
  }
}

export abstract class AbstractViewModel<T> {
  protected constructor(protected apiModel: T) {}

  protected abstract fromApiModel(): void;

  public abstract toApiModel(): T;

  public apply(value: any): void {
    Object.assign(this, value);
  }
}

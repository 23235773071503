import { GroupApiModel } from './group.api.model';
import { Exclude, Expose, Type } from 'class-transformer';

@Exclude()
export class MenuApiModel {
  @Expose()
  public publicId: string;

  @Expose()
  public name: string;

  @Expose()
  public deliveryTypes: string[];

  @Expose()
  public checkPromotions: boolean = true;

  @Expose()
  @Type(() => GroupApiModel)
  public groups: GroupApiModel[];
}

<p-dialog
  [(visible)]="displayDialog"
  [style]="{ 'max-width': '500px', 'max-height': '90%', 'border-radius': '21px', overflow: 'hidden' }"
  [draggable]="false"
  [modal]="true"
  [showHeader]="false"
  class="address-finder-dialog"
  [resizable]="false"
>
  <div class="address-finder-container">
    <div class="address-finder-dialog-mode-title">
      <span>{{ 'tapp-order.select-adress-component.dialog.header' | translate }}</span>
    </div>

    <app-address-finder
      #appAddressFinder
      [checkCurrentPlace]="checkCurrentPlace"
      [redirectToPlace]="redirectToPlace"
      [context]="context"
      (onFoundEvent)="onAddressFound()"
    >
      <ng-template>
        <div *ngIf="message1 || message2" class="col-12 d-flex align-items-center justify-content-center">
          <div class="d-flex align-items-center justify-content-center" style="flex-direction: column">
            <img src="../../../assets/icons/info.png" />
            <p class="address-finder-message-1" *ngIf="message1">
              <span>
                {{ message1 }}
              </span>
            </p>
            <p class="address-finder-message-2" *ngIf="message2">
              {{ message2 }}
            </p>
          </div>
        </div>

        <div class="col ui-clickable d-flex justify-content-center align-items-center">
          <div class="buttons-container">
            <button class="save-button ui-clickable" (click)="submit()">
              {{ 'tapp-order.select-adress-component.dialog.confirm-button' | translate }}
            </button>
            <button class="cancel-button ui-clickable" (click)="cancel()">
              {{ 'tapp-order.select-adress-component.dialog.cancel-button' | translate }}
            </button>
          </div>
        </div>
      </ng-template>
    </app-address-finder>
  </div>
</p-dialog>

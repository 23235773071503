import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { ButtonSizeEnum } from '@ui/button/button-size.enum';
import { ButtonColorEnum } from '@ui/button/button-color.enum';

@Component({
  // tslint:disable-next-line:component-selector
  selector: `button[app-button], button[app-icon-button], button[app-outline-button], button[app-bg-button]`,
  exportAs: 'appButton',
  templateUrl: './button.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent implements OnInit {
  readonly isIconButton: boolean = this._hasHostAttributes('app-icon-button');
  readonly isBgButton: boolean = this._hasHostAttributes('app-bg-button');
  readonly isOutlineButton: boolean = this._hasHostAttributes('app-outline-button');

  @Input()
  get size(): string {
    return this._size;
  }

  set size(value: string) {
    this._size = value;
  }

  private _size: string = ButtonSizeEnum.base;

  @Input()
  get color(): string {
    return this._color;
  }

  set color(value: string) {
    this._color = value;
  }

  private _color: string = ButtonColorEnum.secondary;

  @HostBinding('class')
  get cssClasses(): string {
    const cssClasses = ['btn'];

    if (this.isIconButton) {
      cssClasses.push(`btn-icon btn-icon-${this._color}`);
    }

    if (this.isOutlineButton) {
      cssClasses.push(`btn-outline-${this._color}`);
    }

    if (this.isBgButton) {
      cssClasses.push(`btn-${this._color}`);
    }

    cssClasses.push(this._getSizeCssClass());

    return cssClasses.join(' ');
  }

  constructor(private _elementRef: ElementRef) {}

  ngOnInit() {
    this._elementRef.nativeElement.classList.add('btn');
  }

  private _getSizeCssClass(): string {
    return `btn-${this._size}`;
  }

  private _getHostElement() {
    return this._elementRef.nativeElement;
  }

  private _hasHostAttributes(...attributes: string[]) {
    return attributes.some((attribute) => this._getHostElement().hasAttribute(attribute));
  }
}

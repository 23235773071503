import { Component, OnInit } from '@angular/core';
import { OrderService } from '@core/services/order.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-payment-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss'],
})
export class MessageComponent implements OnInit {
  messageForm: FormGroup;
  message: string = null;

  constructor(private orderService: OrderService) {}

  ngOnInit(): void {
    this.orderService.removeCustomerNotes();
    this.createMessageForm();
  }

  private createMessageForm(): void {
    this.messageForm = new FormGroup({
      message: new FormControl(this.orderService.getCustomerNotes() ? this.orderService.getCustomerNotes() : null),
    });
  }

  setMessage(str: string): void {
    if (str) {
      this.orderService.setCustomerNotes(str);
    }
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { plainToClass } from 'class-transformer';
import { map } from 'rxjs/operators';
import { ProductApiModel } from '../../../models/tapp-order/api-model/product/product.api.model';
import { ModifierApiModel } from '../../../models/tapp-order/api-model/product/modifier.api.model';
import { Cacheable, LocalStorageStrategy } from 'ts-cacheable';

const modifiersCacheBuster$ = new Subject<void>();

@Injectable({
  providedIn: 'root',
})
export class ProductHttpService {
  constructor(private http: HttpClient) {}

  @Cacheable({
    storageStrategy: LocalStorageStrategy,
  })
  getProduct(publicId): Observable<ProductApiModel> {
    return this.http
      .get<ProductApiModel>('/product/' + publicId)
      .pipe(map((result) => plainToClass(ProductApiModel, result as object)));
  }

  @Cacheable({
    storageStrategy: LocalStorageStrategy,
    cacheBusterObserver: modifiersCacheBuster$,
    maxCacheCount: 1000000,
  })
  getProductModifiers(publicId): Observable<ModifierApiModel[]> {
    return this.http
      .get<ModifierApiModel[]>('/product/' + publicId + '/modifiers')
      .pipe(map((result) => plainToClass(ModifierApiModel, result as object[])));
  }

  @Cacheable({
    storageStrategy: LocalStorageStrategy,
  })
  getProductsCrossSell(menuPublicId: string): Observable<ProductApiModel[]> {
    return this.http
      .get<ProductApiModel[]>('/product-crossSell/' + menuPublicId)
      .pipe(map((result) => plainToClass(ProductApiModel, result as object[])));
  }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { PlaceApiModel } from '@core/models/tapp-order/api-model/place/place.api.model';
import { map } from 'rxjs/operators';
import { plainToClass, plainToClassFromExist, plainToInstance } from 'class-transformer';
import { Cacheable, LocalStorageStrategy } from 'ts-cacheable';
import { QueryParamsApiModel } from '@core/models/query-params-api.model';
import { QueryParameterService } from '@core/services/query-parameter.service';
import { PlaceViewModel } from '@core/models/tapp-order/view-model/place/place.view.model';
import { WorkingHoursViewModel } from '@core/models/tapp-order/view-model/place/working-hours.view.model';
import { WorkingHoursApiModel } from '@core/models/tapp-order/api-model/working-hours/working-hours.api.model';

const placeCacheBuster$ = new Subject<void>();
const placesCacheBuster$ = new Subject<void>();

@Injectable({
  providedIn: 'root',
})
export class PlaceHttpService {
  constructor(private http: HttpClient, private paramService: QueryParameterService) {}

  @Cacheable({
    storageStrategy: LocalStorageStrategy,
    cacheBusterObserver: placesCacheBuster$,
  })
  getPlacesList(): Observable<PlaceApiModel[]> {
    return this.http
      .get<PlaceApiModel[]>('/place')
      .pipe(map((result: PlaceApiModel[]) => plainToClass(PlaceApiModel, result as object[])));
  }

  @Cacheable({
    storageStrategy: LocalStorageStrategy,
    cacheBusterObserver: placeCacheBuster$,
  })
  getPlace(publicId: string): Observable<PlaceApiModel> {
    return this.http
      .get<PlaceApiModel>('/place/' + publicId)
      .pipe(map((result) => plainToClass(PlaceApiModel, result as object)));
  }

  @Cacheable({
    storageStrategy: LocalStorageStrategy,
    cacheBusterObserver: placeCacheBuster$,
  })
  getPlaceWorkingHours(publicId: string): Observable<WorkingHoursViewModel[]> {
    return this.http.get<WorkingHoursApiModel[]>('/place/' + publicId + '/workingHours').pipe(
      map((result) => plainToClass(WorkingHoursApiModel, result as object[])),
      map((models: WorkingHoursViewModel[]) =>
        models.map((model: WorkingHoursViewModel) => new WorkingHoursViewModel(model)),
      ),
    );
  }
}

<div class="register-container row">
  <div class="col">
    <div class="register-header row text-center">
      <div class="col">
        <span>{{ 'tapp-order.pages.register.header' | translate }}</span>
      </div>
    </div>
    <form (submit)="onSubmit()" [formGroup]="registerForm" class="row register-form">
      <div class="col">
        <div class="row">
          <div class="col">
            <div class="form-group">
              <input type="text" formControlName="name" pInputText appInput id="name" required="true" />
              <label for="name">{{ 'tapp-order.pages.register.form.name' | translate }}</label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="form-group">
              <input type="text" formControlName="email" appInput pInputText required="true" id="email" />
              <label for="email">{{ 'tapp-order.pages.register.form.email' | translate }}</label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="form-group">
              <input type="text" formControlName="emailRepeat" appInput pInputText required="true" id="emailRepeat" />
              <label for="emailRepeat">{{ 'tapp-order.pages.register.form.emailRepeat' | translate }}</label>
              <div
                *ngIf="registerForm.errors?.notMatching && registerForm.controls['emailRepeat'].touched"
                class="invalid-feedback"
              >
                {{ 'tapp-order.pages.register.form.emailRepeatError' | translate }}
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="form-group phone">
              <input formControlName="phoneNumber" type="text" appInput pInputText required="true" id="phone" />
              <p class="input-label">{{ 'tapp-order.pages.register.form.phone-number-information' | translate }}</p>
              <label for="phone">{{ 'tapp-order.pages.register.form.phone' | translate }}</label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="form-group password" style="margin-bottom: 0">
              <input
                formControlName="password"
                type="password"
                appInput
                pInputText
                id="password"
                required="true"
                #password
              />
              <label for="password">{{ 'tapp-order.pages.register.form.password' | translate }}</label>
              <img
                class="show-password ui-clickable"
                [src]="
                  password.type == 'password'
                    ? '../../../../../../assets/icons/show-password.png'
                    : '../../../../../../assets/icons/show-password-1.png'
                "
                (click)="password.type = password.type == 'password' ? 'text' : 'password'"
              />
            </div>
            <div class="form-group" style="all: unset">
              <p
                class="input-label"
                [ngClass]="{
                  'input-label-error':
                    registerForm.controls['password'].errors && registerForm.controls['password'].touched
                }"
                >{{ 'tapp-order.pages.register.form.password-information' | translate }}</p
              >
            </div>
          </div>
        </div>
        <div class="row mt-5">
          <div class="col-12">
            <small class="clickable-text" (click)="showTermsAndCoditions()">
              {{ 'tapp-order.pages.register.form.privacy-policy' | translate }}
            </small>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <ng-container>
              <ng-container
                *ngFor="let child of termsAndConditionsControl.controls; let i = index"
                formArrayName="termsAndConditions"
              >
                <div class="form-group terms-and-conditions" formGroupName="{{ i }}">
                  <div class="d-flex align-items-start">
                    <p-checkbox [binary]="true" formControlName="selected" [required]="true"></p-checkbox>
                    <p
                      class="p-checkbox-label-required p-checkbox-label"
                      style="margin-bottom: 0; margin-left: 0.2rem"
                      *ngIf="child.value.required"
                      >*
                    </p>
                    <p
                      class="p-checkbox-label"
                      style="margin-bottom: 0; margin-left: 0.2rem"
                      [ngClass]="{ 'required-checkbox-label': child.value.required }"
                      [innerHTML]="child.value.content"
                    ></p>
                  </div>
                </div>
              </ng-container>
            </ng-container>
          </div>
        </div>
        <div class="row mt-3" *ngIf="showError">
          <div class="error col text-center">
            <div
              ><span>{{ errorMessage }}</span></div
            >
          </div>
        </div>
        <div class="row pt-2">
          <div class="col text-center">
            <button type="submit" class="btn btn-primary">
              <span *ngIf="!registerLoading; else spinner">{{
                'tapp-order.pages.register.form.button' | translate
              }}</span>
              <ng-template #spinner>
                <span class="loader"></span>
              </ng-template>
            </button>
          </div>
        </div>
      </div>
    </form>
    <div class="register-footer row">
      <div class="col d-flex justify-content-center">
        <span class="mr-1">{{ 'tapp-order.pages.register.message1' | translate }}</span>
        <a (click)="openDialog()" href="" class="primary-color">{{
          'tapp-order.pages.register.message2' | translate
        }}</a>
      </div>
    </div>
  </div>
</div>

<app-dialog [(visible)]="termsVisible" [closable]="true" (onHide)="handleTermsHide()">
  <ng-template #header>
    <div class="dialog-terms__header">
      <span>{{ 'tapp-order.pages.register.form.privacy-policy' | translate }}</span>
    </div>
  </ng-template>

  <div class="dialog-terms__content">
    <div [innerHTML]="html"></div>
  </div>
</app-dialog>

import { ProductApiModel } from './product.api.model';
import { Exclude, Expose, Type } from 'class-transformer';
import { ModifierTypeEnum } from '@shared/enum/modifier-type.enum';

@Exclude()
export class ModifierApiModel {
  @Expose()
  public internalId: string;

  @Expose()
  public publicId: string;

  @Expose()
  public name: string;

  @Expose()
  public description: string;

  @Expose()
  public minQuantity: number;

  @Expose()
  public maxQuantity: number;

  @Expose()
  public defaultQuantity: number;

  @Expose()
  public modifierType: ModifierTypeEnum;

  @Expose()
  @Type(() => ProductApiModel)
  public products: ProductApiModel[];
}

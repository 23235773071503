<div class="vh-100" class="order-failed-container">
  <div class="order-success-logo">
    <img [src]="getLogo()" />
  </div>

  <div class="order-failed-icon">
    <img src="../../../../../assets/images/failed-payment-icon.png" />
  </div>

  <div class="order-failed-primary-text">
    {{ 'tapp-order.order-failed-primary-text' | translate }}
  </div>
  <div class="order-failed-secondary-text">
    {{ 'tapp-order.order-failed-secondary-text' | translate }}
  </div>
</div>

<div class="bottom-button">
  <app-bottom-button
    centerText="{{ 'tapp-order.order-failed-button-text' | translate }}"
    [routerLinkParam]="['', isDesktop ? (place ? place.publicId : '/') : 'order']"
  ></app-bottom-button>
</div>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppCalendarComponent } from './calendar.component';
import { CalendarModule as PrimeCalendarModule } from 'primeng/calendar';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from '@ui/button';

@NgModule({
  declarations: [AppCalendarComponent],
  imports: [CommonModule, PrimeCalendarModule, FormsModule, TranslateModule, ButtonModule],
  exports: [AppCalendarComponent],
})
export class CalendarModule {}

import { Component, OnInit } from '@angular/core';
import { PaymentMethodViewModel } from './payment-method.view.model';

@Component({
  selector: 'app-select-payment-method',
  templateUrl: './select-payment-method.component.html',
  styleUrls: ['./select-payment-method.component.scss'],
})
export class SelectPaymentMethodComponent implements OnInit {
  public paymentMethods: PaymentMethodViewModel[] = [{ name: 'Card' }, { name: 'Blik' }];

  constructor() {}

  ngOnInit(): void {}

  toggleSelected(selectedPaymentMethod) {
    this.paymentMethods.forEach((thisPaymentMethod) => {
      thisPaymentMethod.selected = false;
    });
    selectedPaymentMethod.selected = true;
  }
}

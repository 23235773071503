<div class="ui-clickable py-2 px-3" (click)="openModal(hamburgerMenu)">
  <i class="ic ic-menu"></i>
</div>

<ng-template #hamburgerMenu>
  <div class="wrapper">
    <div class="container">
      <div class="row burger-button-header-container">
        <div class="modal-x-button-section col-12">
          <div class="row">
            <div class="col-2 x-button hamburger-button-menu-close ui-clickable" (click)="hideSidebar()">
              <img />
            </div>
            <div class="col-4 ui-clickable">
              <app-logo></app-logo>
            </div>
            <div class="col-6 invisible-button" (click)="versionService.handleVersionCallClick()"></div>
            <!-- <div class="language-select col-2 text-right"> -->
            <!-- <app-select-lang [template]="'icon'"></app-select-lang> -->
            <!-- </div> -->
          </div>
        </div>
      </div>
      <div class="row burger-button-menu-positions-container">
        <div class="mt-4 burger-button-menu-positions col-12 align-self-start">
          <!-- <div
            class="col-12 d-flex align-items-center burger-button-menu-position"
          >
            <img src="../../../../../assets/icons/tpizza-menu-icon.png" />
            <a [routerLink]="['', 'products']" (click)="hideSidebar()">{{
              "tapp-order.menu.menu" | translate
            }}</a>
          </div> -->

          <!-- <div
            class="col-12 d-flex align-items-center burger-button-menu-position border-divider"
          >
            <img src="../../../../../assets/icons/tpizza-promotions-icon.png" />
            <a [routerLink]="['']" (click)="hideSidebar()">{{
              "tapp-order.menu.promocje" | translate
            }}</a>
            <div class="col d-flex justify-content-end">
              <div class="d-flex justify-content-center new-marker">
                {{ "tapp-order.menu.new-marker-text" | translate }}
              </div>
            </div>
          </div> -->

          <div class="col-12 d-flex align-items-center burger-button-menu-position" *ngIf="!tableMode && hasPlacesList">
            <img src="../../../../../assets/icons/tpizza-local-icon.png" />
            <a [routerLink]="['places']" (click)="hideSidebar()">{{ 'tapp-order.menu.lokale' | translate }}</a>
          </div>

          <!-- <div
            class="col-12 d-flex align-items-center burger-button-menu-position"
          >
            <img src="../../../../../assets/icons/tpizza-blog-icon.png" />
            <a [routerLink]="['']" (click)="hideSidebar()">{{
              "tapp-order.menu.blog" | translate
            }}</a>
          </div> -->

          <ng-container *ngFor="let link of links">
            <div class="col-12 d-flex align-items-center burger-button-menu-position">
              <a href="{{ link.url }}" (click)="hideSidebar()">{{ link.name }}</a>
            </div>
          </ng-container>
        </div>
        <!-- <div class="burger-button-settings-positions col-12 align-self-center">
          <div
            class="col-12 d-flex align-items-center burger-button-settings-positions-item"
          >
            <a href="#/app/settings" (click)="hideSidebar()">{{
              "tapp-order.menu.ustawienia" | translate
              }}</a>
          </div>
          <div
            class="col-12 d-flex align-items-center burger-button-settings-positions-item"
          >
            <a href="#/app/help" (click)="hideSidebar()">{{
              "tapp-order.menu.pomoc" | translate
              }}</a>
          </div>
        </div> -->
      </div>
      <div class="row burger-button-bottom-container">
        <!-- <div class="col-12 p-3 mt-3">
          <div
            class="burger-button-bottom burger-button-bottom-login-button d-flex justify-content-center align-items-center"
          >
            {{ "tapp-order.menu.login-button-text" | translate }}
            <img src="../../../../../assets/icons/person-32x32.png" />
          </div>
          <div
            class="mt-3 burger-button-bottom d-flex justify-content-center align-items-center"
          >
            {{ "tapp-order.menu.register-text" | translate }}&nbsp;<a>{{
              "tapp-order.menu.register-link-text" | translate
            }}</a>
          </div>
        </div>
        <div class="col-12 d-flex justify-content-center align-items-center">
          <div class="col-6 d-flex justify-content-center">
            <div class="hamburger-download-app-button">
              <small>Pobierz aplikację z</small><br />APP STORE
            </div>
          </div>
          <div class="col-6 d-flex justify-content-center">
            <div class="hamburger-download-app-button">
              <small>Pobierz aplikację z</small><br />GOOGLE PLAY
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</ng-template>

import { Injectable } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root',
})
export class ActivateEmailModalService {
  public dialogRef: MatDialogRef<any> | null = null;

  setDialogRef(dialogRef: MatDialogRef<any> | null): void {
    this.dialogRef = dialogRef;
  }

  closeDialog(): void {
    if (this.dialogRef) {
      this.dialogRef.close();
      this.dialogRef = null;
    }
  }
}

import { Exclude, Expose, Type } from 'class-transformer';
import { SurveyAnswerApiModel } from '@core/models/tapp-order/api-model/survey/survey-answer.api.model';

@Exclude()
export class SurveyAnswerItemApiModel {
  @Expose()
  public orderPublicId?: string;

  @Expose()
  public surveyId: number;

  @Expose()
  @Type(() => SurveyAnswerApiModel)
  public answers: SurveyAnswerApiModel[] = [];
}

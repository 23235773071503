import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ActivateEmailModalService } from '@core/services/activate-email-modal.service';
import { AuthService } from '@core/services/auth.service';
import { VerifyEmailModalComponent } from '@shared/verify-email-modal/verify-email-modal.component';
import { BehaviorSubject, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';

@Injectable()
export class JwtRefreshTokenInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private readonly forbidden_request_url = ['refresh', 'login'];

  constructor(
    private authService: AuthService,
    private router: Router,
    public dialog: MatDialog,
    private activateEmailModalService: ActivateEmailModalService,
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    if (!this.authService.isAuthenticated() && this.requestIncludeForbiddenUrl(req.url)) {
      return next.handle(req);
    }
    return next.handle(req).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse) {
          if (error.status === 401) {
            if (!this.requestIncludeForbiddenUrl(req.url)) {
              if (error.error.code === 'user.auth.not_activated') {
                this.router.navigate(['']);
                this.openDialog();
                return;
              }
              return this.handle401Error(req, next);
            }
          }
          return throwError(error);
        }
      }),
    );
  }

  private requestIncludeForbiddenUrl(url: string): boolean {
    return url.includes(this.forbidden_request_url[0]) || url.includes(this.forbidden_request_url[1]);
  }

  private handle401Error(req: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);
      return this.authService.refreshToken().pipe(
        switchMap(({ token }: any) => {
          this.isRefreshing = false;
          this.refreshTokenSubject.next(token);
          return next.handle(this.addToken(req, token));
        }),
        catchError((error: HttpErrorResponse) => {
          this.handleLogout();
          return throwError(error);
        }),
      );
    } else {
      return this.refreshTokenSubject.pipe(
        filter((token) => token != null),
        take(1),
        switchMap((token) => next.handle(this.addToken(req, token))),
        catchError((error: HttpErrorResponse) => {
          this.handleLogout();
          return throwError(error);
        }),
      );
    }
  }

  openDialog(): void {
    if (!this.activateEmailModalService.dialogRef) {
      this.activateEmailModalService.setDialogRef(
        this.dialog.open(VerifyEmailModalComponent, {
          panelClass: 'verify-email-modal-container',
          disableClose: true,
        }),
      );
    }
  }

  private addToken(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  private handleLogout(): void {
    this.authService.logout();
  }
}

import { DiscountApiModel } from '@api/model/discount/discount.api.model';
import { AbstractViewModel } from '@core/models/abstract.view.model';
import { DateTime } from 'luxon';
import { ConditionViewModel } from './condition.view.model';

export class DiscountViewModel extends AbstractViewModel<DiscountApiModel> {
  public id: number = null;
  public name: string = null;
  public description: string = null;
  public dateFrom: DateTime = null;
  public dateTo: DateTime = null;
  public active: boolean = null;
  public imageUrl: string = null;
  public conditions: ConditionViewModel[] = [];

  public constructor(protected apiModel: DiscountApiModel = new DiscountApiModel()) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.id = this.apiModel.id || null;
    this.name = this.apiModel.name || null;
    this.description = this.apiModel.description || null;
    this.dateFrom = this.apiModel.dateFrom || null;
    this.dateTo = this.apiModel.dateTo || null;
    this.active = this.apiModel.active || false;
    this.imageUrl = this.apiModel.imageUrl || null;
    this.conditions = this.apiModel.conditions
      ? this.apiModel.conditions.map((value) => new ConditionViewModel(value))
      : [];
  }

  public apply(value: any): DiscountViewModel {
    Object.assign(this, value);
    return this;
  }

  toApiModel(): DiscountApiModel {
    return Object.assign(this.apiModel, {
      id: this.id,
      name: this.name,
      description: this.description,
      dateFrom: this.dateFrom,
      dateTo: this.dateTo,
      active: this.active,
      conditions: this.conditions.map((i) => i.toApiModel()),
    });
  }
}

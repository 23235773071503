export const environment = {
  appVersion: require('../../package.json').version + '-dev',
  production: true,
  projectName: 'default',
  title: 'Ordering',
  brand: {
    fullLogo: '',
    subLogo: '',
    showLogo: true,
  },
  defaultLogoName: 'logo-default.png',
  globalize: {
    country: 'US',
    currency: '$',
    language: {
      default: 'en',
      available: ['pl', 'en'],
    },
  },
  baseImgOnError: './assets/images/D.svg',
  tips: [
    { type: 'fixed', value: 0 },
    { type: 'percent', value: 5 },
    { type: 'percent', value: 10 },
    { type: 'percent', value: 15 },
    { type: 'manual', value: 0 },
  ],
  payment: {
    provider: 'worldpay',
    giftcard: '',
    channel: 'web',
  },
  features: {
    callForService: false,
  },
  appUrl: {
    prefix: 'https://',
    domain: 'app-tapporder.dev.lsi-dev.pl',
  },
  baseAppUrl: '',
  ctlUrl: 'https://ctl-tapporder.dev.lsi-dev.pl',
  apiUrl: 'https://api-tapporder.dev.lsi-dev.pl',
  payU: {
    url: 'https://secure.snd.payu.com/javascript/sdk', //sandbox
  },
  geoApiUrl: 'https://geoapi.dev.lsi-dev.pl',
  geoApiAuthToken: '1r8ora8MWHnSVqJdtn0S',
  googleClientID: '380539204197-bt70bfk3eha6jrcmi5s0q9m1bmouutgu.apps.googleusercontent.com',
  facebookAppId: '1359872238183427',
  instagramAppId: '635386628525140',
  instagramAppSecret: '3b79f3cf0ae70676796d17362c031571',
  instagramRedirectUri: `${window.location.origin}/instagram`,
  abandonedBasketTimeout: 15, //in minutes
  abandonedBasketFeatureEnabled: false,
  enableDraftFeatures: true,
};

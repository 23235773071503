import { Component, Input, OnInit, ViewChild, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { LoadingService } from '@shared/loading/loading.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BasketItemViewModel } from '@core/models/tapp-order/view-model/basketItem/basket-item.view.model';
import { ProductViewModel } from '@core/models/tapp-order/view-model/product/product.view.model';
import { MatDialog } from '@angular/material/dialog';
import { ChangeIngredientsDialogComponent } from './../../component/change-ingredients-dialog/change-ingredients-dialog.component';
import { BasketService } from './../../../../../services/basket.service';
import { PlaceService } from './../../../../../services/place.service';
import { MenuDataProvider } from '@core/data-providers/menu.data-provider';
import * as _ from 'lodash';
import { MenuViewModel } from '@core/models/tapp-order/view-model/menu/menu.view.model';
import { BaseDataProvider } from '@core/data-providers/base.data-provider';
import { finalize } from 'rxjs/operators';
import { ProductModifiersComponent } from './../../component/product-modifiers/product-modifiers.component';
import { ProductService } from 'src/app/services/product.service';
import { PlaceViewModel } from '@core/models/tapp-order/view-model/place/place.view.model';

@Component({
  selector: 'app-product-discount-modify',
  templateUrl: './product-discount-modify.component.html',
  styleUrls: ['./product-discount-modify.component.scss'],
  providers: [ProductService],
})
export class ProductDiscountModifyComponent implements OnInit {
  @Input() public productId: string = null;
  @Input() public modifyType: string = null;
  @Input() public originalExternalGroupIdFromDiscount: number = null;
  @Input() conditionSizeIds = [];
  @Input() conditionDoughIds: (number | string)[] = [];
  public id: string;
  public type: string;
  public menu: MenuViewModel;
  public place: PlaceViewModel;

  public pizzaSizes: ProductViewModel[] = [];
  public pizzaDoughs: ProductViewModel[] = [];

  @Output()
  @ViewChild('modifiersComponent')
  modifiersComponent: ProductModifiersComponent;

  constructor(
    private loadingService: LoadingService,
    private activatedRoute: ActivatedRoute,
    public basketService: BasketService,
    private placeService: PlaceService,
    private menuDataProvider: MenuDataProvider,
    private baseDataProvider: BaseDataProvider,
    public dialog: MatDialog,
    public router: Router,
    public productService: ProductService,
  ) {}

  ngOnInit(): void {
    this.productService.updatePizzaDoughFilter({
      availablePizzaDoughIds: this.conditionDoughIds,
    });

    this.loadingService.showLoader();
    this.activatedRoute.url.pipe(finalize(() => {})).subscribe((value) => {
      this.productId ? (this.id = this.productId) : (this.id = value.pop().path);
      this.modifyType ? (this.type = this.modifyType) : (this.type = value.pop().path);
      this.placeService
        .getPlace()
        .pipe(finalize(() => {}))
        .subscribe((place) => {
          this.place = place;

          if (place.menus.length > 0) {
            const menuId = place.menus[0].publicId;
            this.menuDataProvider
              .getMenu(menuId)
              .pipe(finalize(() => {}))
              .subscribe((menu) => {
                this.menu = _.cloneDeep(menu);
              });
          }
        });
      if (this.type === 'new') {
        this.baseDataProvider
          .getProduct(this.id)
          .pipe(finalize(() => {}))
          .subscribe((product) => {
            if (product.selectBasicModifiers) {
              this.productService.prepareSelectBasicModifiers(product);
            }

            this.productService.product = product;
            this.getPizzaSize();
            this.setDefaultPizzaSize();

            this.loadingService.hideLoader();
          });
      } else {
        this.basketService.basketItems$
          .subscribe((items) => {
            if (items.length > 0) {
              this.productService.basketItem = items.find((item) => {
                return item.BasketItemID === this.id;
              });
            }
          })
          .unsubscribe();
      }
      this.loadingService.hideLoader();
    });
  }

  getPizzaSize() {
    let sizes: ProductViewModel[] = [];

    if (this.conditionSizeIds.length <= 0) {
      this.pizzaSizes = this.productService.pizzaSizes;
      return;
    }

    if (this.productService.pizzaSizes !== undefined) {
      this.productService.pizzaSizes.forEach((originSize) => {
        this.conditionSizeIds.forEach((conditionSize) => {
          if (originSize.originalExternalSizeId == conditionSize) {
            sizes.push(originSize);
          }
        });
      });
    }

    this.pizzaSizes = sizes;
  }

  setDefaultPizzaSize() {
    if (this.pizzaSizes.length >= 1) {
      this.productService.selectedPizzaSize = this.pizzaSizes[0];
    }
  }

  onSave(): void {
    if (this.modifiersComponent.isValid()) {
      this.productService.basketItem.hidePizzaSize = !(
        this.productService.isPizza &&
        this.productService.selectedPizzaSize &&
        (!this.place.autohidePizzaOptions ||
          (this.conditionSizeIds ? this.pizzaSizes : this.productService.pizzaSizes).length > 1)
      );

      this.productService.basketItem.hidePizzaDough = !(
        this.productService.isPizza &&
        this.productService.selectedPizzaSize &&
        this.productService.selectedPizzaDough &&
        this.productService.pizzaDoughList &&
        this.productService.pizzaDoughList.length > 0 &&
        (!this.place.autohidePizzaOptions || this.productService.pizzaDoughList.length > 1)
      );

      this.productService.basketItem.originalExternalGroupIdFromDiscount = this.originalExternalGroupIdFromDiscount;
      this.selectProductEvent.emit(this.productService.basketItem);
      this.closeDialog();
    }
  }

  openDialog(isMobile: boolean = false): void {
    let config: any = {
      panelClass: 'ingredients-overlay',
      data: {
        modifierGroups: this.productService.pizzaIngredients,
      },
    };
    const dialogRef = this.dialog.open(ChangeIngredientsDialogComponent, config);
  }

  @Output() public selectProductEvent: EventEmitter<any> = new EventEmitter<any>();

  @Output() public closeDialogEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

  closeDialog() {
    this.closeDialogEvent.emit(true);
  }
}

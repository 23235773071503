import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'removeDots',
})
export class RemoveDotsPipe implements PipeTransform {
  transform(str: string): string {
    return str ? str.replace(/\./g, '') : str;
  }
}

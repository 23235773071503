import { AbstractViewModel } from '../../../abstract.view.model';
import { CardItemShippingAddressApiModel } from '../../../api-model/order/card-item/card-item-shipping-address.api.model';

export class CardItemShippingAddressViewModel extends AbstractViewModel<CardItemShippingAddressApiModel> {
  firstName: string;
  lastName: string;
  city: string;
  street: string;
  streetNumber: string;
  postalCode: string;
  address1: string;
  address2: string;

  constructor(protected apiModel: CardItemShippingAddressApiModel = new CardItemShippingAddressApiModel()) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.firstName = this.apiModel.firstName;
    this.lastName = this.apiModel.lastName;
    this.city = this.apiModel.city;
    this.street = this.apiModel.street;
    this.streetNumber = this.apiModel.streetNumber;
    this.postalCode = this.apiModel.postalCode;
    this.address1 = this.apiModel.address1;
    this.address2 = this.apiModel.address2;
  }

  toApiModel(): CardItemShippingAddressApiModel {
    return undefined;
  }
}

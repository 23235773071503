import { Injectable } from '@angular/core';
import { LoadingService } from 'src/app/shared/loading/loading.service';
import {
  CateringAggregationArticleViewModel,
  CateringAggregationViewModel,
} from '../models/catering-aggregation.view.model';
import { OrderStateService } from './order.state.service';
import { OrderCateringService, SelectedModifierItemMap } from './order-catering.service';
import { CateringService } from './catering.service';
import { cloneDeep } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class CartService {
  constructor(
    private orderStateService: OrderStateService,
    private orderCateringService: OrderCateringService,
    private cateringService: CateringService,
    private loadingService: LoadingService,
  ) {}

  addCart(item: CateringAggregationArticleViewModel) {
    console.log('addCart');
    const requestItem = cloneDeep(item);
    requestItem.basketItemId = requestItem.id;
    requestItem.selectedQuantity = 1;

    this.loadingService.showLoader();
    this.orderCateringService.post(requestItem).subscribe(
      (_) => {
        this.loadingService.hideLoader();
      },
      (err) => {
        this.orderStateService.onError(err);
      },
    );
  }

  patchQuantity(item: CateringAggregationArticleViewModel, quantity: number = 0): number {
    console.log('patchQuantity');
    const requestItem = cloneDeep(item);
    console.log('patchQuantity:', requestItem);

    const model = new CateringAggregationViewModel();
    model.articles = [requestItem];
    const combine = this.buildSelectedArticleCombinationList(model).find((o) =>
      requestItem.basketItemId ? o.basketItemId === requestItem.basketItemId : o.id === requestItem.id,
    );
    console.log('quantity before:', requestItem.selectedQuantity);

    requestItem.selectedQuantity = combine.selectedQuantity + quantity;
    requestItem.basketItemId = combine.basketItemId;

    console.log('quantity after:', requestItem.selectedQuantity);

    this.loadingService.showLoader();
    const actionSource =
      requestItem.selectedQuantity === 0
        ? this.orderCateringService.removeItem(requestItem)
        : this.orderCateringService.patchQuantityItem(requestItem);

    actionSource.subscribe(
      (_) => {
        this.loadingService.hideLoader();
      },
      (err) => this.orderStateService.onError(err),
    );

    return requestItem.selectedQuantity;
  }

  private buildSelectedArticleCombinationList(cateringAggregation: CateringAggregationViewModel) {
    const state = this.orderStateService.getState();
    if (!state || !state.order || !state.order.fbItems.length) {
      return;
    }
    console.log('buildSelectedArticleCombinationList');
    const selectedMap: SelectedModifierItemMap = this.orderCateringService.buildSelectedMap(
      cateringAggregation,
      state.order.fbItems,
    );

    if (Object.keys(selectedMap).length > 0) {
      const articleCombinationList: Array<CateringAggregationArticleViewModel> = [];

      for (const articleId of Object.keys(selectedMap)) {
        for (const mapping of selectedMap[articleId]) {
          const articleCombination: CateringAggregationArticleViewModel = this.cateringService.buildSelectedArticle(
            mapping.article,
            mapping.selectedQuantity,
            mapping.selectedModifierItemMap,
            mapping.selectedSubArticleMap,
          );

          if (articleCombination) {
            articleCombinationList.push(articleCombination);
          }
        }
      }

      return articleCombinationList.map((x) => x);
    } else {
      return [];
    }
  }
}

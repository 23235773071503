import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { cloneDeep } from 'lodash';
import {
  CateringAggregationArticleModifierItemViewModel,
  CateringAggregationArticleModifierViewModel,
  CateringAggregationArticleViewModel,
  CateringAggregationViewModel,
} from '../models/catering-aggregation.view.model';
import { OrderStateService } from './order.state.service';
import { OrderDataProvider } from '../data-providers/order.data-provider';
import { OrderViewModel } from '../models/view-model/order/order.view.model';
import { OrderStateModel } from '../models/order.state.model';
import { FbItemViewModel } from '../models/view-model/order/fb-item/fb-item.view.model';
import { FbItemSubArticleViewModel } from '../models/view-model/order/fb-item/fb-item-sub-article.view.model';
import { FbItemModifierItemViewModel } from '../models/view-model/order/fb-item/fb-item-modifier-item.view.model';

export type SelectedModifierItemMap = {
  [key: string]: Array<{
    article: CateringAggregationArticleViewModel;
    selectedQuantity: number;
    selectedModifierItemMap: Map<string, Map<string, boolean>>;
    selectedSubArticle: Map<string, Map<string, boolean>>;
  }>;
}[];

@Injectable({
  providedIn: 'root',
})
export class OrderCateringService {
  private _selectedOrderCatering = [];

  constructor(private orderStateService: OrderStateService, private orderDataProvider: OrderDataProvider) {}

  /**
   * Patches catering items into Order
   */
  public patch(articleCombinationList: CateringAggregationArticleViewModel[]): Observable<OrderViewModel> {
    const state: OrderStateModel = this.orderStateService.getState();
    const items: FbItemViewModel[] = articleCombinationList.map((articleCombination) => {
      return this.buildArticleCombinationOrderItem(articleCombination);
    });
    return this.orderDataProvider.patchCateringItems(state.place.publicId, items, state.order).pipe(
      tap((resOrder: OrderViewModel) => {
        this.orderStateService.setOrder(resOrder);
      }),
    );
  }

  /**
   * Post catering item into Order
   */
  public post(articleCombination: CateringAggregationArticleViewModel): Observable<OrderViewModel> {
    const state: OrderStateModel = this.orderStateService.getState();
    const item = this.buildArticleCombinationOrderItem(articleCombination);
    return this.orderDataProvider.postCateringItem(state.place.publicId, state.order.id, item).pipe(
      tap((resOrder: OrderViewModel) => {
        this.orderStateService.setOrder(resOrder);
      }),
    );
  }

  /**
   * Post catering item into Order
   */
  public patchItem(articleCombination: CateringAggregationArticleViewModel): Observable<OrderViewModel> {
    const state: OrderStateModel = this.orderStateService.getState();
    const item = this.buildArticleCombinationOrderItem(articleCombination);
    return this.orderDataProvider
      .patchCateringItem(state.place.publicId, state.order.id, articleCombination.basketItemId, item)
      .pipe(
        tap((resOrder: OrderViewModel) => {
          this.orderStateService.setOrder(resOrder);
        }),
      );
  }

  public patchQuantityItem(articleCombination: CateringAggregationArticleViewModel): Observable<OrderViewModel> {
    const state: OrderStateModel = this.orderStateService.getState();
    return this.orderDataProvider
      .patchQuantityCateringItem(
        state.place.publicId,
        state.order.id,
        articleCombination.basketItemId,
        articleCombination.selectedQuantity,
      )
      .pipe(
        tap((resOrder: OrderViewModel) => {
          this.orderStateService.setOrder(resOrder);
        }),
      );
  }

  public removeItem(articleCombination: CateringAggregationArticleViewModel): Observable<OrderViewModel> {
    const state: OrderStateModel = this.orderStateService.getState();
    return this.orderDataProvider
      .deleteCateringItem(state.place.publicId, state.order.id, articleCombination.basketItemId)
      .pipe(
        tap((resOrder: OrderViewModel) => {
          this.orderStateService.setOrder(resOrder);
        }),
      );
  }

  /**
   * Builds selected map
   */
  public buildSelectedMap(
    cateringAggregation: CateringAggregationViewModel,
    orderItems: FbItemViewModel[],
  ): SelectedModifierItemMap {
    const rootMap: SelectedModifierItemMap = {} as any;

    orderItems.forEach((orderCateringItem) => {
      const article: CateringAggregationArticleViewModel = this.getArticleFromOrderItem(
        cateringAggregation,
        orderCateringItem,
      );
      article.basketItemId = orderCateringItem.id;
      article.price = orderCateringItem.price;
      article.voucherName = orderCateringItem.voucherName;
      article.voucherNumber = orderCateringItem.voucherNumber;

      const res = {
        article,
        selectedQuantity: orderCateringItem.quantity,
        selectedModifierItemMap: new Map<string, Map<string, boolean>>(),
        selectedSubArticleMap: Array<FbItemSubArticleViewModel>(),
      };

      article.modifierArticleList.forEach((articleModifier) => {
        const map: Map<string, boolean> = new Map<string, boolean>();

        articleModifier.itemCollection.forEach((articleModifierItem) => {
          const isSelected: boolean =
            orderCateringItem.modifierItemList.find((x) => x.modifierItemId === articleModifierItem.id) !== undefined;

          map.set(articleModifierItem.id, isSelected);
        });

        res.selectedModifierItemMap.set(articleModifier.id, map);
      });

      res.selectedSubArticleMap = orderCateringItem.subArticleList;

      if (!rootMap[article.id]) {
        rootMap[article.id] = [];
      }

      rootMap[article.id].push(res);
    });

    return rootMap;
  }

  private getArticleFromOrderItem(
    cateringAggregation: CateringAggregationViewModel,
    orderedItem: FbItemViewModel,
  ): CateringAggregationArticleViewModel {
    if (!cateringAggregation.articles) {
      return null;
    }

    let article: CateringAggregationArticleViewModel | undefined = cateringAggregation.articles.find((element) => {
      return element.id === orderedItem.articleId;
    });

    if (!article) {
      article = this.buildCateringAggregationArticleViewModel(orderedItem);
    } else {
      article = cloneDeep(article);
    }

    return article;
  }

  private buildArticleCombinationOrderItem(articleCombination: CateringAggregationArticleViewModel): FbItemViewModel {
    const orderItem: FbItemViewModel = new FbItemViewModel();
    orderItem.articleId = articleCombination.id;
    orderItem.quantity = articleCombination.selectedQuantity;

    articleCombination.modifierArticleList.forEach((modifier) => {
      modifier.itemCollection.forEach((modifierItem) => {
        const orderModifierItem: FbItemModifierItemViewModel = new FbItemModifierItemViewModel();
        orderModifierItem.modifierItemId = modifierItem.id;

        orderItem.modifierItemList.push(orderModifierItem);
      });
    });

    articleCombination.subArticleList.forEach((subArticle) => {
      const orderSubArticle = new FbItemSubArticleViewModel();
      orderSubArticle.articleId = subArticle.id;

      subArticle.replacementList.forEach((replacer) => {
        orderSubArticle.articleId = replacer.id;
      });

      orderItem.subArticleList.push(orderSubArticle);
    });

    return orderItem;
  }

  public set selectedOrderCatering(value) {
    this._selectedOrderCatering = value;
  }

  public get selectedOrderCatering() {
    return this._selectedOrderCatering;
  }

  private buildCateringAggregationArticleViewModel(orderItem: FbItemViewModel): CateringAggregationArticleViewModel {
    const cateringAggregationArticleViewModel: CateringAggregationArticleViewModel =
      new CateringAggregationArticleViewModel();
    cateringAggregationArticleViewModel.id = orderItem.articleId;
    cateringAggregationArticleViewModel.name = orderItem.name;
    cateringAggregationArticleViewModel.selectedQuantity = orderItem.quantity;
    cateringAggregationArticleViewModel.price = orderItem.price;
    cateringAggregationArticleViewModel.modifierArticleList = new Array<CateringAggregationArticleModifierViewModel>();
    cateringAggregationArticleViewModel.subArticleList = new Array<CateringAggregationArticleViewModel>();

    if (orderItem.modifierItemList) {
      orderItem.modifierItemList.forEach((modifier) => {
        const articleModifierItemViewModel: CateringAggregationArticleModifierItemViewModel =
          new CateringAggregationArticleModifierItemViewModel();
        articleModifierItemViewModel.id = modifier.modifierItemId;
        articleModifierItemViewModel.quantity = modifier.quantity;
        articleModifierItemViewModel.price = modifier.price * modifier.quantity;
        articleModifierItemViewModel.name = modifier.modifierItemName;

        const foundModifier = cateringAggregationArticleViewModel.modifierArticleList.find(
          (element) => element.id === modifier.modifierId,
        );

        if (foundModifier) {
          foundModifier.itemCollection.push(articleModifierItemViewModel);
        } else {
          const articleModifierViewModel = new CateringAggregationArticleModifierViewModel();
          articleModifierViewModel.itemCollection.push(articleModifierItemViewModel);
          articleModifierViewModel.id = modifier.modifierId;
          articleModifierViewModel.name = modifier.modifierName;
          articleModifierViewModel.separateItem = Boolean(modifier.isSeparate);
          cateringAggregationArticleViewModel.modifierArticleList.push(articleModifierViewModel);
        }
      });
    }

    if (orderItem.subArticleList) {
      orderItem.subArticleList.forEach((subArticle) => {
        const articleViewModel = new CateringAggregationArticleViewModel();
        articleViewModel.id = subArticle.articleId;
        articleViewModel.selectedQuantity = subArticle.quantity;
        articleViewModel.price = subArticle.price;
        articleViewModel.name = subArticle.name;
        articleViewModel.taxRate = subArticle.taxRate;
        cateringAggregationArticleViewModel.subArticleList.push(articleViewModel);
      });
    }

    return cateringAggregationArticleViewModel;
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HintComponent } from './hint.component';
import { TranslateModule } from '@ngx-translate/core';
import { TooltipModule } from 'primeng/tooltip';

@NgModule({
  imports: [CommonModule, TranslateModule, TooltipModule],
  declarations: [HintComponent],
  exports: [HintComponent],
  providers: [],
})
export class HintModule {}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { classToPlain, plainToClass } from 'class-transformer';
import { map } from 'rxjs/operators';
import { PayuPaymentMethodsApiModel } from '../../model/payu/payu-payment-methods.api.model';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class PayuPaymentMethodsRestService {
  constructor(private http: HttpClient) {}

  public getPaymentMethods(): Observable<PayuPaymentMethodsApiModel> {
    let placeId = sessionStorage.getItem('placeId');
    return this.http
      .get<PayuPaymentMethodsApiModel>(`/order/paymentMethods/PayU/` + placeId)
      .pipe(map((result) => plainToClass(PayuPaymentMethodsApiModel, result as object)));
  }
}

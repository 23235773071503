import { MissingTranslationHandlerParams } from '@ngx-translate/core';

export class WrapMissingTranslationHandler implements WrapMissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams) {
    // return params.key;
    return params.key.startsWith('layout.header.languagechange.')
      ? params.key.replace('layout.header.languagechange.', '')
      : params.key;
  }
}

import { Injectable } from '@angular/core';
import { OrderService } from '@core/services/order.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PlaceService } from 'src/app/services/place.service';
import { BaseDataProvider } from './base.data-provider';
import { SurveyCategoryEnum } from '@shared/enum/survey-category.enum';
import { SurveyHttpService } from '@core/http/tapp-order/survey/survey.http.service';
import { SurveyApiModel } from '@core/models/tapp-order/api-model/survey/survey.api.model';
import { SurveyViewModel } from '@core/models/tapp-order/view-model/survey/survey.view.model';
import { SurveyAnswersViewModel } from '@core/models/tapp-order/view-model/survey/survey-answers.view.model';

@Injectable({
  providedIn: 'root',
})
export class SurveyDataProvider {
  constructor(
    private placeService: PlaceService,
    private baseDataProvider: BaseDataProvider,
    private orderService: OrderService,
    private surveyHttpService: SurveyHttpService,
  ) {}

  getSurveysList(placeId: string, category?: SurveyCategoryEnum): Observable<SurveyViewModel[]> {
    return this.surveyHttpService
      .getSurveysList(placeId, category)
      .pipe(map((surveys: SurveyApiModel[]) => surveys.map((item) => new SurveyViewModel(item))));
  }

  saveSurveyAnswers(viewModel: SurveyAnswersViewModel): Observable<void> {
    return this.surveyHttpService.saveSurveyAnswers(viewModel.toApiModel()).pipe();
  }
}

<!-- Content -->
<div id="products-container" class="content" (scroll)="onScrollGroups($event)">
  <div class="navigator-empty-area" *ngIf="menu"></div>
  <!-- <ng-container *ngTemplateOutlet="closeAlert"></ng-container> -->

  <span [innerHTML]="'tapp-order.product-list-pizza-second-part.choose-second-half' | translate"></span>

  <ng-container *ngIf="menu; else noMenu">
    <ng-container *ngIf="menu.groups">
      <ng-container *ngFor="let group of menu.groups">
        <div id="{{ group.publicId }}" class="group" #groupBar>
          <div class="group__title">
            <div class="group__title__background-text">{{ group.name }}</div>
            <div class="group__title__front">
              <h2 class="group__title__front__text">{{ group.name }}</h2>
            </div>
          </div>

          <div class="group__products" *ngIf="group.products?.length">
            <app-product-item
              *ngFor="let product of group.products"
              [data]="product"
              [buttonVisible]="isOpen && !placeIsPreviewOnly"
              [buttonLabel]="'tapp-order.pages.product.common.select' | translate"
              (onAdd)="handleSelectSecondPart(product)"
            ></app-product-item>
          </div>

          <div class="subgroups" *ngIf="group.subgroups?.length">
            <div *ngFor="let subgroup of group.subgroups" class="subgroup">
              <ng-container *ngIf="subgroup.products?.length">
                <span class="subgroup__title">
                  <img
                    *ngIf="subgroup.icon.url"
                    src="{{ subgroup.icon.url }}"
                    class="icon-color"
                    style="height: 24px; width: 24px; margin-right: 1rem"
                  />
                  <span>{{ subgroup.name }}</span></span
                >
                <div class="subgroup__products">
                  <app-product-item
                    *ngFor="let product of subgroup.products"
                    [data]="product"
                    [buttonVisible]="isOpen && !placeIsPreviewOnly"
                    [buttonLabel]="'tapp-order.pages.product.common.select' | translate"
                    (onAdd)="handleSelectSecondPart(product)"
                  ></app-product-item>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>

  <ng-template #noMenu>
    <p>{{ 'products.noItemsTitle' | translate }}</p>
    <p>{{ 'products.noItemsDescription' | translate }}</p>
  </ng-template>

  <div class="buttom-button-empty-area" *ngIf="menu"></div>
</div>

<!-- Close alert -->
<!-- <ng-template #closeAlert>
  <div class="close-alert" *ngIf="!isOpen">
    <span>{{ "products.local-closed-message" | translate }}</span>
    <span *ngIf="nextWorkingHours">
      {{ "tapp-order.sidebar-component.local-opening" | translate }}:
      <b>
        {{ "app.weekDays." + nextWorkingHours.weekDay | translate }}
        {{ nextWorkingHours.startTime }}</b
      >
    </span>
  </div>
</ng-template> -->

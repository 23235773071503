export class ScreeningItemExtraFeeApiModel {
  id: string;

  name: string;

  quantity: number;

  price: number;

  taxRate: number;

  isOptional: boolean;

  defaultPriceLevelPrice: number;
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { plainToClass } from 'class-transformer';
import { map } from 'rxjs/operators';
import { TpayPaymentMethodsApiModel } from '@api/model/tpay/tpay-payment-methods.api.model';

@Injectable({
  providedIn: 'root',
})
export class TpayPaymentMethodsRestService {
  constructor(private http: HttpClient) {}

  public getPaymentMethods(): Observable<TpayPaymentMethodsApiModel> {
    let placeId = sessionStorage.getItem('placeId');
    return this.http
      .get<TpayPaymentMethodsApiModel>(`/order/paymentMethods/Tpay/` + placeId)
      .pipe(map((result) => plainToClass(TpayPaymentMethodsApiModel, result as object)));
  }
}

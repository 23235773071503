import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { plainToInstance } from 'class-transformer';
import { Cacheable, LocalStorageStrategy } from 'ts-cacheable';
import { HolidayApiModel } from '@core/models/tapp-order/api-model/holiday/holiday.api.model';

@Injectable({
  providedIn: 'root',
})
export class HolidayHttpService {
  constructor(private http: HttpClient) {}

  @Cacheable({
    storageStrategy: LocalStorageStrategy,
  })
  getHolidaysList(): Observable<HolidayApiModel[]> {
    return this.http
      .get<HolidayApiModel[]>('/core/holidays')
      .pipe(map((result: HolidayApiModel[]) => plainToInstance(HolidayApiModel, result as object[])));
  }
}

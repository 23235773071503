import { AbstractViewModel } from '../../../abstract.view.model';
import { ModifierApiModel } from '../../api-model/product/modifier.api.model';
import { ProductViewModel } from './product.view.model';
import { ModifierTypeEnum } from '@shared/enum/modifier-type.enum';

export class ModifierViewModel extends AbstractViewModel<ModifierApiModel> {
  public internalId: string;
  public publicId: string;
  public name: string;
  public description: string;
  public minQuantity: number;
  public maxQuantity: number;
  public defaultQuantity: number;
  public modifierType: ModifierTypeEnum;
  public products: ProductViewModel[];

  public hasError: boolean = false;
  public isExpanded: boolean = true;

  constructor(protected apiModel: ModifierApiModel = new ModifierApiModel()) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.internalId = this.apiModel.internalId;
    this.publicId = this.apiModel.publicId;
    this.name = this.apiModel.name;
    this.description = this.apiModel.description;
    this.minQuantity = this.apiModel.minQuantity;
    this.maxQuantity = this.apiModel.maxQuantity;
    this.defaultQuantity = this.apiModel.defaultQuantity;
    this.modifierType = this.apiModel.modifierType;
    this.products = this.apiModel.products
      ? this.apiModel.products.map((product) => {
          let productViewModel = new ProductViewModel(product);
          productViewModel.modifierType = this.modifierType;
          productViewModel.groupId = this.publicId;
          productViewModel.groupName = this.name;

          return productViewModel;
        })
      : [];
  }

  toApiModel(): ModifierApiModel {
    return undefined;
  }
}

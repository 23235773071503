<div class="basket-wrapper">
  <div class="basket-name">
    <span>{{ 'tapp-order.sidebar-basket-header' | translate }}</span>
  </div>
  <div class="basket-list">
    <ng-container *ngIf="basketItems?.length === 0">
      <div class="empty-basket d-flex align-items-center flex-column justify-content-center h-100">
        <img src="./assets/icons/basket1.png" alt="" />
        <span>{{ 'tapp-order.your-order-no-items' | translate }}</span>
        <span>{{ 'tapp-order.your-order-add-something' | translate }}</span>
      </div>
    </ng-container>
    <ng-container *ngFor="let item of basketItems">
      <app-article-card-expandable
        *ngIf="place"
        [showDescription]="false"
        [basketItem]="item"
        [item]="item"
        [renderedInOrderSummary]="true"
        [quantity]="item.CurrentQuantity"
        [basketMode]="true"
        [mobileMode]="true"
        [showModifyButton]="true"
        [showModifyRedirect]="[place.publicId + '/modify/' + item.BasketItemID]"
      >
        <ng-template #articleCardPopup>
          <app-article-card-expandable
            [renderedInOrderSummary]="true"
            [basketItem]="item"
            [quantity]="item.CurrentQuantity"
            [item]="item"
            [isExpandable]="false"
            [expanded]="true"
            [isInModal]="true"
            (closeModal)="hideModal()"
          ></app-article-card-expandable>
        </ng-template>
      </app-article-card-expandable>
    </ng-container>
  </div>
  <div class="basket-summary-box">
    <div *ngIf="discountService.data$ | async as discountData" class="promo-code">
      <span class="promo-code__text">{{ 'tapp-order.sidebar-component.discount-code' | translate }}</span>
      <input
        pInputText
        class="promo-code__input"
        [class.ng-invalid]="discountData.promoCodeStatus === 'not_active'"
        type="text"
        placeholder=""
        [ngModel]="promoCode"
        (ngModelChange)="hanldePromoCodeChange($event)"
      />
      <button
        pButton
        pRipple
        type="button"
        class="p-button p-button-sm p-button-primary"
        [label]="'common.action.activate' | translate"
        (click)="activateDiscountCode()"
        [disabled]="!promoCode || discountService.promoCode === promoCode"
      ></button>
      <span class="promo-code__tip">
        <small *ngIf="discountData.promoCodeStatus === 'active_used'" class="promo-code__tip--success">
          {{ 'tapp-order.sidebar-component.code-activated' | translate }}
        </small>
        <small *ngIf="discountData.promoCodeStatus === 'not_active'" class="p-error">
          {{ 'tapp-order.sidebar-component.no-active-promotion-for-code' | translate }}
        </small>
        <small *ngIf="discountData.promoCodeStatus === 'active_not_used_other_conditions_missed'" class="p-error">
          {{ 'tapp-order.sidebar-component.promotion-conditions-not-met' | translate }}
        </small>
        <small *ngIf="discountData.promoCodeStatus === 'active_not_used'" class="promo-code__tip--warning">
          {{ 'tapp-order.sidebar-component.code-valid-but-better-promotion-applied' | translate }}
        </small>
      </span>
    </div>
    <div class="food-cost">
      <span>{{ 'tapp-order.sidebar-basket-header' | translate }}</span>
      <span class="price">{{ basketService.foodTotalPrice$ | async | currency: 'PLN':'zł':'1.2-2':'pl-PL' }}</span>
    </div>
    <div class="delivery-cost" *ngIf="deliveryZone && deliveryZone.deliveryPrice">
      <span>{{ 'tapp-order.order-component.delivery-cost' | translate }}</span>
      <span class="price">{{ basketService.deliveryCost$ | async | currency: 'PLN':'zł':'1.2-2':'pl-PL' }}</span>
    </div>
    <div class="row">
      <div class="col">
        <div *ngIf="tableId" class="amount text-left">
          {{ 'tapp-order.order-summary-room' | translate }}: {{ roomName }}
          <br />
          {{ 'tapp-order.order-summary-table' | translate }}: {{ tableName }}
        </div>
      </div>
      <div class="col">
        <span class="amount"
          >{{ 'tapp-order.order-component.quantity' | translate }} {{ basketService.calculateAllItemsInBasket() }}</span
        >
      </div>
    </div>
    <ng-container
      *ngIf="calculateMinOrderPrice() <= 0 || basketService.getOrderType() !== 'delivery'; else minOrderInfo"
    >
      <div
        *ngIf="
          !place?.maxOrderPrice || (basketService.foodTotalPrice$ | async) <= place.maxOrderPrice;
          else overpricedInfo
        "
        class="button"
        [ngClass]="{ disabled: basketService.basketItems.length === 0 }"
        (click)="redirectToPaymentsPage()"
      >
        <div class="left d-flex flex-row align-items-center">
          <img src="/assets/icons/shopping-cart.png" class="fa fa-cart-shopping" />
          <span class="order-btn">{{ 'tapp-order.ordering-button-text' | translate }}</span>
        </div>
        <span class="right">{{ basketService.finalPrice$ | async | currency: 'PLN':'zł':'1.2-2':'pl-PL' }}</span>
      </div>
    </ng-container>
    <ng-template #overpricedInfo>
      <span class="p-error min-price-error">
        {{ 'tapp-order.sidebar-component.max-order-amount' | translate }}
        {{ place.maxOrderPrice | currency: 'PLN':'zł':'1.2-2':'pl-PL' }}
      </span>
    </ng-template>
    <ng-template #minOrderInfo>
      <div *ngIf="calculateMinOrderPrice() > 0" class="min-order-info">
        <span>{{ 'tapp-order.order-component.minimal-order-price-info' | translate }}</span>
        <span class="min-order-inof-price"> {{ calculateMinOrderPrice() | currency: 'PLN':'zł':'1.2-2':'pl-PL' }}</span>
      </div>
      <div *ngIf="calculateMinOrderPrice() > 0" class="min-order-info-text">
        <span>{{ 'tapp-order.order-component.add-more-articles' | translate }}</span>
      </div>
    </ng-template>
  </div>
</div>

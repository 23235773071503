import { Exclude, Expose, Type } from 'class-transformer';

@Exclude()
export class ResultListApiModel<T> {
  @Expose()
  @Type((options) => (options.newObject as ResultListApiModel<T>).type)
  public records: T[];

  @Expose()
  public total: number;

  private type: Function;

  constructor(type: Function) {
    this.type = type;
  }
}

<ng-container *ngIf="ordersListTotal === 0; else historyList">
  <div class="row no-orders">
    <div class="col d-flex align-items-center justify-content-center flex-column">
      <div class="row">
        <div class="col">
          <i class="ic ic-order-history"></i>
        </div>
      </div>
      <div class="row">
        <div class="col title p-3">
          <span>{{ 'tapp-order.pages.logged-in-user.components.order-history-list.no-orders-yet' | translate }}</span>
        </div>
      </div>
      <div class="row">
        <div class="col button">
          <div class="ui-clickable" [routerLink]="this.productsPage">
            <span> {{ 'tapp-order.pages.logged-in-user.components.order-history-list.make-order' | translate }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<ng-template #historyList>
  <div class="row">
    <div
      class="col"
      infiniteScroll
      [infiniteScrollDistance]="2"
      [alwaysCallback]="true"
      (scrolled)="onScroll()"
      [scrollWindow]="false"
      [fromRoot]="true"
      [infiniteScrollContainer]="scrollContainer"
    >
      <ng-container *ngFor="let order of ordersListItems">
        <app-order-item [order]="order"></app-order-item>
      </ng-container>
    </div>
  </div>
</ng-template>

import { AbstractViewModel } from '../../../abstract.view.model';
import { ScreeningItemExtraFeeApiModel } from '../../../api-model/order/screening-item/screening-item-extra-fee.api.model';

export class ScreeningItemExtraFeeViewModel extends AbstractViewModel<ScreeningItemExtraFeeApiModel> {
  id: string;
  name: string;
  quantity: number;
  price: number;
  taxRate: number;
  isOptional: boolean;
  defaultPriceLevelPrice: number;

  constructor(protected apiModel: ScreeningItemExtraFeeApiModel = new ScreeningItemExtraFeeApiModel()) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.id = this.apiModel.id;
    this.name = this.apiModel.name;
    this.quantity = this.apiModel.quantity;
    this.price = this.apiModel.price;
    this.taxRate = this.apiModel.taxRate;
    this.isOptional = this.apiModel.isOptional;
    this.defaultPriceLevelPrice = this.apiModel.defaultPriceLevelPrice;
  }

  toApiModel(): ScreeningItemExtraFeeApiModel {
    return Object.assign(this.apiModel, {
      id: this.id,
      quantity: this.quantity,
    });
  }
}

import { CalculateDiscountResponseApiModel } from '@api/model/discount/calculate-discount-response.api.model';
import { AbstractViewModel } from '@core/models/abstract.view.model';
import { DateTime } from 'luxon';
import { CalculateDiscountItemResponseViewModel } from './calculate-discount-item-response.view.model';
import { CalculateDiscountNotUsedResponseViewModel } from './calculate-discount-not-used-response.view.model';
import { CalculateDiscountUsedResponseViewModel } from './calculate-discount-used-response.view.model';

export class CalculateDiscountResponseViewModel extends AbstractViewModel<CalculateDiscountResponseApiModel> {
  public placeId: string = null;
  public onlyForcedDiscount: boolean = false;
  public totalItemsPrice: number = null;
  public totalItemsDiscount: DateTime = null;
  public itemsCount: DateTime = null;
  public items: CalculateDiscountItemResponseViewModel[] = [];
  public discounts: CalculateDiscountUsedResponseViewModel[] = [];
  public forcedDiscountsNotUsed: CalculateDiscountNotUsedResponseViewModel[] = [];

  /**
   * @see CalculateDiscountResponseApiModel.promoCodeStatus for more details.
   */
  public promoCodeStatus: 'active_used' | 'active_not_used' | 'not_active' | 'active_not_used_other_conditions_missed';

  public constructor(protected apiModel: CalculateDiscountResponseApiModel = new CalculateDiscountResponseApiModel()) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.placeId = this.apiModel.placeId || null;
    this.onlyForcedDiscount = this.apiModel.onlyForcedDiscount || false;
    this.totalItemsPrice = this.apiModel.totalItemsPrice || null;
    this.totalItemsDiscount = this.apiModel.totalItemsDiscount || null;
    this.itemsCount = this.apiModel.itemsCount || null;
    this.items = this.apiModel.items
      ? this.apiModel.items.map((value) => new CalculateDiscountItemResponseViewModel(value))
      : [];
    this.discounts = this.apiModel.discounts
      ? this.apiModel.discounts.map((value) => new CalculateDiscountUsedResponseViewModel(value))
      : [];
    this.forcedDiscountsNotUsed = this.apiModel.forcedDiscountsNotUsed
      ? this.apiModel.forcedDiscountsNotUsed.map((value) => new CalculateDiscountNotUsedResponseViewModel(value))
      : [];
    this.promoCodeStatus = this.apiModel.promoCodeStatus;
  }

  public apply(value: any): CalculateDiscountResponseViewModel {
    Object.assign(this, value);
    return this;
  }

  toApiModel(): CalculateDiscountResponseApiModel {
    return Object.assign(this.apiModel, {
      placeId: this.placeId,
      onlyForcedDiscount: this.onlyForcedDiscount,
      totalItemsPrice: this.totalItemsPrice,
      totalItemsDiscount: this.totalItemsDiscount,
      itemsCount: this.itemsCount,
      items: this.items.map((i) => i.toApiModel()),
      discounts: this.discounts.map((i) => i.toApiModel()),
      forcedDiscountsNotUsed: this.forcedDiscountsNotUsed.map((i) => i.toApiModel()),
    });
  }
}

<div class="row" *ngIf="!isLoading">
  <div class="col">
    <div class="row">
      <div class="col d-md-none d-flex header">
        <span>{{ 'tapp-order.pages.logged-in-user.user-settings.page-title' | translate }}</span>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <app-delivery-payment-address
          [isPaymentMode]="false"
          [user]="user"
          [isAccountPage]="true"
          [context]="'account'"
        ></app-delivery-payment-address>
      </div>
    </div>
    <div class="row companies-title">
      <div class="col">
        <span>{{ 'tapp-order.pages.logged-in-user.user-settings.invoice' | translate }}</span>
      </div>
    </div>
    <div class="row companies-list">
      <div class="col-12 col-md-6" *ngFor="let company of companies">
        <app-company-tab
          (deleteCompanyEvent)="onDeleteCompanyEvent($event)"
          [company]="company"
          [isActive]="company.isDefault"
          (changeCompanyListEvent)="onChangeCompanyListEvent($event)"
          (changeDefaultCompanyEvent)="onChangeDefaultCompanyEvent($event)"
        ></app-company-tab>
      </div>
      <div class="col-12 col-md-6">
        <app-company-tab (changeCompanyListEvent)="onChangeCompanyListEvent($event)"></app-company-tab>
      </div>
    </div>
    <div class="row terms-and-conditions-title">
      <div class="col">
        <span>{{ 'tapp-order.pages.logged-in-user.user-settings.terms-and-conditions' | translate }}</span>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-12">
        <small class="clickable-text" (click)="showTermsAndCoditions()">
          {{ 'tapp-order.pages.logged-in-user.user-settings.privacy-policy' | translate }}
        </small>
      </div>
    </div>
    <div #termsAndConditionsBlockRef class="row terms-and-conditions pt-5">
      <div class="col-12 col-md-6" *ngFor="let termAndCondition of termsAndConditions">
        <app-term-and-condition
          [termAndCondition]="termAndCondition"
          [borderRed]="showErrors && termAndCondition.required && !termAndCondition.selected"
          (termAndConditionChangeEvent)="onTermAndConditionChangeEvent($event)"
        ></app-term-and-condition>
      </div>
    </div>
    <div class="row pt-5">
      <div class="col d-flex">
        <div (click)="confirmAccountDelete()" class="delete-button ui-clickable">
          <i class="ic ic-delete"></i>
          {{ 'tapp-order.pages.logged-in-user.logged-in-user.delete-account' | translate }}</div
        >
      </div>
    </div>
  </div>
</div>

<app-dialog [(visible)]="termsVisible" [closable]="true" (onHide)="handleTermsHide()">
  <ng-template #header>
    <div class="dialog-terms__header">
      <span>{{ 'tapp-order.pages.logged-in-user.user-settings.privacy-policy' | translate }}</span>
    </div>
  </ng-template>

  <div class="dialog-terms__content">
    <div [innerHTML]="html"></div>
  </div>
</app-dialog>

import { OrderViewModel } from './view-model/order/order.view.model';
import { PlaceViewModel } from './tapp-order/view-model/place/place.view.model';

export class OrderStateModel {
  constructor(public place: PlaceViewModel = null, public order: OrderViewModel = null) {}

  public get readyForUse() {
    return this.place !== null && this.order != null;
  }
}

<div class="row">
  <div class="col">
    <div class="header-container d-flex justify-content-between">
      <div class="header-title text-left">
        <span>{{ 'tapp-order.pages.logged-in-user.components.order-history-modal.order' | translate }}</span>
      </div>
      <div class="header-content text-right">
        <span>{{ data.createdAt | date: 'dd.MM yyyy' }} </span>
        <span
          >{{ 'tapp-order.pages.logged-in-user.components.order-history-modal.hour' | translate }}
          {{ data.createdAt | date: 'HH:mm':'+0400' }}</span
        >
      </div>
    </div>
    <div class="row items-list-container sidebar-list">
      <div class="col">
        <app-article-card-expandable
          *ngFor="let item of data.orderItems; let i = index"
          [showDescription]="false"
          [basketItem]="transformToBasketModel(item)"
          [basketMode]="true"
          [item]="transformToBasketModel(item)"
          [editable]="false"
          [renderedInOrderSummary]="false"
          [quantity]="item.CurrentQuantity"
          [isSummaryMode]="true"
          [basketItemIndex]="i"
          [isDiscountTurnOn]="false"
          [paymentMode]="false"
          [showPrice]="true"
        >
        </app-article-card-expandable>
      </div>
    </div>
    <div class="row delivery-title mt-2">
      <div class="col">
        <span>{{ 'tapp-order.pages.logged-in-user.components.order-history-modal.delivery-address' | translate }}</span>
      </div>
    </div>
    <div class="row delivery-content">
      <ng-container *ngIf="data.deliveryAddress; else pickup">
        <div [class]="data.deliveryAddress.localNo ? 'col-6 text-left' : 'col-12 text-left'">
          <span>{{
            data.deliveryAddress.city + ', ul. ' + data.deliveryAddress.street + ' ' + data.deliveryAddress.buildingNo
          }}</span>
        </div>
        <div class="col-6 text-right" *ngIf="data.deliveryAddress.localNo">
          <span>{{
            ('tapp-order.pages.logged-in-user.components.order-history-modal.local-number' | translate) +
              ': ' +
              data.deliveryAddress.localNo
          }}</span>
        </div>
      </ng-container>
      <ng-template #pickup>
        <div class="col-12">
          <span>-</span>
        </div>
      </ng-template>
    </div>
    <div class="row">
      <div class="col">
        <div class="row price-order">
          <div class="col-6 price-order-title">
            <span>{{ 'tapp-order.pages.logged-in-user.components.order-history-modal.order' | translate }}: </span>
          </div>
          <div class="col-6 text-right">
            <span>{{ data.orderPayment.foodTotalPrice | currency: 'PLN':'zł':'1.2-2':'pl-PL' }}</span>
          </div>
        </div>
        <div class="row price-delivery" *ngIf="data.type === 'delivery'">
          <div class="col-6 price-delivery-title">
            <span>{{ 'tapp-order.pages.logged-in-user.components.order-history-modal.delivery' | translate }}: </span>
          </div>
          <div class="col-6 text-right">
            <span>{{ data.orderPayment.deliveryCost | currency: 'PLN':'zł':'1.2-2':'pl-PL' }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="row total-payment-container">
      <div class="col">
        <div class="row">
          <div class="col-6 total-payment-title">
            <span>{{ 'tapp-order.pages.logged-in-user.components.order-history-modal.total' | translate }}:</span>
          </div>
          <div class="col-6 text-right total-payment-value">
            <span>{{ data.orderPayment.totalPrice | currency: 'PLN':'zł':'1.2-2':'pl-PL' }}</span>
          </div>
        </div>
        <div class="row">
          <div class="col total-payment-method">
            <span
              >{{ ('tapp-order.pages.logged-in-user.components.order-history-modal.payment-method' | translate) + ': '
              }}{{
                'tapp-order.payment.payment-method.' + (data.orderPayment.paymentMethod | lowercase) | translate
              }}</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

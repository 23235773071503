import { Component, HostListener, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { PayuPaymentMethodsApiModel } from '@api/model/payu/payu-payment-methods.api.model';
import { PayuPaymentMethodsRestService } from '@api/rest/payu/payu-payment-methods.rest.service';
import { PaymentMethodTypeEnum } from '@core/pages/payment/enum/payment-method-type.enum';
import { OrderService } from '@core/services/order.service';
import { PaymentFormService } from '@core/pages/payment/payment-form.service';
import { TpayPaymentMethodsRestService } from '@api/rest/tpay/tpay-payment-methods.rest.service';
import { PlaceService } from '../../../services/place.service';
import { TpayPaymentMethodsApiModel } from '@api/model/tpay/tpay-payment-methods.api.model';
import { PlaceViewModel } from '@core/models/tapp-order/view-model/place/place.view.model';
import { PaymentProviderEnum } from '@core/models/tapp-order/api-model/order/PaymentProviderEnum';

declare let PayU: any;

@Component({
  selector: 'app-payment-method',
  templateUrl: './payment-method.component.html',
  styleUrls: ['./payment-method.component.scss'],
})
export class PaymentMethodComponent implements OnInit {
  private paymentMethodKey: string = 'paymentMethod';
  private paymentMethodTypeKey: string = 'paymentMethodType';
  private paymentMethodValueKey: string = 'paymentMethodValue';
  public paymentMethodTypeEnum: PaymentMethodTypeEnum;
  public selectedPaymentMethodValue: string;
  public paymentProviderEnum = PaymentProviderEnum;

  public blikForm: FormGroup;
  public blikCode: string = '';

  public paymentMethods: any = [];
  public selectedPaymentMethod: string = null;
  public paymentProvider: string = null;

  payuPaymentMethods: PayuPaymentMethodsApiModel;
  tpayPaymentMethods: TpayPaymentMethodsApiModel;

  constructor(
    public paymentFormService: PaymentFormService,
    private payuPaymentMethodsService: PayuPaymentMethodsRestService,
    private tpayPaymentMethodsRestService: TpayPaymentMethodsRestService,
    private orderService: OrderService,
    private placeService: PlaceService,
  ) {}

  ngOnInit(): void {
    this.placeService.getPlace().subscribe((place: PlaceViewModel) => {
      const paymentProvider = place.paymentProviders.find((item) => true);

      if (place.paymentByCardActive) {
        this.paymentMethods.push({ name: PaymentMethodTypeEnum.CARD });
      }

      if (place.paymentByCashActive) {
        this.paymentMethods.push({ name: PaymentMethodTypeEnum.CASH });
      }

      if (paymentProvider?.paymentProvider == PaymentProviderEnum.PayU) {
        this.paymentProvider = PaymentProviderEnum.PayU;
        this.orderService.setPaymentProvider(this.paymentProvider);

        this.payuPaymentMethodsService.getPaymentMethods().subscribe((payuPaymentsMethods) => {
          this.payuPaymentMethods = payuPaymentsMethods;

          this.paymentMethods = [
            ...this.paymentMethods,
            ...[
              { name: PaymentMethodTypeEnum.ONLINE },
              { name: PaymentMethodTypeEnum.BLIK },
              { name: PaymentMethodTypeEnum.CARD_TOKEN },
            ],
          ];
        });
      }

      if (paymentProvider?.paymentProvider == PaymentProviderEnum.Tpay) {
        this.paymentProvider = PaymentProviderEnum.Tpay;
        this.orderService.setPaymentProvider(this.paymentProvider);

        this.tpayPaymentMethodsRestService.getPaymentMethods().subscribe((tpayPaymentsMethods) => {
          this.tpayPaymentMethods = tpayPaymentsMethods;

          this.paymentMethods = [
            ...this.paymentMethods,
            ...[
              { name: PaymentMethodTypeEnum.ONLINE },
              { name: PaymentMethodTypeEnum.BLIK },
              //{ name: PaymentMethodTypeEnum.CARD_TOKEN },
            ],
          ];
        });
      }
    });
  }

  selectPaymentMethod() {
    localStorage.removeItem(this.paymentMethodKey);
    localStorage.removeItem(this.paymentMethodTypeKey);
    localStorage.removeItem(this.paymentMethodValueKey);
    switch (this.selectedPaymentMethod) {
      case PaymentMethodTypeEnum.ONLINE:
        this.orderService.setPaymentMethod(PaymentMethodTypeEnum.ONLINE);
        this.orderService.setPaymentMethodType(PaymentMethodTypeEnum.OTHER);
        break;
      case PaymentMethodTypeEnum.BLIK:
        this.orderService.setPaymentMethod(PaymentMethodTypeEnum.ONLINE);
        this.orderService.setPaymentMethodType(PaymentMethodTypeEnum.BLIK);
        break;
      case PaymentMethodTypeEnum.CARD_TOKEN:
        this.orderService.setPaymentMethod(PaymentMethodTypeEnum.ONLINE);
        this.orderService.setPaymentMethodType(PaymentMethodTypeEnum.CARD_TOKEN);
        break;
      case PaymentMethodTypeEnum.CARD:
        this.orderService.setPaymentMethod(PaymentMethodTypeEnum.CARD);
        break;
      case PaymentMethodTypeEnum.CASH:
        this.orderService.setPaymentMethod(PaymentMethodTypeEnum.CASH);
        break;
    }
  }

  selectPaymentMethodType(selectedPaymentMethod: string): void {
    this.selectedPaymentMethod = selectedPaymentMethod;
  }

  setSelectedPaymentValue() {
    this.orderService.setPaymentMethodValue(this.blikCode);
  }

  @HostListener('paste', ['$event'])
  onPaste(event) {
    console.log(event);
    console.log(event.clipboardData.getData('text'));
    event.preventDefault();
    let clipboardData = event.clipboardData;
    let pastedText = clipboardData.getData('text');
    let trimmedText = pastedText.trim();
    trimmedText = trimmedText.replace(/\s/g, '');
    trimmedText = trimmedText.replace(/\D/g, '');

    if (trimmedText.length > 0) {
      if (trimmedText.length > 6) {
        trimmedText = trimmedText.slice(0, 6 - trimmedText.length);
      }
      this.blikCode = trimmedText;

      if (this.blikCode.length === 6) {
        this.orderService.setPaymentMethodValue(this.blikCode);
        this.paymentFormService.paymentMethodRejected = false;
        this.paymentFormService.paymentBlikRejected = false;
      } else {
        this.paymentFormService.paymentBlikRejected = true;
      }
    }
  }

  numberOnly(event) {
    const charCode = event.which ? event.which : event.keyCode;
    if (event.target.value.length >= 6) {
      return false;
    }
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  trimBlikCode(event) {
    let blikCode = event.target.value;
    let pastedText = blikCode.getData('text');
    let trimmedText = pastedText.trim();
    trimmedText = trimmedText.replace(/\s/g, '');
    trimmedText = trimmedText.replace(/\D/g, '');

    if (trimmedText.length > 0) {
      this.blikCode = trimmedText;
    }

    if (trimmedText.length > 0) {
      if (trimmedText.length > 6) {
        trimmedText = trimmedText.slice(0, 6 - trimmedText.length);
      }
      this.blikCode = trimmedText;

      if (this.blikCode.length === 6) {
        this.paymentFormService.paymentBlikRejected = false;
      } else {
        this.paymentFormService.paymentBlikRejected = true;
      }
    }
  }

  public selectPaymentMethodValue(selectedPayuPaymentMethodValue: string): void {
    this.selectedPaymentMethodValue = selectedPayuPaymentMethodValue;
    this.orderService.setPaymentMethodValue(selectedPayuPaymentMethodValue);
  }
}

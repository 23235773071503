import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BasketItemViewModel } from '@core/models/tapp-order/view-model/basketItem/basket-item.view.model';
import { ProductViewModel } from '@core/models/tapp-order/view-model/product/product.view.model';
import { OrderViewModel } from '@core/pages/logged-in-user/model/order.view.model';

@Component({
  selector: 'app-order-history-modal',
  templateUrl: './order-history-modal.component.html',
  styleUrls: ['./order-history-modal.component.scss'],
})
export class OrderHistoryModalComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: OrderViewModel) {}

  // Temporary solution
  public transformToBasketModel(product: ProductViewModel): BasketItemViewModel {
    return product as BasketItemViewModel;
  }
}

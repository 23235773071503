<div class="payment-component payment-message-component">
  <div class="row mb-3">
    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
      <h2 class="header">
        {{ 'tapp-order.payment.payment-message.title' | translate }}
        <span class="light">({{ 'tapp-order.payment.payment-message.optional' | translate }})</span>
      </h2>
      <div class="message-parent-box" [formGroup]="messageForm">
        <textarea
          pInputTextarea
          formControlName="message"
          [autoResize]="true"
          [rows]="2"
          [maxLength]="250"
          [(ngModel)]="message"
          (ngModelChange)="setMessage($event)"
        ></textarea>
      </div>
    </div>
  </div>
</div>

<div
  class="help-tooltip mx-2 flex-wrap align-content-center d-inline-flex"
  [pTooltip]="message"
  [tooltipPosition]="position"
  [tooltipStyleClass]="tooltipClass"
  [escape]="false"
>
  <i class="fi flaticon-ask-inactive"></i>
  <i class="fi flaticon-ask"></i>
</div>

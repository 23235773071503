import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';
import { BehaviorSubject, forkJoin, Observable, ReplaySubject } from 'rxjs';
import { storageKey } from 'src/app/app.consts';
import { BaseDataProvider } from '../data-providers/base.data-provider';
import { OrderDataProvider } from '../data-providers/order.data-provider';
import { OrderHttpService } from '../http/order.http.service';
import { OrderStateModel } from '../models/order.state.model';
import { PaymentMethodModel } from '../models/payment-method.model';
import { PlaceViewModel } from '../models/tapp-order/view-model/place/place.view.model';
import { OrderViewModel } from '../models/view-model/order/order.view.model';
import { OrderService } from './order.service';

@Injectable()
export class OrderStateService {
  private model: OrderStateModel = new OrderStateModel();

  private state = new BehaviorSubject<OrderStateModel>(new OrderStateModel());
  state$: Observable<OrderStateModel> = this.state.asObservable();

  voucher$ = new ReplaySubject<string[]>();

  private orderExpirationDate = new BehaviorSubject<DateTime>(null);
  orderExpired = this.orderExpirationDate.asObservable();
  inProgress = false;

  constructor(
    private orderService: OrderService,
    private orderDataProvider: OrderDataProvider,
    private baseDataProvider: BaseDataProvider,
  ) {
    //this.loadState();
  }

  public orderNotExist(): boolean {
    return !this.model || !this.model.order;
  }

  loadState() {
    const placeId = sessionStorage.getItem(storageKey.placeId);
    const orderId = this.orderService.getOrderId();
    if (!placeId) {
      this.cleanStateAll();
      return;
    }

    forkJoin({
      place: this.baseDataProvider.getPlace(placeId),
      order: orderId ? this.orderDataProvider.findById(placeId, orderId) : this.orderDataProvider.create(placeId),
    }).subscribe(
      (res) => {
        this.setState(new OrderStateModel(res.place, res.order));
      },
      (error) => this.cleanStateAll(),
    );
  }

  getState(): OrderStateModel {
    return this.model;
  }

  setState(state: OrderStateModel) {
    this.model = state;
    if (state.order !== null) {
      this.orderService.setOrderId(state.order.id);
    }

    this.state.next(state);
  }

  cleanStateAll() {
    sessionStorage.removeItem(storageKey.placeId);
    this.cleanState();
  }

  cleanState() {
    sessionStorage.removeItem(storageKey.screening);
    sessionStorage.removeItem(storageKey.vouchers);
    sessionStorage.removeItem(storageKey.embededPaymentUrl);
    this.orderService.removeOrderId();

    this.model = new OrderStateModel();
    this.state.next(this.model);

    OrderHttpService.cacheBuster$.next();
  }

  init(placeId: string) {
    this.cleanState();
    this.setPlaceById(placeId);
  }

  private setPlace(place: PlaceViewModel) {
    this.model.place = place;
    this.state.next(this.model);

    sessionStorage.setItem(storageKey.placeId, place.publicId);
  }

  setPlaceById(placeId: string) {
    if (!this.model.place || this.model.place.publicId !== placeId) {
      const placeModel = new PlaceViewModel();
      placeModel.publicId = placeId;
      this.setPlace(placeModel);
    }
  }

  setOrder(order: OrderViewModel) {
    this.model.order = order;
    this.state.next(this.model);
    if (order.id !== null) {
      this.orderService.setOrderId(order.id);
    }
  }

  getOrderId() {
    return this.model && this.model.order ? this.model.order.id : null;
  }

  setScreeningInfo(value: any) {
    sessionStorage.setItem(storageKey.screening, JSON.stringify(value));
  }

  getScreeningInfo() {
    return JSON.parse(sessionStorage.getItem(storageKey.screening));
  }

  setVoucher(voucherNumber: string) {
    if (!voucherNumber) {
      return;
    }

    const vouchers = this.getVouchers();
    vouchers.push(voucherNumber);
    sessionStorage.setItem(storageKey.vouchers, vouchers.join(';'));
    this.voucher$.next(vouchers);
  }

  removeVoucher(voucherNumber: string) {
    if (!voucherNumber) {
      return;
    }

    const vouchers = this.getVouchers();
    const index = vouchers.indexOf(voucherNumber);
    if (index > -1) {
      vouchers.splice(index, 1);
    }

    if (vouchers.length) {
      sessionStorage.setItem(storageKey.vouchers, vouchers.join(';'));
      this.voucher$.next(vouchers);
    } else {
      this.removeVouchers();
      this.voucher$.next([]);
    }
  }

  removeVouchers() {
    sessionStorage.removeItem(storageKey.vouchers);
  }

  getVouchers() {
    const vouchersString = sessionStorage.getItem(storageKey.vouchers);
    return vouchersString ? vouchersString.split(';') : [];
  }

  setPaymentMethod(model: PaymentMethodModel) {
    sessionStorage.setItem(storageKey.selectedPaymentMethod, JSON.stringify(model));
  }

  getPlaceId() {
    return this.model && this.model.place ? this.model.place.publicId : null;
  }

  onError(error) {
    if (error && error.error && error.error.error && error.error.error.code === 2) {
      this.removeOrder();
    }
  }

  setEmail(value: string) {
    localStorage.setItem(storageKey.email, value);
  }

  getEmail() {
    return localStorage.getItem(storageKey.email);
  }

  removeOrder() {
    this.model.order = null;
    this.state.next(this.model);
    this.orderService.removeOrderId();
    OrderHttpService.cacheBuster$.next();
  }

  public isOrderSavedInStorage(): boolean {
    return Boolean(this.orderService.getOrderId());
  }

  public isPlaceSavedInStorage(): boolean {
    return Boolean(sessionStorage.getItem(storageKey.placeId));
  }

  checkOrderIsExpired() {
    if (this.model && this.model.order) {
      const now = DateTime.local();
      if (this.model.order.dateTimeToLive < now) {
        this.removeOrder();
        this.orderExpirationDate.next(now);
      }
    }
  }

  public setEmbededPaymentUrl(value: string) {
    sessionStorage.setItem(storageKey.embededPaymentUrl, value);
  }

  public getEmbededPaymentUrl() {
    return sessionStorage.getItem(storageKey.embededPaymentUrl);
  }

  removeEmbededPaymentUrl() {
    sessionStorage.removeItem(storageKey.embededPaymentUrl);
  }
}

<div class="row">
  <div class="col">
    <div class="row header">
      <div class="col">
        <span>{{ 'tapp-order.pages.logged-in-user.components.activation-successful-modal.header' | translate }}</span>
      </div>
    </div>
    <div class="row body">
      <div class="col">
        <span>{{ 'tapp-order.pages.logged-in-user.components.activation-successful-modal.body' | translate }}</span>
      </div>
    </div>
    <div class="row order-button-cont">
      <div class="col">
        <div class="ui-clickable" (click)="dialogRef.close()">
          <span>{{
            'tapp-order.pages.logged-in-user.components.activation-successful-modal.order-button-cont' | translate
          }}</span>
        </div>
      </div>
    </div>
    <div class="row user-button-cont">
      <div class="col">
        <div class="ui-clickable" (click)="redirect(['user', 'order-history'])">
          <span>{{
            'tapp-order.pages.logged-in-user.components.activation-successful-modal.user-button-cont' | translate
          }}</span>
        </div>
      </div>
    </div>
  </div>
</div>

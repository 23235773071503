<div class="container d-flex justify-content-center align-items-center found-local-container h-100">
  <div class="row d-flex justify-content-center">
    <div class="col-12 d-flex justify-content-center found-local-image">
      <img src="../../../../assets/images/place.png" />
    </div>
    <div class="col-12 d-flex justify-content-center found-local-header-text">
      <h1>{{ 'tapp-order.found-local-component.header-text' | translate }}</h1>
    </div>
    <div class="col-12 d-flex justify-content-center found-local-address m-2">
      {{ placeData.city }}<br />
      {{ placeData.street }} {{ placeData.buildingNo }}
    </div>
    <div class="col-12 d-flex justify-content-center found-local-pizza-image">
      <img src="../../../../assets/images/pizza-slice-time.png" />
    </div>
    <div class="col-12 d-flex justify-content-center">
      <h1>
        {{ 'tapp-order.found-local-component.delivery-time-text' | translate }}
      </h1>
      <br />
    </div>
    <div class="col-12 d-flex justify-content-center found-local-delivery-time m-4"> 14:05 </div>
    <div
      class="col-11 d-flex justify-content-center ui-clickable found-local-menu-button"
      [routerLink]="[placeData.publicId]"
    >
      {{ 'tapp-order.found-local-component.button-text' | translate }}
    </div>
  </div>
</div>

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CompanyApiModel } from '@api/model/user/company.api.model';
import { DeliveryAddressApiModel } from '@api/model/user/delivery-address.api.model';
import { OrderApiModel } from '@api/model/user/order.api.model';
import { PasswordChangeApiModel } from '@api/model/user/password-change.api.model';
import { PasswordResetApiModel } from '@api/model/user/password-reset.api.model';
import { UserDataApiModel } from '@api/model/user/user-data.api.model';
import { TermsAndConditionsItemApiModel } from '@core/models/tapp-order/api-model/terms-and-conditions/terms-and-conditions-item.api.model';
import { TermsAndConditionsItemViewModel } from '@core/models/tapp-order/view-model/terms-and-conditions/terms-and-conditions-item.view.model';
import { instanceToPlain, plainToClass } from 'class-transformer';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UserRestService {
  constructor(private http: HttpClient) {}

  public getUser(): Observable<UserDataApiModel> {
    return this.http
      .get<UserDataApiModel>('/user')
      .pipe(map((result) => plainToClass(UserDataApiModel, result as object)));
  }

  public putUser(apiModel: UserDataApiModel): Observable<UserDataApiModel> {
    return this.http
      .put<UserDataApiModel>('/user', instanceToPlain(apiModel))
      .pipe(map((result) => plainToClass(UserDataApiModel, result as object)));
  }

  public putUserDeliveryAddress(apiModel: DeliveryAddressApiModel): Observable<UserDataApiModel> {
    return this.http
      .put<UserDataApiModel>('/user/delivery-address', instanceToPlain(apiModel))
      .pipe(map((result) => plainToClass(UserDataApiModel, result as object)));
  }

  public postUserCompany(apiModel: CompanyApiModel): Observable<CompanyApiModel> {
    return this.http
      .post<CompanyApiModel>('/user/company', instanceToPlain(apiModel))
      .pipe(map((result) => plainToClass(CompanyApiModel, result as object)));
  }

  public putUserCompany(apiModel: CompanyApiModel): Observable<CompanyApiModel> {
    return this.http
      .put<CompanyApiModel>(`/user/company/${apiModel.id}`, instanceToPlain(apiModel))
      .pipe(map((result) => plainToClass(CompanyApiModel, result as object)));
  }

  public deleteUserCompany(apiModel: CompanyApiModel): Observable<ArrayBuffer> {
    return this.http.delete<ArrayBuffer>(`/user/company/${apiModel.id}`);
  }

  public patchUserAgreement(apiModel: TermsAndConditionsItemApiModel): Observable<void> {
    return this.http.patch<void>(`/user/agreement/${apiModel.id}`, instanceToPlain(apiModel));
  }

  public getOrders(page: number = 1): Observable<any> {
    const params = new HttpParams().set('page', page);
    return this.http.get<OrderApiModel>('/user/orders', { params });
  }

  public getAllOrders(): Observable<any> {
    return this.http.get<OrderApiModel>('/user/orders');
  }

  public postResetPassword(apiModel: PasswordResetApiModel): Observable<void> {
    return this.http.post<void>('/reset-password', instanceToPlain(apiModel));
  }

  public postResetPasswordChange(apiModel: PasswordChangeApiModel): Observable<void> {
    return this.http.post<void>('/reset-password/change-password', instanceToPlain(apiModel));
  }
}

<div class="d-flex flex-row justify-content-center">
  <div
    class="d-flex p-2 bottom-button bottom-button--position-{{ buttonPosition }}"
    [routerLink]="routerLinkParam"
    [ngSwitch]="display"
  >
    <ng-template [ngSwitchCase]="1">
      <div class="col-4 d-flex flex-row bottom-button-text">{{ leftText }}</div>
      <div class="col-8 d-flex flex-row bottom-button-price">
        {{ centerText }}
      </div>
    </ng-template>
    <ng-template [ngSwitchCase]="2">
      <div class="col-12 bottom-button-text">{{ centerText }}</div>
    </ng-template>
    <ng-template [ngSwitchCase]="3">
      <div class="col-8 d-flex flex-row-reverse bottom-button-text">
        {{ centerText }}
      </div>
      <div class="col-4 d-flex flex-row-reverse bottom-button-price">
        {{ rightText }}
      </div>
    </ng-template>
    <ng-template [ngSwitchCase]="4">
      <div class="col-3 bottom-button-text">{{ leftText }}</div>
      <div class="col-5 bottom-button-price text-center">{{ centerText }}</div>
      <div class="col-4 d-flex flex-row-reverse bottom-button-price">
        {{ rightText }}
      </div>
    </ng-template>
  </div>
</div>

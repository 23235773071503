import { Component, OnInit } from '@angular/core';

declare var AppleID: any;

@Component({
  selector: 'app-apple-login-button',
  templateUrl: './apple-login-button.component.html',
  styleUrls: ['./apple-login-button.component.scss'],
})
export class AppleLoginButtonComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}

  handleSignInWithApple(event): void {
    event.preventDefault();
    // AppleID.auth.init({
    //   clientId: 'your_client_id',
    //   scope: 'email name',
    //   redirectURI: 'your_redirect_uri',
    //   state: 'your_state',
    //   nonce: 'your_nonce',
    //   usePopup: true // Ustawienie na false otworzy autoryzację w nowym oknie przeglądarki
    // });
    //
    // AppleID.auth.signIn();
  }
}

import { FormControl, ValidationErrors } from '@angular/forms';

export class AppValidators {
  /**
   * First remove any space in number
   * Second match if number has max 8 digits before comma/dot and max 2 digits after it.
   * @param control
   */
  public static money(control: FormControl): ValidationErrors | null {
    if (control.parent && control.value) {
      const v = control.value.toString().match(/(?=.*?\d)^\$?(([1-9]\d{0,2}([,.\s']\d{0,9})*)|\d+)([.,]\d{1,2})?$/g);
      return v && v.length !== 0
        ? null
        : {
            custom: 'features.product.page.product.create.form.messages.invalidPrice',
          };
    }
    return null;
  }

  public static moneyAllowNegative(control: FormControl): ValidationErrors | null {
    if (control.parent && control.value) {
      const v = control.value
        .toString()
        .match(/\-?(?=.*?\d)^\$?\-?(([1-9]\d{0,2}([,.\s']\d{0,9})*)|\d+)([.,]\d{1,2})?$/g);
      return v && v.length !== 0
        ? null
        : {
            custom: 'features.product.page.product.create.form.messages.invalidPrice',
          };
    }
    return null;
  }

  public static percent(control: FormControl): ValidationErrors | null {
    if (control.parent && control.value) {
      const v = control.value
        .toString()
        .replace(/\s+/, '')
        .match(/^\d{1,2}(?:[,|.]\d{1,2})?$/g);
      return v && v.length !== 0
        ? null
        : {
            custom: 'features.discount.page.discount.edit.form.messages.invalidPercentage',
          };
    }

    return null;
  }

  public static taxNumber(control: FormControl): ValidationErrors | null {
    if (control.parent && control.value) {
      const numbers = (control.value.match(/^[A-Za-z0-9]{10,16}$/g) || []).length;
      return numbers === 0 || numbers === control.value.length
        ? {
            custom: 'features.place.page.place.create.form.messages.invalidTaxNumber',
          }
        : null;
    }

    return null;
  }

  public static registrationNumber(control: FormControl): ValidationErrors | null {
    if (control.parent && control.value) {
      const numbers = (control.value.match(/^$|^\d{9}$/g) || []).length;
      return numbers === 0 || numbers === control.value.length
        ? {
            custom: 'features.place.page.place.create.form.messages.invalidREGON',
          }
        : null;
    }

    return null;
  }

  public static zipCode(control: FormControl): ValidationErrors | null {
    let zipCodeMaxLength = 6;
    if (control.parent && control.value !== null && control.value !== undefined) {
      const numbers = (control.value.match(/[0-9]{2}-[0-9]{3}$/g) || []).length;
      return numbers === 0 || numbers === control.value.length || control.value.length !== zipCodeMaxLength
        ? { custom: 'common.form.zipCode' }
        : null;
    }

    return null;
  }
}

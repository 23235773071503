import { Exclude, Expose, Type } from 'class-transformer';
import { SurveyQuestionTypeEnum } from '@shared/enum/survey-question-type.enum';
import { SurveyQuestionChoiceApiModel } from '@core/models/tapp-order/api-model/survey/survey-question-choice.api.model';

@Exclude()
export class SurveyQuestionApiModel {
  @Expose()
  public id: number;

  @Expose()
  public value: string;

  @Expose()
  public type: SurveyQuestionTypeEnum;

  @Expose()
  @Type(() => SurveyQuestionChoiceApiModel)
  public choices: SurveyQuestionChoiceApiModel[] = [];
}

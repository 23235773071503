import { Component } from '@angular/core';
import { DeviceDetectorService } from '../../../services/device-detector.service';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss'],
})
export class MainLayoutComponent {
  constructor(public deviceService: DeviceDetectorService) {}
}

import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  public themes = ['light-theme', 'dark-theme'];
  public backArrowPath: string[] = null;
  public mobileNavbarShowBackButton: boolean = false;

  constructor(@Inject(DOCUMENT) private document: Document) {
    const { hostname } = new URL(window.location.href);
    const [subdomain] = hostname.split('.');

    if (subdomain == 'oleh') {
      this.switchTheme('dark-theme');
    } else {
      this.switchTheme('light-theme');
    }
  }

  switchTheme(theme: string) {
    let themeLink = this.document.getElementById('app-theme') as HTMLLinkElement;

    if (themeLink) {
      themeLink.href = theme + '.css?v=' + environment.appVersion;
    }
  }

  public getBackArrowPath(): string[] {
    return this.backArrowPath;
  }

  public setbackArrowPath(value: string[]): void {
    this.backArrowPath = value;
  }
}

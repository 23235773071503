<div class="row">
  <div class="col">
    <div class="row">
      <div class="col d-flex justify-content-start">
        <div class="article-card-x-button ui-clickable" (click)="closeDialog()">
          <i class="ic ic-close"></i>
        </div>
      </div>
    </div>
    <div class="row modal-container">
      <div class="col">
        <div class="row">
          <div class="col">
            <i class="ic ic-send-mail"></i>
          </div>
        </div>
        <div class="row title">
          <div class="col">
            <span>{{ 'tapp-order.pages.logged-in-user.components.verify-email-modal.title' | translate }}</span>
          </div>
        </div>
        <div class="row message">
          <div class="col">
            <span>{{ 'tapp-order.pages.logged-in-user.components.verify-email-modal.message1' | translate }}</span>
          </div>
        </div>
        <div class="row message">
          <div class="col d-flex justify-content-center">
            <span style="font-weight: 600">{{ email }}</span>
          </div>
        </div>
        <div class="row message with-border">
          <div class="col">
            <span>{{ 'tapp-order.pages.logged-in-user.components.verify-email-modal.message2' | translate }}</span>
          </div>
        </div>
        <div class="row not-delivered" *ngIf="isResent == 'false'">
          <div class="col">
            <span>{{ 'tapp-order.pages.logged-in-user.components.verify-email-modal.not-sent' | translate }}</span>
          </div>
        </div>
        <div class="row resend-btn-container" *ngIf="isResent == 'false'">
          <div class="col">
            <ng-container *ngIf="(secondsRemaining$ | async) > 0; else resendButton">
              <div class="button mx-auto">
                <span>{{ secondsRemaining$ | async | date: 'ss' }}</span>
              </div>
            </ng-container>
            <ng-template #resendButton>
              <div class="ui-clickable button mx-auto" (click)="resend()">
                <span *ngIf="!resendLoading; else spinner">{{
                  'tapp-order.pages.logged-in-user.components.verify-email-modal.resend' | translate
                }}</span>
                <ng-template #spinner>
                  <span class="loader"></span>
                </ng-template>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

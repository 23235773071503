import { AbstractViewModel } from '../../../abstract.view.model';
import { MenuApiModel } from '../../api-model/menu/menu.api.model';
import { GroupViewModel } from './group.view.model';

export class MenuViewModel extends AbstractViewModel<MenuApiModel> {
  public publicId: string;
  public name: string;
  public deliveryTypes: string[];
  public checkPromotions: boolean = true;
  public groups: GroupViewModel[];

  constructor(protected apiModel: MenuApiModel = new MenuApiModel()) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.publicId = this.apiModel.publicId;
    this.name = this.apiModel.name;
    this.deliveryTypes = this.apiModel.deliveryTypes;
    this.checkPromotions = this.apiModel.checkPromotions === false ? false : true;
    this.groups = this.apiModel.groups ? this.apiModel.groups.map((group) => new GroupViewModel(group)) : [];
  }

  toApiModel(): MenuApiModel {
    return undefined;
  }

  toString() {
    return this.name;
  }
}

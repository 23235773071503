import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ButtonBackService {
  public redirectUrl?: string[];

  constructor() {}
}

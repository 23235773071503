import * as _ from 'lodash';
import { CateringArticleModel } from './catering.model';
import { ModifierTypeEnum } from './modifier-type.enum';
import { NutritionalInfoModel } from './nutritional-info.response.model';

export class CateringAggregationViewModel {
  groups: CateringAggregationArticleGroupViewModel[] = [];
  articles: CateringAggregationArticleViewModel[] = [];
}

export class CateringAggregationArticleGroupViewModel {
  id: string;
  name?: string;
  description?: string;
  parentGroup?: CateringAggregationArticleGroupViewModel;
  graphicUrl?: string;

  hasOrderBox = false;
  articles: CateringAggregationArticleViewModel[] = [];
}

export class CateringAggregationArticleViewModel {
  id: string;
  basketItemId?: string;
  parentGroup?: CateringAggregationArticleGroupViewModel;
  name?: string;
  price: number;
  taxRate: number;
  description?: string;
  voucherName?: string;
  voucherNumber?: string;
  isLocked: boolean;
  graphicUrl?: string;
  graphicIsLoaded = false;
  subArticleList: CateringAggregationArticleViewModel[] = [];
  replacementList: CateringAggregationArticleViewModel[] = [];
  modifierArticleList: CateringAggregationArticleModifierViewModel[] = [];
  selectedQuantity: number | null = null;
  selectedCombinationHash: string | null = null;
  nutritionalInfo?: NutritionalInfoModel;

  origin: CateringArticleModel;
  orderArticles: CateringAggregationArticleViewModel[] = [];
}

export class CateringAggregationArticleModifierViewModel {
  id: string;
  type?: string;
  name?: string;
  isRequired: boolean;
  multiChoice: boolean;
  multiChoiceMax: number;
  multiChoiceMin: number;
  separateItem: boolean;
  itemCollection: CateringAggregationArticleModifierItemViewModel[] = [];
}

export class CateringAggregationArticleModifierItemViewModel {
  id: string;
  name?: string;
  description?: string;
  price: number;
  type?: string;
  quantity: number;
  relatedModifiers: CateringAggregationArticleModifierViewModel[] = [];

  isNoTypeModifierItem(): boolean {
    return this.type === ModifierTypeEnum.NO_TYPE;
  }
}

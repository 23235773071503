import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { environment } from '@env/environment';
import { BasketService } from '../../../../services/basket.service';
import { PlaceService } from '../../../../services/place.service';
import { PlaceViewModel } from '@core/models/tapp-order/view-model/place/place.view.model';
import { VersionService } from '@core/services/version.service';
import { LinkViewModel } from '@core/models/tapp-order/view-model/place/link.view.model';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-hamburger-button',
  templateUrl: './hamburger-button.component.html',
  styleUrls: ['./hamburger-button.component.scss'],
})
export class HamburgerButtonComponent implements OnInit {
  private modalRef: BsModalRef;
  environment: any = environment;
  private modalClasses: string;
  public hasPlacesList: boolean = false;
  public links: LinkViewModel[] = [];

  constructor(
    private modalService: BsModalService,
    public basketService: BasketService,
    public placeService: PlaceService,
    public versionService: VersionService,
    private router: Router,
  ) {
    this.placeService.getPlaces().subscribe((places: PlaceViewModel[]) => {
      if (places.filter((place: PlaceViewModel) => place.onlineOrderActive).length > 1) {
        this.hasPlacesList = true;
      }

      if (!this.placeService.getPlaceId()) {
        const place = places.find((place: PlaceViewModel) => place.links && place.links.length > 0);
        if (place) {
          this.links = place.links;
        }
      }
    });
  }

  ngOnInit(): void {
    this.modalClasses = 'hamburger-menu-modal';

    this.initPlace();
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.initPlace();
      }
    });
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, {
      class: this.modalClasses,
    });
  }

  hideSidebar() {
    this.modalRef.hide();
  }

  get tableMode() {
    return sessionStorage.getItem('tableId') && sessionStorage.getItem('tableName');
  }

  initPlace(): void {
    this.placeService.getPlace().subscribe((place: PlaceViewModel) => {
      this.links = place.links;
    });
  }
}

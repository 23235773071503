import { ChangeDetectorRef, Component, Input, OnInit, TemplateRef } from '@angular/core';
import {
  loadingAnimationTypes,
  ILoadingConfig,
  LoadingConfig,
  defaultLoaderConfig,
  loadingSize,
  loadingStyle,
} from './model/loading-config';
import { LoadingService } from './loading.service';
import { environment } from '@env/environment';
import { LoaderState } from './model/loader-state.model';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
})
export class LoadingComponent implements OnInit {
  @Input() id = '';
  @Input() show = false;
  @Input() config: ILoadingConfig = new LoadingConfig();
  @Input() template: TemplateRef<any>;
  @Input() size: string;
  @Input() centerScreen = false;

  private defaultConfig: ILoadingConfig = defaultLoaderConfig[environment.projectName];
  public loadingAnimationTypes = loadingAnimationTypes;
  public loadingSize = loadingSize;
  public loadingStyle = loadingStyle;
  constructor(private loadingService: LoadingService, private changeDetectorRef: ChangeDetectorRef) {}

  ngOnInit() {
    this.setupConfig();

    this.loadingService.loaderState$.subscribe((res: LoaderState) => {
      if (res !== null && res.id === this.id) {
        this.show = res.active;
      }
    });
  }

  private setupConfig(): void {
    for (const option in this.defaultConfig) {
      if (this.config[option] != null) {
        continue;
      }

      if (this.size && option === 'size') {
        this.config[option] = this.size;
        continue;
      }

      this.config[option] =
        this.loadingService.loadingConfig[option] != null
          ? this.loadingService.loadingConfig[option]
          : this.defaultConfig[option];
    }
  }

  public setShow(show: boolean): void {
    this.show = show;
    this.changeDetectorRef.markForCheck();
  }
}

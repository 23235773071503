import { AbstractViewModel } from '../../../abstract.view.model';
import { VoucherItemApiModel } from '../../../api-model/order/voucher-item/voucher-item.api.model';
import { VoucherItemMembershipPriceViewModel } from './voucher-item-membership-price.view.model';

export class VoucherItemViewModel extends AbstractViewModel<VoucherItemApiModel> {
  orderItemId: string;
  itemName: string;
  itemId: string;
  quantity: number;
  itemTaxRate: number;
  itemPrice: number;
  value: number;
  bookingId: string;
  promotionId: string;
  promotionName: string;
  itemRedemptionPoints: number;
  membershipPrices: VoucherItemMembershipPriceViewModel[] = [];

  constructor(protected apiModel: VoucherItemApiModel = new VoucherItemApiModel()) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.orderItemId = this.apiModel.orderItemId;
    this.itemName = this.apiModel.itemName;
    this.itemId = this.apiModel.itemId;
    this.quantity = this.apiModel.quantity;
    this.itemTaxRate = this.apiModel.itemTaxRate;
    this.itemPrice = this.apiModel.itemPrice;
    this.value = this.apiModel.value;
    this.bookingId = this.apiModel.bookingId;
    this.promotionId = this.apiModel.promotionId;
    this.promotionName = this.apiModel.promotionName;
    this.itemRedemptionPoints = this.apiModel.itemRedemptionPoints;
    this.membershipPrices = this.apiModel.membershipPrices.map(
      (membershipPrice) => new VoucherItemMembershipPriceViewModel(membershipPrice),
    );
  }

  toApiModel(): VoucherItemApiModel {
    return Object.assign(this.apiModel, {
      orderItemId: this.orderItemId,
      itemId: this.itemId,
      quantity: this.quantity,
    });
  }
}

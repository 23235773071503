export class FbItemModifierItemApiModel {
  modifierId: string;

  name: string;

  isSeparate: boolean;

  modifierName: string;

  modifierItemId: string;

  modifierItemName: string;

  modifierItemDescription: string;

  quantity: number;

  taxRate: number;

  price: number;

  primaryModifierItemId: string;

  defaultPriceLevelPrice: number;
}

import { Exclude, Expose } from 'class-transformer';

@Exclude()
export class CalculateDiscountModifierResponseApiModel {
  @Expose()
  public internalId: string;

  @Expose()
  public productId: number;

  @Expose()
  public groupId: number;

  @Expose()
  public quantity: number;

  @Expose()
  public baseUnitPrice: number;

  @Expose()
  public baseUnitDiscount: number;

  @Expose()
  public unitPrice: number;

  @Expose()
  public unitDiscount: number;

  @Expose()
  public totalPrice: number;

  @Expose()
  public totalDiscount: number;
}

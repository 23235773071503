<div class="toolbar-wrapper">
  <div class="toolbar-menu-wrapper">
    <div class="ui-clickable toolbar-arrow toolbar-arrow--backward invisible" (click)="scrollTolbarPrevious()">
      <i class="ic ic-chevron-left"></i>
    </div>
    <div
      #toolbarMenu
      id="toolbar-menu"
      [class]="'toolbar-menu'"
      [class.toolbar-menu--scrolling]="toolbarScrolling"
      (scroll)="onScrollEvent()"
      (mousedown)="toolbarStartDragging($event, false, toolbarMenu)"
      (mouseup)="toolbarStopDragging($event, false)"
      (mouseleave)="toolbarStopDragging($event, false)"
      (mousemove)="toolbarMoveEvent($event, toolbarMenu)"
    >
      <div class="toolbar-menu-inner-wrapper">
        <div
          *ngIf="hasDiscounts"
          class="product-group w-auto toolbar-menu-item d-flex align-items-center ui-clickable"
          [class.toolbar-menu-item-active]="!this.chosenProductGroupId"
          (click)="redirectToDiscounts()"
        >
          <img src="/assets/icons/ic-promotions.svg" class="icon-color" style="height: 24px; width: 24px" />
          <p style="margin-top: 0; margin-bottom: 0rem; margin-left: 0.5rem">
            {{ 'tapp-order.pages.product.common.discounts' | translate | uppercase }}
          </p>
        </div>
        <ng-container *ngFor="let group of groups; let i = index">
          <ng-container *ngIf="(group.products && group.products?.length > 0) || !group.subgroupsIsEmpty()">
            <div
              pRipple
              class="product-group w-auto toolbar-menu-item d-flex align-items-center ui-clickable"
              [class.toolbar-menu-item-active]="group.publicId === chosenProductGroupId"
              [attr.index]="i"
              [id]="'menu-item' + group.publicId"
              (click)="chooseProductGroup(group.publicId)"
            >
              <img
                *ngIf="group.icon.url"
                src="{{ group.icon.url }}"
                class="icon-color"
                style="height: 24px; width: 24px"
              />
              <p>{{ group.name }}</p>
            </div>
          </ng-container></ng-container
        >
      </div>
    </div>
    <div class="ui-clickable toolbar-arrow toolbar-arrow--forward" (click)="scrollTolbarNext()">
      <i class="ic ic-chevron-right"></i>
    </div>
  </div>
</div>

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bottom-notification',
  templateUrl: './bottom-notification.component.html',
  styleUrls: ['./bottom-notification.component.scss'],
})
export class BottomNotificationComponent implements OnInit {
  public active: boolean = false;

  constructor() {}

  ngOnInit(): void {}

  show(text: string, time: number = 1000) {
    let notificationHtmlElement = document.getElementsByClassName('bottom-notification')[0];
    this.active = true;
    notificationHtmlElement.innerHTML = text;
    notificationHtmlElement.classList.add('bottom-notification-active');
    setTimeout(() => {
      notificationHtmlElement.classList.remove('bottom-notification-active');
      this.active = false;
    }, time);
  }
}

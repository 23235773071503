import { Exclude, Expose } from 'class-transformer';

@Exclude()
export class InvoiceApiModel {
  @Expose()
  public id: number;

  @Expose()
  public taxIdNumber: string;

  @Expose()
  public company: string;

  @Expose()
  public city: string;

  @Expose()
  public street: string;

  @Expose()
  public streetNumber: string;

  @Expose()
  public apartmentNumber: string;

  @Expose()
  public zipCode: string;

  @Expose()
  public country: string;
}

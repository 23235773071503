import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@core/services/auth.service';
import { Observable } from 'rxjs';

@Injectable()
export class JwtTokenInterceptor implements HttpInterceptor {
  urlsWithoutAuthorization: string[] = ['resend-token', 'activation'];

  constructor(private authService: AuthService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const authToken = this.authService.getToken();

    if (authToken !== null && !this.isUrlWithoutAuthorization(req)) {
      req = req.clone({
        headers: req.headers.set('Authorization', 'Bearer ' + authToken),
      });
    }

    return next.handle(req);
  }

  public isUrlWithoutAuthorization(req: HttpRequest<any>): boolean {
    return (
      this.urlsWithoutAuthorization.filter((url) => {
        return req.url.includes(url);
      }).length > 0
    );
  }
}

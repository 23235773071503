<button
  pButton
  pRipple
  class="tooltip-button p-button p-button-text p-button-rounded p-button-secondary"
  type="button"
  icon="pi pi-question"
  [style.opacity]="visible ? '100' : '0'"
  [disabled]="!visible"
  [pTooltip]="hint"
  (mouseenter)="onShow.emit()"
  (mouseleave)="onHide.emit()"
></button>

<div class="payment-form-component payment-invoice-form-component">
  <div class="row">
    <div class="col">
      <button
        (click)="trigger()"
        class="d-flex justify-content-between align-items-center flex-wrap btn-reset btn-back"
      >
        <span class="label">Dane do faktury</span>
        <span class="icon-close"></span>
      </button>
    </div>
  </div>
  <form (submit)="onSubmit($event)" [formGroup]="invoiceForm" class="row">
    <div class="col-lg-3 col-md-6">
      <div class="form-group">
        <input
          type="text"
          formControlName="taxIdNumber"
          appInput
          pInputText
          id="taxIdNumber"
          [appAutofocus]="true"
          placeholder=" "
        />
        <label for="taxIdNumber">NIP</label>
      </div>
    </div>
    <div class="col-lg-3 col-md-6">
      <div class="form-group">
        <input type="text" formControlName="company" appInput pInputText id="company" placeholder=" " />
        <label for="company">Nazwa firmy</label>
      </div>
    </div>
    <div class="col-lg-3 col-md-6">
      <div class="form-group">
        <input formControlName="city" type="text" appInput pInputText id="city" placeholder=" " />
        <label for="city">Miasto</label>
      </div>
    </div>
    <div class="col-lg-3 col-md-6">
      <div class="form-group">
        <input formControlName="street" type="text" appInput pInputText id="street" placeholder=" " />
        <label for="street">Ulica</label>
      </div>
    </div>
    <div class="col-lg-3 col-md-6">
      <div class="form-group">
        <input formControlName="streetNumber" type="text" appInput pInputText id="streetNumber" placeholder=" " />
        <label for="streetNumber">Numer ulicy</label>
      </div>
    </div>
    <div class="col-lg-3 col-md-6">
      <div class="form-group">
        <input formControlName="apartmentNumber" type="text" appInput pInputText id="apartmentNumber" placeholder=" " />
        <label for="apartmentNumber">Numer lokalu</label>
      </div>
    </div>
    <div class="col-lg-3 col-md-6">
      <div class="form-group">
        <input formControlName="zipCode" type="text" appInput pInputText id="zipCode" placeholder=" " />
        <label for="zipCode">Kod pocztowy</label>
      </div>
    </div>
    <div class="col-lg-3 col-md-6">
      <div class="form-group">
        <input formControlName="country" type="text" appInput pInputText id="country" placeholder=" " />
        <label for="country">Kraj</label>
      </div>
    </div>
    <div class="col-lg-4 col-md-6 ml-auto">
      <button type="submit" class="btn btn-primary">Zapisz</button>
    </div>
  </form>
</div>

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { plainToClass } from 'class-transformer';
import { map } from 'rxjs/operators';
import { Cacheable, LocalStorageStrategy } from 'ts-cacheable';
import { DeliveryZoneApiModel } from '@core/models/tapp-order/api-model/delivery-zones/delivery-zone.api.model';
import { DeliveryZoneViewModel } from '@core/models/tapp-order/view-model/delivery-zones/delivery-zone.view.model';

const deliveryZonesCacheBuster$ = new Subject<void>();
const deliveryZoneCacheBuster$ = new Subject<void>();

@Injectable({
  providedIn: 'root',
})
export class DeliveryZonesHttpService {
  constructor(private http: HttpClient) {}

  @Cacheable({
    storageStrategy: LocalStorageStrategy,
    cacheBusterObserver: deliveryZonesCacheBuster$,
  })
  getDeliveryZoneList(): Observable<DeliveryZoneViewModel[]> {
    return this.http
      .get<DeliveryZoneViewModel[]>('/deliveryZones')
      .pipe(map((result) => plainToClass(DeliveryZoneViewModel, result as object[])));
  }

  @Cacheable({
    storageStrategy: LocalStorageStrategy,
    cacheBusterObserver: deliveryZonesCacheBuster$,
  })
  getDeliveryZones(publicId): Observable<DeliveryZoneApiModel[]> {
    return this.http
      .get<DeliveryZoneApiModel[]>('/place/' + publicId + '/deliveryZones')
      .pipe(map((result) => plainToClass(DeliveryZoneApiModel, result as object[])));
  }

  @Cacheable({
    storageStrategy: LocalStorageStrategy,
    cacheBusterObserver: deliveryZoneCacheBuster$,
  })
  getDeliveryZoneById(publicId, deliveryZoneId): Observable<DeliveryZoneApiModel> {
    return this.http
      .get<DeliveryZoneApiModel>('/place/' + publicId + '/deliveryZones/' + deliveryZoneId)
      .pipe(map((result) => plainToClass(DeliveryZoneApiModel, result as object)));
  }
}

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthApiModel } from '@api/model/auth/auth.api.model';
import { AuthRestService } from '@api/rest/auth/auth.rest.service';
import { UserRestService } from '@api/rest/user/user.rest.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { BaseDataProvider } from '@core/data-providers/base.data-provider';
import { PlaceDataProvider } from '@core/data-providers/place.data-provider';
import { SocialMediaEnum } from '@core/services/enums/SocialMediaTypeEnum';
import { Observable, forkJoin, of, throwError } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

@Injectable()
export class AuthService {
  private tokenKey = 'auth-token';
  private refreshTokenKey = 'refresh-token';
  private emailKey = 'email';
  private isResentKey = 'is-resent';
  public jwtHelper: JwtHelperService;

  constructor(
    private router: Router,
    private authRestService: AuthRestService,
    private placeDataProvider: PlaceDataProvider,
    private userRestService: UserRestService,
  ) {
    !this.getIsResented() ? this.setIsResent(false.toString()) : null;
    this.jwtHelper = new JwtHelperService();
  }

  public login(
    authApiModel: AuthApiModel = null,
    socialMedia: boolean = false,
    type: SocialMediaEnum = null,
    accessToken: string = null,
  ): Observable<void> {
    if (authApiModel && !socialMedia) {
      const authRequest = this.authRestService.login(authApiModel);
      return authRequest.pipe(
        map(({ token, refreshToken }) => {
          this.setToken(token);
          this.setRefreshToken(refreshToken);
          this.setEmail(authApiModel.username);
          this.router.navigate(['user', 'user-settings'], {
            state: { compareUserDeliveryAddressWithCurrent: true },
          });
        }),
      );
    } else {
      switch (type) {
        case SocialMediaEnum.fb: {
          const fbAuthRequest = this.authRestService.facebookLogin(accessToken);
          return fbAuthRequest.pipe(
            map(({ token, refreshToken }) => {
              this.setToken(token);
              this.setRefreshToken(refreshToken);
              this.handleSocialAuth();
            }),
          );
        }
        case SocialMediaEnum.google: {
          const googleAuthRequest = this.authRestService.googleLogin(accessToken);
          return googleAuthRequest.pipe(
            map(({ token, refreshToken }) => {
              this.setToken(token);
              this.setRefreshToken(refreshToken);
              this.handleSocialAuth();
            }),
          );
        }
        default:
          return throwError('Invalid social media type');
      }
    }
  }

  public activate(activationToken): Observable<null> {
    return this.authRestService.activate(activationToken).pipe(
      tap(({ token, refreshToken }) => {
        this.setToken(token);
        this.setRefreshToken(refreshToken);

        this.userRestService.getUser().subscribe({
          next: (user) => {
            this.setEmail(user.email);
          },
        });
      }),
      map(() => null),
    );
  }

  private handleSocialAuth(): void {
    forkJoin([this.userRestService.getUser(), this.placeDataProvider.getTermsAndConditions('userSettings')])
      .pipe(
        switchMap(([user, terms]) => {
          const required = terms.filter((value) => value.required);

          const agreementsToUpdate = required
            .filter((term) => !user.agreementIds.includes(term.id))
            .map((term) => this.userRestService.patchUserAgreement(term));

          if (agreementsToUpdate.length === 0) {
            return of(user);
          }

          return forkJoin(agreementsToUpdate);
        }),
      )
      .subscribe({
        next: () =>
          this.router.navigate(['user', 'user-settings'], {
            state: { compareUserDeliveryAddressWithCurrent: true },
          }),
        error: () => {},
      });
  }

  private setToken(token: string): void {
    localStorage.setItem(this.tokenKey, token);
  }

  private setRefreshToken(refreshToken: string): void {
    localStorage.setItem(this.refreshTokenKey, refreshToken);
  }

  private setEmail(email: string): void {
    localStorage.setItem(this.emailKey, email);
  }

  public setIsResent(isResent: string): void {
    localStorage.setItem(this.isResentKey, isResent);
  }

  public getRefreshToken(): string {
    return localStorage.getItem(this.refreshTokenKey);
  }

  public getToken(): string {
    return localStorage.getItem(this.tokenKey);
  }

  public getEmail(): string {
    return localStorage.getItem(this.emailKey);
  }

  public getIsResented(): string {
    return localStorage.getItem(this.isResentKey);
  }

  // public resetPassword(emailApiModel: EmailApiModel) {
  //   return this.authRestService.resetPassword(emailApiModel);
  // }
  //
  // public resetPasswordConfirm(
  //   resetPasswordConfirnmApiModel: ResetPasswordConfirmApiModel
  // ) {
  //   return this.authRestService.resetPasswordConfirm(
  //     resetPasswordConfirnmApiModel
  //   );
  // }
  //
  public refreshToken(): Observable<any> {
    return this.authRestService.refreshToken(this.getRefreshToken()).pipe(
      tap((result) => {
        this.setToken(result.token);
        this.setRefreshToken(result.refreshToken);
      }),
    );
  }

  public logout(): boolean {
    this.removeToken();
    if (this.router.url.includes('user')) {
      this.router.navigate(['']);
    }
    return true;
  }

  public deleteAccount(): void {
    this.authRestService.deleteAccount().subscribe(() => {
      this.removeToken();
      this.router.navigate(['']);
    });
  }

  public resendMail(placeId?: string): Observable<any> {
    return this.authRestService.resend(this.getEmail(), placeId);
  }

  public isAuthenticated(): boolean {
    const token = localStorage.getItem(this.tokenKey);
    return !this.jwtHelper.isTokenExpired(token);
  }

  private removeToken(): void {
    localStorage.removeItem(this.tokenKey);
    localStorage.removeItem(this.refreshTokenKey);
  }

  //
  //
  // get user(): Observable<AuthUserViewModel> {
  //   if (this._user === null) {
  //     return this.refreshUser();
  //   }
  //
  //   return of(this._user);
  // }
  //
  // public refreshUser(): Observable<AuthUserViewModel> {
  //   return this.authDataProvider.current().pipe(
  //     debounceTime(500),
  //     distinctUntilChanged(),
  //     tap((result) => {
  //       this._user = result;
  //       this.currentSettingsService.languageCode = result.language.symbol;
  //       this._permissions = result.roles;
  //       this.permissionsService.loadPermissions(this._permissions);
  //     })
  //   );
  // }
  //
  // get permissions(): string[] {
  //   if (this._permissions.length === 0) {
  //     this.user.subscribe((u: AuthUserViewModel) => {
  //       this._permissions = u.roles;
  //     });
  //   }
  //   return this._permissions;
  // }
  //
}

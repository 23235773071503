import { Injectable } from '@angular/core';
import { Observable, from, of } from 'rxjs';
import { first } from 'rxjs/operators';

type PlatformType = 'browser' | 'android' | 'ios';

@Injectable({
  providedIn: 'root',
})
export class PlatformService {
  constructor() {}

  public getPlatformType(): PlatformType {
    if (window.AndroidShareHandler) {
      return 'android';
    }

    if (window?.webkit?.messageHandlers?.getFirebaseToken) {
      return 'ios';
    }

    return 'browser';
  }

  public fetchPushNotificationToken(): Observable<string | null> {
    switch (this.getPlatformType()) {
      case 'android':
        return of(window.AndroidShareHandler.getFirebaseToken());
      case 'ios':
        return from(window.webkit.messageHandlers.getFirebaseToken.postMessage('APPLE')).pipe(first());
      case 'browser':
        return of(null);
    }
  }
}

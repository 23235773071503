import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthApiModel } from '@api/model/auth/auth.api.model';
import { AuthService } from '@core/services/auth.service';
import { environment } from '@env/environment';
import { SocialMediaEnum } from '@core/services/enums/SocialMediaTypeEnum';

const PAT_EMAIL = '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+[.][a-zA-Z]{2,4}$';

@Component({
  selector: 'app-login-modal',
  templateUrl: './login-modal.component.html',
  styleUrls: ['./login-modal.component.scss'],
})
export class LoginModalComponent implements OnInit {
  @ViewChild('submitButton', { static: false }) submitButton: ElementRef;
  public loginForm: FormGroup;
  public loginApiModel: AuthApiModel = null;
  public loginLoading: boolean = false;
  public error: string = null;
  public isPasswordReset: boolean = false;
  public socialMediaArray: SocialMediaEnum[] = [];
  public SocialMediaEnum = SocialMediaEnum;

  constructor(
    public dialogRef: MatDialogRef<LoginModalComponent>,
    private authService: AuthService,
    private fb: FormBuilder,
  ) {
    this.loginApiModel = new AuthApiModel();
  }

  ngOnInit(): void {
    environment.googleClientID ? this.socialMediaArray.push(SocialMediaEnum.google) : null;
    environment.facebookAppId ? this.socialMediaArray.push(SocialMediaEnum.fb) : null;
    this.loginForm = this.fb.group({
      username: new FormControl(null, [Validators.required, Validators.email]),
      password: new FormControl(null, Validators.required),
    });
    this.dialogRef.keydownEvents().subscribe((event) => {
      if (event.key === 'Escape') {
        this.dialogRef.close();
      }
      if (event.key === 'Enter') {
        event.preventDefault();
        this.submitButton.nativeElement.click();
      }
    });
  }

  onSubmit(): void {
    if (this.loginForm.valid) {
      this.error = null;
      this.loginLoading = true;
      Object.assign(this.loginApiModel, this.loginForm.value);
      this.authService.login(this.loginApiModel).subscribe(
        () => {
          this.loginLoading = false;
          this.dialogRef.close();
        },
        (e) => {
          this.error = e.error.message;
          this.loginLoading = false;
        },
        () => {},
      );
    }
  }
}

import { Directive, ElementRef } from '@angular/core';
import { environment } from '@env/environment';

@Directive({ selector: 'img' })
export class ImgDirective {
  constructor({ nativeElement }: ElementRef<HTMLImageElement>) {
    const supports = 'loading' in HTMLImageElement.prototype;
    if (supports) {
      nativeElement.setAttribute('loading', 'auto');
      nativeElement.setAttribute(
        'onerror',
        environment.baseImgOnError.length
          ? `this.onerror=null;this.src='${environment.baseImgOnError}';`
          : `this.style.display='none'`,
      );
    }
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PlaceViewModel } from '@core/models/tapp-order/view-model/place/place.view.model';

@Component({
  selector: 'app-place-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
export class ListComponent implements OnInit {
  @Input() placesList: PlaceViewModel[] = [];
  @Input() cities: string[] = [];

  @Output() cityEvent: EventEmitter<string> = new EventEmitter<string>();

  constructor() {}

  ngOnInit(): void {}

  place(city: string): void {
    this.cityEvent.emit(city);
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { PayuPaymentMethodsApiModel } from '@api/model/payu/payu-payment-methods.api.model';
import { PaymentMethodTypeEnum } from '@core/pages/payment/enum/payment-method-type.enum';
import { Observable } from 'rxjs';
import { PaymentFormService } from '@core/pages/payment/payment-form.service';

declare let PayU: any;

@Component({
  selector: 'app-credit-card',
  templateUrl: './credit-card.component.html',
  styleUrls: ['./credit-card.component.scss'],
})
export class CreditCardComponent implements OnInit {
  public payuSdkForms: any;
  @Input() payuPaymentMethods: PayuPaymentMethodsApiModel;
  constructor(private paymentFormService: PaymentFormService) {}

  ngOnInit(): void {
    this.createCreditCardForm();
  }

  public createCreditCardForm(): void {
    let optionsForms = {
      cardIcon: true,
      style: {
        basic: {
          fontSize: '16px',
        },
      },
      placeholder: {
        number: '',
        date: 'MM/YY',
        cvv: '',
      },
      lang: 'pl',
    };
    this.payuSdkForms = PayU(this.payuPaymentMethods.posId);
    let secureForms = this.payuSdkForms.secureForms();
    let cardNumber = secureForms.add('number', optionsForms);
    let cardDate = secureForms.add('date', optionsForms);
    let cardCvv = secureForms.add('cvv', optionsForms);
    cardNumber.render('#payu-card-number');
    cardDate.render('#payu-card-date');
    cardCvv.render('#payu-card-cvv');
    this.paymentFormService.payuSdkForms = this.payuSdkForms;
  }
}

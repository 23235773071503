import { DeliveryAddressApiModel } from '@api/model/user/delivery-address.api.model';

export class DeliveryAddressViewModel {
  public street: string;
  public buildingNo: string;
  public localNo: string;
  public zipCode: string;
  public city: string;

  constructor(data: DeliveryAddressApiModel) {
    this.street = data.street;
    this.buildingNo = data.buildingNo;
    this.localNo = data.localNo;
    this.zipCode = data.zipCode;
    this.city = data.city;
  }
}

import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { OrderService } from '@core/services/order.service';

@Component({
  selector: 'app-invoice-preview',
  templateUrl: './invoice-preview.component.html',
  styleUrls: ['./invoice-preview.component.scss'],
})
export class InvoicePreviewComponent implements OnInit {
  @Output() openInvoiceFormEmitter: EventEmitter<any> = new EventEmitter<any>();

  constructor(public orderService: OrderService) {}

  ngOnInit(): void {}
}

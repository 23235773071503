import { Exclude, Expose, Type } from 'class-transformer';

@Exclude()
export class HolidayApiModel {
  @Expose()
  public name: string;

  @Expose()
  @Type(() => Date)
  public date: Date;
}

import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { AuthApiModel } from '@api/model/auth/auth.api.model';
import { RegisterApiModel } from '@api/model/auth/register.api.model';
import { AuthRestService } from '@api/rest/auth/auth.rest.service';
import { PlaceDataProvider } from '@core/data-providers/place.data-provider';
import { TermsAndConditionsItemViewModel } from '@core/models/tapp-order/view-model/terms-and-conditions/terms-and-conditions-item.view.model';
import { AuthService } from '@core/services/auth.service';
import { environment } from '@env/environment';
import { LoginModalComponent } from '@shared/login-modal/login-modal.component';
import { first } from 'rxjs/operators';
import { PlaceService } from '../../../../../services/place.service';

const PAT_EMAIL = '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+[.][a-zA-Z]{2,4}$';

@Component({
  selector: 'app-register-form',
  templateUrl: './register-form.component.html',
  styleUrls: ['./register-form.component.scss'],
})
export class RegisterFormComponent implements OnInit {
  public readonly environment = environment;

  public registerForm: FormGroup;
  public termsAndConditionsControl: FormArray;
  public termsAndConditions: TermsAndConditionsItemViewModel[] = [];
  public registerApiModel: RegisterApiModel = null;
  public registerLoading: boolean = false;
  public showError: boolean = false;
  public errorMessage: string;
  public termsVisible: boolean = false;
  public html: SafeHtml = this.domSanitizer.bypassSecurityTrustHtml('');

  constructor(
    private fb: FormBuilder,
    public dialog: MatDialog,
    private placeDataProvider: PlaceDataProvider,
    private domSanitizer: DomSanitizer,
    private authRestService: AuthRestService,
    private authService: AuthService,
    private placeService: PlaceService,
  ) {
    this.registerApiModel = new RegisterApiModel();
  }

  ngOnInit(): void {
    this.registerForm = this.fb.group(
      {
        termsAndConditions: this.fb.array([]),
        name: new FormControl(null, Validators.required),
        email: new FormControl(null, [Validators.required, Validators.email]),
        emailRepeat: new FormControl(null, [Validators.required, Validators.email]),
        phoneNumber: new FormControl(null, [
          Validators.required,
          Validators.pattern('^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[0-9]*$'),
          Validators.maxLength(15),
        ]),
        password: new FormControl(null, [
          Validators.required,
          Validators.pattern(/\d/),
          Validators.pattern(/[A-Z]/),
          Validators.pattern(/[a-z]/),
          Validators.pattern(/[!@#$%^&*()_+\-=\[\]{};':"|,.<>\/?]/),
          Validators.minLength(8),
        ]),
      },
      { validator: this.matchEmails },
    );

    this.termsAndConditionsControl = this.registerForm.get('termsAndConditions') as FormArray;

    this.placeDataProvider.getTermsAndConditions('registration').subscribe((value) => {
      this.termsAndConditions = value;
      this.patch();
    });
  }

  private matchEmails(group: FormGroup) {
    const email = group.controls['email'].value;
    const emailRepeat = group.controls['emailRepeat'].value;

    return email === emailRepeat ? null : { notMatching: true };
  }

  private patch(): void {
    this.termsAndConditions.forEach((item) => {
      this.termsAndConditionsControl.push(this.patchValues(item));
    });
  }

  private patchValues(item: TermsAndConditionsItemViewModel): AbstractControl {
    return this.fb.group({
      required: item.required ?? false,
      name: item.content ?? '',
      id: item.id ?? null,
      selected: [item.selected ?? false, item.required ? Validators.requiredTrue : null],
      content: item.content ?? '',
    });
  }

  onSubmit(): void {
    if (!this.registerForm.valid) {
      const termsAndConditions = this.registerForm.get('termsAndConditions') as FormArray;
      termsAndConditions.controls.forEach((value) => {
        value.get('selected').markAsDirty();
      });
      return;
    }
    if (this.registerForm.valid) {
      this.showError = false;
      this.registerLoading = true;
      Object.assign(this.registerApiModel, this.registerForm.value);
      this.registerApiModel.agreementIds = this.termsAndConditionsControl.value
        .filter((el) => {
          return el.selected;
        })
        .map((el) => {
          return el.id;
        });
      this.registerApiModel.passwordRepeat = this.registerApiModel.password;
      this.registerApiModel.activationUrlTemplate = window.location.origin + '/activation/{activationToken}';
      this.registerApiModel.placePublicId = this.placeService.getPlaceId() ?? null;
      this.authRestService.register(this.registerApiModel).subscribe(
        () => {
          this.registerLoading = false;
          const authApiModel = new AuthApiModel();
          authApiModel.password = this.registerApiModel.password;
          authApiModel.username = this.registerApiModel.email;
          this.authService.login(authApiModel).pipe(first()).subscribe();
        },
        (error) => {
          this.showError = true;
          this.errorMessage = error.error.message;
          this.registerLoading = false;
        },
        () => {},
      );
    }
  }

  openDialog(): boolean {
    this.dialog.open(LoginModalComponent, {
      panelClass: 'login-form-container',
    });
    return false;
  }

  public showTermsAndCoditions(): void {
    this.termsVisible = true;

    this.placeDataProvider.getTermsAndPrivacyPolicy().subscribe({
      next: (terms) => {
        this.html = this.domSanitizer.bypassSecurityTrustHtml('');

        if (terms.length) {
          const term = terms.reduce((prev, current) => (prev.id < current.id ? prev : current));

          if (term) {
            this.html = this.domSanitizer.bypassSecurityTrustHtml(term.content || '');
          }
        }
      },
    });
  }

  public handleTermsHide(): void {
    this.termsVisible = false;
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replace',
})
export class ReplacePipe implements PipeTransform {
  transform(input: any, pattern: any, replacement: any): any {
    return input.replace(pattern, replacement);
  }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TermsAndConditionsItemApiModel } from '../../../models/tapp-order/api-model/terms-and-conditions/terms-and-conditions-item.api.model';
import { plainToClass } from 'class-transformer';
import { map } from 'rxjs/operators';
import { TermsAndPrivacyPolicyItemApiModel } from '@core/models/tapp-order/api-model/terms-and-conditions/terms-and-privacy-policy-item.api.model';

@Injectable({
  providedIn: 'root',
})
export class TermsAndConditionsHttpService {
  constructor(private http: HttpClient) {}

  /**
   * This method will be removed in the future. Can be deleted after 08.10.2023
   * @deprecated use "get" method of "MarketingConsentsHttpService" instead
   */
  public getTermsAndConditions(category): Observable<TermsAndPrivacyPolicyItemApiModel[]> {
    const categoryParams = new HttpParams();
    categoryParams.set('category', category);

    return this.http
      .get<TermsAndConditionsItemApiModel[]>(`/termsAndConditions`, { params: categoryParams })
      .pipe(map((result) => plainToClass(TermsAndConditionsItemApiModel, result as object[])));
  }

  public get(
    placeId: string,
    category: 'orders' | 'registration' | 'userSettings',
  ): Observable<TermsAndConditionsItemApiModel[]> {
    let categoryParams = new HttpParams();

    categoryParams = categoryParams.set('category', category);

    return this.http
      .get<TermsAndConditionsItemApiModel[]>(`/termsAndConditions/${placeId}`, { params: categoryParams })
      .pipe(map((result) => plainToClass(TermsAndConditionsItemApiModel, result as object[])));
  }
}

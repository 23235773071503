import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { TranslateService } from '@ngx-translate/core';
import { storageKey } from '../app.consts';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  private currentLanguage = environment.globalize.language.default;

  constructor(private translateService: TranslateService) {}

  public initLanguage() {
    let selectedLanguage = sessionStorage.getItem(storageKey.lang);
    if (selectedLanguage == null) {
      selectedLanguage = navigator?.language?.split('-')[0] ?? environment.globalize.language.default;
    }

    this.applyLanguage(selectedLanguage);
  }

  //to use in locale switcher
  public setLanguage(language: string) {
    sessionStorage.setItem(storageKey.lang, language);
    this.applyLanguage(language);
  }

  public getLanguage(): string {
    return this.currentLanguage;
  }

  private async applyLanguage(language: string) {
    this.currentLanguage = language;
    this.translateService.setDefaultLang(language);
    // await the http request for the translation file
    await this.translateService.use(language).toPromise();
  }
}

import { Component, EventEmitter, Input, OnInit, Output, AfterViewInit } from '@angular/core';
import { timer, Subscription } from 'rxjs';

@Component({
  selector: 'app-payment-pending',
  templateUrl: './payment-pending.component.html',
  styleUrls: ['./payment-pending.component.scss'],
})
export class PaymentPendingComponent implements OnInit, AfterViewInit {
  @Output() public goBackEvent: EventEmitter<null> = new EventEmitter<null>();
  @Input() showGoBackBtn: boolean = false;
  public countDown: Subscription;
  public counter = 180;
  @Input() timerDateTo;

  constructor() {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.counter = this.timerDateTo.getTime() + 180000 - new Date().getTime();
    this.counter = this.counter / 1000;
    this.countDown = timer(0, 1000).subscribe(() => {
      if (this.counter < 0) {
        this.counter = 0;
        this.countDown = null;
      } else {
        --this.counter;
      }
    });
  }

  ngOnDestroy() {
    this.countDown = null;
  }

  goBack() {
    this.goBackEvent.emit();
  }
}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PlaceService } from '../../../services/place.service';
import { PlaceViewModel } from '@core/models/tapp-order/view-model/place/place.view.model';
import { OrderTypeEnum } from '@core/models/tapp-order/api-model/order/OrderTypeEnum';
import { AddressFinderService } from '@core/services/address-finder.service';
import { PickupOption } from '@core/interfaces/interfaces';

@Component({
  selector: 'app-select-adress',
  templateUrl: './select-adress.component.html',
  styleUrls: ['./select-adress.component.scss'],
  providers: [AddressFinderService],
})
export class SelectAdressComponent implements OnInit {
  public isLoaded: boolean = false;

  public pickupOptions: PickupOption[] = [
    {
      time: null, //30
      type: OrderTypeEnum.delivery,
      i18key: 'tapp-order.select-adress-component.pickup-options.delivery',
    },
    {
      time: null, //10
      type: OrderTypeEnum.pick_up,
      i18key: 'tapp-order.select-adress-component.pickup-options.pick-up',
    },
  ];

  public selectedOrderType: OrderTypeEnum = OrderTypeEnum.delivery;

  constructor(
    private router: Router,
    private placeService: PlaceService,
    private addressFinderService: AddressFinderService,
  ) {
    if (sessionStorage.getItem('tableId') && sessionStorage.getItem('placeId')) {
      this.router.navigate(['/', sessionStorage.getItem('placeId')]);
    }

    this.placeService
      .getPlaces()
      .subscribe((places: PlaceViewModel[]) => {
        if (places.filter((place: PlaceViewModel) => place.apiModel.onlineOrderActive).length == 0) {
          this.router.navigate(['/', 'start']);
        }
      })
      .add(() => {
        this.isLoaded = true;
      });
  }

  ngOnInit(): void {
    this.selectedOrderType = this.addressFinderService.selectedOrderType;
  }

  selectPickupOption(option: PickupOption): void {
    this.selectedOrderType = option.type;

    this.addressFinderService.deliveryOrderType = option.type;
    this.addressFinderService.saveDataToService();
  }
}

import { Injectable } from '@angular/core';
import { HolidayHttpService } from '@core/http/tapp-order/holiday/holiday.http.service';
import { TableHttpService } from '@core/http/tapp-order/place/table.http.service';
import { ProductApiModel } from '@core/models/tapp-order/api-model/product/product.api.model';
import { HolidayViewModel } from '@core/models/tapp-order/view-model/holiday/holiday.view.model';
import { TableViewModel } from '@core/models/tapp-order/view-model/place/table.view.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseHttpService } from '../http/base.http.service';
import { ProductHttpService } from '../http/tapp-order/product/product.http.service';
import { PlaceApiModel } from '../models/tapp-order/api-model/place/place.api.model';
import { PlaceViewModel } from '../models/tapp-order/view-model/place/place.view.model';
import { ModifierViewModel } from '../models/tapp-order/view-model/product/modifier.view.model';
import { ProductViewModel } from '../models/tapp-order/view-model/product/product.view.model';

@Injectable({
  providedIn: 'root',
})
export class BaseDataProvider {
  constructor(
    private httpService: BaseHttpService,
    private productHttpService: ProductHttpService,
    private holidayHttpService: HolidayHttpService,
    private tableHttpService: TableHttpService,
  ) {}

  public getPlaces() {
    return this.httpService
      .getPlaces()
      .pipe(map((models: PlaceApiModel[]) => models.map((model: PlaceApiModel) => new PlaceViewModel(model))));
  }

  getPlace(id: string, opts?: { bypassCache?: boolean }): Observable<PlaceViewModel> {
    if (opts?.bypassCache) {
      this.httpService.placeCacheBuster$.next();
    }
    return this.httpService.getPlace(id).pipe(map((res) => new PlaceViewModel(res)));
  }

  getTable(id: string): Observable<TableViewModel> {
    return this.tableHttpService.getTable(id).pipe(map((res) => new TableViewModel(res)));
  }

  getProduct(id: string): Observable<ProductViewModel> {
    return this.productHttpService.getProduct(id).pipe(map((result) => new ProductViewModel(result)));
  }

  getProductsCrossSell(menuPublicId: string): Observable<ProductViewModel[]> {
    return this.productHttpService
      .getProductsCrossSell(menuPublicId)
      .pipe(map((models: ProductApiModel[]) => models.map((model: ProductApiModel) => new ProductViewModel(model))));
  }

  getProductModifiers(id: string) {
    return this.productHttpService
      .getProductModifiers(id)
      .pipe(map((result) => result.map((modifier) => new ModifierViewModel(modifier))));
  }

  getHolidaysList(): Observable<HolidayViewModel[]> {
    return this.holidayHttpService
      .getHolidaysList()
      .pipe(map((holidays) => holidays.map((holiday) => new HolidayViewModel(holiday))));
  }
}

import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { PlaceDataProvider } from '@core/data-providers/place.data-provider';

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss'],
})
export class TermsAndConditionsComponent implements OnInit {
  public html: SafeHtml = this.domSanitizer.bypassSecurityTrustHtml('');

  constructor(private domSanitizer: DomSanitizer, private placeDataProvider: PlaceDataProvider) {}

  ngOnInit(): void {
    this.placeDataProvider.getTermsAndPrivacyPolicy().subscribe({
      next: (terms) => {
        this.html = this.domSanitizer.bypassSecurityTrustHtml('');

        if (terms.length) {
          const term = terms.reduce((prev, current) => (prev.id < current.id ? prev : current));

          if (term) {
            this.html = this.domSanitizer.bypassSecurityTrustHtml(term.content || '');
          }
        }
      },
    });
  }
}

import { Exclude, Expose } from 'class-transformer';

@Exclude()
export class SurveyAnswerApiModel {
  @Expose()
  public questionId: number;

  @Expose()
  public rating?: number;

  @Expose()
  public selectedChoiceId?: number;

  @Expose()
  public selectedChoicesIds?: number[];

  @Expose()
  public ownAnswer?: string;
}

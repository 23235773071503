export const _getComputedHeightFromCSSVar = (cssVarName: `--${string}`): number => {
  let computedHeight: number = 0;

  // Create a temporary element
  const tempElement = document.createElement('div');

  // Apply the custom property to the temporary element
  tempElement.style.height = `var(${cssVarName})`;

  // Append the element to the DOM (it won't be visible)
  document.body.appendChild(tempElement);

  // Force a reflow to ensure styles are applied
  void tempElement.offsetHeight;

  // Get the computed height in pixels
  const computedStyle = getComputedStyle(tempElement);
  computedHeight = parseFloat(computedStyle.height);

  // Remove the temporary element from the DOM
  document.body.removeChild(tempElement);

  return computedHeight;
};

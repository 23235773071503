import { Exclude, Expose, Type } from 'class-transformer';
import { NutritionApiModel } from '@core/models/tapp-order/api-model/product/nutrition.api.model';

@Exclude()
export class NutritionValueApiModel {
  @Expose()
  public value: string;

  @Expose()
  @Type(() => NutritionApiModel)
  public nutrition: NutritionApiModel;
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Cacheable, LocalStorageStrategy } from 'ts-cacheable';
import { Observable, of, Subject } from 'rxjs';
import { ApiUrlApiModel } from '../models/api-model/ctl/api-url.api.model';
import { environment } from '@env/environment';
import { Router } from '@angular/router';
import { PlaceService } from '../../services/place.service';

const apiUrlCacheBuster$ = new Subject<void>();

@Injectable({
  providedIn: 'root',
})
export class CtlHttpService {
  constructor(private http: HttpClient, private router: Router) {}

  @Cacheable({
    storageStrategy: LocalStorageStrategy,
    cacheBusterObserver: apiUrlCacheBuster$,
    maxCacheCount: 1,
  })
  getApiSubdomain(): Observable<ApiUrlApiModel> {
    const hostname = this.getHostname();
    const subdomain = this.getSubdomain();

    return this.http.get<ApiUrlApiModel>(
      environment.ctlUrl + `/api/url?publicSubdomain=${subdomain}&customDomain=${hostname}`,
    );
  }

  init(): Observable<any> {
    if (sessionStorage.getItem('customDomain') && sessionStorage.getItem('customDomain') != this.getHostname()) {
      window.location.href = environment.appUrl.prefix + sessionStorage.getItem('customDomain');
    }

    if (sessionStorage.getItem('api_subdomain')) {
      return of(sessionStorage.getItem('api_subdomain'));
    }

    return new Observable((observer) => {
      this.getApiSubdomain().subscribe(
        (result) => {
          if (result.customDomain) {
            sessionStorage.setItem('customDomain', result.customDomain);

            if (result.customDomain != this.getHostname()) {
              window.location.href = environment.appUrl.prefix + result.customDomain;

              observer.error();
            }
          }

          sessionStorage.setItem('api_subdomain', result.subdomain);
          observer.next(result.subdomain);
        },
        (result) => {
          observer.error(result);
          if (!['/start', '/scanner', '/error/20000'].includes(this.router.url)) {
            environment.baseAppUrl !== ''
              ? (window.location.href = environment.baseAppUrl)
              : this.router.navigate(['']);
          }
        },
      );
    });
  }

  getSubdomain() {
    const [subdomain] = this.getHostname().split('.');

    return subdomain;
  }

  getHostname() {
    const { hostname } = new URL(window.location.href);

    return hostname;
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { ArticleCard } from '../../models/article-card.model';
import { CardActionTypeEnum } from '../../models/card-action-type.enum';
import { CateringAggregationArticleViewModel } from '../../models/catering-aggregation.view.model';
import { ProductDetailsPageComponent } from '../../pages/product-details/product-details.component';
import { CartService } from '../../services/cart.service';

@Component({
  selector: 'app-article-card',
  templateUrl: './article-card.component.html',
  styleUrls: ['./article-card.component.scss'],
})
export class ArticleCardComponent implements OnInit {
  @Input() disabled = false;
  bsModalRef?: BsModalRef;
  hide = false;

  _item: CateringAggregationArticleViewModel;
  get item(): CateringAggregationArticleViewModel {
    return this._item;
  }
  @Input() set item(value: CateringAggregationArticleViewModel) {
    this._item = value;
    this.setValues();
  }

  _totalQuantity: number = null;
  public get totalQuantity() {
    return this._totalQuantity;
  }

  _hasAnyMods = false;
  public get hasAnyMods() {
    return this._hasAnyMods;
  }

  @Input() mode = '';
  @Output() public onChange: EventEmitter<ArticleCard> = new EventEmitter<ArticleCard>();

  public cardActionTypeEnum: typeof CardActionTypeEnum = CardActionTypeEnum;

  constructor(private cartService: CartService, private router: Router, private modalService: BsModalService) {}

  ngOnInit(): void {}

  setValues() {
    if (this.item) {
      this.item.orderArticles = _.orderBy(this.item.orderArticles, ['price'], ['asc']);
      this._totalQuantity = _.sumBy(this.item.orderArticles, 'selectedQuantity');
      console.log(
        'sumowanie tablicy this.item.orderArticles[' +
          this.item.orderArticles.length +
          '] dla item.name: ' +
          this.item.name,
      );
      this._hasAnyMods =
        (this.item.modifierArticleList
          ? this.item.modifierArticleList.some((o) => o.isRequired) ||
            this.item.subArticleList.some((o) => o.modifierArticleList.some((o) => o.isRequired))
          : false) ||
        (this.item.subArticleList !== null && this.item.subArticleList.length > 0);
    }
  }

  add() {
    if (this._hasAnyMods) {
      this.navigateToDetails();
      return;
    }

    this.updateOrder(this.item, CardActionTypeEnum.ADD);
  }

  increment(article: CateringAggregationArticleViewModel) {
    this.updateOrder(article, CardActionTypeEnum.PATCH_QUANTITY, 1);
  }

  decrement(article: CateringAggregationArticleViewModel) {
    this.updateOrder(article, CardActionTypeEnum.PATCH_QUANTITY, -1);
  }

  navigateToDetails() {
    this.openModalWithComponent();
  }

  openModalWithComponent() {
    const initialState: ModalOptions = {
      initialState: { article: this.item },
    };
    this.bsModalRef = this.modalService.show(ProductDetailsPageComponent, initialState);
    this.bsModalRef.content.closeBtnName = 'Close';
  }

  public modifiers(article: CateringAggregationArticleViewModel): string {
    if (article && article.modifierArticleList) {
      const res = [].concat(...article.modifierArticleList.map((modifierArticle) => modifierArticle.itemCollection));
      return res.map((o) => (o.quantity > 1 ? `${o.quantity}x ${o.name}` : o.name)).join(', ');
    }

    return '';
  }

  public subarticles(article: CateringAggregationArticleViewModel): string {
    if (article && article.subArticleList) {
      return article.subArticleList
        .map((o) => (o.selectedQuantity > 1 ? `${o.selectedQuantity}x ${o.name}` : o.name))
        .join(', ');
    }

    return '';
  }

  private updateOrder(article: CateringAggregationArticleViewModel, action: CardActionTypeEnum, value: number = null) {
    this.onChange.emit(new ArticleCard(article, action, value));
    if (value === null) {
      this.cartService.addCart(article);
    } else {
      this.hide = this.cartService.patchQuantity(article, value) === 0;
    }
  }
}

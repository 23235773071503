import { Component, OnChanges, OnInit, SimpleChanges, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
import { PlaceService } from 'src/app/services/place.service';
import { BasketService } from 'src/app/services/basket.service';
import { BasketItemViewModel } from '@core/models/tapp-order/view-model/basketItem/basket-item.view.model';
import { ThemeService } from '../../../services/theme.service';
import { DeliveryZonesHttpService } from '@core/http/tapp-order/delivery-zones/delivery-zones.http.service';
import { DeliveryZoneApiModel } from '@core/models/tapp-order/api-model/delivery-zones/delivery-zone.api.model';
import { OrderService } from '@core/services/order.service';
import { OrderTypeEnum } from '@core/models/tapp-order/api-model/order/OrderTypeEnum';
import { DiscountService } from '@core/pages/discounts/service/discount.service';

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss'],
})
export class OrderComponent implements OnInit {
  public orderType: String;
  public basketItems: BasketItemViewModel[];
  public deliveryZone: DeliveryZoneApiModel;
  modalRef?: BsModalRef;

  constructor(
    public basketService: BasketService,
    private modalService: BsModalService,
    private placeService: PlaceService,
    private router: Router,
    private themeService: ThemeService,
    private discountService: DiscountService,
    private deliveryZoneService: DeliveryZonesHttpService,
    private orderService: OrderService,
  ) {
    this.orderType = this.orderService.getOrderType();
    this.orderService.orderTypeSubject$.subscribe((res: string) => {
      if (res !== null) {
        this.orderType = res;
      }
    });
  }

  ngOnInit(): void {
    this.themeService.mobileNavbarShowBackButton = true;
    this.placeService.getPlace().subscribe((value) => {
      if (value.isPreviewOnly) {
        this.router.navigate(['/']);
        return;
      }
      this.basketItems = this.basketService.basketItems;
      /* Obserwowanie zmian w koszyku wymagających odświeżenia */
      this.basketService.refreshBasketEvent.subscribe(() => {
        this.basketItems = this.basketService.basketItems;
      });
    });
  }
}

import { CalculateDiscountItemResponseApiModel } from '@api/model/discount/calculate-discount-item-response.api.model';
import { AbstractViewModel } from '@core/models/abstract.view.model';
import { CalculateDiscountModifierResponseViewModel } from './calculate-discount-modifier-response.view.model';

export class CalculateDiscountItemResponseViewModel extends AbstractViewModel<CalculateDiscountItemResponseApiModel> {
  public internalUid: string = null;
  public internalId: string = null;
  public sizeId: number = null;
  public doughId: number = null;
  public discountId: number = null;
  public productId: number = null;
  public groupId: number = null;
  public quantity: number = null;
  public baseUnitPrice: number = null;
  public baseUnitDiscount: number = null;
  public unitPrice: number = null;
  public unitDiscount: number = null;
  public totalPrice: number = null;
  public totalDiscount: number = null;
  public modifiers: CalculateDiscountModifierResponseViewModel[] = [];
  public additionalIngredients: CalculateDiscountModifierResponseViewModel[] = [];

  public constructor(
    protected apiModel: CalculateDiscountItemResponseApiModel = new CalculateDiscountItemResponseApiModel(),
  ) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.internalId = this.apiModel.internalId || null;
    this.sizeId = this.apiModel.sizeId || null;
    this.doughId = this.apiModel.doughId || null;
    this.discountId = this.apiModel.discountId || null;
    this.productId = this.apiModel.productId || null;
    this.groupId = this.apiModel.groupId || null;
    this.quantity = this.apiModel.quantity || null;
    this.baseUnitPrice = this.apiModel.baseUnitPrice || null;
    this.baseUnitDiscount = this.apiModel.baseUnitDiscount || null;
    this.unitPrice = this.apiModel.unitPrice || null;
    this.unitDiscount = this.apiModel.unitDiscount || null;
    this.totalPrice = this.apiModel.totalPrice || null;
    this.totalDiscount = this.apiModel.totalDiscount || null;
    this.modifiers = this.apiModel.modifiers
      ? this.apiModel.modifiers.map((value) => new CalculateDiscountModifierResponseViewModel(value))
      : [];
    this.additionalIngredients = this.apiModel.additionalIngredients
      ? this.apiModel.additionalIngredients.map((value) => new CalculateDiscountModifierResponseViewModel(value))
      : [];
  }

  public apply(value: any): CalculateDiscountItemResponseViewModel {
    Object.assign(this, value);
    return this;
  }

  toApiModel(): CalculateDiscountItemResponseApiModel {
    return Object.assign(this.apiModel, {
      internalId: this.internalId,
      sizeId: this.sizeId,
      doughId: this.doughId,
      discountId: this.discountId,
      productId: this.productId,
      groupId: this.groupId,
      quantity: this.quantity,
      baseUnitPrice: this.baseUnitPrice,
      baseUnitDiscount: this.baseUnitDiscount,
      unitPrice: this.unitPrice,
      unitDiscount: this.unitDiscount,
      totalPrice: this.totalPrice,
      totalDiscount: this.totalDiscount,
      modifiers: this.modifiers.map((i) => i.toApiModel()),
    });
  }
}

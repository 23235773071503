<div class="discount-configurator-content">
  <ng-container *ngIf="loadingStatus == 'success'; else loading">
    <div>
      <p class="discount-configurator-header-select-product" *ngIf="activeIndex < steps.slice(-1).pop().index + 1">
        {{ 'tapp-order.pages.discount.discount-configurator.select-product' | translate }}
      </p>
    </div>
    <ng-container *ngFor="let step of steps">
      <div *ngIf="activeIndex == step.index" class="discount-configurator-content-list">
        <ng-container *ngFor="let product of step.products">
          <app-product-pizza-card
            [product]="product"
            [discountMode]="true"
            [conditionSizeIds]="conditionSizeIds"
            [conditionDoughIds]="conditionDoughIds"
            (selectProductEvent)="getProduct($event)"
            *ngIf="product.productType == 'pizza' || product.productType == 'composed'; else productCard"
          ></app-product-pizza-card>

          <ng-template #productCard>
            <app-article-card-expandable
              [item]="product"
              [placeIsPreviewOnly]="false"
              [isDiscountMode]="true"
              [isPriceInDescription]="true"
              [showPrice]="false"
              (selectProductEvent)="getProduct($event)"
            >
              <ng-template #articleCardPopup>
                <app-article-card-expandable
                  [isPriceInDescription]="true"
                  [item]="product"
                  [isExpandable]="false"
                  [expanded]="false"
                  [isInModal]="false"
                  [placeIsPreviewOnly]="false"
                  [showPrice]="false"
                  [isDiscountMode]="true"
                  (selectProductEvent)="getProduct($event)"
                ></app-article-card-expandable>
              </ng-template>
            </app-article-card-expandable>
          </ng-template>
        </ng-container>
      </div>
    </ng-container>
    <ng-container *ngIf="steps.length > 0">
      <div *ngIf="activeIndex == steps.slice(-1).pop().index + 1" class="selected-products-summary">
        <div>
          <p class="discount-configurator-header-selected-product">
            {{ 'tapp-order.pages.discount.discount-configurator.selected-products' | translate }}
          </p>
        </div>
        <ng-container *ngFor="let product of selectedProducts">
          <app-product-pizza-card
            [product]="product"
            [isOpen]="isOpen"
            [summaryMode]="true"
            [conditionSizeIds]="conditionSizeIds"
            [conditionDoughIds]="conditionDoughIds"
            (selectProductEvent)="getProduct($event)"
          ></app-product-pizza-card>
        </ng-container>
        <div class="promotion-products-buttons-container">
          <div
            class="d-flex justify-content-between align-items-center btn-reset btn-basket add-promotion-products-button"
            (click)="add()"
          >
            <img src="../../../../../assets/icons/shopping-cart.png" class="fa fa-cart-shopping padding-left-right" />
            <span class="add-promotion-products-button-text">
              {{ 'tapp-order.pages.discount.discount-configurator.add-to-order' | translate }}
            </span>
            <span *ngIf="false" class="add-promotion-products-button-text-right">{{ totalItemsPrice }} zł</span>
          </div>
          <div pRipple class="btn-cancel" (click)="onClose.emit()">
            {{ 'tapp-order.pages.discount.discount-configurator.cancel' | translate }}
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
  <ng-template #loading>
    <app-loading></app-loading>
  </ng-template>
</div>

import { CalculateDiscountUsedResponseApiModel } from '@api/model/discount/calculate-discount-used-response.api.model';
import { AbstractViewModel } from '@core/models/abstract.view.model';

export class CalculateDiscountUsedResponseViewModel extends AbstractViewModel<CalculateDiscountUsedResponseApiModel> {
  public id: number = null;
  public name: string = null;

  public constructor(
    protected apiModel: CalculateDiscountUsedResponseApiModel = new CalculateDiscountUsedResponseApiModel(),
  ) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.id = this.apiModel.id || null;
    this.name = this.apiModel.name || null;
  }

  public apply(value: any): CalculateDiscountUsedResponseViewModel {
    Object.assign(this, value);
    return this;
  }

  toApiModel(): CalculateDiscountUsedResponseApiModel {
    return Object.assign(this.apiModel, {
      id: this.id,
      name: this.name,
    });
  }
}

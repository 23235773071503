import { AbstractViewModel } from '../../../abstract.view.model';
import { CardItemMembershipPriceApiModel } from '../../../api-model/order/card-item/card-item-membership-price.api.model';

export class CardItemMembershipPriceViewModel extends AbstractViewModel<CardItemMembershipPriceApiModel> {
  isAppliedOnOrder: boolean;
  membershipLevel: number;
  membershipCardTypeId: string;
  membershipValue: number;

  constructor(protected apiModel: CardItemMembershipPriceApiModel = new CardItemMembershipPriceApiModel()) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.isAppliedOnOrder = this.apiModel.isAppliedOnOrder;
    this.membershipLevel = this.apiModel.membershipLevel;
    this.membershipCardTypeId = this.apiModel.membershipCardTypeId;
    this.membershipValue = this.apiModel.membershipValue;
  }

  toApiModel(): CardItemMembershipPriceApiModel {
    return undefined;
  }
}

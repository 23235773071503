import { Exclude, Expose } from 'class-transformer';

@Exclude()
export class CompanyApiModel {
  @Expose()
  public street: string;

  @Expose()
  public buildingNo: string;

  @Expose()
  public localNo: string;

  @Expose()
  public zipCode: string;

  @Expose()
  public city: string;

  @Expose()
  public country: string;

  @Expose()
  public name: string;

  @Expose()
  public registrationNumber: string;

  @Expose()
  public taxIdNumber: string;
}

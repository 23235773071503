import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-activation-successful-modal',
  templateUrl: './activation-successful-modal.component.html',
  styleUrls: ['./activation-successful-modal.component.scss'],
})
export class ActivationSuccessfulModalComponent implements OnInit {
  constructor(private router: Router, public dialogRef: MatDialogRef<ActivationSuccessfulModalComponent>) {}

  ngOnInit(): void {}

  public redirect(directory: string[]): void {
    this.router.navigate(directory);
    this.dialogRef.close();
  }
}

import { Exclude, Expose, Type } from 'class-transformer';

@Exclude()
export class DeliveryZoneApiModel {
  @Expose()
  public id: number;

  @Expose()
  public placePublicId: string;

  @Expose()
  public name: string;

  @Expose()
  public shapeType: string;

  @Expose()
  public deliveryPrice: number;

  @Expose()
  public minOrderPrice: number;

  @Expose()
  public minOrderPriceForFreeDelivery: number;

  @Expose()
  public color: string;

  @Expose()
  @Type(() => Array)
  public coordinates: Array<Array<number>[]>[] = [[]];

  @Expose()
  public radius: number;
}

import { Injectable } from '@angular/core';
import { UserRestService } from '@api/rest/user/user.rest.service';
import { map } from 'rxjs/operators';
import { PlaceHttpService } from '@core/http/tapp-order/place/place.http.service';
import { plainToClassFromExist } from 'class-transformer';
import { ResultListApiModel } from '@core/models/api-model/result-list.api.model';
import { OrderApiModel } from '@api/model/user/order.api.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OrderDataProvider {
  constructor(private userRestService: UserRestService, private placeHttpService: PlaceHttpService) {}

  public getOrders(page = 1): Observable<Promise<ResultListApiModel<OrderApiModel>>> {
    return this.userRestService.getOrders(page).pipe(
      map((result) => {
        const getPlaces = async () => {
          return await Promise.all(
            result.records.map(async (val: any) => {
              await this.placeHttpService
                .getPlace(val.place.publicId)
                .toPromise()
                .then((value) => {
                  val.place = value;
                });
            }),
          );
        };
        return getPlaces().then(() => {
          return plainToClassFromExist(new ResultListApiModel<OrderApiModel>(OrderApiModel), result as object);
        });
      }),
    );
  }

  public getPoints(): Observable<number> {
    return this.userRestService.getAllOrders().pipe(
      map((result) => {
        const points = result.records.reduce((acc, val) => {
          if (val.status === 'finished' && val.orderPayment.paymentStatus === 'completed') {
            return acc + val.orderPayment.totalPrice;
          } else {
            return acc;
          }
        }, 0);

        return points;
      }),
    );
  }
}

import { AbstractViewModel } from '../../../abstract.view.model';
import { HolidayApiModel } from '@core/models/tapp-order/api-model/holiday/holiday.api.model';

export class HolidayViewModel extends AbstractViewModel<HolidayApiModel> {
  public name: string;
  public date: Date;

  constructor(protected apiModel: HolidayApiModel = new HolidayApiModel()) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.name = this.apiModel.name;
    this.date = this.apiModel.date;
  }

  toApiModel(): HolidayApiModel {
    return undefined;
  }

  toString() {
    return this.name;
  }
}

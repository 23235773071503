<div #messageElement>
  <div class="message-container pt-1 pt-md-3 pb-0" *ngIf="message$ | async as message; else messageEmpty">
    <div *ngIf="!message.excluded || custom" class="alert alert-{{ message.type }} alert-dismissible fade show mb-0">
      {{ message.message }}
      <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="onClose()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>
</div>

<ng-template #messageEmpty> ZZZZZ </ng-template>

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { OrderApiModel } from '@api/model/user/order.api.model';
import { SurveyDataProvider } from '@core/data-providers/survey.data-provider';
import { SurveyCategoryEnum } from '@shared/enum/survey-category.enum';
import { SurveyViewModel } from '@core/models/tapp-order/view-model/survey/survey.view.model';
import { LoadingStatus } from '@shared/loading/model/loading-status.enum';
import { AbstractControl, FormArray, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { SurveyQuestionViewModel } from '@core/models/tapp-order/view-model/survey/survey-question.view.model';
import { SurveyQuestionTypeEnum } from '@shared/enum/survey-question-type.enum';
import { Observable, of } from 'rxjs';
import { SurveyAnswersViewModel } from '@core/models/tapp-order/view-model/survey/survey-answers.view.model';
import { LoadingService } from '@shared/loading/loading.service';
import { SurveySuccessModalComponent } from '@shared/survey-modal/component/survey-success-modal.component';

@Component({
  selector: 'app-survey-modal',
  templateUrl: './survey-modal.component.html',
  styleUrls: ['./survey-modal.component.scss'],
  providers: [SurveyDataProvider],
})
export class SurveyModalComponent implements OnInit {
  public loadingStatus: string = LoadingStatus.pending;
  public loadingStatusEnum = LoadingStatus;
  public surveyQuestionTypeEnum = SurveyQuestionTypeEnum;
  public formSubmitted: boolean = false;
  public surveys: SurveyViewModel[] = [];
  public form: FormGroup = null;
  public successDialogRef: MatDialogRef<SurveySuccessModalComponent>;

  constructor(
    private surveyDataProvider: SurveyDataProvider,
    private fb: FormBuilder,
    private loadingService: LoadingService,
    public dialogRef: MatDialogRef<SurveyModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: OrderApiModel,
    public dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    let placeId = null;
    let category = null;

    switch (this.data.constructor) {
      case OrderApiModel:
        placeId = this.data.place.publicId;
        category = SurveyCategoryEnum.ORDER;
        break;
      default:
        this.dialogRef.close();
        return;
    }

    this.surveyDataProvider
      .getSurveysList(placeId, category)
      .subscribe(
        (result: SurveyViewModel[]) => {
          this.surveys = result;
          this.initForm();

          this.loadingStatus = LoadingStatus.success;
        },
        () => {
          this.dialogRef.close();
        },
      )
      .add(() => {
        this.loadingService.hideLoader();
      });
  }

  private initForm(): void {
    this.form = this.fb.group({
      surveys: this.fb.array(<Array<FormGroup>>[], null),
    });
    const surveysForm = this.form.get('surveys') as FormArray;

    this.surveys.forEach((survey: SurveyViewModel) => {
      let surveyForm: FormGroup = this.fb.group({
        surveyId: [survey.id, [Validators.required]],
        orderPublicId: [this.data.publicId, [Validators.required]],
        survey: [survey, [Validators.required]],
        answers: this.fb.array(<Array<FormGroup>>[], null),
      });

      const answersForm = surveyForm.get('answers') as FormArray;

      survey.questions.forEach((question: SurveyQuestionViewModel) => {
        let answerForm: FormGroup = this.fb.group({
          questionId: [question.id, [Validators.required]],
          question: [question, [Validators.required]],
          rating: [null],
          ownAnswer: [null],
          selectedChoiceId: [null],
          selectedChoicesIds: [null],
        });

        if (question.type === SurveyQuestionTypeEnum.RATING) {
          answerForm.get('rating').setValidators([Validators.required, Validators.min(1), Validators.max(5)]);
        }

        if (question.type === SurveyQuestionTypeEnum.OPEN_ANSWER) {
          answerForm.get('ownAnswer').setValidators([Validators.required, Validators.maxLength(250)]);
        }

        if (question.type === SurveyQuestionTypeEnum.SINGLE_CHOICE) {
          answerForm.get('selectedChoiceId').setValidators([Validators.required]);
        }

        if (question.type === SurveyQuestionTypeEnum.MULTIPLE_CHOICE) {
          answerForm.get('selectedChoicesIds').setAsyncValidators([this.validateCheckbox.bind(this)]);
        }

        answersForm.push(answerForm);
      });

      surveysForm.push(surveyForm);
    });
  }

  public onSubmit(event: Event): void {
    event.preventDefault();
    this.formSubmitted = true;

    if (this.form.valid) {
      this.loadingService.showLoader();

      let data = new SurveyAnswersViewModel();
      data.apply(this.form.value);

      this.surveyDataProvider
        .saveSurveyAnswers(data)
        .subscribe(() => {
          this.data.hasSurveyToComplete = false;

          this.successDialogRef = this.dialog.open(SurveySuccessModalComponent, {
            panelClass: 'survey-success-modal',
          });

          this.dialogRef.close();
        })
        .add(() => {
          this.loadingService.hideLoader();
        });
    }
  }

  public validateCheckbox(control: AbstractControl): Observable<ValidationErrors> {
    if (!control.value || control.value.length == 0) {
      return of({
        custom: 'common.form.required',
      });
    }
    return of(null);
  }

  public cancel(): void {
    this.dialogRef.close();
  }
}

import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

const LOCAL_STORAGE_APP_VERSION_KEY = 'appVersion';
const SESSION_STORAGE_APP_VERSION_KEY = 'appVersion';

@Injectable({
  providedIn: 'root',
})
export class MigrationService {
  private currentVersion = environment.appVersion.match(/\d+\.\d+\.\d+/)[0];

  constructor() {
    this.monitorLocalStorageDataVersionChange();
  }

  public migrateApp(): void {
    this.migrateLocalStorageData();
    this.migrateSessionStorageData();
  }

  /**
   * @description Listens for changes in the local storage data version.
   */
  private monitorLocalStorageDataVersionChange(): void {
    window.addEventListener('storage', (event) => {
      if (event.key === LOCAL_STORAGE_APP_VERSION_KEY) {
        const localStorageDataVersion = this.getLocalStorageDataVersion();

        if (localStorageDataVersion !== this.currentVersion) {
          window.location.reload();
        }
      }
    });
  }

  private migrateLocalStorageData(): void {
    const localStorageDataVersion = this.getLocalStorageDataVersion();

    if (!localStorageDataVersion) {
      localStorage.setItem(LOCAL_STORAGE_APP_VERSION_KEY, environment.appVersion);
      return;
    }

    if (localStorageDataVersion === this.currentVersion) {
      return;
    }

    this.consoleMessage('Local storage data version before migration ' + localStorageDataVersion);

    if (localStorageDataVersion < '2.1.38') {
      this.migrateLocalStorageTo_2_1_38();
    }

    if (localStorageDataVersion < '2.1.39') {
      this.migrateLocalStorageTo_2_1_39();
    }

    if (localStorageDataVersion < '2.1.43') {
      this.migrateLocalStorageTo_2_1_43();
    }

    localStorage.setItem(LOCAL_STORAGE_APP_VERSION_KEY, environment.appVersion);
    this.consoleMessage('Local storage data migrated to version ' + this.currentVersion + ' successfully');
  }

  private migrateSessionStorageData(): void {
    const sessionStorageDataVersion = this.getSessionStorageDataVersion();

    if (!sessionStorageDataVersion) {
      sessionStorage.setItem(SESSION_STORAGE_APP_VERSION_KEY, environment.appVersion);
      return;
    }

    if (sessionStorageDataVersion === this.currentVersion) {
      return;
    }

    this.consoleMessage('Session storage data version before migration ' + sessionStorageDataVersion);

    if (sessionStorageDataVersion < '2.1.39') {
      this.migrateSessionStorageTo_2_1_39();
    }

    sessionStorage.setItem(SESSION_STORAGE_APP_VERSION_KEY, environment.appVersion);
    this.consoleMessage('Session storage data migrated to version ' + this.currentVersion + ' successfully');
  }

  private getLocalStorageDataVersion(): string | null {
    const localStorageDataVersion = localStorage.getItem(LOCAL_STORAGE_APP_VERSION_KEY);

    if (!localStorageDataVersion) {
      return null;
    }

    return localStorageDataVersion.match(/\d+\.\d+\.\d+/)[0];
  }

  private getSessionStorageDataVersion(): string | null {
    const sessionStorageDataVersion = sessionStorage.getItem(SESSION_STORAGE_APP_VERSION_KEY);

    if (!sessionStorageDataVersion) {
      return null;
    }

    return sessionStorageDataVersion.match(/\d+\.\d+\.\d+/)[0];
  }

  /**
   * @description In this version, we decided to clear all local storage data to ensure that there are no conflicts with the new version.
   */
  private migrateLocalStorageTo_2_1_38(): void {
    localStorage.clear();
    this.consoleMessage('Migrated local storage data to version 2.1.38');
  }

  /**
   * @description In this version, the order ID is not stored in local storage.
   */
  private migrateLocalStorageTo_2_1_39(): void {
    localStorage.removeItem('orderId');
    this.consoleMessage('Migrated local storage data to version 2.1.39');
  }

  /**
   * @description In this version of the application, we no longer use "null" to indicate an empty value, only an empty string.
   * Therefore, we remove the keys "street" and "localNo" from local storage and replace them with empty strings.
   */
  private migrateLocalStorageTo_2_1_43(): void {
    const street = localStorage.getItem('street');
    const localNo = localStorage.getItem('localNo');

    if (street === 'null') {
      localStorage.setItem('street', '');
    }

    if (localNo === 'null') {
      localStorage.setItem('localNo', '');
    }

    this.consoleMessage('Migrated local storage data to version 2.1.43');
  }

  /**
   * @description In this version, the order ID is not stored in session storage.
   */
  private migrateSessionStorageTo_2_1_39(): void {
    sessionStorage.removeItem('orderId');
    this.consoleMessage('Migrated session storage data to version 2.1.39');
  }

  private consoleMessage(message: string): void {
    console.log(message);
  }
}

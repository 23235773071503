import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PaymentService } from '@core/services/payment.service';
import { AppValidators } from '@core/validator/app.validators';
import { LoadingService } from '@shared/loading/loading.service';

@Component({
  selector: 'app-payment-invoice-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
})
export class FormComponent implements OnInit {
  visible: boolean;
  invoiceForm: FormGroup;

  constructor(
    private paymentService: PaymentService,
    private formBuilder: FormBuilder,
    private loadingService: LoadingService,
  ) {
    this.visible = false;
  }

  @Output() public invoiceStatus: EventEmitter<boolean> = new EventEmitter<boolean>();

  ngOnInit(): void {
    this.loadingService.showLoader();
    this.createInvoiceForm();
    this.loadingService.hideLoader();
  }

  createInvoiceForm(): void {
    this.invoiceForm = this.formBuilder.group({
      taxIdNumber: [null, [Validators.required, AppValidators.taxNumber]],
      company: [null, [Validators.required, Validators.minLength(2), Validators.maxLength(255)]],
      city: [null, [Validators.required, Validators.minLength(3), Validators.maxLength(55)]],
      street: [null, [Validators.required, Validators.minLength(2), Validators.maxLength(55)]],
      streetNumber: [null, [Validators.required, Validators.minLength(1), Validators.maxLength(10)]],
      apartmentNumber: [null, [Validators.required, Validators.minLength(1), Validators.maxLength(10)]],
      zipCode: [null, [Validators.required, AppValidators.zipCode]],
      country: [null, [Validators.required, Validators.minLength(3), Validators.maxLength(255)]],
    });
  }

  private sendRequest(submitForm: FormGroup): void {
    // const contractor = this.prepareRequest(submitForm);
    // this.dataProvider.create(contractor).subscribe(() => {
    //   this.loadingStatus = LoadingStatus.success;
    //   this.messagesService.addSuccessMessage(
    //     'features.contractor.page.contractor.create.form.messages.success'
    //   );
    //przejście po stworzeniu rekordu
    this.paymentService.invoiceTrigger();
    this.invoiceStatus.emit(this.paymentService.getInvoiceStatus());
    // });
  }

  setInvoiceLabel(): void {
    var label: string = null;
    label = this.invoiceForm.get('taxIdNumber').value;
    this.paymentService.setInvoiceLabel(label);
  }

  onSubmit(submitForm): void {
    if (this.invoiceForm.valid) {
      this.sendRequest(submitForm);
    } else {
      this.trigger();
      this.setInvoiceLabel();
    }
  }

  trigger(): void {
    this.paymentService.invoiceTrigger();
    this.invoiceStatus.emit(this.paymentService.getInvoiceStatus());
  }
}

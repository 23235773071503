import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { PlaceDataProvider } from '@core/data-providers/place.data-provider';
import { TermsAndConditionsItemViewModel } from '@core/models/tapp-order/view-model/terms-and-conditions/terms-and-conditions-item.view.model';
import { PaymentFormService } from '@core/pages/payment/payment-form.service';
import { OrderService } from '@core/services/order.service';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-payment-approve',
  templateUrl: './approve.component.html',
  styleUrls: ['./approve.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ApproveComponent implements OnInit {
  public readonly environment = environment;

  public termsAndConditions$: Observable<TermsAndConditionsItemViewModel[]>;
  public termsAndConditions: TermsAndConditionsItemViewModel[] = [];
  public termsVisible: boolean = false;
  public html: SafeHtml = this.domSanitizer.bypassSecurityTrustHtml('');

  @Input() public formRejected: boolean;
  @Output() public selectApproveEvent: EventEmitter<TermsAndConditionsItemViewModel[]> = new EventEmitter<
    TermsAndConditionsItemViewModel[]
  >();

  constructor(
    public placeDataProvider: PlaceDataProvider,
    public paymentFormService: PaymentFormService,
    private domSanitizer: DomSanitizer,
    private orderService: OrderService,
  ) {}

  ngOnInit(): void {
    this.termsAndConditions$ = this.placeDataProvider.getTermsAndConditions('orders');
    this.placeDataProvider.getTermsAndConditions('orders').subscribe((value) => {
      this.termsAndConditions = value;
    });
  }

  public sendSelectApprove(item): void {
    this.termsAndConditions = this.termsAndConditions.map((obsItem: TermsAndConditionsItemViewModel) => {
      if (item.id === obsItem.id) {
        obsItem.selected = !obsItem.selected;
        return obsItem;
      } else {
        return obsItem;
      }
    });
    this.paymentFormService.selectedTermsAndConditions = this.termsAndConditions as TermsAndConditionsItemViewModel[];

    this.orderService.setSelectedTermsAndConditions(this.paymentFormService.selectedTermsAndConditions);
  }

  public showTermsAndCoditions(): void {
    this.termsVisible = true;

    this.placeDataProvider.getTermsAndPrivacyPolicy().subscribe({
      next: (terms) => {
        this.html = this.domSanitizer.bypassSecurityTrustHtml('');

        if (terms.length) {
          const term = terms.reduce((prev, current) => (prev.id < current.id ? prev : current));

          if (term) {
            this.html = this.domSanitizer.bypassSecurityTrustHtml(term.content || '');
          }
        }
      },
    });
  }

  public handleTermsHide(): void {
    this.termsVisible = false;
  }
}

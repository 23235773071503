import { Component, Input, OnInit } from '@angular/core';
import { ProductViewModel } from '@core/models/tapp-order/view-model/product/product.view.model';

@Component({
  selector: 'app-product-size-button',
  templateUrl: './product-size-button.component.html',
  styleUrls: ['./product-size-button.component.scss'],
})
export class ProductSizeButtonComponent implements OnInit {
  @Input() pizzaType: ProductViewModel = null;
  @Input() selected: boolean = null;
  @Input() pizzaPrice: number;
  @Input() showPrice: boolean = true;

  constructor() {}

  ngOnInit(): void {}
}

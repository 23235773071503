<!-- Navigation by product groups -->
<app-product-group
  id="app-navbar"
  class="navigator"
  [groups]="filteredMenu.groups"
  [hasDiscounts]="menu.checkPromotions"
  [qrMenuMode]="qrMenuMode"
  *ngIf="menu"
></app-product-group>

<!-- Content -->
<div *ngIf="isLoaded" class="content">
  <div class="navigator-empty-area" *ngIf="menu"></div>
  <ng-container *ngTemplateOutlet="closeAlert"></ng-container>

  <div class="search-wrapper">
    <div class="plug" style="width: 200px"></div>
    <ng-container *ngTemplateOutlet="currentLocalLabel"></ng-container>
    <div class="right-wrapper">
      <div class="search-inner-wrapper">
        <p-autoComplete
          [placeholder]="'tapp-order.search' | translate"
          [suggestions]="searchSuggestions"
          (completeMethod)="onSearch($event)"
          (onClear)="onSearchClear()"
        >
        </p-autoComplete>

        <i class="pi pi-search"></i>
      </div>
      <div
        (click)="openFiltersDialog()"
        class="filters-btn ui-clickable"
        *ngIf="attributes.length > 0 || allergens.length > 0 || nutritions.length > 0"
      >
        <img class="icon-arrow" src="/assets/icons/arrow-down-toggle.svg" alt="" />
        <img class="icon" src="/assets/icons/icon-filters.svg" alt="" />
      </div>
    </div>
  </div>

  <ng-container *ngIf="filteredMenu && hasMenu; else noMenu">
    <ng-container *ngIf="filteredMenu.groups">
      <ng-container *ngFor="let group of filteredMenu.groups">
        <ng-container *ngIf="(group.products && group.products?.length > 0) || !group.subgroupsIsEmpty()">
          <div id="{{ group.publicId }}" class="group">
            <div class="group__title">
              <div class="group__title__background-text">{{ group.name }}</div>
              <div class="group__title__front">
                <h2 class="group__title__front__text">{{ group.name }}</h2>
                <!-- TODO: implement -->
                <app-pizza-divide-switch *ngIf="false" class="group__title__front__switch"></app-pizza-divide-switch>
              </div>
            </div>

            <div class="group__products" *ngIf="group.products?.length">
              <app-product-item
                *ngFor="let product of group.products"
                [data]="product"
                [buttonVisible]="isOpen && !placeIsPreviewOnly && !qrMenuMode"
                (onAdd)="handleAddProductToBasket(product)"
              ></app-product-item>
            </div>

            <div class="subgroups" *ngIf="group.subgroups?.length">
              <div *ngFor="let subgroup of group.subgroups" class="subgroup">
                <ng-container *ngIf="subgroup.products?.length > 0">
                  <span class="subgroup__title">
                    <img
                      *ngIf="subgroup.icon.url"
                      src="{{ subgroup.icon.url }}"
                      class="icon-color"
                      style="height: 24px; width: 24px; margin-right: 1rem"
                    />
                    <span>{{ subgroup.name }}</span></span
                  >
                  <div class="subgroup__products">
                    <app-product-item
                      *ngFor="let product of subgroup.products"
                      [data]="product"
                      [buttonVisible]="isOpen && !placeIsPreviewOnly && !qrMenuMode"
                      (onAdd)="handleAddProductToBasket(product)"
                    ></app-product-item>
                  </div>
                </ng-container>
              </div>
            </div>
          </div> </ng-container
      ></ng-container>
    </ng-container>
  </ng-container>

  <ng-template #noMenu>
    <ng-container *ngIf="isLoaded">
      <p>{{ 'products.noItemsTitle' | translate }}</p>
      <p>{{ 'products.noItemsDescription' | translate }}</p>
    </ng-container>
  </ng-template>

  <div class="buttom-button-empty-area" *ngIf="menu"></div>
</div>

<!-- Close alert -->
<ng-template #closeAlert>
  <div
    class="close-alert"
    *ngIf="!isOpen || (!place.qrMenuActive && !place.tableOrderActive && !place.onlineOrderActive)"
  >
    <span *ngIf="!nextWorkingHours">{{ 'products.local-closed-message' | translate }}</span>
    <span *ngIf="nextWorkingHours">
      {{ 'products.local-closed-message-2' | translate }}
      <b>
        {{ 'app.weekDays.' + nextWorkingHours.weekDay | translate }}
        {{ nextWorkingHours.startTime }}</b
      >.
    </span>
    <span> {{ 'products.local-closed-message-3' | translate }}</span>
  </div>
</ng-template>

<!-- Current local label -->
<ng-template #currentLocalLabel>
  <div
    class="col-12"
    *ngIf="orderService.tableId"
    style="
      margin-top: 1rem;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      text-align: center;
      color: #979797;
    "
  >
    {{ 'tapp-order.order-summary-room' | translate }}: {{ orderService.roomName }} <br />
    {{ 'tapp-order.order-summary-table' | translate }}:
    {{ orderService.tableName }}
  </div>

  <p
    *ngIf="!orderService.tableId && place && place.onlineOrderActive"
    style="
      margin-top: 1rem;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      text-align: center;
      color: #979797;
    "
  >
    {{ 'products.local-realizing-order-label' | translate }}:
    <span
      style="
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        text-align: center;
        color: var(--primary);
        cursor: pointer;
      "
      (click)="changeLocal()"
      >{{ placeString ? placeString : null }}</span
    >
  </p>
</ng-template>

<!-- Basket -->
<app-sidebar
  *ngIf="place && (place.onlineOrderActive || orderService.tableId) && !qrMenuMode && !placeIsPreviewOnly"
  class="basket"
  [basketItemsAmount]="basketService.calculateAllItemsInBasket()"
  [basketItems]="basketService.basketItems$ | async"
  [basketItemsPrice]="basketService.calculatePrice()"
  [nextWorkingHours]="nextWorkingHours"
  [isOpen]="isOpen"
  [productsCrossSell]="productsCrossSell.length"
  (openCrossSellDialogEvent)="showCrossSellProducts()"
></app-sidebar>

<!-- Button show basket -->
<app-bottom-button
  *ngIf="
    bottomNotificationComponent.active == false &&
    !placeIsPreviewOnly &&
    !qrMenuMode &&
    filteredMenu &&
    isOpen &&
    hasMenu
  "
  class="button-show-basket"
  [centerText]="generateButtonText()[0]"
  [rightText]="generateButtonText()[1] | currency: 'PLN':'zł':'1.2-2':'pl-PL'"
  [display]="2"
  (click)="navigateToPayment()"
></app-bottom-button>

<!-- Dialog address selection -->
<app-dialog-address-finder
  [displayDialog]="displayDialog"
  [redirectToPlace]="true"
  (onCloseDialog)="closeDialog()"
  [message1]="'tapp-order.select-adress-component.dialog.adress-change-warning-1' | translate"
  [message2]="'tapp-order.select-adress-component.dialog.adress-change-warning-3' | translate"
></app-dialog-address-finder>

<!-- Dialog cross-sell -->
<app-dialog [(visible)]="displayCrossSellDialog" [closable]="false" (onHide)="closeCrossSellProducts()">
  <ng-template #header>
    <div class="dialog-cross-sell__header">{{ 'tapp-order.pages.product.dialog.cross-sell.header' | translate }}</div>
  </ng-template>

  <div class="dialog-cross-sell__content">
    <app-product-item
      *ngFor="let product of productsCrossSell"
      [data]="product"
      [buttonVisible]="true"
      (onAdd)="handleAddProductToBasket(product)"
    ></app-product-item>
  </div>

  <ng-template #footer>
    <div class="dialog-cross-sell__footer">
      <button
        pButton
        class="p-button"
        type="button"
        [label]="'tapp-order.pages.product.dialog.cross-sell.button-label' | translate"
        (click)="displayCrossSellDialog = false"
      ></button>
    </div>
  </ng-template>
</app-dialog>

<!-- Dialog if tables orders disabled -->
<p-dialog
  [(visible)]="orderService.tableId && placeIsPreviewOnly"
  [style]="{ 'max-width': '500px' }"
  [draggable]="false"
  [modal]="true"
  [showHeader]="false"
  [closable]="false"
>
  <h4 class="mt-4 pt-2 text-center">{{ 'tapp-order.order-table-disabled' | translate }}</h4>
</p-dialog>

<!-- Other components -->
<ng-template #callForServiceTemplate>
  <div class="modal-header">
    <h4 class="modal-title pull-left">
      {{ 'app.components.call-for-service.title' | translate }}
    </h4>
    <button type="button" mat-icon-button (click)="modalRef?.hide()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="modal-body">
    {{ 'app.components.call-for-service.message' | translate }}
  </div>
</ng-template>

<div class="payment-component payment-invoice-component">
  <div class="row">
    <div class="col" [ngClass]="paymentFormService.paymentInvoiceRejected ? 'border-red' : ''">
      <div class="d-flex align-items-center justify-content-between">
        <span class="label">{{ 'tapp-order.payment.invoice.title' | translate }}</span>
        <app-input-switch
          [ngClass]="'switch'"
          [(checked)]="checked"
          (checkedChange)="changeInvoiceSwitch($event)"
        ></app-input-switch>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col" *ngIf="checked && displayInvoiceForm === false">
      <app-invoice-preview (openInvoiceFormEmitter)="showInvoiceForm()"></app-invoice-preview>
    </div>
  </div>
</div>

<p-dialog
  [(visible)]="displayInvoiceForm"
  [draggable]="false"
  [resizable]="false"
  [modal]="true"
  [showHeader]="false"
  (onHide)="onCloseDialog()"
>
  <app-invoice-form #invoiceForm (closeDialogEmmiter)="onCloseDialog()"></app-invoice-form>
</p-dialog>

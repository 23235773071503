import { Exclude, Expose } from 'class-transformer';

@Exclude()
export class GusApiModel {
  @Expose()
  public taxNumber: string;

  @Expose()
  public companyName: string;

  @Expose()
  public street: string;

  @Expose()
  public buildingNo: string;

  @Expose()
  public localNo: string;

  @Expose()
  public zipCode: string;

  @Expose()
  public city: string;

  @Expose()
  public country: string;
}

<div class="pizza-card-container">
  <div class="col-3 pizza-card-image" *ngIf="product?.image?.url">
    <img src="{{ product?.image?.url }}" />
  </div>
  <div class="pizza-card-context" [class]="product?.image?.url ? 'col-9' : 'col-12'">
    <div class="pizza-card-context-container">
      <p class="pizza-card-context-title">
        {{ product.name }}
      </p>
      <p class="pizza-card-context-subtitle" *ngIf="summaryMode === true">
        <ng-container *ngIf="getPizzaSize(product)"> Rozmiar: {{ getPizzaSize(product) }} | </ng-container>
        <ng-container *ngIf="getPizzaDough(product)"> Ciasto: {{ getPizzaDough(product) }} </ng-container>
      </p>
      <p class="pizza-card-context-subtitle">
        {{ product.description | removeDots }}
      </p>
      <div class="selected-products__products__item__modifiers" *ngIf="summaryMode === true">
        <p
          *ngFor="let addedModifier of getSelectedModifiers(product)"
          class="selected-products__products__item__modifiers__modifier selected-products__products__item__modifiers__modifier--added"
        >
          +
          <ng-template [ngIf]="addedModifier.CurrentQuantity > 1">{{ addedModifier.CurrentQuantity }}x </ng-template
          >{{ addedModifier.name | removeDots }}
        </p>
        <p
          *ngFor="let removedModifier of getRemovedModifiers(product)"
          class="selected-products__products__item__modifiers__modifier selected-products__products__item__modifiers__modifier--removed"
        >
          - {{ removedModifier.name | removeDots }}
        </p>
      </div>
    </div>

    <div class="pizza-card-buttons-action" *ngIf="summaryMode === false && isOpen">
      <button class="add-button ui-clickable" (click)="redirectToModify()">
        <span *ngIf="discountMode === true">{{ 'tapp-order.article-card-select-text' | translate }}</span>
        <span *ngIf="discountMode === false" class="add-button__text">
          {{ 'tapp-order.article-card-add-text' | translate }}
          <span class="plus">+</span>
        </span>
      </button>
    </div>

    <div class="pizza-card-buttons-action" *ngIf="summaryMode === true">
      <i class="ic ic-check primary-color"></i>
    </div>
  </div>
</div>

<p-dialog
  [(visible)]="displayDialog"
  [draggable]="false"
  [modal]="true"
  [showHeader]="true"
  [header]="product.name"
  class="p-dialog-discount-product"
  [styleClass]="'product-pizza-card-dialog'"
  [transitionOptions]="'0ms'"
  [resizable]="false"
>
  <app-product-discount-modify
    *ngIf="displayDialog"
    [productId]="product.publicId"
    [originalExternalGroupIdFromDiscount]="product.originalExternalGroupIdFromDiscount"
    [modifyType]="'new'"
    [conditionSizeIds]="conditionSizeIds"
    [conditionDoughIds]="conditionDoughIds"
    (selectProductEvent)="selectProduct($event)"
  ></app-product-discount-modify>
</p-dialog>

import { Component, Input, OnInit } from '@angular/core';
import { environment } from '@env/environment';
import { PlaceHttpService } from '../../core/http/tapp-order/place/place.http.service';
import { PlaceService } from '../../services/place.service';
import { PlaceViewModel } from '@core/models/tapp-order/view-model/place/place.view.model';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss'],
})
export class LogoComponent implements OnInit {
  @Input() hide = false;
  @Input() back_arrow: boolean;

  public redirectUrl: string = '';
  public logo: string = null;
  public defaultPlaceLogo: string = null;
  public environment = environment;

  constructor(private placeHttpService: PlaceHttpService, private placeService: PlaceService, private router: Router) {
    this.getRedirectUrl();

    if (environment.brand.showLogo) {
      this.placeService.getPlaces().subscribe((places: PlaceViewModel[]) => {
        const place = places.find((place: PlaceViewModel) => place.image?.url);
        if (place) {
          this.defaultPlaceLogo = place.image.url + '?v=' + environment.appVersion;
        } else {
          this.defaultPlaceLogo = '/assets/images/' + environment.defaultLogoName + '?v=' + environment.appVersion;
        }
      });
    }
  }

  ngOnInit(): void {
    if (environment.brand.showLogo) {
      this.placeService.updatePlaceSubject$.subscribe(() => {
        this.initLogo();
      });
    } else {
      this.logo = null;
    }
  }

  public getRedirectUrl(): void {
    this.redirectUrl = sessionStorage.getItem('tableId')
      ? '/' + sessionStorage.getItem('placeId')
      : window.location.origin;
  }

  public initLogo() {
    if (!this.placeService.getPlaceId()) {
      return;
    }

    this.placeService.getPlace().subscribe((placeResponse) => {
      this.logo = placeResponse.image?.url
        ? placeResponse.image?.url + '?v=' + environment.appVersion
        : '/assets/images/' + environment.defaultLogoName + '?v=' + environment.appVersion;
    });
  }
}

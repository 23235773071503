import { TransformFnParams } from 'class-transformer';

export namespace _classTransformerHelper {
  export const emptyToString = ({ value }: TransformFnParams): unknown => {
    return value || '';
  };
  export const nullStringToEmptyString = ({ value }: TransformFnParams): unknown => {
    return value === 'null' ? '' : value;
  };
  export const emptyToNullString = ({ value }: TransformFnParams): unknown => {
    return value || 'null';
  };
}

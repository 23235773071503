import { Exclude, Expose } from 'class-transformer';
import { PayuPaymentMethodApiModel } from '../../model/payu/payu-payment-method.api.model';

export class PayuPaymentMethodsApiModel {
  @Expose()
  public posId: number;

  @Expose()
  public paymentMethods: PayuPaymentMethodApiModel[];
}

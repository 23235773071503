import { Component, NgZone, OnInit } from '@angular/core';
import { environment } from '@env/environment';
import { AuthService } from '@core/services/auth.service';
import { take } from 'rxjs/operators';
import { SocialMediaEnum } from '@core/services/enums/SocialMediaTypeEnum';
import { MatDialogRef } from '@angular/material/dialog';
import { LoginModalComponent } from '@shared/login-modal/login-modal.component';

declare const FB: any;

@Component({
  selector: 'app-facebook-login-button',
  templateUrl: './facebook-login-button.component.html',
  styleUrls: ['./facebook-login-button.component.scss'],
})
export class FacebookLoginButtonComponent implements OnInit {
  constructor(
    private authService: AuthService,
    public dialogRef: MatDialogRef<LoginModalComponent>,
    private ngZone: NgZone,
  ) {}

  ngOnInit(): void {
    this.loadFacebookSDK();
  }

  loadFacebookSDK() {
    (window as any).fbAsyncInit = function () {
      FB.init({
        appId: environment.facebookAppId,
        cookie: true,
        xfbml: true,
        version: 'v10.0',
      });
    };

    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = `https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v10.0&appId=${environment.facebookAppId}&autoLogAppEvents=1`;
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'facebook-jssdk');
  }

  loginWithFacebook(event): void {
    event.preventDefault();
    FB.login(
      (response: any) => {
        if (response.authResponse) {
          this.ngZone.run(() => {
            const accessToken = response.authResponse.accessToken;
            this.authService.login(null, true, SocialMediaEnum.fb, accessToken).subscribe(
              () => {
                this.dialogRef.close();
              },
              (e) => {
                console.log(e);
              },
              () => {},
            );
          });
        } else {
        }
      },
      { scope: 'email' },
    );
  }
}

import { Component, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-start-page',
  templateUrl: './start.component.html',
  styleUrls: ['./start.component.scss'],
})
export class StartPageComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit(): void {}

  runScanner() {
    this.router.navigate(['scanner']);
  }
}

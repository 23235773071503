export enum PaymentOrderStatusEnum {
  paymentPending = 'paymentPending',
  paymentRefused = 'paymentRefused',
  paymentRefunded = 'paymentRefunded',
  orderPending = 'orderPending',
  orderAccepted = 'orderAccepted',
  orderInDelivery = 'orderInDelivery',
  orderFinished = 'orderFinished',
  orderRefused = 'orderRefused',
  paymentRefundedOwh = 'paymentRefundedOwh', //outside working hours
}

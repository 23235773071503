import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ActivateEmailModalService } from '@core/services/activate-email-modal.service';
import { AuthService } from '@core/services/auth.service';
import { ActivationSuccessfulModalComponent } from '@shared/activation-successful-modal/activation-successful-modal.component';

@Component({
  selector: 'app-activation',
  templateUrl: './activation.component.html',
  styleUrls: ['./activation.component.scss'],
})
export class ActivationComponent implements OnInit {
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private activateEmailModalService: ActivateEmailModalService,
    private authService: AuthService,
  ) {}

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      const newVisualization = params['activationToken'];
      this.authService.activate(newVisualization).subscribe(
        () => {
          this.router.navigate([sessionStorage.getItem('placeId') ?? '/']);
          this.dialog.open(ActivationSuccessfulModalComponent, {
            panelClass: 'activation-successful-modal-container',
          });
          this.activateEmailModalService.closeDialog();
        },
        () => {
          this.router.navigate(['']);
        },
        () => {},
      );
    });
  }
}

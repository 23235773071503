import { Injectable } from '@angular/core';
import {
  CateringAggregationArticleModifierItemViewModel,
  CateringAggregationArticleModifierViewModel,
  CateringAggregationArticleViewModel,
} from '../models/catering-aggregation.view.model';
import { ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ArticleBuilderService {
  private selectedModifierItemList: Map<string, Map<string, boolean>> = new Map<string, Map<string, boolean>>();
  private selectedModifierItemListSubject = new ReplaySubject<Map<string, Map<string, boolean>>>(1);
  public selectedModifierItem$ = this.selectedModifierItemListSubject.asObservable();
  private selectedSubArticleList: Map<string, Map<string, boolean>> = new Map<string, Map<string, boolean>>();
  private selectedSubArticleListSubject = new ReplaySubject<Map<string, Map<string, boolean>>>(1);
  public selectedSubArticleList$ = this.selectedSubArticleListSubject.asObservable();

  constructor() {}

  public initialize(article: CateringAggregationArticleViewModel): void {
    article.modifierArticleList.forEach((modifier) => {
      this.resetSelectedModifierItem(modifier.id, modifier.itemCollection);
    });

    article.subArticleList.forEach((subArticle) => {
      this.resetSelectedSubArticle(subArticle.id, subArticle.replacementList);
    });

    this.selectedSubArticleListSubject.next(this.selectedSubArticleList);
    this.selectedModifierItemListSubject.next(this.selectedModifierItemList);
  }

  /**
   * Marks all modifier items as not selected
   */
  public resetSelectedModifierItem(
    modifierId: string,
    modifierItemList: Array<CateringAggregationArticleModifierItemViewModel>,
  ): void {
    const selectedModifierItemMap = new Map<string, boolean>();

    modifierItemList.forEach((item) => {
      selectedModifierItemMap.set(item.id, false);
    });

    this.selectedModifierItemList.set(modifierId, selectedModifierItemMap);
  }

  public resetSelectedSubArticle(
    subArticleId: string,
    replacementList: Array<CateringAggregationArticleViewModel>,
  ): void {
    if (!this.isBaseIngredient(replacementList)) {
      const selectedSubArticleMap = new Map<string, boolean>();

      replacementList.forEach((item) => {
        selectedSubArticleMap.set(item.id, false);
      });

      this.selectedSubArticleList.set(subArticleId, selectedSubArticleMap);
    }
  }

  private isBaseIngredient(replacementList: Array<CateringAggregationArticleViewModel>): boolean {
    return replacementList.length === 0;
  }

  public switchModifierItemSelectionState(
    modifier: CateringAggregationArticleModifierViewModel,
    modifierItem: CateringAggregationArticleModifierItemViewModel,
  ): void {
    const selectedModifier = this.selectedModifierItemList.get(modifier.id);
    const selectedModifierItem = selectedModifier.has(modifierItem.id) ? selectedModifier.get(modifierItem.id) : false;

    if (!modifier.multiChoice && !selectedModifierItem) {
      this.resetSelectedModifierItem(modifier.id, modifier.itemCollection);
    }

    if (
      this.selectedModifierItemList.get(modifier.id) &&
      this.selectedModifierItemList.get(modifier.id).has(modifierItem.id)
    ) {
      const selectedCount = Array.from(this.selectedModifierItemList.get(modifier.id).entries())
        .map(([key, value]) => value)
        .filter((v) => v);
      const isSelectedMaximumItems = modifier.multiChoiceMax === selectedCount.length;

      if (!modifier.multiChoice) {
        if (selectedModifierItem) {
          if (!modifier.isRequired) {
            this.selectedModifierItemList.get(modifier.id).set(modifierItem.id, false);
            (document.getElementById(modifierItem.id) as HTMLInputElement).checked = false;
          }
        } else {
          this.selectedModifierItemList.get(modifier.id).set(modifierItem.id, true);
        }
      } else {
        if (selectedModifierItem) {
          this.selectedModifierItemList.get(modifier.id).set(modifierItem.id, false);
        } else if (isSelectedMaximumItems) {
          (document.getElementById(modifierItem.id) as HTMLInputElement).checked = false;
        } else {
          this.selectedModifierItemList.get(modifier.id).set(modifierItem.id, true);
        }
      }
    } else {
      this.selectedModifierItemList.get(modifier.id).set(modifierItem.id, true);
    }

    this.selectedModifierItemListSubject.next(this.selectedModifierItemList);
  }

  public switchSubArticleSelectionState(
    subArticle: CateringAggregationArticleViewModel,
    subArticleReplacer: CateringAggregationArticleViewModel,
  ): void {
    this.resetSelectedSubArticle(subArticle.id, subArticle.replacementList);

    if (subArticle.id !== subArticleReplacer.id) {
      this.selectedSubArticleList.get(subArticle.id).set(subArticleReplacer.id, true);
    }

    this.selectedSubArticleListSubject.next(this.selectedSubArticleList);
  }
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '../../services/notification.service';

@Component({
  selector: 'app-scanner',
  templateUrl: './scanner.component.html',
  styleUrls: ['./scanner.component.scss'],
})
export class ScannerComponent implements OnInit {
  availableDevices: MediaDeviceInfo[];
  currentDevice: MediaDeviceInfo = null;
  hasDevices: boolean;
  hasPermission: boolean;

  constructor(private router: Router, private notificationService: NotificationService) {}

  ngOnInit(): void {}

  onCodeResult(code: string): void {
    if (code && code.length) {
      const parsedString = this.parseQR(code);
      if (parsedString) {
        // this.notificationService.show(parsedString);
        window.location.href = `${parsedString}`;
        return;
      }

      this.notificationService.showError('Code qr incorrect, try again.');
    }
  }

  onHasPermission(has: boolean): void {
    this.hasPermission = has;
    if (!has) {
      this.notificationService.showError('Could not access your camera. Please enable camera permission access');
      this.navigateToSplash();
    }
  }

  onCamerasNotFound(result: any): void {
    this.notificationService.showError('Device not found');
  }

  parseQR(qrString: string): string {
    const matchArray: RegExpMatchArray = qrString.match(/(\/)[-a-f\d]{36}/g);
    return matchArray && matchArray.length ? matchArray.map((item) => item).join('') : '';
  }

  navigateToSplash() {
    setTimeout(() => {
      this.router.navigate(['start']);
    }, 3000);
  }
}

<div class="h-100 pt-5 pb-5 d-flex justify-content-center align-items-center text-center">
  <div class="col">
    <div class="row pb-5">
      <div class="col">
        <svg xmlns="http://www.w3.org/2000/svg" width="76" height="76" viewBox="0 0 76 76" fill="none">
          <path
            d="M39.8079 37.5155L49.4734 27.8501C49.6386 27.6848 49.7625 27.499 49.8451 27.2924C49.9484 27.0859 50 26.8691 50 26.6419C50 26.1875 49.8348 25.8055 49.5043 25.4957C49.1945 25.1652 48.8125 25 48.3581 25C48.1309 25 47.9141 25.0516 47.7076 25.1549C47.501 25.2375 47.3152 25.3614 47.1499 25.5266L37.4845 35.1921L27.8191 25.5266C27.6539 25.3821 27.4783 25.2685 27.2924 25.1859C27.1066 25.1033 26.9 25.062 26.6729 25.062C26.2185 25.062 25.8261 25.2272 25.4957 25.5576C25.1859 25.8674 25.031 26.2495 25.031 26.7038C25.031 26.931 25.0723 27.1375 25.1549 27.3234C25.2375 27.5093 25.3511 27.6848 25.4957 27.8501L35.1611 37.5155L25.4957 47.1809C25.3304 47.3461 25.2065 47.532 25.1239 47.7385C25.0413 47.9244 25 48.1309 25 48.3581C25 48.5853 25.0413 48.8021 25.1239 49.0087C25.2065 49.1945 25.3304 49.3598 25.4957 49.5043C25.6402 49.6696 25.8055 49.7935 25.9913 49.8761C26.1979 49.9587 26.4147 50 26.6419 50C26.8691 50 27.0756 49.9587 27.2615 49.8761C27.468 49.7935 27.6539 49.6696 27.8191 49.5043L37.4845 39.8389L47.1499 49.5043C47.3152 49.6696 47.4907 49.7935 47.6766 49.8761C47.8831 49.9587 48.1 50 48.3271 50C48.5543 50 48.7608 49.9587 48.9467 49.8761C49.1532 49.7935 49.3288 49.6696 49.4734 49.5043C49.6386 49.3598 49.7625 49.1945 49.8451 49.0087C49.9277 48.8021 49.969 48.5853 49.969 48.3581C49.969 48.1309 49.9277 47.9244 49.8451 47.7385C49.7625 47.532 49.6386 47.3461 49.4734 47.1809L39.8079 37.5155Z"
            fill="#DC313B"
          />
          <circle cx="38" cy="38" r="37" stroke="#E13F49" stroke-opacity="0.3" stroke-width="2" />
        </svg>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="row pb-5" style="font-weight: 500; font-size: 21px; line-height: 25px; color: var(--font-primary)">
          <div class="col">
            <span>{{ 'tapp-order.pages.order.order-processing.order-refused.order-canceled' | translate }}</span>
          </div>
        </div>
        <div class="row">
          <div class="col d-flex justify-content-center align-items-center ui-clickable">
            <div
              (click)="newOrderEvent.emit()"
              style="
                background: var(--primary);
                border-radius: var(--border-radius-input);
                max-width: 270px;
                width: 100%;
                padding: 7px 20px;
              "
            >
              <span
                style="
                  font-weight: 600;
                  font-size: 14px;
                  line-height: 24px;
                  text-transform: uppercase;
                  color: var(--font-white);
                "
                >{{ 'tapp-order.pages.order.order-processing.order-refused.try-again' | translate }}</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

import { ScreeningItemExtraFeeApiModel } from './screening-item-extra-fee.api.model';
import { ScreeningItemMembershipPriceApiModel } from './screening-item-membership-price.api.model';

export class ScreeningItemApiModel {
  id: string;

  seatId: string;

  screeningId: string;

  ticketId: string;

  price: number;

  ticketPrice: number;

  quantity: number;

  tax: number;

  extraFees: ScreeningItemExtraFeeApiModel[] = [];

  membershipPrices: ScreeningItemMembershipPriceApiModel[] = [];

  bookingId: string;

  cardId: string;

  optionalExtraFees: string[] = [];

  name: string;

  printoutName: string;

  voucherNumber: string;

  voucherName: string;

  defaultPriceLevelPrice: number;

  ticketDefaultPriceLevelPrice: number;

  itemEarnedPoints: number;

  itemRedemptionPoints: number;

  promotionId: string;

  promotionName: string;
}

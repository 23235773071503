import { Component, HostListener, ViewChild } from '@angular/core';
import { ConfirmDialog } from 'primeng/confirmdialog';

@Component({
  selector: 'app-cofirm-dialog-live-order',
  templateUrl: './cofirm-dialog-live-order.component.html',
  styleUrls: ['./cofirm-dialog-live-order.component.scss'],
})
export class CofirmDialogLiveOrderComponent {
  constructor() {}

  @ViewChild('cd') private readonly confirmDialog: ConfirmDialog;

  @HostListener('window:keydown', ['$event'])
  handleKeyDown(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.confirmDialog.accept();
    } else if (event.key === 'Escape') {
      this.confirmDialog.reject();
    }
  }
}

import { Component, Inject, Input } from '@angular/core';
import { AttributeViewModel } from '@core/models/tapp-order/view-model/product/attribute.view.model';
import { AllergenViewModel } from '@core/models/tapp-order/view-model/product/allergen.view.model';
import { NutritionViewModel } from '@core/models/tapp-order/view-model/product/nutrition.view.model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import _ from 'lodash';

interface DialogData {
  attributes: AttributeViewModel[];
  allergens: AllergenViewModel[];
  nutritions: NutritionViewModel[];
}

@Component({
  selector: 'app-filters-dialog',
  templateUrl: './filters-dialog.component.html',
  styleUrls: ['./filters-dialog.component.scss'],
})
export class FiltersDialogComponent {
  public attributes: AttributeViewModel[] = [];
  public allergens: AllergenViewModel[] = [];
  public nutritions: NutritionViewModel[] = [];
  public calories: NutritionViewModel = null;

  constructor(
    public dialogRef: MatDialogRef<FiltersDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) {
    this.attributes = _.cloneDeep(data.attributes);
    this.allergens = _.cloneDeep(data.allergens);
    this.nutritions = _.cloneDeep(data.nutritions);
    this.calories = this.nutritions.find((nutrition) => nutrition.itemType === 'calories') ?? null;
  }

  public closeModal() {
    this.dialogRef.close();
  }

  public calculateRangeLabelLeftPosition(value: number): number {
    return ((value - this.calories.minValue) / (this.calories.maxValue - this.calories.minValue)) * 100;
  }

  get showResetBtn(): boolean {
    if (this.attributes.find((item) => item.checked)) {
      return true;
    } else if (this.allergens.find((item) => item.checked)) {
      return true;
    } else if (
      this.calories.valuesRange &&
      this.calories &&
      (this.calories.minValue != this.calories.valuesRange[0] || this.calories.maxValue != this.calories.valuesRange[1])
    ) {
      return true;
    }

    return false;
  }

  public resetFilters() {
    this.attributes.forEach((item) => {
      item.checked = false;
    });

    this.allergens.forEach((item) => {
      item.checked = false;
    });

    if (this.calories) {
      this.calories.valuesRange = [this.calories.minValue, this.calories.maxValue];
    }
  }

  public cancel() {
    this.dialogRef.close();
  }

  public submit() {
    this.dialogRef.close({
      attributes: this.attributes,
      allergens: this.allergens,
      nutritions: this.nutritions,
    });
  }
}

import { Component, ContentChild, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
})
export class DialogComponent implements OnInit {
  @Input() visible: boolean = false;
  @Output() visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() closable: boolean = true;
  @Input() size: 'sm' | 'md' | 'lg' = 'md';

  @Output() onHide: EventEmitter<void> = new EventEmitter<void>();

  @ContentChild('header') headerTemplate: TemplateRef<any>;
  @ContentChild('footer') footerTemplate: TemplateRef<any>;

  public dialogPosition: 'center' | 'bottom' = 'center';
  public dialogTransitionOptions: '150ms' | '300ms' = '150ms';

  constructor() {}

  ngOnInit(): void {
    this.onViewportChange();

    window.addEventListener('resize', () => {
      this.onViewportChange();
    });

    window.addEventListener('orientationchange', () => {
      this.onViewportChange();
    });
  }

  private onViewportChange(): void {
    switch (this.size) {
      case 'sm':
        this.dialogPosition = 'center';
        this.dialogTransitionOptions = '150ms';
        break;
      case 'md':
      case 'lg':
        if (window.innerWidth >= 768 /* see scss file */) {
          this.dialogPosition = 'center';
          this.dialogTransitionOptions = '150ms';
        } else {
          this.dialogPosition = 'bottom';
          this.dialogTransitionOptions = '300ms';
        }
        break;
    }
  }
}

import { EventEmitter, Injectable } from '@angular/core';
import { OrderTypeEnum } from '@core/models/tapp-order/api-model/order/OrderTypeEnum';
import { OrderService } from '@core/services/order.service';
import { ModifierTypeEnum } from '@shared/enum/modifier-type.enum';
import { AbandonedBasketService } from '@ui/abandoned-basket/abandoned-basket.service';
import * as _ from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { BasketItemViewModel } from '../core/models/tapp-order/view-model/basketItem/basket-item.view.model';
import { ProductViewModel } from '../core/models/tapp-order/view-model/product/product.view.model';
import { DeliveryZoneApiModel } from './../core/models/tapp-order/api-model/delivery-zones/delivery-zone.api.model';

@Injectable({
  providedIn: 'root',
})
export class BasketService {
  public set basketItems(basketItemViewModel: BasketItemViewModel[]) {
    this._basketItems = basketItemViewModel;
    this.basketItemsSubject.next(basketItemViewModel);

    if (basketItemViewModel.length === 0) {
      this.setFoodTotalPriceBackendCalculation(0);
      this.setFoodTotalPriceBackendCalculation(0);
    }
  }

  public get basketItems(): BasketItemViewModel[] {
    //tutaj muszę sprawdzić jaki jest current url, konstruktor nie spełnie warunku ponieważ current url zostanie później zainicjalizowany
    if (this.currentUrl !== this._orderUrl) {
      this.summaryChecker();
    }
    return this._basketItems;
  }

  public get basketItems$(): Observable<BasketItemViewModel[]> {
    return this.basketItemsSubject.asObservable();
  }

  private _basketItems: BasketItemViewModel[] = [];
  private _deliveryZone: DeliveryZoneApiModel = null;
  private _orderType: String = null;
  private basketItemsSubject: BehaviorSubject<BasketItemViewModel[]> = new BehaviorSubject(this._basketItems);
  public refreshBasketEvent: EventEmitter<any> = new EventEmitter();
  private _currentUrl: string = null;
  private _orderUrl: string = 'order';

  private _foodTotalPriceBackendCalculation: number = 0;
  private _deliveryCostBackendCalculation: number = 0;
  private _finalPriceBackendCalculation: number = 0;

  private _foodTotalPrice$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  private _deliveryCost$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  private _finalPrice$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  public foodTotalPrice$: Observable<number> = this._foodTotalPrice$.asObservable();
  public deliveryCost$: Observable<number> = this._deliveryCost$.asObservable();
  public finalPrice$: Observable<number> = this._finalPrice$.asObservable();

  constructor(private orderService: OrderService, private abandonedBasketService: AbandonedBasketService) {
    if (sessionStorage.getItem('basketItems') != null) {
      this.basketItems = JSON.parse(sessionStorage.getItem('basketItems'));
    }

    this._deliveryZone = this.orderService.getDeliveryZone();
    this.orderService.deliveryZoneSubject$.subscribe((res: DeliveryZoneApiModel) => {
      if (res !== null) {
        this._deliveryZone = res;
      }
      this.refreshPrices();
    });

    this._orderType = this.orderService.getOrderType();
    this.orderService.orderTypeSubject$.subscribe((res: string) => {
      if (res !== null) {
        this._orderType = res;
      }
      this.refreshPrices();
    });

    this.updateAbandonedBasketNotice();
  }

  add(
    item: ProductViewModel,
    selectedModifiers: ProductViewModel[],
    missingProducts: ProductViewModel[] = null,
    product2: ProductViewModel = null,
    selectedModifiers2: ProductViewModel[] = null,
    missingProducts2: ProductViewModel[] = null,
  ) {
    const basketItem = _.cloneDeep(item) as BasketItemViewModel;
    const itemSelectedModifiers = _.cloneDeep(selectedModifiers ?? []);

    basketItem.CurrentQuantity = 1;

    basketItem.BasketItemID = uuidv4();
    if (!basketItem.internalId) {
      basketItem.internalId = uuidv4();
    }

    basketItem.SelectedModifiers = itemSelectedModifiers;

    if (missingProducts) {
      basketItem.unselectedModifiers = missingProducts;
    }

    basketItem.modifiersToShow = itemSelectedModifiers.filter(
      (modifierProduct) =>
        modifierProduct.modifierType != ModifierTypeEnum.PIZZA_DOUGH &&
        modifierProduct.modifierType != ModifierTypeEnum.PIZZA_SIZE &&
        modifierProduct.modifierType != ModifierTypeEnum.PIZZA_FREE_INGREDIENT,
    );

    if (basketItem.SelectedModifiers !== undefined) {
      basketItem.SelectedModifiers.forEach((modifierProduct) => {
        if (!modifierProduct.internalId) {
          modifierProduct.internalId = uuidv4();
        }
      });
    }

    if (basketItem.unselectedModifiers !== undefined) {
      basketItem.unselectedModifiers.forEach((modifierProduct) => {
        if (!modifierProduct.internalId) {
          modifierProduct.internalId = uuidv4();
        }
      });
    }

    const isDividedPizza = !!product2;

    if (isDividedPizza) {
      const itemSelectedModifiers2 = _.cloneDeep(selectedModifiers2 ?? []);
      const itemMissingProducts2 = _.cloneDeep(missingProducts2 ?? []);

      basketItem.product2 = _.cloneDeep(product2);

      if (!basketItem.product2.internalId) {
        basketItem.product2.internalId = uuidv4();
      }

      basketItem.product2.CurrentQuantity = 0.5;
      basketItem.SelectedModifiers2 = itemSelectedModifiers2;
      basketItem.unselectedModifiers2 = itemMissingProducts2;

      basketItem.modifiersToShow2 = itemSelectedModifiers2.filter(
        (modifierProduct) =>
          modifierProduct.modifierType != ModifierTypeEnum.PIZZA_DOUGH &&
          modifierProduct.modifierType != ModifierTypeEnum.PIZZA_SIZE &&
          modifierProduct.modifierType != ModifierTypeEnum.PIZZA_FREE_INGREDIENT,
      );

      if (basketItem.SelectedModifiers2 !== undefined) {
        basketItem.SelectedModifiers2.forEach((modifierProduct) => {
          if (!modifierProduct.internalId) {
            modifierProduct.internalId = uuidv4();
          }
        });
      }

      if (basketItem.unselectedModifiers2 !== undefined) {
        basketItem.unselectedModifiers2.forEach((modifierProduct) => {
          if (!modifierProduct.internalId) {
            modifierProduct.internalId = uuidv4();
          }
        });
      }
    }

    this.basketItems.push(this.clearBasketItemModel(basketItem));
    this.saveBasket();
  }

  update(item: BasketItemViewModel, selectedModifiers: ProductViewModel[]) {
    const basketItem = _.cloneDeep(item) as BasketItemViewModel;
    const itemSelectedModifiers = _.cloneDeep(selectedModifiers);

    basketItem.SelectedModifiers = itemSelectedModifiers;
    basketItem.modifiersToShow = itemSelectedModifiers.filter(
      (modifierProduct) =>
        modifierProduct.modifierType != ModifierTypeEnum.PIZZA_DOUGH &&
        modifierProduct.modifierType != ModifierTypeEnum.PIZZA_SIZE &&
        modifierProduct.modifierType != ModifierTypeEnum.PIZZA_FREE_INGREDIENT,
    );
    this.basketItems = this.basketItems.filter((thisBasketItem) => {
      return thisBasketItem.BasketItemID != basketItem.BasketItemID;
    });

    this.basketItems.push(this.clearBasketItemModel(basketItem));
    this.saveBasket();
  }

  //usuwamy nieużywane w koszyku zmienne
  clearBasketItemModel(item: BasketItemViewModel): BasketItemViewModel {
    if (item.modifiers === null) {
      item.modifiers = null;
    } else {
      item.modifiers = [];
    }
    if (item.apiModel) {
      if (item.apiModel.modifiers === null) {
        item.apiModel.modifiers = null;
      } else {
        item.apiModel.modifiers = [];
      }
    }

    if (item.modifiersToShow && item.modifiersToShow.length > 0) {
      item.modifiersToShow = item.modifiersToShow.map((item) => {
        item.modifiers = [];
        return item;
      });
    }

    if (item.modifiersToShow2 && item.modifiersToShow2.length > 0) {
      item.modifiersToShow2 = item.modifiersToShow2.map((item) => {
        item.modifiers = [];
        return item;
      });
    }

    if (item.unselectedModifiers && item.unselectedModifiers.length > 0) {
      item.unselectedModifiers = item.unselectedModifiers.map((item) => {
        item.modifiers = [];
        return item;
      });
    }

    if (item.unselectedModifiers2 && item.unselectedModifiers2.length > 0) {
      item.unselectedModifiers2 = item.unselectedModifiers2.map((item) => {
        item.modifiers = [];
        return item;
      });
    }

    if (item.SelectedModifiers && item.SelectedModifiers.length > 0) {
      item.SelectedModifiers = item.SelectedModifiers.map((item) => {
        item.modifiers = [];
        return item;
      });
    }

    if (item.SelectedModifiers2 && item.SelectedModifiers2.length > 0) {
      item.SelectedModifiers2 = item.SelectedModifiers2.map((item) => {
        item.modifiers = [];
        return item;
      });
    }

    if (item.product2) {
      if (item.product2.modifiers === null) {
        item.product2.modifiers = null;
      } else {
        item.product2.modifiers = [];
      }
    }

    return item;
  }

  remove(item: BasketItemViewModel) {
    this.basketItems = this.basketItems.filter((thisBasketItem) => {
      return thisBasketItem.BasketItemID != item.BasketItemID;
    });
    this.saveBasket();
    this.refreshBasket();

    if (this.basketItems.length == 0) {
      this.setFoodTotalPriceBackendCalculation(0);
      this.setFinalPriceBackendCalculation(0);
    }
  }

  removeBasket() {
    sessionStorage.setItem('basketItems', '[]');
    this.setFoodTotalPriceBackendCalculation(0);
    this.setFinalPriceBackendCalculation(0);
    this.refreshBasket();
  }

  updateQuantity(basketItem: BasketItemViewModel, quantity) {
    let item = this.basketItems.find((item) => basketItem.BasketItemID == item.BasketItemID);
    if (item) {
      item.CurrentQuantity = quantity;
    }

    if (quantity == 0) {
      this.remove(basketItem);
    }
    this.saveBasket();
  }

  updateModifiers(basketItem: BasketItemViewModel, selectedModifiers: ProductViewModel[]) {
    this.basketItems.forEach((thisBasketItem) => {
      if (thisBasketItem.BasketItemID == basketItem.BasketItemID) {
        thisBasketItem.SelectedModifiers = selectedModifiers;
        /* Dla pewnosci ustawianie thisBasketItem.modifiers.currentQuantity na selesctedModifiers.currentQuantity*/
        thisBasketItem.modifiers.forEach((thisBasketItemModifier) => {
          thisBasketItemModifier.products.forEach((thisBasketItemModifierProduct) => {
            /* Sprawdzanie czy to nie jest modyfikator zagnieżdżony */
            if (thisBasketItemModifierProduct.hasModifiers) {
              thisBasketItemModifierProduct.modifiers.forEach((thisBasketItemNestedModifier) => {
                thisBasketItemNestedModifier.products.forEach((thisBasketItemNestedModifierProduct) => {
                  selectedModifiers.forEach((thisSelectedModifier) => {
                    /* Ustawianie ilosci w thisBasketItemNestedModifierProduct na selectedModifiers */
                    if (thisSelectedModifier.publicId == thisBasketItemNestedModifierProduct.publicId) {
                      thisBasketItemNestedModifierProduct.CurrentQuantity = thisSelectedModifier.CurrentQuantity;
                    }
                    /* Ustawianie ilosci w thisBasketItemModifierProduct na selectedModifiers*/
                    if (thisBasketItemModifierProduct.publicId == thisSelectedModifier.publicId) {
                      thisBasketItemModifierProduct.CurrentQuantity = thisSelectedModifier.CurrentQuantity;
                    }
                  });
                });
              });
            }
          });
        });
        this.saveBasket();
      }
    });
  }

  saveBasket() {
    this.basketItems = this.basketItems;

    try {
      sessionStorage.setItem('basketItems', JSON.stringify(this.basketItems));
    } catch (error) {
      console.error('Error saving to sessionStorage', error);
    }

    this.updateAbandonedBasketNotice();
  }

  refreshBasket(): any {
    this.basketItems = JSON.parse(sessionStorage.getItem('basketItems'));
    this.refreshBasketEvent.emit();
  }

  /**
   * @deprecated This method is deprecated and will be removed in future versions.
   * Use the foodTotalPrice$ observable or the getFoodTotalPrice() method as alternatives.
   */
  public calculatePrice(): number {
    let finalPrice = 0;
    this.basketItems.forEach((thisBasketItem) => {
      this.compensationIngredient(thisBasketItem);

      if (thisBasketItem.discountId) {
        finalPrice += Number(thisBasketItem.totalPriceWithDiscounts);
      } else {
        let thisSelectedModifiersPrice = 0;
        thisBasketItem.SelectedModifiers.forEach((thisSelectedModifier) => {
          thisSelectedModifiersPrice += thisSelectedModifier.totalPrice;
        });
        finalPrice +=
          thisBasketItem.price * thisBasketItem.CurrentQuantity +
          thisSelectedModifiersPrice * thisBasketItem.CurrentQuantity;
      }
    });

    return finalPrice;
  }

  private compensationIngredient(thisBasketItem: BasketItemViewModel): void {
    if (thisBasketItem.baseAdditionSwitchAllowed) {
      let bufferPrice = 0;
      let copySelectedModifier = _.cloneDeep(thisBasketItem.SelectedModifiers);
      let selectedPizzaSize: string;
      let index = 0;

      copySelectedModifier.forEach((ingredient) => {
        if (ingredient.modifierType === ModifierTypeEnum.PIZZA_PAID_INGREDIENT) {
          ingredient.price = ingredient.originalInternalPrice;
          ingredient.totalPrice = ingredient.originalInternalPrice * ingredient.CurrentQuantity;
        }
      });

      copySelectedModifier.forEach((ingredient) => {
        if (ingredient.modifierType === ModifierTypeEnum.PIZZA_SIZE) {
          selectedPizzaSize = ingredient.originalExternalSizeId;
        }
      });

      if (thisBasketItem.unselectedModifiers.length > 0) {
        thisBasketItem.unselectedModifiers.forEach((deletedModifier) => {
          if (deletedModifier.originalExternalPrice) {
            deletedModifier.originalExternalPriceEncoded.forEach((originalSize) => {
              if (selectedPizzaSize == originalSize.sizeId.toString()) {
                bufferPrice += originalSize.price;
              }
            });
          }
        });
      }

      thisBasketItem.SelectedModifiers.forEach((ingredient) => {
        if (ingredient.modifierType === ModifierTypeEnum.PIZZA_PAID_INGREDIENT) {
          if (bufferPrice > 0) {
            if (bufferPrice >= copySelectedModifier[index].totalPrice) {
              ingredient.totalPrice = 0;
              bufferPrice = bufferPrice - copySelectedModifier[index].totalPrice;
            } else {
              ingredient.totalPrice = copySelectedModifier[index].totalPrice - bufferPrice;
              bufferPrice = 0;
            }
          }
        }
        index++;
      });
    }
  }

  /**
   * @deprecated This method is deprecated and will be removed in future versions.
   * Use the finalPrice$ observable or the getFinalPrice() method as alternatives.
   */
  public calculatePriceMobile(): number {
    let finalPrice = this.calculatePrice();

    if (this._orderType !== OrderTypeEnum.delivery) {
      return finalPrice;
    }

    if (
      this._deliveryZone &&
      this._deliveryZone.deliveryPrice &&
      this._deliveryZone.minOrderPriceForFreeDelivery > finalPrice
    ) {
      finalPrice += this._deliveryZone.deliveryPrice;
    }

    return finalPrice;
  }

  allowDisplaySubmitBtn() {
    return !this._deliveryZone || (this._deliveryZone && this._deliveryZone.minOrderPrice <= this.getFoodTotalPrice());
  }

  calculateAllItemsInBasket(): number {
    let itemsCount = 0;
    this.basketItems.forEach((thisBasketItem) => {
      itemsCount += thisBasketItem.CurrentQuantity;
    });
    return itemsCount;
  }

  itemPresentInBasket(item: ProductViewModel, selectedModifiers: ProductViewModel[]): BasketItemViewModel | null {
    let toReturn: BasketItemViewModel | null = null;

    this.basketItems.forEach((thisBasketItem) => {
      if (thisBasketItem.publicId == item.publicId) {
        if (
          this.areModifiersIdentical(selectedModifiers, thisBasketItem.SelectedModifiers) &&
          thisBasketItem.notes == item.notes
        ) {
          toReturn = thisBasketItem;
        }
      }
    });

    return toReturn;
  }

  areModifiersIdentical(selectedModifiers1: ProductViewModel[], selectedModifiers2: ProductViewModel[]): boolean {
    /* Sprawdzanie czy wszystkie modyfikatory 1 są w modyfikatorach 2 */
    let allModifiersIdentical = true;
    selectedModifiers2.forEach((thisSelectedModifier2) => {
      if (
        selectedModifiers1.filter((thisSelectedModifier1) => {
          return (
            thisSelectedModifier2.publicId == thisSelectedModifier1.publicId &&
            thisSelectedModifier2.CurrentQuantity == thisSelectedModifier1.CurrentQuantity
          );
        }).length == 0
      ) {
        /* Modyfikator 2 nie występuje w selectedModifiers1 */
        allModifiersIdentical = false;
        // console.log('modyfikator z grupy 2' + thisSelectedModifier2.name + ' nie występuje w grupie 1');
      }
    });

    if (allModifiersIdentical) {
      /* Sprawdzanie czy modyfikatory z grupy 2 są obecne w grupie 1 */
      selectedModifiers1.forEach((thisSelectedModifier1) => {
        if (
          selectedModifiers2.filter((thisSelectedModifier2) => {
            return (
              thisSelectedModifier1.publicId == thisSelectedModifier2.publicId &&
              thisSelectedModifier1.CurrentQuantity == thisSelectedModifier2.CurrentQuantity
            );
          }).length == 0
        ) {
          /* Modyfikator 1 nie występuje w selectedModifiers2 */
          allModifiersIdentical = false;
          // console.log('modyfikator z grupy 1' + thisSelectedModifier1.name + ' nie występuje w grupie 2');
        }
      });
    }

    return allModifiersIdentical;
  }

  private clearOrderInfo(): void {
    this.orderService.removeDeliveryHours();
    this.orderService.removeDeliveryAddressCity();
    this.orderService.removeDeliveryAddressZipCode();
    this.orderService.removeDeliveryAddressStreet();
    this.orderService.removeDeliveryAddressBuildingNo();
    this.orderService.removeDeliveryAddressLocalNo();
  }

  setCurrentUrl(url: string): void {
    this._currentUrl = url;
  }

  get currentUrl(): string {
    return this._currentUrl;
  }

  private summaryChecker(): void {
    if (sessionStorage.getItem('clearBasket') != null) {
      sessionStorage.removeItem('clearBasket');
      this.removeBasket();
    }
  }

  getOrderType() {
    if (sessionStorage.getItem('orderType') != null) {
      return sessionStorage.getItem('orderType');
    }
    return this._orderType;
  }

  calculateMinOrderPrice() {
    let foodTotalPrice = this.getFoodTotalPrice();
    if (this._deliveryZone?.minOrderPrice > foodTotalPrice) {
      return this._deliveryZone.minOrderPrice - foodTotalPrice;
    }

    return 0;
  }

  public setFoodTotalPriceBackendCalculation(foodTotalPrice: number): void {
    this._foodTotalPriceBackendCalculation = foodTotalPrice;
    this.refreshPrices();
  }

  public setDeliveryCostBackendCalculation(deliveryCost: number): void {
    this._deliveryCostBackendCalculation = deliveryCost;
    this.refreshPrices();
  }

  public setFinalPriceBackendCalculation(finalPrice: number): void {
    this._finalPriceBackendCalculation = finalPrice;
    this.refreshPrices();
  }

  public getFoodTotalPrice(): number {
    return this._foodTotalPrice$.value;
  }

  public getDeliveryCost(): number {
    return this._deliveryCost$.value;
  }

  public getFinalPrice(): number {
    return this._finalPrice$.value;
  }

  private updateAbandonedBasketNotice(): void {
    if (this.basketItems.length > 0) {
      this.abandonedBasketService.reloadTimer();
    } else {
      this.abandonedBasketService.disableTimer();
    }
  }

  private refreshPrices(): void {
    this.refreshBasketItemsTotalPrice();
    this.refreshDeliveryCost();
    this.refreshFinalPrice();
  }

  private refreshBasketItemsTotalPrice(): void {
    let foodTotalPrice: number = 0;

    foodTotalPrice = this._foodTotalPriceBackendCalculation;

    this._foodTotalPrice$.next(foodTotalPrice);
  }

  private refreshDeliveryCost(): void {
    let deliveryCost: number = 0;

    if (this.orderService.getOrderType() === OrderTypeEnum.delivery) {
      if (this._deliveryCostBackendCalculation) {
        deliveryCost = this._deliveryCostBackendCalculation;
      } else if (this._deliveryZone) {
        if (this._foodTotalPrice$.value >= this._deliveryZone.minOrderPriceForFreeDelivery) {
          deliveryCost = 0;
        } else {
          deliveryCost = this._deliveryZone.deliveryPrice;
        }
      }
    }
    this._deliveryCost$.next(deliveryCost);
  }

  private refreshFinalPrice(): void {
    let finalPrice: number = 0;

    if (this._finalPriceBackendCalculation) {
      finalPrice = this._finalPriceBackendCalculation;
    } else {
      finalPrice = this._foodTotalPrice$.value + this._deliveryCost$.value;
    }

    this._finalPrice$.next(finalPrice);
  }
}

<mat-card class="card card-details" *ngIf="article">
  <img mat-card-image [src]="article.graphicUrl" [alt]="article.name" />
  <button mat-icon-button class="close" (click)="navigateBack()">
    <mat-icon>close</mat-icon>
  </button>
  <mat-card-header class="card-details-header">
    <mat-card-title>
      <div class="title row justify-content-end">
        <div class="col-6">
          {{ article.name }}
        </div>
        <div class="col-3 title-price text-nowrap">
          <!-- {{ article.price | number: '1.2-2' | money }} -->
        </div>
      </div>
    </mat-card-title>
    <mat-card-subtitle> </mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <app-article-builder *ngIf="article" (formEvent)="onAddedArticle($event)" [article]="article"></app-article-builder>
  </mat-card-content>
</mat-card>

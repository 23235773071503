<p-calendar
  #calendar
  [(ngModel)]="dates"
  [showIcon]="true"
  [selectionMode]="selectionMode"
  [numberOfMonths]="numberOfMonths"
  [inline]="inline"
  [dateFormat]="dateFormat"
  (onSelect)="onSelectEvent()"
  (onInput)="onInputEvent($event)"
  (onClose)="onCloseEvent()"
  (keyup.enter)="onSave()"
  [locale]="locale"
>
  <p-header>
    <p>{{ 'calendar.title' | translate }}</p>
  </p-header>
  <p-footer *ngIf="displayFooter">
    <div>
      <!--<button
      app-bg-button
      color="success"
      size="sm"
      type="button"
      (click)="onSave()"
    >
      {{ "common.choose" | translate }}
    </button>-->
      <button app-bg-button color="light" size="sm" type="button" (click)="changeDate('today')">
        {{ 'calendar.today' | translate }}
      </button>
      <button app-bg-button color="light" size="sm" type="button" (click)="changeDate('yesterday')">
        {{ 'calendar.yesterday' | translate }}
      </button>
      <button app-bg-button color="light" size="sm" type="button" (click)="changeDate('thisWeek')">
        {{ 'calendar.thisWeek' | translate }}
      </button>
      <button app-bg-button color="light" size="sm" type="button" (click)="changeDate('lastWeek')">
        {{ 'calendar.lastWeek' | translate }}
      </button>
      <!--<button
      app-bg-button
      color="light"
      size="sm"
      type="button"
      (click)="changeDate('thisMonth')"
    >
      {{ "calendar.thisMonth" | translate }}
    </button>
    <button
      app-bg-button
      color="light"
      size="sm"
      type="button"
      (click)="changeDate('lastMonth')"
    >
      {{ "calendar.lastMonth" | translate }}
    </button>-->
    </div>
  </p-footer>
</p-calendar>

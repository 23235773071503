import { ProductApiModel } from '@core/models/tapp-order/api-model/product/product.api.model';
import { Exclude, Expose } from 'class-transformer';

@Exclude()
export class ProductDiscountApiModel {
  @Expose()
  public value: number;

  @Expose()
  public originalExternalProductId: number;

  @Expose()
  public product: ProductApiModel;
}

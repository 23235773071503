import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { cloneDeep, orderBy } from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { OrderCateringService, SelectedModifierItemMap } from 'src/app/core/services/order-catering.service';
import { OrderDataProvider } from '../data-providers/order.data-provider';
import { CateringAggregationBuilder } from '../models/catering-aggregation.builder';
import {
  CateringAggregationArticleModifierItemViewModel,
  CateringAggregationArticleModifierViewModel,
  CateringAggregationArticleViewModel,
  CateringAggregationViewModel,
} from '../models/catering-aggregation.view.model';
import { FbItemSubArticleViewModel } from '../models/view-model/order/fb-item/fb-item-sub-article.view.model';
import { FbItemViewModel } from '../models/view-model/order/fb-item/fb-item.view.model';
import { OrderViewModel } from '../models/view-model/order/order.view.model';
import { PlaceViewModel } from '../models/tapp-order/view-model/place/place.view.model';

@Injectable({
  providedIn: 'root',
})
export class CateringService {
  constructor(private orderCateringService: OrderCateringService, private orderDataProvider: OrderDataProvider) {}

  public buildSelectedCateringArticleCombinationList(
    place: PlaceViewModel,
    order: OrderViewModel,
    sourceCombinationList: Array<CateringAggregationArticleViewModel>,
  ): Observable<CateringAggregationArticleViewModel[]> {
    sourceCombinationList = sourceCombinationList || [];

    return this.orderDataProvider.getCatering(place.publicId, order.id).pipe(
      map((catering) => {
        const cateringAggregation: CateringAggregationViewModel = new CateringAggregationBuilder(catering).build();
        const articleCombinationList: Array<CateringAggregationArticleViewModel> = sourceCombinationList;
        const selectedMap: SelectedModifierItemMap = this.orderCateringService.buildSelectedMap(
          cateringAggregation,
          order.fbItems,
        );

        if (Object.keys(selectedMap).length > 0) {
          for (const articleId of Object.keys(selectedMap)) {
            for (const mapping of selectedMap[articleId]) {
              const articleCombination: CateringAggregationArticleViewModel = this.buildSelectedArticle(
                mapping.article,
                mapping.selectedQuantity,
                mapping.selectedModifierItemMap,
                mapping.selectedSubArticleMap,
              );

              articleCombinationList.push(articleCombination);
            }
          }
        }

        const articles = [];
        cateringAggregation.groups.forEach((group) => {
          group.articles.forEach((article) => {
            article.orderArticles = articleCombinationList.filter((o) => o.id === article.id);
            if (article.orderArticles && article.orderArticles.length) {
              group.hasOrderBox = true;
              articles.push(article);
            }
          });
        });

        return articles;
      }),
    );
  }

  /**
   * Builds article combination
   */
  public buildSelectedArticle(
    sourceArticle: CateringAggregationArticleViewModel,
    articleQuantity: number,
    selectedModifierItemMap: Map<string, Map<string, boolean>>,
    selectedSubArticleMap: Array<FbItemSubArticleViewModel> | Map<string, Map<string, boolean>>,
  ): CateringAggregationArticleViewModel {
    if (!sourceArticle) {
      return null;
    }

    const article: CateringAggregationArticleViewModel = cloneDeep(sourceArticle);
    article.subArticleList = [];
    article.replacementList = [];
    article.modifierArticleList = [];

    if (selectedSubArticleMap) {
      if (selectedSubArticleMap instanceof Array) {
        selectedSubArticleMap.forEach((orderedSubArticle) => {
          const subArticle = new CateringAggregationArticleViewModel();
          subArticle.id = orderedSubArticle.articleId;
          subArticle.selectedQuantity = orderedSubArticle.quantity;
          subArticle.name = orderedSubArticle.name;
          subArticle.taxRate = orderedSubArticle.taxRate;
          subArticle.price = orderedSubArticle.price;

          article.subArticleList.push(subArticle);
        });
      } else {
        selectedSubArticleMap.forEach((subArticleReplacerMap, subArticleId) => {
          const sourceArticleModifier = sourceArticle.subArticleList.find((element) => element.id === subArticleId);

          if (!sourceArticleModifier) {
            return;
          }

          const articleReplacement = cloneDeep(sourceArticleModifier);

          articleReplacement.replacementList = articleReplacement.replacementList.filter((subArticleReplacer) => {
            return (
              subArticleReplacerMap.has(subArticleReplacer.id) &&
              subArticleReplacerMap.get(subArticleReplacer.id) === true
            );
          });

          article.subArticleList.push(articleReplacement);
        });
      }
    }

    if (selectedModifierItemMap) {
      selectedModifierItemMap.forEach((modifierItemMap, modifierId) => {
        const sourceArticleModifier: CateringAggregationArticleModifierViewModel | undefined =
          sourceArticle.modifierArticleList.find((element) => element.id === modifierId);

        if (!sourceArticleModifier) {
          return;
        }

        const articleModifier: CateringAggregationArticleModifierViewModel = cloneDeep(sourceArticleModifier);

        articleModifier.itemCollection = articleModifier.itemCollection.filter((articleModifierItem) => {
          return modifierItemMap.has(articleModifierItem.id) && modifierItemMap.get(articleModifierItem.id) === true;
        });

        if (articleModifier.itemCollection.length) {
          article.modifierArticleList.push(articleModifier);
        }
      });
    }

    article.selectedQuantity = articleQuantity;
    article.selectedCombinationHash = this.calculateArticleCombinationHash(article);

    return article;
  }

  public buildSelectedArticleFromSalesDocument(sourceArticle: FbItemViewModel): CateringAggregationArticleViewModel {
    const article: FbItemViewModel = cloneDeep(sourceArticle);
    const articleViewModel: CateringAggregationArticleViewModel = new CateringAggregationArticleViewModel();

    articleViewModel.name = article.name;
    articleViewModel.price = article.price;
    articleViewModel.voucherName = article.voucherName;
    articleViewModel.voucherNumber = article.voucherNumber;
    articleViewModel.selectedQuantity = article.quantity;
    articleViewModel.subArticleList = article.subArticleList
      ? article.subArticleList.map((subArticle) => {
          const cateringAggregationArticleViewModel: CateringAggregationArticleViewModel =
            new CateringAggregationArticleViewModel();
          cateringAggregationArticleViewModel.name = subArticle.name;
          cateringAggregationArticleViewModel.price = subArticle.price;
          cateringAggregationArticleViewModel.selectedQuantity = subArticle.quantity;

          return cateringAggregationArticleViewModel;
        })
      : [];
    articleViewModel.modifierArticleList.push(new CateringAggregationArticleModifierViewModel());
    articleViewModel.modifierArticleList[0].name = 'Virtual modifier';
    articleViewModel.modifierArticleList[0].itemCollection = article.modifierItemList
      ? article.modifierItemList.map((modifier) => {
          const cateringAggregationArticleModifierItemViewModel: CateringAggregationArticleModifierItemViewModel =
            new CateringAggregationArticleModifierItemViewModel();
          cateringAggregationArticleModifierItemViewModel.name = modifier.modifierName; // or .modifierItemName or .itemName
          cateringAggregationArticleModifierItemViewModel.price = modifier.price;
          cateringAggregationArticleModifierItemViewModel.quantity = modifier.quantity;

          return cateringAggregationArticleModifierItemViewModel;
        })
      : [];

    articleViewModel.modifierArticleList[0].itemCollection = orderBy(
      articleViewModel.modifierArticleList[0].itemCollection,
      ['name', 'price'],
    );
    articleViewModel.subArticleList = orderBy(articleViewModel.subArticleList, ['name', 'price']);
    articleViewModel.selectedCombinationHash = this.calculateArticleCombinationHashForSalesDocument(articleViewModel);

    return articleViewModel;
  }

  private calculateArticleCombinationHashForSalesDocument(article: CateringAggregationArticleViewModel): string {
    let hash = article.name;
    hash += article.price;

    article.modifierArticleList.forEach((articleModifier) => {
      articleModifier.itemCollection.forEach((articleModifierItem) => {
        hash += articleModifierItem.name;
        // hash += articleModifierItem.price;
      });
    });

    article.subArticleList.forEach((articleReplacement) => {
      hash += articleReplacement.name;
      // hash += articleModifierItem.price;
    });

    return hash;
  }

  public recalculateArticleCombinationHash(article: CateringAggregationArticleViewModel): void {
    article.selectedCombinationHash = this.calculateArticleCombinationHash(article);
  }

  private calculateArticleCombinationHash(article: CateringAggregationArticleViewModel): string {
    let hash = article.id;
    hash += article.price; // needed for promotions or vouchers

    article.modifierArticleList.forEach((articleModifier) => {
      hash += articleModifier.id;

      articleModifier.itemCollection.forEach((articleModifierItem) => {
        hash += articleModifierItem.id;
      });
    });

    article.subArticleList.forEach((articleReplacement) => {
      hash += articleReplacement.id;

      articleReplacement.replacementList.forEach((subArticleReplacer) => {
        hash += subArticleReplacer.id;
      });
    });

    return hash;
  }
}

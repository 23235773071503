import { AbstractViewModel } from '../../../abstract.view.model';
import { WorkingHoursApiModel } from '@core/models/tapp-order/api-model/working-hours/working-hours.api.model';
import { WeekDayType } from '@core/types/week-day.type';

export class WorkingHoursViewModel extends AbstractViewModel<WorkingHoursApiModel> {
  public id: number;
  public placePublicId: string;
  public externalId: string;
  public startTime: string;
  public endTime: string;
  public weekDay: WeekDayType;
  public workingHourString: string = '';

  constructor(protected apiModel: WorkingHoursApiModel = new WorkingHoursApiModel()) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.id = this.apiModel.id;
    this.placePublicId = this.apiModel.placePublicId;
    this.externalId = this.apiModel.externalId;
    this.startTime = this.apiModel.startTime;
    this.endTime = this.apiModel.endTime;
    this.weekDay = this.apiModel.weekDay.toLowerCase() as WeekDayType;
    if (this.startTime && this.endTime) {
      this.workingHourString = this.startTime + ' - ' + this.endTime;
    }
  }

  toApiModel(): WorkingHoursApiModel {
    return undefined;
  }
}

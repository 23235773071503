import { Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { CompanyApiModel } from '@api/model/user/company.api.model';
import { UserDataApiModel } from '@api/model/user/user-data.api.model';
import { UserRestService } from '@api/rest/user/user.rest.service';
import { MenuDataProvider } from '@core/data-providers/menu.data-provider';
import { PlaceDataProvider } from '@core/data-providers/place.data-provider';
import { DeliveryZoneApiModel } from '@core/models/tapp-order/api-model/delivery-zones/delivery-zone.api.model';
import { BasketItemViewModel } from '@core/models/tapp-order/view-model/basketItem/basket-item.view.model';
import { GroupViewModel } from '@core/models/tapp-order/view-model/menu/group.view.model';
import { MenuViewModel } from '@core/models/tapp-order/view-model/menu/menu.view.model';
import { ProductViewModel } from '@core/models/tapp-order/view-model/product/product.view.model';
import { TermsAndConditionsItemViewModel } from '@core/models/tapp-order/view-model/terms-and-conditions/terms-and-conditions-item.view.model';
import { TermsAndPrivacyPolicyItemViewModel } from '@core/models/tapp-order/view-model/terms-and-conditions/terms-and-privacy-policy-item.view.model';
import { AuthService } from '@core/services/auth.service';
import { OrderService } from '@core/services/order.service';
import { environment } from '@env/environment';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService } from 'primeng/api';
import { Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { BasketService } from 'src/app/services/basket.service';
import { PlaceService } from '../../../../services/place.service';

@Component({
  selector: 'app-user-settngs',
  templateUrl: './user-settngs.component.html',
  styleUrls: ['./user-settngs.component.scss'],
})
export class UserSettngsComponent implements OnInit, OnDestroy {
  private destroyed$ = new Subject<void>();

  public readonly environment = environment;

  public user: UserDataApiModel;
  public termsAndConditions: TermsAndConditionsItemViewModel[];
  public termsAndPrivacyPolicy: TermsAndPrivacyPolicyItemViewModel[];
  public isLoading: boolean = true;
  public companies: CompanyApiModel[] = [];
  public termsVisible: boolean = false;
  public html: SafeHtml = this.domSanitizer.bypassSecurityTrustHtml('');
  public showErrors: boolean = false;

  @Output() changeCompanyListEvent = new EventEmitter<CompanyApiModel>();

  @ViewChild('termsAndConditionsBlockRef') termsAndConditionsBlockRef: ElementRef;

  constructor(
    private domSanitizer: DomSanitizer,
    private placeService: PlaceService,
    private authService: AuthService,
    private userRestService: UserRestService,
    private placeDataProvider: PlaceDataProvider,
    private confirmationService: ConfirmationService,
    private translateService: TranslateService,
    private orderService: OrderService,
    private basketService: BasketService,
    private menuDataProvider: MenuDataProvider,
  ) {}

  ngOnInit(): void {
    this.isDeliveryZone();
    this.getUser();
    this.handleRouterData();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  canDeactivate(): boolean | Promise<boolean> {
    if (!this.authService.isAuthenticated()) {
      return true;
    } else if (this.hasUserUnacceptedRequiredAgreements()) {
      this.showUnacceptedAgreementsWarning();
      return false;
    } else {
      return true;
    }
  }

  private isDeliveryZone(): void {
    this.placeService.getPlace().pipe(first(), takeUntil(this.destroyed$)).subscribe();
  }

  private getUser(): void {
    this.isLoading = true;

    this.userRestService
      .getUser()
      .pipe(first(), takeUntil(this.destroyed$))
      .subscribe({
        next: (value) => {
          this.user = value;
          this.companies = value.companies;
          this.getTermsAndConditions();
        },
        error: () => {
          this.isLoading = false;
        },
      });
  }

  private handleRouterData(): void {
    if (history.state.showUnacceptedAgreementsWarning) {
      this.showUnacceptedAgreementsWarning();
    }
    if (history.state.compareUserDeliveryAddressWithCurrent) {
      this.compareUserDeliveryAddressWithCurrent();
    }
  }

  private showUnacceptedAgreementsWarning(): void {
    this.confirmationService.confirm({
      header: ' ',
      message: this.translateService.instant(
        'tapp-order.pages.logged-in-user.user-settings.unaccepted-agreements-warning-dialog.message',
      ),
      acceptLabel: this.translateService.instant(
        'tapp-order.pages.logged-in-user.user-settings.unaccepted-agreements-warning-dialog.accept-label',
      ),
      rejectLabel: '',
      accept: () => {
        this.goToErrors();
      },
      reject: () => {
        this.goToErrors();
      },
    });
  }

  private showDifferentDeliveryAddressWarning(): void {
    this.confirmationService.confirm({
      header: ' ',
      message: this.translateService.instant(
        'tapp-order.pages.logged-in-user.user-settings.different-delivery-address-warning-dialog.message',
      ),
      acceptLabel: this.translateService.instant(
        'tapp-order.pages.logged-in-user.user-settings.different-delivery-address-warning-dialog.accept-label',
      ),
      rejectLabel: this.translateService.instant(
        'tapp-order.pages.logged-in-user.user-settings.different-delivery-address-warning-dialog.cancel-label',
      ),
      accept: () => {
        this.compareUserDeliveryZoneWithCurrent();
      },
      reject: () => {},
    });
  }

  private showDifferentPlaceWarning(userDeliveryZone: DeliveryZoneApiModel): void {
    this.confirmationService.confirm({
      header: ' ',
      message: this.translateService.instant(
        'tapp-order.pages.logged-in-user.user-settings.different-place-warning-dialog.message',
      ),
      acceptLabel: this.translateService.instant(
        'tapp-order.pages.logged-in-user.user-settings.different-place-warning-dialog.accept-label',
      ),
      rejectLabel: this.translateService.instant(
        'tapp-order.pages.logged-in-user.user-settings.different-place-warning-dialog.cancel-label',
      ),
      accept: () => {
        this.placeService.use(userDeliveryZone.placePublicId);

        this.orderService.setDeliveryData({
          zone: userDeliveryZone,
          zipCode: this.user.deliveryAddress.zipCode,
          city: this.user.deliveryAddress.city,
          street: this.user.deliveryAddress.street,
          buildingNo: this.user.deliveryAddress.buildingNo,
          localNo: this.user.deliveryAddress.localNo,
        });

        this.replaceOldBasketItemsWithNewBasketItems(userDeliveryZone);
      },
      reject: () => {},
    });
  }

  private compareUserDeliveryAddressWithCurrent(): void {
    const placeId = this.placeService.getPlaceId();

    if (!placeId) {
      return;
    }

    this.userRestService
      .getUser()
      .pipe(first(), takeUntil(this.destroyed$))
      .subscribe({
        next: (user) => {
          if (!user.deliveryAddress) {
            return;
          }

          if (!this.isCurrentDeliveryAddressEqualToUser(user)) {
            this.showDifferentDeliveryAddressWarning();
          }
        },
        error: () => {},
      });
  }

  private isCurrentDeliveryAddressEqualToUser(user: UserDataApiModel): boolean {
    const currentDeliveryCity = this.orderService.getDeliveryAddressCity();
    const currentDeliveryStreet = this.orderService.getDeliveryAddressStreet();
    const currentDeliveryBuildingNumber = this.orderService.getDeliveryAddressBuildingNo();
    const currentDeliveryZipCode = this.orderService.getDeliveryAddressZipCode();

    if (!currentDeliveryZipCode || !currentDeliveryCity || !currentDeliveryStreet || !currentDeliveryBuildingNumber) {
      return false;
    }

    return (
      user.deliveryAddress.zipCode.toLowerCase() === currentDeliveryZipCode.toLowerCase() &&
      user.deliveryAddress.city.toLowerCase() === currentDeliveryCity.toLowerCase() &&
      user.deliveryAddress.street.toLowerCase() === currentDeliveryStreet.toLowerCase() &&
      user.deliveryAddress.buildingNo.toLowerCase() === currentDeliveryBuildingNumber.toLowerCase()
    );
  }

  private hasUserUnacceptedRequiredAgreements(): boolean {
    return this.termsAndConditions.some((term) => term.required && !term.selected);
  }

  private compareUserDeliveryZoneWithCurrent(): void {
    this.placeDataProvider
      .findDeliveryZoneByAddress({
        postCode: this.user.deliveryAddress.zipCode,
        city: this.user.deliveryAddress.city,
        street: this.user.deliveryAddress.street,
        buildingNo: this.user.deliveryAddress.buildingNo,
      })
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (userDeliveryZone) => {
          if (!userDeliveryZone) {
            return;
          }

          const currentPlaceId = this.placeService.getPlaceId();

          if (this.basketService.basketItems.length > 0 && currentPlaceId !== userDeliveryZone.placePublicId) {
            this.showDifferentPlaceWarning(userDeliveryZone);
          } else {
            this.placeService.use(userDeliveryZone.placePublicId);

            this.orderService.setDeliveryData({
              zone: userDeliveryZone,
              zipCode: this.user.deliveryAddress.zipCode,
              city: this.user.deliveryAddress.city,
              street: this.user.deliveryAddress.street,
              buildingNo: this.user.deliveryAddress.buildingNo,
              localNo: this.user.deliveryAddress.localNo,
            });
          }
        },
        error: () => {},
      });
  }

  private replaceOldBasketItemsWithNewBasketItems(userDeliveryZone: DeliveryZoneApiModel): void {
    this.menuDataProvider
      .getMenuByPlaceId(userDeliveryZone.placePublicId, this.orderService.getMenuType())
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (userMenu) => {
          this.removeUnavailableItemsFromBasket(userMenu);
        },
        error: () => {},
      });
  }

  private removeUnavailableItemsFromBasket(newMenu: MenuViewModel): void {
    const removedItems: BasketItemViewModel[] = [];

    this.basketService.basketItems.forEach((basketItem) => {
      const isDividedPizza: boolean = !!basketItem.product2;

      if (!isDividedPizza) {
        const product = this.findProduct(newMenu.groups, basketItem.publicId);

        if (!product) {
          removedItems.push(basketItem);
          this.basketService.remove(basketItem);
        }
      } else {
        const product1 = this.findProduct(newMenu.groups, basketItem.publicId);
        const product2 = this.findProduct(newMenu.groups, basketItem.product2.publicId);

        if (!product1 || !product2) {
          removedItems.push(basketItem);
          this.basketService.remove(basketItem);
        }
      }
    });

    if (removedItems.length > 0) {
      this.showRemovedBasketItemsWarning(removedItems);
    }
  }

  private findProduct(groups: GroupViewModel[], publicId: string): ProductViewModel | null {
    for (const group of groups) {
      for (const product of group.products) {
        if (product.publicId === publicId) {
          return product;
        }
      }

      const foundProduct = this.findProduct(group.subgroups, publicId);
      if (foundProduct) return foundProduct;
    }

    return null;
  }

  private showRemovedBasketItemsWarning(basketItems: BasketItemViewModel[]): void {
    setTimeout(() => {
      const getName = (basketItem: BasketItemViewModel): string => {
        if (basketItem.product2) {
          return `${basketItem.name} + ${basketItem.product2.name}`;
        }

        return basketItem.name;
      };

      this.confirmationService.confirm({
        header: ' ',
        message: this.translateService.instant(
          'tapp-order.pages.logged-in-user.user-settings.removed-basket-items-warning-dialog.message',
          {
            items: basketItems.map((basketItem) => getName(basketItem)).join(', '),
          },
        ),
        acceptLabel: this.translateService.instant(
          'tapp-order.pages.logged-in-user.user-settings.removed-basket-items-warning-dialog.accept-label',
        ),
        rejectLabel: '',
        accept: () => {},
        reject: () => {},
      });
    });
  }

  public goToErrors(): void {
    this.termsAndConditionsBlockRef.nativeElement.scrollIntoView({ behavior: 'smooth' });
    this.showErrors = false;
    setTimeout(() => {
      this.showErrors = true;
    });
  }

  public getTermsAndConditions(): void {
    this.placeDataProvider
      .getTermsAndConditions('userSettings')
      .pipe(first(), takeUntil(this.destroyed$))
      .subscribe({
        next: (data) => {
          this.termsAndConditions = data;

          this.termsAndConditions.forEach((terms) => {
            terms.selected = !!this.user.agreementIds.find((agreement) => agreement === terms.id) ?? false;
          });

          this.isLoading = false;
        },
        error: () => {
          this.isLoading = false;
        },
      });
  }

  public onChangeDefaultCompanyEvent(event): void {
    if (!event.id) {
      return;
    }
    const newCompany = this.companies.filter((value) => {
      return value.id === event.id;
    })[0];
    this.companies = this.companies.map((value) => {
      value.isDefault = event.id === value.id && event.isDefault;
      return value;
    });
    const selectedCompany = this.companies.find((val) => val.isDefault === true);
    if (selectedCompany) {
      this.turnOnInvoice(selectedCompany);
    } else {
      this.turnOffInvoice();
    }
    this.userRestService.putUserCompany(newCompany).pipe(first()).pipe(first(), takeUntil(this.destroyed$)).subscribe();
  }

  public onTermAndConditionChangeEvent(event): void {
    this.userRestService.patchUserAgreement(event).pipe(first(), takeUntil(this.destroyed$)).subscribe();
  }

  public onChangeCompanyListEvent(event): void {
    if (
      !this.companies.find((val) => {
        return val.id === event.id;
      })
    ) {
      this.companies.push(event);
      this.onChangeDefaultCompanyEvent(event);
    }
    this.companies.forEach((val, index) => {
      if (val.id === event.id) {
        this.companies[index] = event;
      }
    });
  }

  public onDeleteCompanyEvent(event): void {
    this.userRestService
      .deleteUserCompany(event)
      .pipe(first(), takeUntil(this.destroyed$))
      .subscribe(() => {
        this.companies = this.companies.filter((value) => {
          return value.id !== event.id;
        });
        if (!this.companies.find((value) => value.isDefault) || this.companies.length === 0) {
          this.turnOffInvoice();
        }
      });
  }

  public deleteAccount(): void {
    this.authService.deleteAccount();
  }

  public confirmAccountDelete(): void {
    this.confirmationService.confirm({
      header: this.translateService.instant('app.components.confirm-dialog-live-order.delete-account.header'),
      message: this.translateService.instant('app.components.confirm-dialog-live-order.delete-account.message'),
      acceptLabel: this.translateService.instant(
        'app.components.confirm-dialog-live-order.delete-account.accept-label',
      ),
      rejectLabel: this.translateService.instant(
        'app.components.confirm-dialog-live-order.delete-account.reject-label',
      ),
      accept: () => {
        this.deleteAccount();
      },
      reject: () => {},
      key: 'confirm-dialog-live-order',
    });
  }

  public turnOffInvoice(): void {
    this.orderService.setInvoiceSelected('false');
    this.orderService.setInvoiceCompanyCity('');
    this.orderService.setInvoiceCompanyStreetNo('');
    this.orderService.setInvoiceCompanyLocalNo('');
    this.orderService.setInvoiceCompanyStreet('');
    this.orderService.setInvoiceCompanyName('');
    this.orderService.setInvoiceCountry('');
    this.orderService.setInvoiceNip('');
    this.orderService.setInvoicePostalCode('');
  }

  public turnOnInvoice(value): void {
    this.orderService.setInvoiceSelected('true');
    this.orderService.setInvoiceCompanyCity(value.city);
    this.orderService.setInvoiceCompanyStreetNo(value.buildingNo);
    this.orderService.setInvoiceCompanyLocalNo(value.localNo);
    this.orderService.setInvoiceCompanyStreet(value.street);
    this.orderService.setInvoiceCompanyName(value.companyName);
    this.orderService.setInvoiceCountry(value.country);
    this.orderService.setInvoiceNip(value.taxNumber);
    this.orderService.setInvoicePostalCode(value.zipCode);
  }

  public showTermsAndCoditions(): void {
    this.termsVisible = true;

    const placeId = this.placeService.getPlaceId();

    if (!placeId) {
      console.error('#092J3FKJDS90ASDUFJ: Place id is not defined');
      return;
    }

    this.placeDataProvider
      .getTermsAndPrivacyPolicy()
      .pipe(first(), takeUntil(this.destroyed$))
      .subscribe({
        next: (terms) => {
          this.html = this.domSanitizer.bypassSecurityTrustHtml('');

          if (terms.length) {
            const term = terms.reduce((prev, current) => (prev.id < current.id ? prev : current));

            if (term) {
              this.html = this.domSanitizer.bypassSecurityTrustHtml(term.content || '');
            }
          }
        },
      });
  }

  public handleTermsHide(): void {
    this.termsVisible = false;
  }
}

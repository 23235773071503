import { ImageApiModel } from './image.api.model';
import { Exclude, Expose, Type } from 'class-transformer';

@Exclude()
export class AttributeApiModel {
  @Expose()
  @Type(() => ImageApiModel)
  public icon: ImageApiModel;

  @Expose()
  public name: string;

  @Expose()
  public publicId: string;

  @Expose()
  public iconName: string;
}

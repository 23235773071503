import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { first, mergeMap } from 'rxjs/operators';
import { CtlHttpService } from '../http/ctl.http.service';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
  constructor(private ctlService: CtlHttpService, private translateService: TranslateService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.indexOf(environment.ctlUrl) || req.url.indexOf(environment.apiUrl)) {
      req = req.clone({
        headers: req.headers.set('Version-Web', environment.appVersion),
      });
    }

    if (
      req.url.indexOf('.json') === -1 &&
      req.url.indexOf(environment.ctlUrl) === -1 &&
      req.url.indexOf(environment.geoApiUrl) === -1 &&
      !req.url.includes('api.instagram.com')
    ) {
      return this.ctlService.init().pipe(
        first(),
        mergeMap((apiSubdomain) => {
          req = req.clone({ url: environment.apiUrl + req.url });

          req = req.clone({
            headers: req.headers.set('Subdomain', apiSubdomain),
          });

          req = req.clone({
            headers: req.headers.set('Locale', this.translateService.currentLang),
          });
          return next.handle(req);
        }),
      );
    } else {
      return next.handle(req);
    }
  }
}

<div class="d-flex align-items-start container" [@borderRedAnimation]="borderRed ? 'active' : 'inactive'">
  <p-checkbox
    [(ngModel)]="termAndCondition.selected"
    [binary]="true"
    ngDefaultControl
    (onChange)="onTermAndConditionChange()"
    [disabled]="termAndCondition.required && termAndCondition.selected"
  >
  </p-checkbox>
  <p
    class="p-checkbox-label-required p-checkbox-label"
    style="margin-bottom: 0; margin-left: 0.2rem"
    *ngIf="termAndCondition.required"
    >*
  </p>
  <p
    class="p-checkbox-label"
    style="margin-bottom: 0; margin-left: 0.2rem"
    [ngClass]="{ 'required-checkbox-label': termAndCondition.required }"
    [innerHTML]="termAndCondition.content"
  >
  </p>
</div>

import { AbstractViewModel } from '../../../abstract.view.model';
import { SurveyQuestionApiModel } from '@core/models/tapp-order/api-model/survey/survey-question.api.model';
import { SurveyQuestionTypeEnum } from '@shared/enum/survey-question-type.enum';
import { SurveyQuestionChoiceViewModel } from '@core/models/tapp-order/view-model/survey/survey-question-choice.view.model';

export class SurveyQuestionViewModel extends AbstractViewModel<SurveyQuestionApiModel> {
  public id: number;
  public value: string;
  public type: SurveyQuestionTypeEnum;
  public choices: SurveyQuestionChoiceViewModel[] = [];

  constructor(protected apiModel: SurveyQuestionApiModel = new SurveyQuestionApiModel()) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.id = this.apiModel.id;
    this.value = this.apiModel.value;
    this.type = this.apiModel.type;
    this.choices =
      this.apiModel.choices.length > 0
        ? this.apiModel.choices.map((apiModel) => new SurveyQuestionChoiceViewModel(apiModel))
        : [];
  }

  toApiModel(): SurveyQuestionApiModel {
    return undefined;
  }

  toString() {
    return this.value;
  }
}

import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';
import packageJson from '../../../../package.json';
import { PlatformService } from './platform.service';

@Injectable({
  providedIn: 'root',
})
export class VersionService {
  private clickCount: number = 0;
  private displayedMessage: boolean = false;
  private timeouts: ReturnType<typeof setTimeout>[] = [];

  constructor(private messageService: MessageService, private platformService: PlatformService) {}

  private showVersion(): void {
    console.log(`Current web version: v${packageJson.version}`);
  }

  public init(): void {
    this.clickCount = 0;
    this.showVersion();
  }

  public handleVersionCallClick(): void {
    if (this.displayedMessage) {
      return;
    }

    this.clickCount++;
    const sub = setTimeout(() => {
      if (this.clickCount > 0) {
        this.clickCount--;
      }
    }, 3000); // 3s
    this.timeouts.push(sub);

    if (this.clickCount > 5) {
      this.displayedMessage = true;
      this.clickCount = 0;
      this.timeouts.forEach((timeout) => clearTimeout(timeout));
      this.timeouts = [];

      this.messageService.clear();
      this.messageService.addAll([
        {
          severity: 'info',
          summary: 'Info',
          detail: `Web: v${packageJson.version}`,
          life: 6000, // 6s
        },
        {
          severity: 'info',
          summary: 'Info',
          detail: `Platform: ${this.platformService.getPlatformType()}`,
          life: 6000, // 6s
        },
      ]);

      setTimeout(() => {
        this.displayedMessage = false;
      }, 6000);
    }
  }
}

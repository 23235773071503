import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-survey-success-modal',
  templateUrl: './survey-success-modal.component.html',
  styleUrls: ['./survey-success-modal.component.scss'],
})
export class SurveySuccessModalComponent {
  constructor(public dialogRef: MatDialogRef<SurveySuccessModalComponent>) {}

  public closeDialog(): void {
    this.dialogRef.close();
  }
}

import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-payment-refused',
  templateUrl: './payment-refused.component.html',
  styleUrls: ['./payment-refused.component.scss'],
})
export class PaymentRefusedComponent implements OnInit {
  @Output() public newOrderEvent: EventEmitter<null> = new EventEmitter<null>();

  constructor() {}

  ngOnInit(): void {}
}

import { AbstractViewModel } from '../../../abstract.view.model';
import { TermsAndPrivacyPolicyItemApiModel } from '../../api-model/terms-and-conditions/terms-and-privacy-policy-item.api.model';

export class TermsAndPrivacyPolicyItemViewModel extends AbstractViewModel<TermsAndPrivacyPolicyItemApiModel> {
  public id: number;
  public content: string;

  constructor(protected apiModel: TermsAndPrivacyPolicyItemApiModel = new TermsAndPrivacyPolicyItemApiModel()) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.id = this.apiModel.id;
    this.content = this.apiModel.content;
  }

  toApiModel(): TermsAndPrivacyPolicyItemApiModel {
    return undefined;
  }
}

import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-hint',
  exportAs: 'appHint',
  templateUrl: './hint.component.html',
})
export class HintComponent implements OnInit {
  @Input() message: string = 'undefined';
  @Input() position: string = 'right';
  @Input() tooltipClass: string = 'tooltip-blue';

  constructor() {}

  ngOnInit() {}
}

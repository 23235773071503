<div class="content">
  <div *ngIf="themeService.mobileNavbarShowBackButton; else menu" (click)="back()" class="button-wrapper ui-clickable">
    <i class="ic ic-back mx-3"></i>
    {{ 'tapp-order.pages.discount.common.back' | translate }}
  </div>
  <div class="d-flex align-items-center">
    <ng-template #menu>
      <app-hamburger-button></app-hamburger-button>
      <app-logo></app-logo>
    </ng-template>
  </div>
  <ng-container *ngIf="!authService.isAuthenticated(); else loggedIn">
    <app-register-message-nav-bar></app-register-message-nav-bar>
    <i class="ic ic-user ui-clickable mr-3" (click)="openDialog()"></i>
  </ng-container>
  <ng-template #loggedIn>
    <app-account-icon></app-account-icon>
  </ng-template>
</div>

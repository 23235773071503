<p-confirmDialog #cd [styleClass]="'confirm-dialog'" [header]="'Confirmation'" [blockScroll]="false">
  <ng-template pTemplate="footer">
    <button
      pButton
      pRipple
      class="p-button p-button-danger"
      type="button"
      [label]="cd.confirmation.acceptLabel"
      (click)="cd.accept()"
    ></button>
    <div pRipple *ngIf="cd.confirmation.rejectLabel" class="btn-cancel btn-cancel--short" (click)="cd.reject()">
      {{ cd.confirmation.rejectLabel }}
    </div>
  </ng-template>
</p-confirmDialog>

export class PaymentMethodApiModel {
  id: string;

  name: string;

  identifier: string;

  cardId: string;

  value: number;
}

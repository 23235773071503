import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { TranslateHttpLoader } from '@core/http/translate-http-loader';
import { OrderService } from '@core/services/order.service';
import { environment } from '@env/environment';
import { MissingTranslationHandler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { AbandonedBasketComponent } from '@ui/abandoned-basket/abandoned-basket.component';
import { AbandonedBasketService } from '@ui/abandoned-basket/abandoned-basket.service';
import { AddressAutocompleteService } from '@ui/address-finder/service/address-autocomplete.service';
import { defineLocale } from 'ngx-bootstrap/chronos';
import * as locales from 'ngx-bootstrap/locale';
import { Observable } from 'rxjs';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreRoutingModule } from './core/core-routing.module';
import { CoreModule } from './core/core.module';
import { WrapMissingTranslationHandler } from './core/handler/wrap-missing-translation-handler';
import { MaterialModule } from './modules/material/material.module';
import { DeliveryZoneService } from './services/deliveryZone.service';
import { DeviceDetectorService } from './services/device-detector.service';
import { NavigationService } from './services/navigation.service';
import { PlaceService } from './services/place.service';
import { SharedModule } from './shared/shared.module';

function defineLocales() {
  for (const locale in locales) {
    defineLocale(locales[locale].abbr, locales[locale]);
  }
}
defineLocales();

export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json' + '?v=' + environment.appVersion);
}

@NgModule({
  declarations: [AppComponent, AbandonedBasketComponent],
  imports: [
    BrowserModule,
    CoreModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: WrapMissingTranslationHandler,
      },
    }),
    BrowserAnimationsModule,
    SharedModule,
    AppRoutingModule,
    CoreRoutingModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
    }),
  ],
  providers: [
    PlaceService,
    OrderService,
    Title,
    DeliveryZoneService,
    DeviceDetectorService,
    NavigationService,
    AbandonedBasketService,
    AddressAutocompleteService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

declare global {
  interface Window {
    /* Android interface */
    AndroidShareHandler?: {
      getFirebaseToken: () => string;
    };

    /* IOS interface */
    webkit?: {
      messageHandlers?: {
        getFirebaseToken?: {
          postMessage: (param: 'APPLE') => Observable<string>;
        };
      };
    };
  }
}

import { Exclude, Expose, Transform } from 'class-transformer';
import { appUtils } from 'src/app/utils/app.utils';

@Exclude()
export class CreateOrderInvoiceRequestApiModel {
  @Expose()
  public taxNumber: string;

  @Expose()
  public companyName: string;

  @Expose()
  public street: string;

  @Expose()
  public buildingNo: string;

  @Expose()
  public localNo: string;

  @Expose()
  public zipCode: string;

  @Expose()
  public city: string;

  @Expose()
  public country: string;
}

import { AbstractViewModel } from '../../../abstract.view.model';
import { ImageViewModel } from './image.view.model';
import { AttributeApiModel } from '@core/models/tapp-order/api-model/product/attribute.api.model';

export class AttributeViewModel extends AbstractViewModel<AttributeApiModel> {
  public icon: ImageViewModel;
  public name: string;
  public publicId: string;
  public iconName: string;

  public checked: boolean = false;

  constructor(public apiModel: AttributeApiModel = new AttributeApiModel()) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.icon = this.apiModel.icon ? new ImageViewModel(this.apiModel.icon) : new ImageViewModel();
    this.name = this.apiModel.name;
    this.publicId = this.apiModel.publicId;
    this.iconName = this.apiModel.iconName;
  }

  toApiModel(): AttributeApiModel {
    return undefined;
  }

  toString() {
    return this.name;
  }
}

import { Expose, Type } from 'class-transformer';
import { OrderItemModifierApiModel } from './order-item-modifier.api.model';
import { ProductApiModel } from '../product/product.api.model';

@Expose()
export class OrderItemApiModel {
  @Expose()
  public pizzaGroupId?: number | null;

  @Expose()
  public parentPizzaPublicId?: string | null;

  @Expose()
  public name: string;

  @Expose()
  public quantity: number;

  @Expose()
  public notes: string;

  @Expose()
  public baseUnitPrice: object;

  @Expose()
  public productPublicId: string;

  @Expose()
  public unitPrice: object;

  @Expose()
  public totalPrice: object | number;

  @Expose()
  public discounts: {
    name: string;
  }[];

  @Expose()
  @Type(() => OrderItemModifierApiModel)
  public selectedModifiers: OrderItemModifierApiModel[];

  @Expose()
  @Type(() => OrderItemModifierApiModel)
  public unselectedModifiers?: OrderItemModifierApiModel[];
}

import { ProductApiModel } from '../product/product.api.model';
import { Exclude, Expose, Type } from 'class-transformer';
import { ImageApiModel } from '@core/models/tapp-order/api-model/product/image.api.model';

@Exclude()
export class GroupApiModel {
  @Expose()
  public publicId: string;

  @Expose()
  public name: string;

  @Expose()
  @Type(() => ProductApiModel)
  public products: ProductApiModel[] = [];

  @Expose()
  @Type(() => GroupApiModel)
  public subgroups: GroupApiModel[] = [];

  @Expose()
  @Type(() => ImageApiModel)
  public icon: ImageApiModel;
}

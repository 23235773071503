import { AbstractViewModel } from '../../../abstract.view.model';
import { TermsAndConditionsItemApiModel } from '@core/models/tapp-order/api-model/terms-and-conditions/terms-and-conditions-item.api.model';

export class TermsAndConditionsItemViewModel extends AbstractViewModel<TermsAndConditionsItemApiModel> {
  public id: number;
  public content: string;
  public required: boolean;
  public categories: Array<'orders' | 'registration' | 'userSettings'>;
  public selected: boolean;

  constructor(protected apiModel: TermsAndConditionsItemApiModel = new TermsAndConditionsItemApiModel()) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.id = this.apiModel.id;
    this.content = this.apiModel.content;
    this.required = this.apiModel.required;
    this.categories = this.apiModel.categories;
    this.selected = false;
  }

  toApiModel(): TermsAndConditionsItemApiModel {
    return undefined;
  }
}

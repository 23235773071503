import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseDataProvider } from '@core/data-providers/base.data-provider';
import { MenuDataProvider } from '@core/data-providers/menu.data-provider';
import { CanComponentDeactivate } from '@core/interfaces/interfaces';
import { MenuViewModel } from '@core/models/tapp-order/view-model/menu/menu.view.model';
import { PlaceViewModel } from '@core/models/tapp-order/view-model/place/place.view.model';
import { ButtonBackService } from '@core/services/button-back.service';
import { OrderService } from '@core/services/order.service';
import { TranslateService } from '@ngx-translate/core';
import { ParamKeys } from '@shared/enum/param-kyes.enum';
import { LoadingService } from '@shared/loading/loading.service';
import * as _ from 'lodash';
import { ConfirmationService, PrimeNGConfig } from 'primeng/api';
import { Subject, Subscription, forkJoin, of } from 'rxjs';
import { finalize, map, mergeMap, take, takeUntil } from 'rxjs/operators';
import { ProductPizzaService, ProductService2 } from 'src/app/services/product-pizza.service';
import { ThemeService } from 'src/app/services/theme.service';
import { BasketService } from '../../../../../services/basket.service';
import { PlaceService } from '../../../../../services/place.service';
import { ProductService } from '../../../../../services/product.service';
import { ChangeIngredientsDialogComponent } from './../../component/change-ingredients-dialog/change-ingredients-dialog.component';
import { ProductModifiersComponent } from './../../component/product-modifiers/product-modifiers.component';
import { ProductAllergensDialogComponent } from '@core/pages/products/component/product-allergens-dialog/product-allergens-dialog.component';
import {ProductViewModel} from "@core/models/tapp-order/view-model/product/product.view.model";
import {ProductTypeEnum} from "@shared/enum/product-type.enum";
import {ModifierTypeEnum} from "@shared/enum/modifier-type.enum";

@Component({
  selector: 'app-product-modify',
  templateUrl: './product-modify.component.html',
  styleUrls: ['./product-modify.component.scss'],
  providers: [ProductService, ProductPizzaService, { provide: ProductService2, useClass: ProductService }],
})
export class ProductModifyComponent implements OnInit, OnDestroy, CanComponentDeactivate {
  public menu: MenuViewModel;
  public basketItemData: any;
  public place: PlaceViewModel;
  public switchDisplayed: boolean = false;
  public selectedPart: 'left' | 'right' = 'left';

  private productId?: string;
  private productId2?: string;
  private basketItemId?: string;

  private productAddedToBasket: boolean = false;

  private getProductsBasketDataSubsc: Subscription;

  private redirectingToPizzaSecondPart: boolean = false;

  private destroyed$ = new Subject<void>();

  @ViewChild('modifiersComponent')
  modifiersComponent: ProductModifiersComponent;

  constructor(
    public productPizzaService: ProductPizzaService,
    private loadingService: LoadingService,
    private activatedRoute: ActivatedRoute,
    private basketService: BasketService,
    private placeService: PlaceService,
    private orderService: OrderService,
    private menuDataProvider: MenuDataProvider,
    private baseDataProvider: BaseDataProvider,
    private translateService: TranslateService,
    private confirmationService: ConfirmationService,
    private primengConfig: PrimeNGConfig,
    private buttonBackService: ButtonBackService,
    private themeService: ThemeService,
    private dialog: MatDialog,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.primengConfig.ripple = true;

    this.activatedRoute.params.subscribe((params) => {
      const placeId = params[ParamKeys.PlaceId];
      this.productId = params[ParamKeys.ProductId];
      this.productId2 = params[ParamKeys.ProductId2];
      this.basketItemId = params[ParamKeys.BasketItemId];
      this.fetchPlace(placeId);
      this.initData();
    });
  }

  canDeactivate() {
    if (this.productAddedToBasket || this.redirectingToPizzaSecondPart) {
      return true;
    } else {
      return true; //https://jira.lsisoftware.pl/browse/TGO-1135
      //return this.confirmLeavePage();
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
    this.getProductsBasketDataSubsc?.unsubscribe();
  }

  public handlePizzaSplitSwitch(checked: boolean): void {
    if (checked) {
      this.redirectToSecondPart();
    } else {
      this.productPizzaService.switchToSinglePizza();
    }
  }

  private redirectToSecondPart(): void {
    const placeId = sessionStorage.getItem('placeId');

    if (!placeId) {
      this.router.navigate(['/']);
      return;
    }

    let currentLocationPath = this.router.url.split('/');
    let currentLocationPathProductsIndex = currentLocationPath.indexOf('products');
    if (currentLocationPathProductsIndex !== -1 && currentLocationPathProductsIndex + 1 < currentLocationPath.length) {
      currentLocationPath = currentLocationPath.slice(0, currentLocationPathProductsIndex + 2);
    }

    this.redirectingToPizzaSecondPart = true;
    this.themeService.mobileNavbarShowBackButton = true;
    this.themeService.backArrowPath = currentLocationPath;
    this.buttonBackService.redirectUrl = currentLocationPath;
    this.router.navigate([...currentLocationPath, 'choose-product']);
  }

  public choosePart(part: 'left' | 'right'): void {
    this.selectedPart = part;
  }

  onSave(): void {
    // TODO: add divided pizza support
    if (this.productPizzaService.divided || this.modifiersComponent.isValid()) {
      if (this.basketItemId) {
        this.basketService.remove(this.basketItemData);
      }
      if (this.productPizzaService.divided) {
        const basketItem = this.productPizzaService.part1.basketItem;
        basketItem.CurrentQuantity = 1;

        if (this.productPizzaService.single.isPizza) {
          if (!this.productPizzaService.single.selectedPizzaSize) {
            this.productPizzaService.single.selectedPizzaSize = this.productPizzaService.single.selectedPizzaSize;
          }
          if (!this.productPizzaService.single.selectedPizzaDough) {
            this.productPizzaService.single.selectedPizzaDough = this.productPizzaService.single.selectedPizzaDough;
          }

          basketItem.hidePizzaSize = !(
            !this.place.autohidePizzaOptions || this.productPizzaService.single.pizzaSizes.length > 1
          );

          basketItem.hidePizzaDough = !(
            this.productPizzaService.single.selectedPizzaSize &&
            this.productPizzaService.single.pizzaDoughList &&
            this.productPizzaService.single.pizzaDoughList.length > 0 &&
            (!this.place.autohidePizzaOptions || this.productPizzaService.single.pizzaDoughList.length > 1)
          );
        }

        this.basketService.add(
          basketItem,
          this.productPizzaService.part1.basketItem.SelectedModifiers,
          this.productPizzaService.part1.basketItem.unselectedModifiers,
          this.productPizzaService.part2.basketItem,
          this.productPizzaService.part2.basketItem.SelectedModifiers,
          this.productPizzaService.part2.basketItem.unselectedModifiers,
        );
      } else {
        this.productPizzaService.single.basketItem.hidePizzaSize = !(
          this.productPizzaService.single.isPizza &&
          this.productPizzaService.single.selectedPizzaSize &&
          (!this.place.autohidePizzaOptions || this.productPizzaService.single.pizzaSizes.length > 1)
        );

        this.productPizzaService.single.basketItem.hidePizzaDough = !(
          this.productPizzaService.single.isPizza &&
          this.productPizzaService.single.selectedPizzaSize &&
          this.productPizzaService.single.selectedPizzaDough &&
          this.productPizzaService.single.pizzaDoughList &&
          this.productPizzaService.single.pizzaDoughList.length > 0 &&
          (!this.place.autohidePizzaOptions || this.productPizzaService.single.pizzaDoughList.length > 1)
        );

        this.basketService.add(
          this.productPizzaService.single.basketItem,
          this.productPizzaService.single.basketItem.SelectedModifiers,
          this.productPizzaService.single.basketItem.unselectedModifiers,
        );
      }

      this.productAddedToBasket = true;

      const placeId = sessionStorage.getItem('placeId');

      if (placeId) {
        this.router.navigate([placeId, 'products']);
      } else {
        this.router.navigate(['/']);
      }
    }
  }

  public openDialog(): void {
    if (!this.productPizzaService.divided) {
      const config = {
        panelClass: 'ingredients-overlay',
        data: {
          modifierGroups: this.productPizzaService.single.pizzaIngredients,
        },
      };
      this.dialog.open(ChangeIngredientsDialogComponent, config);
    } else {
      if (this.selectedPart === 'left') {
        const config = {
          panelClass: 'ingredients-overlay',
          data: {
            modifierGroups: this.productPizzaService.part1.pizzaIngredients,
          },
        };
        this.dialog.open(ChangeIngredientsDialogComponent, config);
      } else {
        const config = {
          panelClass: 'ingredients-overlay',
          data: {
            modifierGroups: this.productPizzaService.part2.pizzaIngredients,
          },
        };
        this.dialog.open(ChangeIngredientsDialogComponent, config);
      }
    }
  }

  private initData(): void {
    const newProductMode = this.productId && !this.productId2 && !this.basketItemId;
    const newDividedPizzaProductMode = this.productId && this.productId2 && !this.basketItemId;
    const baskteItemMode = !this.productId && !this.productId2 && this.basketItemId;

    if (newProductMode) {
      this.loadingService.showLoader();
      this.baseDataProvider
        .getProduct(this.productId)
        .pipe(finalize(() => {}))
        .subscribe(
          (product) => {
            if (product.selectBasicModifiers) {
              this.productPizzaService.prepareSelectBasicModifiers(product);
            }

            this.productPizzaService.single.product = product;
            this.loadingService.hideLoader();
          },
          (error) => this.loadingService.hideLoader(),
        );
    } else if (newDividedPizzaProductMode) {
      this.loadingService.showLoader();
      forkJoin([
        this.baseDataProvider.getProduct(this.productId),
        this.baseDataProvider.getProduct(this.productId2),
      ]).subscribe(
        ([productData, productData2]) => {
          //TODO: uncomment and test when modifiers will be implemented for divided pizza
          //if (productData.selectBasicModifiers) {
          //  this.prepareSelectBasicModifiers(productData);
          //}

          this.productPizzaService.part1.product = productData;
          this.productPizzaService.part2.product = productData2;
          this.productPizzaService.switchToDividedPizza();
          this.productPizzaService.initPizzaDough();
          this.loadingService.hideLoader();
        },
        (error) => {
          this.loadingService.hideLoader();
        },
      );
    } else if (baskteItemMode) {
      this.loadProductDataFromBasket();
    }

    if (newProductMode || newDividedPizzaProductMode) {
      this.switchDisplayed = true;
    } else {
      this.switchDisplayed = false;
    }
  }

  private fetchPlace(placeId: string): void {
    this.placeService
      .useAsObservable(placeId)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (place) => {
          this.place = place;
          this.fetchMenu(place);
        },
        error: () => {
          this.router.navigate(['/']);
        },
      });
  }

  private fetchMenu(place: PlaceViewModel): void {
    if (place.menus.length > 0 && place.getMenuByType(this.orderService.getMenuType())) {
      const menuId = place.getMenuByType(this.orderService.getMenuType()).publicId;
      this.menuDataProvider
        .getMenu(menuId)
        .pipe(finalize(() => {}))
        .subscribe((menu) => {
          this.menu = _.cloneDeep(menu);
        });
    }
  }

  private loadProductDataFromBasket(): void {
    const productsData = this.basketService.basketItems$.pipe(
      take(1),
      map((items) => items.find((el) => el.BasketItemID === this.basketItemId)),
      mergeMap((item) =>
        forkJoin({
          productData: this.baseDataProvider.getProduct(item.publicId),
          basketItemData: of(item),
        }),
      ),
    );

    this.getProductsBasketDataSubsc = productsData.subscribe(({ productData, basketItemData }) => {
      this.productPizzaService.single.product = productData;
      this.basketItemData = basketItemData;

      const pizzaSizeBasketItem = basketItemData.SelectedModifiers.find((item) => item.modifierType === 'PIZZA_SIZE');
      const pizzaDoughBasketItem = basketItemData.SelectedModifiers.find((item) => item.modifierType === 'PIZZA_DOUGH');

      if (pizzaSizeBasketItem) {
        this.productPizzaService.single.selectedPizzaSize = productData.modifiers[0].products.find(
          (item) => item.publicId === pizzaSizeBasketItem.publicId,
        );
      }
      if (pizzaDoughBasketItem) {
        this.productPizzaService.single.selectedPizzaDough = this.productPizzaService.single.pizzaDoughList.find(
          (item) => item.publicId === pizzaDoughBasketItem.publicId,
        );
      }

      if (this.productPizzaService.single.selectedPizzaSize) {
        const paidIngredients = this.productPizzaService.single.pizzaIngredients?.find(
          (item) => item.modifierType === 'PIZZA_PAID_INGREDIENT',
        );
        const freeIngredients = this.productPizzaService.single.pizzaIngredients?.find(
          (item) => item.modifierType === 'PIZZA_FREE_INGREDIENT',
        );

        const loadAdditionsStandard = (): void => {
          const freeIngredientsBasketItem = basketItemData.SelectedModifiers.filter(
            (item) => item.modifierType === 'PIZZA_FREE_INGREDIENT',
          );
          freeIngredients.products.forEach((item) => {
            const ifAdditionsExist = freeIngredientsBasketItem.find(
              (freeIngredientItem) => freeIngredientItem.publicId === item.publicId,
            );
            if (!ifAdditionsExist) {
              item.isChecked = false;
              item.CurrentQuantity = 0;
            }
          });
        };

        const loadAdditionsPaid = (): void => {
          const paidIngredientsBasketItem = basketItemData.SelectedModifiers.filter(
            (item) => item.modifierType === 'PIZZA_PAID_INGREDIENT',
          );
          paidIngredientsBasketItem.forEach((item) => {
            const addition = paidIngredients.products.find(
              (paidIngredient) => paidIngredient.publicId === item.publicId,
            );
            addition.CurrentQuantity = item.CurrentQuantity;
          });
        };

        loadAdditionsStandard();
        loadAdditionsPaid();
      }

      const loadAdditionsModifier = (): void => {
        const pizzaModifierBasketItem = basketItemData.SelectedModifiers.filter(
          (item) => item.modifierType === 'MODIFIER',
        );
        pizzaModifierBasketItem.forEach((item) => {
          const group = this.productPizzaService.single.modifiers.find(
            (modifier) => modifier.publicId === item.groupId,
          );
          const product = group.products.find((el) => el.publicId === item.publicId);
          product.CurrentQuantity = item.CurrentQuantity;
        });
      };

      loadAdditionsModifier();
    });
  }

  private confirmLeavePage(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      this.confirmationService.confirm({
        header: this.translateService.instant('app.components.confirm-dialog-live-order.leave-page.header'),
        message: this.translateService.instant('app.components.confirm-dialog-live-order.leave-page.message'),
        acceptLabel: this.translateService.instant('app.components.confirm-dialog-live-order.leave-page.accept-label'),
        rejectLabel: this.translateService.instant('app.components.confirm-dialog-live-order.leave-page.reject-label'),
        accept: () => {
          resolve(true);
        },
        reject: () => {
          this.loadingService.hideLoader();
          resolve(false);
        },
        key: 'confirm-dialog-live-order',
      });
    });
  }

  get showAllergensBtn(): boolean {
    if (!this.productPizzaService.divided) {
      return (
        this.productPizzaService.single.product.allergens.length > 0 ||
        this.productPizzaService.single.product.attributes.length > 0 ||
        this.productPizzaService.single.product.nutritionValues.length > 0
      );
    } else {
      if (this.selectedPart === 'left') {
        return (
          this.productPizzaService.part1.product.allergens.length > 0 ||
          this.productPizzaService.part1.product.attributes.length > 0 ||
          this.productPizzaService.part1.product.nutritionValues.length > 0
        );
      } else {
        return (
          this.productPizzaService.part2.product.allergens.length > 0 ||
          this.productPizzaService.part2.product.attributes.length > 0 ||
          this.productPizzaService.part2.product.nutritionValues.length > 0
        );
      }
    }
  }

  public showAllergensDialog(): void {
    if (!this.productPizzaService.divided) {
      const config = {
        panelClass: 'allergens-overlay',
        data: {
          allergens: this.productPizzaService.single.product.allergens,
          attributes: this.productPizzaService.single.product.attributes,
          nutritionValues: this.productPizzaService.single.product.nutritionValues,
        },
      };
      this.dialog.open(ProductAllergensDialogComponent, config);
    } else {
      if (this.selectedPart === 'left') {
        const config = {
          panelClass: 'allergens-overlay',
          data: {
            allergens: this.productPizzaService.part1.product.allergens,
            attributes: this.productPizzaService.part1.product.attributes,
            nutritionValues: this.productPizzaService.part1.product.nutritionValues,
          },
        };
        this.dialog.open(ProductAllergensDialogComponent, config);
      } else {
        const config = {
          panelClass: 'allergens-overlay',
          data: {
            allergens: this.productPizzaService.part2.product.allergens,
            attributes: this.productPizzaService.part2.product.attributes,
            nutritionValues: this.productPizzaService.part2.product.nutritionValues,
          },
        };
        this.dialog.open(ProductAllergensDialogComponent, config);
      }
    }
  }
}

import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '@env/environment';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-instagram-login-button',
  templateUrl: './instagram-login-button.component.html',
  styleUrls: ['./instagram-login-button.component.scss'],
})
export class InstagramLoginButtonComponent implements OnInit {
  private clientId = environment.instagramAppId;
  private clientSecret = environment.instagramAppSecret;
  private redirectUri = environment.instagramRedirectUri;

  constructor(private route: ActivatedRoute, private http: HttpClient) {}

  ngOnInit(): void {
    this.handleAuthorizationRedirect();
  }

  handleAuthorizationRedirect(): void {
    this.route.queryParams.subscribe((params) => {
      const code = params['code'];

      if (code) {
        const tokenUrl = 'https://api.instagram.com/oauth/access_token';

        const body = new HttpParams()
          .set('client_id', this.clientId)
          .set('client_secret', this.clientSecret)
          .set('grant_type', 'authorization_code')
          .set('redirect_uri', this.redirectUri)
          .set('code', code);

        const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

        this.http.post(tokenUrl, body.toString(), { headers }).subscribe((response: any) => {
          const accessToken = response.access_token;
          console.log('Access Token:', accessToken);
          // Tutaj możesz wykonywać żądania do Instagram API używając access tokena
        });
      }
    });
  }

  authorize(event): void {
    event.preventDefault();
    window.location.href = `https://www.instagram.com/oauth/authorize/?client_id=${this.clientId}&redirect_uri=${this.redirectUri}&response_type=code&scope=user_profile`;
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PaymentOrderStatusEnum } from '@core/pages/order/enum/payment-order-status.enum';

@Component({
  selector: 'app-order-refunded',
  templateUrl: './order-refunded.component.html',
  styleUrls: ['./order-refunded.component.scss'],
})
export class OrderRefundedComponent implements OnInit {
  @Input() public orderStatus: PaymentOrderStatusEnum;
  @Output() public newOrderEvent: EventEmitter<null> = new EventEmitter<null>();

  public paymentOrderStatusEnum = PaymentOrderStatusEnum;

  constructor() {}

  ngOnInit(): void {}
}

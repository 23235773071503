import {
  CateringAggregationArticleGroupViewModel,
  CateringAggregationArticleModifierItemViewModel,
  CateringAggregationArticleModifierViewModel,
  CateringAggregationArticleViewModel,
  CateringAggregationViewModel,
} from './catering-aggregation.view.model';
import { ViewModelBuilderInterface } from './builder/view-model-builder.interface';
import {
  CateringArticleGroupModel,
  CateringArticleModel,
  CateringArticleModifierModel,
  CateringModel,
} from './catering.model';
import { cloneDeep } from 'lodash';

export class CateringAggregationBuilder implements ViewModelBuilderInterface<CateringAggregationViewModel> {
  private cateringSource: CateringModel = null;

  public constructor(cateringSource: CateringModel) {
    this.cateringSource = cateringSource;
  }

  public build(): CateringAggregationViewModel {
    const groupCollection: Array<CateringAggregationArticleGroupViewModel> = this.cateringSource.groups.map(
      (groupSource) => this.buildGroupViewModel(groupSource),
    );

    const articleCollection: Array<CateringAggregationArticleViewModel> = this.cateringSource.articles.map(
      (articleSource) => this.buildArticleViewModel(groupCollection, articleSource),
    );

    const aggregation = new CateringAggregationViewModel();
    aggregation.groups = groupCollection.map((aggregationGroup) => {
      aggregationGroup.articles = articleCollection.filter(
        (aggregationArticle) =>
          aggregationArticle.parentGroup && aggregationArticle.parentGroup.id === aggregationGroup.id,
      );
      return aggregationGroup;
    });

    aggregation.articles = articleCollection;
    return aggregation;
  }

  // public buildArticle() {

  // }

  /**
   * Builds group view model
   */
  private buildGroupViewModel(groupSource: CateringArticleGroupModel): CateringAggregationArticleGroupViewModel {
    const groupViewModel: CateringAggregationArticleGroupViewModel = new CateringAggregationArticleGroupViewModel();
    groupViewModel.id = groupSource.id;
    groupViewModel.description = groupSource.description;
    groupViewModel.graphicUrl = groupSource.graphicUrl;
    groupViewModel.name = groupSource.name;

    if (groupSource.parentGroupId) {
      const parentGroupSource: CateringArticleGroupModel | undefined = this.cateringSource.groups.find(
        (element) => element.id === groupSource.parentGroupId,
      );

      groupViewModel.parentGroup = parentGroupSource ? this.buildGroupViewModel(parentGroupSource) : null;
    }

    return groupViewModel;
  }

  /**
   * Builds article view model
   */
  private buildArticleViewModel(
    groupAggregationCollection: Array<CateringAggregationArticleGroupViewModel>,
    articleSource: CateringArticleModel,
  ): CateringAggregationArticleViewModel {
    const articleViewModel: CateringAggregationArticleViewModel = new CateringAggregationArticleViewModel();
    articleViewModel.id = articleSource.id;
    articleViewModel.name = articleSource.name;
    articleViewModel.price = articleSource.price;
    articleViewModel.taxRate = articleSource.taxRate;
    articleViewModel.description = articleSource.description;
    articleViewModel.isLocked = articleSource.isLocked;
    articleViewModel.graphicUrl = articleSource.graphicUrl;
    articleViewModel.nutritionalInfo = articleSource.nutritionalInfo;
    articleViewModel.origin = cloneDeep(articleSource);

    if (articleSource.parentGroupId) {
      const parentGroup: CateringAggregationArticleGroupViewModel | undefined = groupAggregationCollection.find(
        (element) => element.id === articleSource.parentGroupId,
      );

      articleViewModel.parentGroup = parentGroup ? parentGroup : null;
    }

    articleViewModel.subArticleList = articleSource.subArticleList.map((element) =>
      this.buildArticleViewModel(groupAggregationCollection, element),
    );

    articleViewModel.replacementList = articleSource.replacementList.map((element) =>
      this.buildArticleViewModel(groupAggregationCollection, element),
    );

    articleViewModel.modifierArticleList =
      articleSource.modifierCollectionList /*.filter(x => this.isMuviProject() ? x.type !== ModifierTypeEnum.NOTE : x)*/
        .map((element) => this.buildArticleModifierViewModel(element, articleSource));

    return articleViewModel;
  }

  /**
   * Builds article modifier view model
   */
  private buildArticleModifierViewModel(
    modifierSource: CateringArticleModifierModel,
    articleSource: CateringArticleModel | null = null,
  ): CateringAggregationArticleModifierViewModel {
    const modifierViewModel: CateringAggregationArticleModifierViewModel =
      new CateringAggregationArticleModifierViewModel();
    modifierViewModel.id = modifierSource.id;
    modifierViewModel.type = modifierSource.type;
    modifierViewModel.name = modifierSource.name;
    modifierViewModel.isRequired = modifierSource.isRequired;
    modifierViewModel.multiChoice = modifierSource.multiChoice;
    modifierViewModel.multiChoiceMax = modifierSource.multiChoiceMax || modifierSource.itemCollection.length;
    modifierViewModel.multiChoiceMin = modifierSource.multiChoiceMin || (modifierViewModel.isRequired ? 1 : 0);
    modifierViewModel.separateItem = modifierSource.separateItem;
    modifierViewModel.itemCollection = modifierSource.itemCollection.map((element) => {
      const itemViewModel: CateringAggregationArticleModifierItemViewModel =
        new CateringAggregationArticleModifierItemViewModel();
      itemViewModel.id = element.id;
      itemViewModel.name = element.name;
      itemViewModel.description = element.description;
      itemViewModel.type = modifierSource.type;
      itemViewModel.price = element.price * element.quantity;
      itemViewModel.quantity = element.quantity;
      itemViewModel.relatedModifiers = element.relatedItemList.map((elem) => {
        const modifierViewModel: CateringAggregationArticleModifierViewModel =
          new CateringAggregationArticleModifierViewModel();
        modifierViewModel.id = elem;
        return modifierViewModel;
      });

      return itemViewModel;
    });

    return modifierViewModel;
  }
}

import { Inject, Injectable, InjectionToken } from '@angular/core';
import { ProductViewModel } from '@core/models/tapp-order/view-model/product/product.view.model';
import { ProductService } from './product.service';

// Create unique token for each ProductService instance
export const ProductService2 = new InjectionToken<ProductService>('ProductService2');

@Injectable()
export class ProductPizzaService {
  // TODO: replace with BehaviorSubject or Subject
  public readonly divided: boolean = false;

  constructor(
    public single: ProductService,
    public part1: ProductService,
    @Inject(ProductService2) public part2: ProductService,
  ) {
    // Ensure that single is not the same instance as part1 or part2
    if (this.single !== this.part1) {
      throw new Error('ProductService1 and ProductService2 should be the same instance.');
    }

    // Ensure that part1 and part2 are not the same instance
    if (this.part1 === this.part2) {
      throw new Error('ProductService1 and ProductService2 should not be the same instance.');
    }
  }

  public get finalPrice(): number {
    if (this.divided) {
      const pizzaPart1Price = this.part1.getPizzaSizePrice();
      const pizzaPart2Price = this.part2.getPizzaSizePrice();

      const pizzaPart1DoughPrice = this.part1.getPizzaDoughPrice();
      const pizzaPart2DoughPrice = this.part2.getPizzaDoughPrice();

      const pizzaPart1PaidIngredientsPrice = this.part1.getPizzaPaidIngredientsPrice();
      const pizzaPart2PaidIngredientsPrice = this.part2.getPizzaPaidIngredientsPrice();

      const pizzaPart1UnselectedFreeIngredientsPrice = this.part1.getPizzaUnselectedFreeIngredientsPrice();
      const pizzaPart2UnselectedFreeIngredientsPrice = this.part2.getPizzaUnselectedFreeIngredientsPrice();

      let pizzaPart1IngredientsPrice = pizzaPart1PaidIngredientsPrice - pizzaPart1UnselectedFreeIngredientsPrice;
      let pizzaPart2IngredientsPrice = pizzaPart2PaidIngredientsPrice - pizzaPart2UnselectedFreeIngredientsPrice;

      if (pizzaPart1IngredientsPrice < 0) {
        pizzaPart1IngredientsPrice = 0;
      }

      if (pizzaPart2IngredientsPrice < 0) {
        pizzaPart2IngredientsPrice = 0;
      }

      const additions = this.single.getAdditions();

      const finalPrice =
        pizzaPart1Price +
        pizzaPart2Price +
        pizzaPart1DoughPrice +
        pizzaPart2DoughPrice +
        pizzaPart1IngredientsPrice +
        pizzaPart2IngredientsPrice +
        additions;

      return finalPrice;
    } else {
      if (this.single.basketItem.totalPriceWithDiscounts && this.single.basketItem.discountName) {
        return this.single.basketItem.totalPriceWithDiscounts;
      }
      return this.single.finalPrice;
    }
  }

  public initPizzaDough(): void {
    if (this.divided) {
      this.part1.initPizzaDough();
      this.part2.initPizzaDough();
    } else {
      this.single.initPizzaDough();
    }
  }

  public getPizzaSizePrice(pizzaSize: ProductViewModel): number {
    if (this.divided) {
      const pizzaPart1Size = this.part1.pizzaSizes.find((x) => x.name === pizzaSize.name);
      const pizzaPart2Size = this.part2.pizzaSizes.find((x) => x.name === pizzaSize.name);

      const pizzaPart1Price = this.part1.getPizzaSizePrice(pizzaPart1Size);
      const pizzaPart2Price = this.part2.getPizzaSizePrice(pizzaPart2Size);

      return pizzaPart1Price + pizzaPart2Price;
    } else {
      return this.single.getPizzaSizePrice(pizzaSize);
    }
  }

  public onPizzaSizeChange(pizzaSize: ProductViewModel) {
    if (this.divided) {
      const pizzaPart1Size = this.part1.pizzaSizes.find((x) => x.name === pizzaSize.name);
      const pizzaPart2Size = this.part2.pizzaSizes.find((x) => x.name === pizzaSize.name);
      this.part1.onPizzaSizeChange(pizzaPart1Size);
      this.part2.onPizzaSizeChange(pizzaPart2Size);
    } else {
      this.single.onPizzaSizeChange(pizzaSize);
    }
  }

  public onPizzaDoughChange(pizzaDough: ProductViewModel) {
    if (this.divided) {
      this.part1.setPizzaDoughByName(pizzaDough.name);
      this.part2.setPizzaDoughByName(pizzaDough.name);
    } else {
      this.single.setPizzaDoughByName(pizzaDough.name);
    }
  }

  public switchToSinglePizza() {
    (<boolean>this.divided) = false;
    this.single.switchToFullPizza();
  }

  public switchToDividedPizza() {
    (<boolean>this.divided) = true;
    this.part1.switchToHalfPizza();
    this.part2.switchToHalfPizza();
  }

  public prepareSelectBasicModifiers(product: ProductViewModel) {
    this.single.prepareSelectBasicModifiers(product);
  }
}

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, timer, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class AbandonedBasketService {
  private _timerValue: number = 900000; //default 15 minutes
  private _timerSubscription: Subscription = null;

  private noticeSource = new BehaviorSubject<boolean>(false);
  public notice$: Observable<boolean>;

  constructor() {
    this._timerValue = environment.abandonedBasketTimeout
      ? environment.abandonedBasketTimeout * 60 * 1000
      : this._timerValue;

    this.notice$ = this.noticeSource.asObservable();
    this.notice$.subscribe();
  }

  public reloadTimer() {
    this._timerSubscription?.unsubscribe();

    this._timerSubscription = timer(this._timerValue)
      .pipe(take(1))
      .subscribe(() => {
        this.noticeSource.next(true);
        this._timerSubscription?.unsubscribe();
      });
  }

  public disableTimer() {
    this._timerSubscription?.unsubscribe();
  }

  get notice(): Observable<boolean> {
    return this.notice$;
  }
}

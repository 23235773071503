import { ModifierApiModel } from './modifier.api.model';
import { ImageApiModel } from './image.api.model';
import { Exclude, Expose, Type } from 'class-transformer';
import { AttributeApiModel } from '@core/models/tapp-order/api-model/product/attribute.api.model';
import { AllergenApiModel } from '@core/models/tapp-order/api-model/product/allergen.api.model';
import { NutritionValueApiModel } from '@core/models/tapp-order/api-model/product/nutrition-value.api.model';

@Exclude()
export class ProductApiModel {
  @Expose()
  @Type(() => ImageApiModel)
  public image: ImageApiModel;

  @Expose()
  public hasModifiers: boolean;

  @Expose()
  public internalId: string;

  @Expose()
  public publicId: string;

  @Expose()
  public name: string;

  @Expose()
  public description: string;

  @Expose()
  public productType: string;

  @Expose()
  public available: boolean;

  @Expose()
  public price: number;

  @Expose()
  public taxRate: number;

  @Expose()
  public taxValue: number;

  @Expose()
  public minQuantity: number;

  @Expose()
  public maxQuantity: number;

  @Expose()
  public maxParts: number | null;

  @Expose()
  public defaultQuantity: number;

  @Expose()
  public discountId: string = null;

  @Expose()
  public discountGroupUid: string = null;

  @Expose()
  public discountName: string = null;

  @Expose()
  public originalExternalId: string = null;

  @Expose()
  public originalExternalSizeId: string = null;

  @Expose()
  public originalExternalDoughId: string = null;

  @Expose()
  @Type(() => ModifierApiModel)
  public modifiers: ModifierApiModel[];

  @Expose()
  public originalExternalPrice: string = null;

  @Expose()
  public baseAdditionSwitchAllowed: boolean = null;

  @Expose()
  public baseAdditionsDeletionAllowed: boolean = null;

  @Expose()
  public hasPriceFrom: boolean = null;

  @Expose()
  public totalDiscount: number = null;

  @Expose()
  @Type(() => AttributeApiModel)
  public attributes: AttributeApiModel[];

  @Expose()
  @Type(() => AllergenApiModel)
  public allergens: AllergenApiModel[];

  @Expose()
  @Type(() => NutritionValueApiModel)
  public nutritionValues: NutritionValueApiModel[];

  @Expose()
  public selectBasicModifiers: boolean = false;
}

import { Component, OnInit, Input } from '@angular/core';
import { PlaceViewModel } from '@core/models/tapp-order/view-model/place/place.view.model';
import { PlaceService } from '../../../../../services/place.service';
import { NewOrderApiModel } from '@core/models/tapp-order/api-model/order/new-order.api.model';
import { OrderTypeEnum } from '@core/models/tapp-order/api-model/order/OrderTypeEnum';

@Component({
  selector: 'app-order-pending',
  templateUrl: './order-pending.component.html',
  styleUrls: ['./order-pending.component.scss'],
})
export class OrderPendingComponent implements OnInit {
  @Input() order: NewOrderApiModel = null;
  public place: PlaceViewModel;
  public orderTypeEnum = OrderTypeEnum;

  constructor(private placeService: PlaceService) {}

  ngOnInit(): void {
    this.placeService.getPlace().subscribe((place) => {
      this.place = place;
    });
  }
}

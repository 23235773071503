import { Expose } from 'class-transformer';
import { TpayPaymentMethodApiModel } from '@api/model/tpay/tpay-payment-method.api.model';

export class TpayPaymentMethodsApiModel {
  @Expose()
  public posId: number;

  @Expose()
  public paymentMethods: TpayPaymentMethodApiModel[];
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DiscountViewModel } from '../../model/discount.view.model';

@Component({
  selector: 'app-discount-card',
  templateUrl: './discount-card.component.html',
  providers: [],
})
export class DiscountCardComponent {
  @Input() discount!: DiscountViewModel;

  @Input() buttonVisible: boolean = true;

  @Output() public onAdd: EventEmitter<void> = new EventEmitter<void>();

  constructor() {}
}

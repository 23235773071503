import { Directive, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { DeviceDetectorService } from 'src/app/services/device-detector.service';

@Directive({
  selector: '[appAttributeScreenSize]',
})
export class AttributeScreenSizeDirective implements OnInit, OnDestroy {
  @HostBinding('attr.screen-size') private screenSize!: 'desktop' | 'mobile';

  private subscription$!: Subscription;

  constructor(private deviceDetectorService: DeviceDetectorService) {}

  ngOnInit(): void {
    this.subscription$ = this.deviceDetectorService.isDesktop.subscribe({
      next: (isDesktop) => {
        this.screenSize = isDesktop ? 'desktop' : 'mobile';
      },
    });
  }

  ngOnDestroy(): void {
    this.subscription$.unsubscribe();
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingService } from 'src/app/shared/loading/loading.service';
import { PlaceHttpService } from '../../http/tapp-order/place/place.http.service';
import { PlaceService } from '../../../services/place.service';
import { environment } from '@env/environment';

@Component({
  selector: 'app-page-message',
  templateUrl: './message.component.html',
  styleUrls: ['./../order/order-success/order-success.component.scss'],
})
export class MessagePageComponent implements OnInit {
  public id: string = null;
  public message: any = null;
  public logo: string;
  @Input() primaryText: string;
  @Input() secondaryText: string;

  constructor(
    public translateService: TranslateService,
    private loadingServide: LoadingService,
    private route: ActivatedRoute,
    private placeHttpService: PlaceHttpService,
    private placeService: PlaceService,
  ) {}

  ngOnInit() {
    /* Ustawianie logo */
    this.placeService.getPlace().subscribe((placeResponse) => {
      if (placeResponse.image && placeResponse.image.url) {
        this.logo = placeResponse.image.url;
      } else {
        this.logo = '../../../assets/images/' + environment.defaultLogoName;
      }
    });

    this.loadingServide.hideLoader();

    this.route.params.subscribe((params) => {
      this.id = params.id;
      this.prepareMessage(this.id);
    });
  }

  private prepareMessage(id: string) {
    this.translateService.get(`message.${id}`).subscribe(
      (res: Object) => {
        this.message = res;
      },
      (err) => {
        this.translateService.get(`message.default`).subscribe((res: Object) => (this.message = res));
      },
    );
  }

  goAction() {
    window.location.href = '/';
  }

  getLogo(): string {
    return this.logo;
  }
}

<div class="footer-container d-flex" *ngIf="isDesktop">
  <div class="d-flex justify-content-end">
    <div class="d-flex w-50 justify-content-between">
      <div class="d-flex flex-column" style="gap: 2%">
        <span class="footer-item-bold ui-clickable">O firmie</span>
        <span class="footer-item-bold ui-clickable">Dzieje się</span>
        <span class="footer-item-bold ui-clickable">Franczyza</span>
      </div>
      <div class="d-flex flex-column ui-clickable" style="gap: 2%">
        <span class="footer-item-bold ui-clickable">Kariera</span>
        <span class="footer-item-bold ui-clickable">Twoja opinia</span>
      </div>
    </div>
  </div>
  <div class="d-flex justify-content-start">
    <div class="d-flex justify-content-between w-100">
      <div class="d-flex flex-column" style="gap: 2%">
        <span class="footer-item ui-clickable">Regulamin serwisu</span>
        <span class="footer-item ui-clickable">Regulamin promocji</span>
        <span class="footer-item ui-clickable">Kontakt</span>
      </div>
      <div class="d-flex flex-column ui-clickable" style="gap: 2%">
        <span class="footer-item ui-clickable">Informacja o alergenach</span>
        <span class="footer-item ui-clickable">Tabela kaloryczności</span>
      </div>
    </div>
  </div>
  <div class="d-flex justify-content-start order-over-the-phone">
    <div class="d-flex flex-column align-items-center">
      <div>
        <img src="../../../../assets/icons/phone.png" width="34px" height="34px" />
      </div>
      <div class="d-flex flex-column pt-2">
        <span class="order-over-the-phone__title">Zamów przez telefon:</span>
        <span class="pt-1 order-over-the-phone__phone-number">(+48) 801 111 111</span>
      </div>
      <div class="d-flex order-over-the-phone__social-media" style="padding-top: 10%; gap: 10px">
        <img class="ui-clickable" src="../../../../assets/icons/twitter.png" />
        <img class="ui-clickable" src="../../../../assets/icons/instagram.png" />
        <img class="ui-clickable" src="../../../../assets/icons/facebook.png" />
      </div>
    </div>
  </div>
</div>

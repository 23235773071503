import { ApplicationRef, Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { concat, interval } from 'rxjs';
import { first } from 'rxjs/operators';
import { NotificationService } from './notification.service';
import { OrderStateService } from './order.state.service';

@Injectable()
export class SwUpdateService {
  constructor(
    appRef: ApplicationRef,
    public updates: SwUpdate,
    private notificationService: NotificationService,
    private orderStateService: OrderStateService,
  ) {
    const appIsStable$ = appRef.isStable.pipe(first((isStable) => isStable === true));
    const checkInterval$ = interval(6 * 60 * 60); // interval(1 * 60 * 60 * 1000);
    const onceAppIsStable$ = concat(appIsStable$, checkInterval$);
    onceAppIsStable$.subscribe(() =>
      updates.checkForUpdate().then(() => {
        console.log('checking for updates');
      }),
    );

    updates.available.subscribe((event) => {
      updates.activateUpdate().then(() => {
        //this.reload();
      });
    });

    updates.unrecoverable.subscribe((event) => {
      const msg =
        'An error occurred that we cannot recover from:\n' + event.reason + ' ' + '\n\nThis page will be reloaded.';

      this.promptUser(msg, () => {
        this.reload();
      });
    });
  }

  private promptUser(message, callback): void {
    const ref = this.notificationService.showWithAction(message);
    ref.afterDismissed().subscribe(() => {
      if (callback) {
        callback();
      }
    });
  }

  private reload() {
    console.log('reload!');
    this.orderStateService.cleanStateAll();
    document.location.reload();
    // window.location.href = '/';
  }
}

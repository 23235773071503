import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { classToPlain, instanceToPlain, plainToClass, plainToInstance } from 'class-transformer';
import { Cacheable, LocalStorageStrategy } from 'ts-cacheable';
import { SurveyApiModel } from '@core/models/tapp-order/api-model/survey/survey.api.model';
import { SurveyCategoryEnum } from '@shared/enum/survey-category.enum';
import { NewOrderApiModel } from '@core/models/tapp-order/api-model/order/new-order.api.model';
import { SurveyAnswersApiModel } from '@core/models/tapp-order/api-model/survey/survey-answers.api.model';

const surveysCacheBuster$ = new Subject<void>();

@Injectable({
  providedIn: 'root',
})
export class SurveyHttpService {
  constructor(private http: HttpClient) {}

  @Cacheable({
    storageStrategy: LocalStorageStrategy,
    cacheBusterObserver: surveysCacheBuster$,
  })
  getSurveysList(placeId: string, category?: SurveyCategoryEnum): Observable<SurveyApiModel[]> {
    const params = new HttpParams();
    if (category) {
      params.set('category', category);
    }

    return this.http
      .get<SurveyApiModel[]>(`/survey/${placeId}`, { params: params })
      .pipe(map((result: SurveyApiModel[]) => plainToInstance(SurveyApiModel, result as object[])));
  }

  public saveSurveyAnswers(apiModel: SurveyAnswersApiModel): Observable<void> {
    return this.http.post<void>(`/survey/answers`, instanceToPlain(apiModel)).pipe();
  }
}

import { AbstractViewModel } from '../../../abstract.view.model';
import { CardTypeItemMembershipPriceApiModel } from '../../../api-model/order/card-type-item/card-type-item-membership-price.api.model';

export class CardTypeItemMembershipPriceViewModel extends AbstractViewModel<CardTypeItemMembershipPriceApiModel> {
  isAppliedOnOrder: boolean;
  membershipLevel: number;
  membershipCardTypeId: string;
  membershipItemPrice: number;
  membershipChargeValue: number;

  constructor(protected apiModel: CardTypeItemMembershipPriceApiModel = new CardTypeItemMembershipPriceApiModel()) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.isAppliedOnOrder = this.apiModel.isAppliedOnOrder;
    this.membershipLevel = this.apiModel.membershipLevel;
    this.membershipCardTypeId = this.apiModel.membershipCardTypeId;
    this.membershipItemPrice = this.apiModel.membershipItemPrice;
    this.membershipChargeValue = this.apiModel.membershipChargeValue;
  }

  toApiModel(): CardTypeItemMembershipPriceApiModel {
    return undefined;
  }
}

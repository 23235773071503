import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { OrderApiModel } from '@api/model/user/order.api.model';
import { ResultListApiModel } from '@core/models/api-model/result-list.api.model';

@Component({
  selector: 'app-order-history-list',
  templateUrl: './order-history-list.component.html',
  styleUrls: ['./order-history-list.component.scss'],
})
export class OrderHistoryListComponent implements OnInit, AfterViewInit {
  public ordersListTotal: number = 0;
  public page: number = 1;
  public limit: number = 10;
  public scrollContainer: string = '.main-layout__content';
  private scrollableElem: Element;
  public lastScrollDownHeight: number;
  public ordersListItems: OrderApiModel[] = [];
  @Output() scrollEvent: EventEmitter<number> = new EventEmitter();
  @Input() isLoading: boolean = true;

  @Input()
  public set orders(value: ResultListApiModel<OrderApiModel>) {
    this.ordersListItems = [...this.ordersListItems, ...value.records];
    this.ordersListTotal = value.total;
    this.scrollableElem.scrollTo(0, this.lastScrollDownHeight);
  }

  constructor() {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.scrollableElem = document.querySelector(this.scrollContainer);
    if (!this.lastScrollDownHeight) {
      setTimeout(() => {
        this.lastScrollDownHeight = this.scrollableElem.scrollHeight;
      }, 50);
    }
  }

  public onScroll(): void {
    if (this.page > this.ordersListTotal / this.limit) {
      return;
    }
    this.page = this.page + 1;
    this.lastScrollDownHeight = this.scrollableElem.scrollTop;
    this.scrollEvent.emit(this.page);
  }

  get productsPage() {
    return ['/', sessionStorage.getItem('placeId')] ?? ['/'];
  }
}

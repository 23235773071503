import { Component, Input, OnInit } from '@angular/core';
import { OrderTypeEnum } from '@core/models/tapp-order/api-model/order/OrderTypeEnum';
import { NewOrderApiModel } from '@core/models/tapp-order/api-model/order/new-order.api.model';
import { PlaceViewModel } from '@core/models/tapp-order/view-model/place/place.view.model';
import { OrderService } from '@core/services/order.service';

@Component({
  selector: 'app-order-in-delivery',
  templateUrl: './order-in-delivery.component.html',
  styleUrls: ['./order-in-delivery.component.scss'],
})
export class OrderInDeliveryComponent implements OnInit {
  @Input() public restaurant: PlaceViewModel;
  @Input() public orderType: string;
  @Input() set localOrderIdentifier(value: string) {
    this._localOrderIdentifier = value;
  }
  @Input() public orderTypeEnum = OrderTypeEnum;
  @Input() public pickupTime: Date;
  @Input() public deliveryTime: Date;
  @Input() order: NewOrderApiModel = null;

  private _localOrderIdentifier: string;

  constructor(public orderService: OrderService) {}

  ngOnInit(): void {}

  get localOrderIdentifier() {
    return this._localOrderIdentifier;
  }
}

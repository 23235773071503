import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { GeoapiApiModel } from '@core/models/tapp-order/api-model/geoapi/geoapi.api.model';
import { PlaceHttpService } from '@core/http/tapp-order/place/place.http.service';
import { PlaceViewModel } from '@core/models/tapp-order/view-model/place/place.view.model';
import { QueryParamsApiModel } from '@core/models/query-params-api.model';
import { forkJoin, Subject } from 'rxjs';
import { FilterParameterApiModel } from '@core/models/filter-parameter.api.model';
import { DeviceDetectorService } from '../../../services/device-detector.service';
import { PlaceService } from '../../../services/place.service';
import { DeliveryZonesHttpService } from '@core/http/tapp-order/delivery-zones/delivery-zones.http.service';
import { DeliveryZoneViewModel } from '@core/models/tapp-order/view-model/delivery-zones/delivery-zone.view.model';
import { BaseDataProvider } from '@core/data-providers/base.data-provider';
import { ThemeService } from 'src/app/services/theme.service';
import { HolidayViewModel } from '@core/models/tapp-order/view-model/holiday/holiday.view.model';
import { ButtonBackService } from '@core/services/button-back.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-places',
  templateUrl: './places.component.html',
  styleUrls: ['./places.component.scss'],
})
export class PlacesComponent implements OnInit, OnDestroy {
  @ViewChild('productListDesktop')
  private productListDesktopHTMLElement: ElementRef<HTMLElement>;
  @ViewChild('placesWrapper') private placesWrapper: ElementRef<HTMLElement>;

  isLoaded: boolean = false;
  isDesktop: boolean = false;
  _placesList: PlaceViewModel[] = [];
  _places: PlaceViewModel[] = [];
  _cities: string[] = [];
  _city: string = null;
  listSubject$: Subject<QueryParamsApiModel> = new Subject();
  _deliveryZoneList: DeliveryZoneViewModel[] = [];
  _holidays: HolidayViewModel[] = [];

  /*szukajka*/
  public placeInputValue = '';
  public results: GeoapiApiModel[] = [];
  public placeInputError = '';

  constructor(
    private placeHttpService: PlaceHttpService,
    private deviceService: DeviceDetectorService,
    private placeService: PlaceService,
    private deliveryZonesHttpService: DeliveryZonesHttpService,
    private themeService: ThemeService,
    private baseDataProvider: BaseDataProvider,
    private router: Router,
  ) {
    this.deviceService.isDesktop.subscribe((isDesktop) => {
      this.isDesktop = isDesktop;
    });

    if (sessionStorage.getItem('tableId') && sessionStorage.getItem('placeId')) {
      this.router.navigate(['/', sessionStorage.getItem('placeId')]);
    }

    this.placeService.getPlaces().subscribe((places: PlaceViewModel[]) => {
      let placesWithOnlineOrder = places.filter((place: PlaceViewModel) => place.apiModel.onlineOrderActive);

      if (placesWithOnlineOrder.length == 0) {
        this.router.navigate(['/', 'start']);
      } else if (placesWithOnlineOrder.length == 1) {
        this.router.navigate(['/', placesWithOnlineOrder[0].publicId]);
      }

      this.isLoaded = true;
    });
  }

  ngOnInit(): void {
    this.themeService.mobileNavbarShowBackButton = true;
    this.getPlacesList();
  }

  ngOnDestroy(): void {
    this.themeService.mobileNavbarShowBackButton = false;
    this.listSubject$.unsubscribe();
  }

  setCity(city: string): void {
    this.scrollListToTop();
    this._city = city;
    this.filterPlaces(city);
    this.scrollToPlacesListOnMobile();
  }

  private scrollToPlacesListOnMobile(): void {
    setTimeout(() => {
      if (window.innerWidth <= 767) {
        this.placesWrapper?.nativeElement.scrollIntoView();
      }
    }, 0);
  }

  private scrollListToTop(): void {
    this.productListDesktopHTMLElement.nativeElement.scrollTo(0, 0);
  }

  private filterPlaces(city: string): void {
    this._places = this._placesList.filter((place) => place.city == city);
  }

  get places(): PlaceViewModel[] {
    return this._places;
  }

  get deliveryZoneList(): DeliveryZoneViewModel[] {
    return this._deliveryZoneList;
  }

  get holidays(): HolidayViewModel[] {
    return this._holidays;
  }

  get cities(): string[] {
    return this._cities;
  }

  get city(): string {
    return this._city;
  }

  get queryParams(): QueryParamsApiModel {
    const queryParams = new QueryParamsApiModel();
    queryParams.orderBy = 'city';
    queryParams.orderDirection = 'ASC';
    // queryParams.filters.push(
    //   new FilterParameterApiModel('isPreviewOnly', '==', false)
    // );
    return queryParams;
  }

  getPlacesList(): void {
    forkJoin([
      this.baseDataProvider.getHolidaysList(),
      this.deliveryZonesHttpService.getDeliveryZoneList(),
      this.placeService.getPlaces(),
    ]).subscribe(
      ([holidays, deliveryZoneList, places]: [HolidayViewModel[], DeliveryZoneViewModel[], PlaceViewModel[]]) => {
        this._placesList = places.filter((place) => place.menus.length > 0 && place.onlineOrderActive);
        this._deliveryZoneList = deliveryZoneList;
        this._holidays = holidays;

        this._placesList.forEach((placeItem) => {
          let flag = true;
          this._cities.forEach((item) => {
            if (item === placeItem.city) {
              flag = false;
            }
          });
          if (flag) {
            this._cities.push(placeItem.city);
          }
        });

        this._cities = this._cities.sort((a, b) => a.localeCompare(b));
      },
    );
  }

  /*szukajka do zaimplementowania potem*/
  getUserLocation(): void {
    // this.placeInputError = '';
    // if (navigator.geolocation) {
    //   navigator.geolocation.getCurrentPosition(async (position) => {
    //     const longitude = position.coords.longitude;
    //     const latitude = position.coords.latitude;
    //     this.geoapiHttpService
    //       .reverseSearch(latitude, longitude)
    //       .subscribe((response) => {
    //         this.selectPickupLocation(response[0]);
    //       });
    //   });
    // }
  }

  selectPickupLocation(autocompleteitem: GeoapiApiModel): void {
    // this.results = [];
    // this.placeInputError = '';
    // this.placeInputValue = autocompleteitem.fullAddress;
    // this.selectedPickupLocation = autocompleteitem;
    // this.lastSearchQuery = this.placeInputValue;
  }
}

import { AbstractViewModel } from '../../../abstract.view.model';
import { TableApiModel } from '../../api-model/place/table.api.model';

export class TableViewModel extends AbstractViewModel<TableApiModel> {
  public publicId: string;
  public name: string;
  public placePublicId: string;
  public roomName: string;

  constructor(protected apiModel: TableApiModel = new TableApiModel()) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.publicId = this.apiModel.publicId;
    this.name = this.apiModel.name;
    this.placePublicId = this.apiModel.placePublicId;
    this.roomName = this.apiModel.roomName;
  }

  toApiModel(): TableApiModel {
    return undefined;
  }

  toString() {
    return this.name;
  }
}

import { CompanyApiModel } from '@api/model/user/company.api.model';
import { DeliveryAddressApiModel } from '@api/model/user/delivery-address.api.model';
import { Exclude, Expose, Type } from 'class-transformer';

@Exclude()
export class UserDataApiModel {
  @Expose()
  public agreementIds: number[];

  @Expose()
  public email: string;

  @Expose()
  public name: string;

  @Expose()
  public phoneNumber: string;

  @Expose()
  public deliveryAddress: DeliveryAddressApiModel;

  @Expose()
  @Type(() => CompanyApiModel)
  public companies: CompanyApiModel[];
}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  template: '',
})
export class RedirectToParentRouteComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit(): void {
    const currentRoute = this.router.url;
    const segments = currentRoute.split('/');

    // Remove the last segment (child route)
    segments.pop();

    // Join the remaining segments to form the parent route
    const parentRoute = segments.join('/');

    this.router.navigate([parentRoute]);
  }
}

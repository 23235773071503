<div class="toolbar-wrapper">
  <div class="toolbar-search-icon my-auto" (click)="toggleSearchBar()">
    <img class="toolbar-search-icon-img" />
  </div>
  <div class="toolbar-menu-wrapper">
    <div [ngClass]="searchBarVisible ? 'toolbar-search-bar my-auto expanded' : 'toolbar-search-bar my-auto'">
      <input class="search-input" (input)="onSearch($event)" placeholder="" autocomplete="off" />
    </div>
    <div
      #toolbarMenu
      id="toolbar-menu"
      [ngClass]="searchBarVisible ? 'toolbar-menu hidden' : 'toolbar-menu'"
      (mousedown)="toolbarStartDragging($event, false, toolbarMenu)"
      (mouseup)="toolbarStopDragging($event, false)"
      (mouseleave)="toolbarStopDragging($event, false)"
      (mousemove)="toolbarMoveEvent($event, toolbarMenu)"
    >
      <div class="toolbar-menu-inner-wrapper">
        <div
          class="w-auto toolbar-menu-item ui-clickable"
          [ngClass]="activeGroupId == group.publicId ? 'toolbar-menu-item-active' : ''"
          *ngFor="let group of groups"
          (click)="scroll(group.publicId)"
          [id]="'menu-item' + group.publicId"
        >
          {{ group.name }}
        </div>
      </div>
    </div>
  </div>
</div>

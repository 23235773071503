import { FormBuilder, FormGroup, ValidatorFn } from '@angular/forms';
import { CateringAggregationArticleViewModel } from '../../models/catering-aggregation.view.model';
import { FormBuilder as Fb } from './form/form.builder';

export class DefaultFormBuilder implements Fb {
  constructor(private formBuilder: FormBuilder = new FormBuilder()) {}

  public getForm(cateringAggregationArticle: CateringAggregationArticleViewModel): FormGroup {
    const form = this.formBuilder.group({});

    for (const modifier of cateringAggregationArticle.modifierArticleList) {
      const validators: Array<ValidatorFn> = [];

      if (modifier.isRequired && this.hasIndependentOptions(modifier)) {
        validators.push(this.atLeastOneCheckboxCheckedValidator(modifier.multiChoiceMin));
      }

      const modifierCollections = modifier.itemCollection.map(() => this.formBuilder.control(false));
      form.addControl(modifier.id, this.formBuilder.array(modifierCollections, validators));
    }

    for (const subArticle of cateringAggregationArticle.subArticleList) {
      form.addControl(`${subArticle.id}-${subArticle.id}`, this.formBuilder.control(null));

      for (const subArticleReplacer of subArticle.replacementList) {
        const name = `${subArticle.id}-${subArticleReplacer.id}`;
        form.addControl(name, this.formBuilder.control(null));
      }

      const subArticleCollections = subArticle.replacementList.map(() => this.formBuilder.control(false));
      form.addControl(subArticle.id, this.formBuilder.array(subArticleCollections));
    }

    return form;
  }

  private hasIndependentOptions(modifier): boolean {
    return (
      modifier.itemCollection.filter((elem) => {
        return elem.relatedModifiers && elem.relatedModifiers.length === 0;
      }).length > 0
    );
  }

  private atLeastOneCheckboxCheckedValidator(minRequired = 1): ValidatorFn {
    return (formGroup: FormGroup) => {
      const checked = Object.keys(formGroup.controls).reduce((acc, key) => {
        const control = formGroup.controls[key];
        if (control.value) {
          acc++;
        }

        return acc;
      }, 0);

      if (checked < minRequired) {
        return {
          required: true,
        };
      }

      return null;
    };
  }
}

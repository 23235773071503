import { Exclude, Expose } from 'class-transformer';

@Exclude()
export class AuthApiModel {
  @Expose()
  public username: string;

  @Expose()
  public password: string;
}

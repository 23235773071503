<app-mobile-navbar *ngIf="deviceService.isMobile | async; else navbarDesktop"></app-mobile-navbar>
<ng-template #navbarDesktop><app-nav-bar></app-nav-bar></ng-template>

<div class="content">
  <main class="page">
    <router-outlet></router-outlet>
  </main>
</div>

<!-- Core components -->
<app-confirm-dialog></app-confirm-dialog>
<app-cofirm-dialog-live-order></app-cofirm-dialog-live-order>

<!-- Libs components -->
<p-toast
  [breakpoints]="{
    '480px': {
      right: '20px',
      left: '20px',
      width: 'calc(100% - 40px)'
    }
  }"
></p-toast>

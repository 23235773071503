import { Component, OnInit } from '@angular/core';
import { PlaceHttpService } from '../../../http/tapp-order/place/place.http.service';
import { PlaceService } from '../../../../services/place.service';
import { DeviceDetectorService } from '../../../../services/device-detector.service';
import { PlaceViewModel } from '@core/models/tapp-order/view-model/place/place.view.model';
import { environment } from '@env/environment';

@Component({
  selector: 'app-order-failed',
  templateUrl: './order-failed.component.html',
  styleUrls: ['./order-failed.component.scss', './../order-success/order-success.component.scss'],
})
export class OrderFailedComponent implements OnInit {
  public logo: string = null;
  public sessionStorageTableId;
  public isDesktop: boolean = false;
  public place: PlaceViewModel;

  constructor(
    private placeHttpService: PlaceHttpService,
    private placeService: PlaceService,
    private deviceService: DeviceDetectorService,
  ) {
    this.deviceService.isDesktop.subscribe((isDesktop) => {
      this.isDesktop = isDesktop;
    });
  }

  ngOnInit(): void {
    /* Pobieranie id stolika z session storage */
    this.sessionStorageTableId = sessionStorage.getItem('tableId');

    /* Ustawianie logo */
    this.placeService.getPlace().subscribe((placeResponse) => {
      this.place = placeResponse;

      if (placeResponse.image && placeResponse.image.url) {
        this.logo = placeResponse.image.url;
      } else {
        this.logo = '../../../assets/images/' + environment.defaultLogoName;
      }
    });
  }

  getLogo(): string {
    return this.logo;
  }
}

import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { ButtonSizeEnum } from '@ui/button/button-size.enum';

@Component({
  selector: 'app-icon',
  exportAs: 'appIcon',
  template: `<i class="fi flaticon-{{ icon }}"></i>`,
})
export class IconComponent implements OnInit {
  @Input()
  get icon(): string {
    return this._icon;
  }
  set icon(value: string) {
    this._icon = value;
  }
  private _icon: string;

  @Input()
  get color(): string {
    return this._color;
  }
  set color(value: string) {
    this._color = value;
  }
  private _color: string;

  @Input()
  get size(): string {
    return this._size;
  }
  set size(value: string) {
    this._size = value;
  }
  private _size: string = ButtonSizeEnum.base;

  @HostBinding('class')
  get cssClasses(): string {
    const cssClasses = [];

    cssClasses.push(this._getColorCssClass());
    cssClasses.push(this._getSizeCssClass());

    return cssClasses.join(' ');
  }

  constructor() {}

  ngOnInit() {}

  private _getColorCssClass(): string {
    return `text-${this._color}`;
  }

  private _getSizeCssClass(): string {
    return `font-size-${this._size}`;
  }
}

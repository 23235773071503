import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, Injector, ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { JwtRefreshTokenInterceptor } from '@core/interceptors/jwt-refresh-token.interceptor';
import { JwtTokenInterceptor } from '@core/interceptors/jwt-token.interceptor';
import { MainLayoutComponent } from '@core/layout/main/main-layout.component';
import { ActivationComponent } from '@core/pages/activation/activation.component';
import { InitTableComponent } from '@core/pages/init-table/init-table.component';
import { CompanyTabComponent } from '@core/pages/logged-in-user/components/company/company-tab/company-tab.component';
import { OrderHistoryListComponent } from '@core/pages/logged-in-user/components/order-history-list/order-history-list.component';
import { OrderItemComponent } from '@core/pages/logged-in-user/components/order-item/order-item.component';
import { OrderHistoryComponent } from '@core/pages/logged-in-user/order-history/order-history.component';
import { UserSettngsComponent } from '@core/pages/logged-in-user/user-settings/user-settngs.component';
import { RegisterFormComponent } from '@core/pages/register/components/register-form/register-form.component';
import { RegisterPageComponent } from '@core/pages/register/register.component';
import { AuthService } from '@core/services/auth.service';
import { TranslateModule } from '@ngx-translate/core';
import { FormComponent } from '@shared/payment/invoice/form/form.component';
import { MessageComponent } from '@shared/payment/message/message.component';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { InputModule } from '@ui/input/input.module';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { ModalModule } from 'ngx-bootstrap/modal';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { DialogService } from 'primeng/dynamicdialog';
import { FileUploadModule } from 'primeng/fileupload';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ProgressBarModule } from 'primeng/progressbar';
import { RippleModule } from 'primeng/ripple';
import { TabViewModule } from 'primeng/tabview';
import { SharedModule } from '../shared/shared.module';
import { ArticleBuilderComponent } from './components/article-builder/article-builder.component';
import { ArticleCardExpandableComponent } from './components/article-card-expandable/article-card-expandable.component';
import { ArticleCardComponent } from './components/article-card/article-card.component';
import { BottomButtonComponent } from './components/bottom-button/bottom-button.component';
import { BottomNotificationComponent } from './components/bottom-notification/bottom-notification.component';
import { ButtonBackComponent } from './components/button-back/button-back.component';
import { CofirmDialogLiveOrderComponent } from './components/cofirm-dialog-live-order/cofirm-dialog-live-order.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { PaymentComponent } from './components/payment/payment.component';
import { TipComponent } from './components/tip/tip.component';
import { CoreRoutingModule } from './core-routing.module';
import { AttributeScreenSizeDirective } from './directives/attribute-screen-size.directive';
import { RequestInterceptor } from './interceptors/request.interceptor';
import { ResponseInterceptor } from './interceptors/response.interceptor';
import { DiscountDeliveryTypeInfoComponent } from './pages/discounts/component/dialog/discount-delivery-type-info/discount-delivery-type-info.component';
import { DiscountCardComponent } from './pages/discounts/component/discount-card/discount-card.component';
import { DiscountConfiguratorComponent } from './pages/discounts/component/discount-configurator/discount-configurator.component';
import { DiscountDialogConfiguratorComponent } from './pages/discounts/component/discount-dialog-configurator/discount-dialog-configurator.component';
import { DiscountDataProvider } from './pages/discounts/data-provider/discount.data-provider';
import { DiscountsComponent } from './pages/discounts/discounts.component';
import { DiscountService } from './pages/discounts/service/discount.service';
import { FoundLocalComponent } from './pages/found-local/found-local.component';
import { OrderHistoryModalComponent } from './pages/logged-in-user/components/order-history-modal/order-history-modal.component';
import { TermAndConditionComponent } from './pages/logged-in-user/components/term-and-condition/term-and-condition.component';
import { LoggedInUserComponent } from './pages/logged-in-user/logged-in-user.component';
import { CustomErrorMessageComponent } from './pages/message/custom-error-message/custom-error-message.component';
import { MessagePageComponent } from './pages/message/message.component';
import { OrderFailedComponent } from './pages/order/order-failed/order-failed.component';
import { OrderPageComponent } from './pages/order/order-page/order-page.component';
import { OrderAcceptedComponent } from './pages/order/order-processing/order-accepted/order-accepted.component';
import { OrderFinishedComponent } from './pages/order/order-processing/order-finished/order-finished.component';
import { OrderInDeliveryComponent } from './pages/order/order-processing/order-in-delivery/order-in-delivery.component';
import { OrderPendingComponent } from './pages/order/order-processing/order-pending/order-pending.component';
import { OrderProcessingComponent } from './pages/order/order-processing/order-processing.component';
import { OrderRefundedComponent } from './pages/order/order-processing/order-refunded/order-refunded.component';
import { OrderRefusedComponent } from './pages/order/order-processing/order-refused/order-refused.component';
import { PaymentPendingComponent } from './pages/order/order-processing/payment-pending/payment-pending.component';
import { PaymentRefusedComponent } from './pages/order/order-processing/payment-refused/payment-refused.component';
import { OrderSuccessComponent } from './pages/order/order-success/order-success.component';
import { OrderSummaryComponent } from './pages/order/order-summary/order-summary.component';
import { OrderComponent } from './pages/order/order.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { PasswordResetComponent } from './pages/password-reset/password-reset.component';
import { PaymentFormComponent } from './pages/payment/component/payment-form/payment-form.component';
import { PaymentPageComponent } from './pages/payment/payment.component';
import { ListComponent } from './pages/places/component/list/list.component';
import { PlaceComponent } from './pages/places/component/place/place.component';
import { PlacesComponent } from './pages/places/places.component';
import { ProductDetailsPageComponent } from './pages/product-details/product-details.component';
import { ChangeIngredientsDialogComponent } from './pages/products/component/change-ingredients-dialog/change-ingredients-dialog.component';
import { PizzaDivideSwitchComponent } from './pages/products/component/pizza-divide-switch/pizza-divide-switch.component';
import { ProductGroupComponent } from './pages/products/component/product-group/product-group.component';
import { ProductItemComponent } from './pages/products/component/product-item/product-item.component';
import { ProductModifiersComponent } from './pages/products/component/product-modifiers/product-modifiers.component';
import { ProductPizzaCardComponent } from './pages/products/component/product-pizza-card/product-pizza-card.component';
import { NavbarComponent } from './pages/products/navbar/navbar.component';
import { ProductDiscountModifyComponent } from './pages/products/page/product-discount-modify/product-discount-modify.component';
import { ProductListPizzaSecondPartComponent } from './pages/products/page/product-list-pizza-second-part/product-list-pizza-second-part.component';
import { ProductModifyComponent } from './pages/products/page/product-modify/product-modify.component';
import { ProductsPageComponent } from './pages/products/products.component';
import { ScannerComponent } from './pages/scanner/scanner.component';
import { SelectAdressComponent } from './pages/select-adress/select-adress.component';
import { SelectPaymentMethodComponent } from './pages/select-payment-method/select-payment-method.component';
import { StartPageComponent } from './pages/start/start.component';
import { TermsAndConditionsComponent } from './pages/terms-and-conditions/terms-and-conditions.component';
import { OrderStateService } from './services/order.state.service';
import { SwUpdateService } from './services/update.service';
import { ResponseValidatorService } from './services/validators/response-validator.service';
import { FiltersDialogComponent } from '@core/pages/products/component/filters-dialog/filters-dialog.component';
import { SliderModule } from 'primeng/slider';
import { ProductAllergensDialogComponent } from '@core/pages/products/component/product-allergens-dialog/product-allergens-dialog.component';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
  declarations: [
    ScannerComponent,
    ProductDetailsPageComponent,
    ArticleBuilderComponent,
    OrderComponent,
    OrderPageComponent,
    ArticleCardComponent,
    ProductsPageComponent,
    TipComponent,
    PaymentComponent,
    PaymentPageComponent,
    PaymentFormComponent,
    InitTableComponent,
    PageNotFoundComponent,
    MessagePageComponent,
    NavbarComponent,
    StartPageComponent,
    BottomNotificationComponent,
    BottomButtonComponent,
    OrderSummaryComponent,
    SelectPaymentMethodComponent,
    OrderSuccessComponent,
    OrderFailedComponent,
    CustomErrorMessageComponent,
    SelectAdressComponent,
    FormComponent,
    MainLayoutComponent,
    FoundLocalComponent,
    OrderProcessingComponent,
    OrderRefusedComponent,
    OrderPendingComponent,
    PaymentPendingComponent,
    PaymentRefusedComponent,
    OrderAcceptedComponent,
    OrderInDeliveryComponent,
    OrderFinishedComponent,
    MessageComponent,
    PlacesComponent,
    ListComponent,
    PlaceComponent,
    OrderRefundedComponent,
    RegisterPageComponent,
    RegisterFormComponent,
    LoggedInUserComponent,
    ActivationComponent,
    UserSettngsComponent,
    ButtonBackComponent,
    AttributeScreenSizeDirective,
    CompanyTabComponent,
    TermAndConditionComponent,
    OrderHistoryListComponent,
    OrderHistoryComponent,
    OrderItemComponent,
    OrderHistoryModalComponent,
    CofirmDialogLiveOrderComponent,
    PasswordResetComponent,
    TermsAndConditionsComponent,
    ProductPizzaCardComponent,
    ProductGroupComponent,
    ProductModifyComponent,
    ProductDiscountModifyComponent,
    ChangeIngredientsDialogComponent,
    ProductModifiersComponent,
    ProductItemComponent,
    PizzaDivideSwitchComponent,
    ProductListPizzaSecondPartComponent,
    DiscountsComponent,
    DiscountCardComponent,
    DiscountConfiguratorComponent,
    DiscountDeliveryTypeInfoComponent,
    DiscountDialogConfiguratorComponent,
    ConfirmDialogComponent,
    FiltersDialogComponent,
    ProductAllergensDialogComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    SharedModule,
    ZXingScannerModule,
    NgxQRCodeModule,
    CoreRoutingModule,
    TranslateModule,
    NgxMaskModule.forRoot(),
    ModalModule.forRoot(),
    RouterModule,
    InputTextModule,
    InputModule,
    InputTextareaModule,
    ButtonModule,
    CheckboxModule,
    InputSwitchModule,
    InfiniteScrollModule,
    TabViewModule,
    ProgressBarModule,
    FileUploadModule,
    BrowserAnimationsModule,
    RippleModule,
    SliderModule,
  ],
  providers: [
    ResponseValidatorService,
    OrderStateService,
    SwUpdateService,
    BottomNotificationComponent,
    MessageService,
    DiscountDataProvider,
    DiscountService,
    DialogService,
  ],
  exports: [
    BottomNotificationComponent,
    BottomButtonComponent,
    ArticleCardExpandableComponent,
    OrderComponent,
    OrderPageComponent,
    PaymentFormComponent,
    ButtonBackComponent,
  ],
  entryComponents: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule, private injector: Injector) {
    // if (parentModule) {
    //   throw new Error(
    //     'CoreModule is already loaded. Import it in the AppModule only'
    //   );
    // }
  }

  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        AuthService,
        {
          provide: HTTP_INTERCEPTORS,
          useClass: RequestInterceptor,
          multi: true,
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: JwtTokenInterceptor,
          multi: true,
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: JwtRefreshTokenInterceptor,
          multi: true,
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: ResponseInterceptor,
          multi: true,
        },
      ],
    };
  }
}

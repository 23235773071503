import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuDataProvider } from '@core/data-providers/menu.data-provider';
import { MenuViewModel } from '@core/models/tapp-order/view-model/menu/menu.view.model';
import { ProductViewModel } from '@core/models/tapp-order/view-model/product/product.view.model';
import { OrderService } from '@core/services/order.service';
import { ParamKeys } from '@shared/enum/param-kyes.enum';
import { LoadingService } from '@shared/loading/loading.service';
import * as _ from 'lodash';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PlaceService } from 'src/app/services/place.service';

@Component({
  selector: 'app-product-list-pizza-second-part',
  templateUrl: './product-list-pizza-second-part.component.html',
  styleUrls: ['./product-list-pizza-second-part.component.scss'],
})
export class ProductListPizzaSecondPartComponent implements OnInit, OnDestroy {
  private destroyed$ = new Subject<void>();

  public menu?: MenuViewModel;
  public placeIsPreviewOnly: boolean = false;
  public isOpen: boolean = true;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private loadingService: LoadingService,
    private placeService: PlaceService,
    private menuDataProvider: MenuDataProvider,
    private orderService: OrderService,
  ) {}

  ngOnInit(): void {
    this.loadingService.showLoader();
    this.activatedRoute.params.subscribe((params) => {
      const placeId = params[ParamKeys.PlaceId];
      this.fetchPlace(placeId);
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  private fetchPlace(placeId: string): void {
    this.loadingService.showLoader();

    this.placeService
      .useAsObservable(placeId)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (place) => {
          if (place.menus.length > 0 && place.getMenuByType(this.orderService.getMenuType())) {
            this.placeIsPreviewOnly = place.isPreviewOnly;

            const menuId = place.getMenuByType(this.orderService.getMenuType())?.publicId;

            this.menuDataProvider.getMenu(menuId).subscribe((menu) => {
              this.loadingService.hideLoader();

              this.menu = _.cloneDeep(menu);

              // Filter only pizza that can be divided
              this.menu.groups.forEach((group) => {
                group.products = group.products.filter((product) => product.canPizzaBeDivided);

                group.subgroups.forEach((subgroup) => {
                  subgroup.products = subgroup.products.filter((product) => product.canPizzaBeDivided);
                });

                group.subgroups = group.subgroups.filter((subgroup) => subgroup.products.length);
              });

              this.menu.groups = this.menu.groups.filter((group) => group.products.length || group.subgroups.length);
            });
          } else {
            this.loadingService.hideLoader();
          }
        },
        error: () => {
          this.loadingService.hideLoader();
          this.router.navigate(['/']);
        },
      });
  }

  public onScrollGroups(data: any): void {
    // TODO: implement scroll to group
  }

  public handleSelectSecondPart(product: ProductViewModel): void {
    const placeId = sessionStorage.getItem(ParamKeys.PlaceId);

    if (!placeId) {
      this.router.navigate(['/']);
      return;
    }

    const currentLocationPath = this.router.url.split('/');

    currentLocationPath.pop();

    this.router.navigate([...currentLocationPath, product.publicId]);
  }
}

import { TitleCasePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { PlaceDataProvider } from '@core/data-providers/place.data-provider';
import { NewOrderService } from '@core/http/tapp-order/order/new-order.service';
import { OrderTypeEnum } from '@core/models/tapp-order/api-model/order/OrderTypeEnum';
import { WorkingHoursApiModel } from '@core/models/tapp-order/api-model/working-hours/working-hours.api.model';
import { PaymentFormService } from '@core/pages/payment/payment-form.service';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService } from 'primeng/api';
import { Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { BasketService } from 'src/app/services/basket.service';

@Component({
  selector: 'app-payment-summary-button-group',
  templateUrl: './summary-button-group.component.html',
  styleUrls: ['./summary-button-group.component.scss'],
})
export class SummaryButtonGroupComponent implements OnInit, OnDestroy {
  public orderTypeEnum = OrderTypeEnum;
  public isOrderButtonActive: boolean = true;
  @Input() public isTermsAndConditionsAccepted: any = false;
  @Output() public termsAndConditionsNotAcceptedEvent: EventEmitter<null> = new EventEmitter<null>();

  private destroyed$ = new Subject<void>();

  constructor(
    public basketService: BasketService,
    private router: Router,
    private translateService: TranslateService,
    private placeDataProvider: PlaceDataProvider,
    private confirmationService: ConfirmationService,
    private newOrderService: NewOrderService,
    private paymentFormService: PaymentFormService,
  ) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  public submit(): void {
    this.placeDataProvider
      .getCurrentOpeningStatus()
      .pipe(first(), takeUntil(this.destroyed$))
      .subscribe((isOpen) => {
        if (!isOpen) {
          this.placeDataProvider
            .getNextWorkingHours()
            .pipe(first(), takeUntil(this.destroyed$))
            .subscribe((nextWorkingHours) => {
              this.showClosedPlaceDialog(nextWorkingHours);
            });
          return;
        }

        this.paymentFormService.isNewOrderCanBeCreated().then((value) => {
          if (value && this.isOrderButtonActive) {
            this.isOrderButtonActive = false;
            this.newOrderService
              .create(this.basketService.basketItems)
              .pipe(first(), takeUntil(this.destroyed$))
              .subscribe(
                (newOrderCreateResponse) => {
                  try {
                    sessionStorage.setItem('NewOrderApiModel', JSON.stringify(newOrderCreateResponse));
                  } catch (error) {
                    console.error('Error saving to sessionStorage', error);
                  }

                  window.location.href = newOrderCreateResponse.orderPayment.providerPaymentUrl;
                },
                (error: HttpErrorResponse) => {
                  this.isOrderButtonActive = true;

                  switch (error.error?.code) {
                    case 'order.order.max_product_quantity_limit':
                      // Exceeded the maximum quantity of some products in the order
                      this.confirmationService.confirm({
                        header: TitleCasePipe.prototype.transform(this.translateService.instant('common.word.failure')),
                        message: this.extractMessageFromApiResponse(error.error),
                        acceptLabel: 'Ok',
                        accept: () => {},
                        reject: () => {},
                        key: 'confirm-dialog-live-order',
                      });
                      break;
                    default:
                      this.router.navigate(['error', '20000']);
                      break;
                  }
                },
              );
          }
        });
      });
  }

  private extractMessageFromApiResponse(response: any): string {
    let message = response.message;
    for (const detail in response.details) {
      const value = response.details[detail];
      message = message.replace(`{{ ${detail} }}`, value);
    }
    return message;
  }

  public getOrderType() {
    return this.basketService.getOrderType();
  }

  public calculateMinOrderPrice() {
    return this.basketService.calculateMinOrderPrice();
  }

  private showClosedPlaceDialog(nextWorkingHours: WorkingHoursApiModel): void {
    this.confirmationService.confirm({
      header: TitleCasePipe.prototype.transform(this.translateService.instant('common.word.failure')),
      message: this.getClosedPlaceMessage(nextWorkingHours),
      acceptLabel: 'Ok',
      accept: () => {
        this.router.navigate(['/', sessionStorage.getItem('placeId')]);
      },
      reject: () => {
        this.router.navigate(['/', sessionStorage.getItem('placeId')]);
      },
      key: 'confirm-dialog-live-order',
    });
  }

  private getClosedPlaceMessage(nextWorkingHours: WorkingHoursApiModel): string {
    const day = this.translateService.instant(`app.weekDays.${nextWorkingHours.weekDay}`);
    const time = nextWorkingHours.startTime;

    return this.translateService.instant('app.pages.payment.closedPlaceMessage', { day, time });
  }
}

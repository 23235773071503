import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { plainToClass } from 'class-transformer';
import { map } from 'rxjs/operators';
import { AuthApiModel } from '@api/model/auth/auth.api.model';
import { UserApiModel } from '@api/model/auth/user.api.model';
import { RegisterApiModel } from '@api/model/auth/register.api.model';

@Injectable({
  providedIn: 'root',
})
export class AuthRestService {
  constructor(private http: HttpClient) {}

  public login(apiModel: AuthApiModel): Observable<UserApiModel> {
    return this.http
      .post<UserApiModel>('/login', apiModel)
      .pipe(map((result) => plainToClass(UserApiModel, result as object)));
  }

  public facebookLogin(accessToken: string): Observable<UserApiModel> {
    return this.http
      .post<UserApiModel>('/login/facebook', { accessToken })
      .pipe(map((result) => plainToClass(UserApiModel, result as object)));
  }

  public googleLogin(tokenId: string): Observable<UserApiModel> {
    return this.http
      .post<UserApiModel>('/login/google', { tokenId })
      .pipe(map((result) => plainToClass(UserApiModel, result as object)));
  }

  public refreshToken(refreshToken: string) {
    return this.http.post('/login/refresh', { refreshToken: refreshToken });
  }

  public activate(activationToken: string): Observable<UserApiModel> {
    return this.http
      .post<UserApiModel>('/registration/activation', { activationToken })
      .pipe(map((result) => plainToClass(UserApiModel, result as object)));
  }

  public resend(email: string, placeId?: string) {
    return this.http.post('/registration/resend-token', { email: email, placePublicId: placeId });
  }

  public deleteAccount(): Observable<any> {
    return this.http.delete('/user');
  }

  public register(apiModel: RegisterApiModel): Observable<void> {
    return this.http.post<void>('/registration', apiModel);
  }
}

<p-dialog
  [visible]="visible"
  (visibleChange)="visibleChange.emit($event)"
  [styleClass]="'app-dialog app-dialog--' + size"
  [draggable]="false"
  [modal]="true"
  [closable]="closable"
  [resizable]="false"
  [position]="dialogPosition"
  [transitionOptions]="dialogTransitionOptions"
  (onHide)="onHide.emit()"
>
  <ng-template pTemplate="header">
    <ng-container *ngTemplateOutlet="headerTemplate"></ng-container>
  </ng-template>

  <ng-content></ng-content>

  <ng-template pTemplate="footer">
    <ng-container *ngTemplateOutlet="footerTemplate"></ng-container>
  </ng-template>
</p-dialog>

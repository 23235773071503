import { AbstractViewModel } from '../../../abstract.view.model';
import { SurveyAnswerApiModel } from '@core/models/tapp-order/api-model/survey/survey-answer.api.model';

export class SurveyAnswerViewModel extends AbstractViewModel<SurveyAnswerApiModel> {
  public questionId: number;
  public rating?: number;
  public selectedChoiceId?: number;
  public selectedChoicesIds?: number[];
  public ownAnswer?: string;

  constructor(protected apiModel: SurveyAnswerApiModel = new SurveyAnswerApiModel()) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {}

  toApiModel(): SurveyAnswerApiModel {
    return Object.assign(this.apiModel, {
      questionId: this.questionId,
      rating: this.rating,
      selectedChoiceId: this.selectedChoiceId,
      selectedChoicesIds: this.selectedChoicesIds,
      ownAnswer: this.ownAnswer,
    });
  }
}

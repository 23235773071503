import { AbstractViewModel } from '../../../abstract.view.model';
import { SurveyQuestionChoiceApiModel } from '@core/models/tapp-order/api-model/survey/survey-question-choice.api.model';

export class SurveyQuestionChoiceViewModel extends AbstractViewModel<SurveyQuestionChoiceApiModel> {
  public id: number;
  public value: string;

  constructor(protected apiModel: SurveyQuestionChoiceApiModel = new SurveyQuestionChoiceApiModel()) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.id = this.apiModel.id;
    this.value = this.apiModel.value;
  }

  toApiModel(): SurveyQuestionChoiceApiModel {
    return undefined;
  }

  toString() {
    return this.value;
  }
}

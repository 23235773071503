import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ModifierViewModel } from '@core/models/tapp-order/view-model/product/modifier.view.model';
import * as _ from 'lodash';
import { ProductModifiersComponent } from '../product-modifiers/product-modifiers.component';

interface DialogData {
  modifierGroups: ModifierViewModel[];
  isMobile: boolean;
}

@Component({
  selector: 'app-change-ingredients-dialog',
  templateUrl: './change-ingredients-dialog.component.html',
  styleUrls: ['./change-ingredients-dialog.component.scss'],
})
export class ChangeIngredientsDialogComponent implements OnInit {
  public oldModifierGroups: ModifierViewModel[];
  public modifierGroups: ModifierViewModel[];
  public isMobile: boolean = false;

  @ViewChild('modifiersComponent')
  modifiersComponent: ProductModifiersComponent;

  constructor(
    public dialogRef: MatDialogRef<ChangeIngredientsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) {
    this.oldModifierGroups = _.cloneDeep(data.modifierGroups);
    this.modifierGroups = data.modifierGroups;
    this.dialogRef.disableClose = true;
    this.isMobile = data.isMobile;
    dialogRef.backdropClick().subscribe(async () => await this.safeClose());
  }

  ngOnInit() {}

  public async safeClose(): Promise<void> {
    this.saveChanges();
  }

  saveChanges(): void {
    this.modifiersComponent.isValid() ? this.dialogRef.close() : null;
  }

  cancelChanges(): void {
    this.oldModifierGroups.forEach((oldModifierGroup) => {
      oldModifierGroup.products.forEach((oldModifierProduct) => {
        let product = this.modifierGroups
          .find((modifierGroup) => modifierGroup.publicId == oldModifierGroup.publicId)
          ?.products.find((product) => product.publicId == oldModifierProduct.publicId);

        if (product) {
          product.CurrentQuantity = oldModifierProduct.CurrentQuantity;
          product.isChecked = oldModifierProduct.isChecked;
        }
      });
    });

    this.dialogRef.close();
  }
}

import { ProductTypeEnum } from '@shared/enum/product-type.enum';
import { AbstractViewModel } from '../../../abstract.view.model';
import { ProductApiModel } from '../../api-model/product/product.api.model';
import { ImageViewModel } from './image.view.model';
import { ModifierViewModel } from './modifier.view.model';
import { SizePriceViewModel } from './size-price.view.model';
import { AttributeViewModel } from '@core/models/tapp-order/view-model/product/attribute.view.model';
import { AllergenViewModel } from '@core/models/tapp-order/view-model/product/allergen.view.model';
import { NutritionValueViewModel } from '@core/models/tapp-order/view-model/product/nutrition-value.view.model';

export class ProductViewModel extends AbstractViewModel<ProductApiModel> {
  public internalUid: string = null;
  public internalId: string = null;
  public image: ImageViewModel;
  public hasModifiers: boolean;
  public publicId: string;
  public name: string;
  public description: string;
  public productType: string;
  public available: boolean;
  public price: number;
  public taxRate: number;
  public taxValue: number;
  public minQuantity: number;
  public maxQuantity: number;
  public defaultQuantity: number;
  public modifiers: ModifierViewModel[];
  public notes: string = '';
  public modifierType: string = '';
  public discountId: number = null;
  public unitDiscount: number = null;
  public baseUnitDiscount: number = null;
  public originalExternalProductId: number = null;
  public originalExternalGroupId: number = null;
  public discountGroupUid: string = null;
  public discountName: string = null;
  public originalExternalId: string = null;
  public originalExternalSizeId: string = null;
  public originalExternalDoughId: string = null;
  public originalExternalGroupIdFromDiscount: number = null;
  public totalPriceWithDiscounts: number = null;
  public originalExternalPrice: string = null;
  public originalExternalPriceEncoded: SizePriceViewModel[] = [];
  public originalInternalPrice: number = null;
  public totalPrice: number = null;
  public totalDiscount: number = null;
  public baseAdditionSwitchAllowed: boolean = null;
  public baseAdditionsDeletionAllowed: boolean = null;
  public attributes: AttributeViewModel[] = [];
  public allergens: AllergenViewModel[] = [];
  public nutritionValues: NutritionValueViewModel[] = [];
  public selectBasicModifiers: boolean = false;

  public isChecked: any = false;
  public checkboxDisabled: any = false;
  public CurrentQuantity: number = 0;
  public groupId: string = '';
  public groupIndex: number;
  public groupName: string = '';
  public hasPriceFrom: boolean = false; // display text "from" in "product-item" component
  public canPizzaBeDivided: boolean = false;

  constructor(public apiModel: ProductApiModel = new ProductApiModel()) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.image = this.apiModel.image ? new ImageViewModel(this.apiModel.image) : new ImageViewModel();
    this.hasModifiers = this.apiModel.hasModifiers;
    this.internalId = this.apiModel.internalId;
    this.publicId = this.apiModel.publicId;
    this.name = this.apiModel.name;
    this.description = this.apiModel.description;
    this.productType = this.apiModel.productType;
    this.available = this.apiModel.available;
    this.price = this.apiModel.price;
    this.taxRate = this.apiModel.taxRate;
    this.taxValue = this.apiModel.taxValue;
    this.minQuantity = this.apiModel.minQuantity;
    this.maxQuantity = this.apiModel.maxQuantity;
    this.defaultQuantity = this.apiModel.defaultQuantity;
    this.originalExternalId = this.apiModel.originalExternalId;
    this.originalExternalSizeId = this.apiModel.originalExternalSizeId;
    this.originalExternalDoughId = this.apiModel.originalExternalDoughId;
    this.originalExternalPrice = this.apiModel.originalExternalPrice;
    this.baseAdditionSwitchAllowed = this.apiModel.baseAdditionSwitchAllowed;
    this.baseAdditionsDeletionAllowed = this.apiModel.baseAdditionsDeletionAllowed;
    this.hasPriceFrom = this.apiModel.hasPriceFrom;
    this.canPizzaBeDivided = this.apiModel.productType === ProductTypeEnum.PRODUCT_PIZZA && this.apiModel.maxParts >= 1;
    this.originalInternalPrice = this.apiModel.price;
    this.totalPrice = this.price;
    this.totalDiscount = this.apiModel.totalDiscount;
    this.modifiers = this.apiModel.modifiers
      ? this.apiModel.modifiers.map((modifier) => new ModifierViewModel(modifier))
      : [];

    if (this.defaultQuantity == 1) {
      this.isChecked = true;
      this.CurrentQuantity = 1;
    }

    this.originalExternalPriceEncoded = this.prepareOriginalExternalPriceEncoded();

    this.attributes = this.apiModel.attributes
      ? this.apiModel.attributes.map((attribute) => new AttributeViewModel(attribute))
      : [];

    this.allergens = this.apiModel.allergens
      ? this.apiModel.allergens.map((allergen) => new AllergenViewModel(allergen))
      : [];

    this.nutritionValues = this.apiModel.nutritionValues
      ? this.apiModel.nutritionValues.map((nutritionValue) => new NutritionValueViewModel(nutritionValue))
      : [];

    this.selectBasicModifiers = this.apiModel.selectBasicModifiers;
  }

  toApiModel(): ProductApiModel {
    return undefined;
  }

  toString() {
    return this.name;
  }

  private prepareOriginalExternalPriceEncoded(): SizePriceViewModel[] {
    let sizePriceModels: SizePriceViewModel[] = [];

    if (this.originalExternalPrice) {
      let endocedOriginalExternalPrice = JSON.parse(atob(this.originalExternalPrice));

      endocedOriginalExternalPrice.forEach((result) => {
        let sizePriceModel = new SizePriceViewModel();
        sizePriceModel.sizeId = result.sizeId;
        sizePriceModel.price = result.price;
        sizePriceModels.push(sizePriceModel);
      });

      return sizePriceModels;
    }
  }
}

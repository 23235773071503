import { Expose, Type } from 'class-transformer';
import { OrderItemModifierApiModel } from './order-item-modifier.api.model';
import { CreateOrderItemModifierRequestApiModel } from './create-order-item-modifier-request.api.model';

@Expose()
export class CreateOrderItemRequestApiModel {
  @Expose()
  public internalId: string;

  @Expose()
  public productPublicId: string;

  @Expose()
  public pizzaGroupId?: number;

  @Expose()
  public parentPizzaPublicId?: string | null;

  @Expose()
  public name: string;

  @Expose()
  public quantity: number;

  @Expose()
  public discountId: string;

  @Expose()
  public originalExternalGroupId: string;

  @Expose()
  public unitDiscount: string;

  @Expose()
  public baseUnitDiscount: string;

  @Expose()
  public notes: string;

  @Expose()
  @Type(() => CreateOrderItemModifierRequestApiModel)
  public selectedModifiers: CreateOrderItemModifierRequestApiModel[];

  @Expose()
  @Type(() => CreateOrderItemModifierRequestApiModel)
  public unselectedModifiers: CreateOrderItemModifierRequestApiModel[];
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { UserDataApiModel } from '@api/model/user/user-data.api.model';
import { UserRestService } from '@api/rest/user/user.rest.service';
import { RouteViewModel } from '@core/pages/logged-in-user/model/route.view.model';
import { AuthService } from '@core/services/auth.service';
import { OrderService } from '@core/services/order.service';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { DeviceDetectorService } from '../../../services/device-detector.service';
import { ThemeService } from '../../../services/theme.service';
import { OrderDataProvider } from './data-providers/order.data-provider';

@Component({
  selector: 'app-logged-in-user',
  templateUrl: './logged-in-user.component.html',
  styleUrls: ['./logged-in-user.component.scss'],
})
export class LoggedInUserComponent implements OnInit, OnDestroy {
  private destroyed$ = new Subject<void>();

  public routes: RouteViewModel[] = [];
  public user: UserDataApiModel;
  public isLoading: boolean = true;
  public isOnRootPath: boolean = false;

  public points: number = 0;
  public pointsLoading: boolean = true;

  constructor(
    private router: Router,
    private userRestService: UserRestService,
    private orderDataProvider: OrderDataProvider,
    public authService: AuthService,
    public orderService: OrderService,
    public deviceDetectorService: DeviceDetectorService,
    public themeService: ThemeService,
    public deviceService: DeviceDetectorService,
  ) {
    this.routes = [
      { name: 'Historia zamówień', route: 'order-history' },
      { name: 'Ustawienia konta', route: 'user-settings' },
    ];

    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .pipe(takeUntil(this.destroyed$))
      .subscribe((event: NavigationEnd) => {
        this.isOnRootPath = event.url === '/user';

        if (!this.isOnRootPath) {
          this.themeService.mobileNavbarShowBackButton = true;
          this.themeService.setbackArrowPath(['user']);
        } else {
          this.themeService.mobileNavbarShowBackButton = false;
        }
      });
  }

  ngOnInit(): void {
    this.userRestService.getUser().subscribe((value) => {
      this.user = value;
      this.isLoading = false;
    });
    this.orderDataProvider.getPoints().subscribe(
      (value) => {
        this.points = value;
        this.pointsLoading = false;
      },
      (error) => {
        this.pointsLoading = false;
      },
    );
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  public logout(): void {
    this.themeService.mobileNavbarShowBackButton = false;
    this.themeService.setbackArrowPath(null);
    this.authService.logout();
    this.router.navigate(['']);
  }

  public isDisplaySetToNone(el): boolean {
    return window.getComputedStyle(el).display === 'none';
  }
}

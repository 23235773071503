import { enableProdMode, LOCALE_ID } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { ConfigService } from './app/configs/config.service';
import { environment } from './environments/environment';
import { TranslateService } from '@ngx-translate/core';

import { registerLocaleData } from '@angular/common';
import pl from '@angular/common/locales/pl';
import localePlExtra from '@angular/common/locales/extra/pl';
import de from '@angular/common/locales/de-CH';
import ar from '@angular/common/locales/ar-SA';

registerLocaleData(pl, 'pl', localePlExtra);
registerLocaleData(de, 'de-CH');
registerLocaleData(ar, 'ar-SA');

if (environment.production) {
  enableProdMode();
}

fetch(
  environment.production
    ? './assets/config.json'
    : './assets/config.json?t=' + Math.floor(new Date().getTime() / 3600000),
)
  .then((response: Response) => response.json())
  .then((config) => {
    ConfigService.setEnvironment(config);
  })
  .then(() => {
    platformBrowserDynamic()
      .bootstrapModule(AppModule, {
        providers: [
          {
            provide: LOCALE_ID,
            useFactory: (translate: TranslateService) => {
              return `${translate.currentLang}-${environment.globalize.country}`;
            },
            deps: [TranslateService],
          },
        ],
      })
      .then(() => {
        if ('serviceWorker' in navigator && environment.production) {
          navigator.serviceWorker.register('ngsw-worker.js');
        }
      })
      .catch((err) => console.log(err));
  });

import { Component, OnInit } from '@angular/core';
import { MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
  selector: 'app-scanner-no-access',
  templateUrl: './scanner-no-access.component.html',
  styleUrls: ['./scanner-no-access.component.scss'],
})
export class ScannerNoAccessComponent implements OnInit {
  constructor(private snackBarRef: MatSnackBarRef<ScannerNoAccessComponent>) {}

  ngOnInit() {}

  close() {
    this.snackBarRef.dismiss();
  }
}

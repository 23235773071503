import { ImageApiModel } from './image.api.model';
import { Exclude, Expose, Type } from 'class-transformer';

@Exclude()
export class NutritionApiModel {
  @Expose()
  public name: string;

  @Expose()
  public publicId: string;

  @Expose()
  public unitName: string;

  @Expose()
  public itemType: string;
}

import { _classTransformerHelper } from './_class-transformer-helper.util';
import { _getComputedHeightFromCSSVar } from './_get-computed-height-from-css-var.util';
import { _isMobileDevice } from './_is-mobile-device.util';
import { _transformBasketItemViewModelsToCreateOrderItemRequestApiModels } from './_transform-basket-item-view-models-to-create-order-item-request-api-models.util';
import { _transformOrderItemApiModelsToBasketItemViewModels } from './_transform-order-item-api-models-to-basket-items-view-models.util';

export namespace appUtils {
  export namespace classTransformerHelper {
    export const emptyToString = _classTransformerHelper.emptyToString;
    export const nullStringToEmptyString = _classTransformerHelper.nullStringToEmptyString;
    export const emptyToNullString = _classTransformerHelper.emptyToNullString;
  }

  export const getComputedHeightFromCSSVar = _getComputedHeightFromCSSVar;

  export const isMobileDevice = _isMobileDevice;

  export const transformOrderItemApiModelsToBasketItemViewModels = _transformOrderItemApiModelsToBasketItemViewModels;

  export const transformBasketItemViewModelsToCreateOrderItemRequestApiModels =
    _transformBasketItemViewModelsToCreateOrderItemRequestApiModels;
}

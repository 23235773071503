import { Component, Input, OnInit } from '@angular/core';
import { BaseDataProvider } from '@core/data-providers/base.data-provider';
import { WeekdaysEnum } from '@core/models/tapp-order/api-model/place/WeekdaysEnum';
import { DeliveryZoneViewModel } from '@core/models/tapp-order/view-model/delivery-zones/delivery-zone.view.model';
import { HolidayViewModel } from '@core/models/tapp-order/view-model/holiday/holiday.view.model';
import { PlaceViewModel } from '@core/models/tapp-order/view-model/place/place.view.model';
import { AddressFinderService } from '@core/services/address-finder.service';
import { OrderService } from '@core/services/order.service';
import { PlaceService } from 'src/app/services/place.service';
import { globalCacheBusterNotifier } from 'ts-cacheable';
import { BasketService } from '../../../../../services/basket.service';

@Component({
  selector: 'app-place',
  templateUrl: './place.component.html',
  styleUrls: ['./place.component.scss'],
  providers: [AddressFinderService],
})
export class PlaceComponent implements OnInit {
  private _places: PlaceViewModel[] = [];
  private _holidays: HolidayViewModel[] = [];

  monday = WeekdaysEnum.MONDAY.toLowerCase();
  tuesday = WeekdaysEnum.TUESDAY.toLowerCase();
  wednsday = WeekdaysEnum.WEDNESDAY.toLowerCase();
  thursday = WeekdaysEnum.THURSDAY.toLowerCase();
  friday = WeekdaysEnum.FRIDAY.toLowerCase();
  saturday = WeekdaysEnum.SATURDAY.toLowerCase();
  sunday = WeekdaysEnum.SUNDAY.toLowerCase();
  _days = [this.monday, this.tuesday, this.wednsday, this.thursday, this.friday, this.saturday, this.sunday];
  midnight = '00:00';
  _tempWorkingHours: any[] = [];

  _deliveryZoneList: DeliveryZoneViewModel[] = [];
  @Input() set deliveryZoneList(value: DeliveryZoneViewModel[]) {
    this._deliveryZoneList = value;
  }

  public displayPosStatusDialog: boolean = false;
  public displayPosStatusDialogPlace: PlaceViewModel;

  constructor(
    private placeService: PlaceService,
    private addressFinderService: AddressFinderService,
    private baseDataProvider: BaseDataProvider,
    private orderService: OrderService,
    public basketService: BasketService,
  ) {}

  @Input() set placesAndHolidays([places, holidays]: [PlaceViewModel[], HolidayViewModel[]]) {
    this._places = places;
    this._holidays = holidays;

    this.getWorkingPlaces();
  }

  ngOnInit(): void {}

  get places(): PlaceViewModel[] {
    return this._places;
  }

  get deliveryZoneList(): DeliveryZoneViewModel[] {
    return this._deliveryZoneList;
  }

  private getWorkingPlaces(): void {
    this._places.forEach((place) => {
      this._days.forEach((day) => {
        place.workingHours.forEach((model) => {
          if (day === model.weekDay) {
            let flag = true;
            this._tempWorkingHours.forEach((tempModel) => {
              if (tempModel.weekDay === model.weekDay) {
                tempModel.workingHourString += ', ' + model.startTime + ' - ' + model.endTime;
                flag = false;
              }
            });
            if (flag) {
              this._tempWorkingHours.push(model);
            }
          }
        });
      });
      place.workingHours = this._tempWorkingHours;
      this._tempWorkingHours = [];
    });
  }

  showMenu(place: PlaceViewModel): void {
    if (sessionStorage.getItem('placeId') && place.publicId != sessionStorage.getItem('placeId')) {
      this.basketService.removeBasket();

      this.orderService.removeDeliveryZoneId();
      this.orderService.removeDeliveryAddressCity();
      this.orderService.removeDeliveryAddressZipCode();
      this.orderService.removeDeliveryAddressStreet();
      this.orderService.removeDeliveryAddressBuildingNo();
      this.orderService.removeDeliveryAddressLocalNo();
    }

    this.placeService.use(place.publicId);

    let isDeliveryZone = false;

    this.deliveryZoneList.forEach((deliveryZone) => {
      if (deliveryZone.placePublicId === sessionStorage.getItem('placeId')) {
        isDeliveryZone = true;
      }
    });

    if (isDeliveryZone) {
      this.addressFinderService.searchDefaultLocal();
    } else {
      this.addressFinderService.searchLocal(place);
    }
  }

  checkPosStatus(place: PlaceViewModel) {
    let isOpen = this.orderService.isPlaceOpen(place, this._holidays);
    if (!isOpen) {
      this.showMenu(place);
      return;
    }

    //busting cache to fetch current place status
    //TODO: what to do in offline mode? skip cache busting?
    globalCacheBusterNotifier.next();
    this.displayPosStatusDialog = false;
    this.displayPosStatusDialogPlace = null;

    this.baseDataProvider.getPlace(place.publicId).subscribe((placeViewModel: PlaceViewModel) => {
      if (placeViewModel.posStatusCheck === true) {
        if (placeViewModel.posStatus == 'PAUSED') {
          setTimeout(() => {
            this.displayPosStatusDialog = true;
            this.displayPosStatusDialogPlace = place;
          }, 1);
        } else {
          this.showMenu(place);
        }
      } else {
        this.showMenu(place);
      }
    });
  }

  closeDialog() {
    this.displayPosStatusDialog = false;
    this.displayPosStatusDialogPlace = null;
  }
}

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AppHelper } from 'src/app/shared/helpers/app-helper';
import { environment } from '@env/environment';
import { OrderDataProvider } from '../data-providers/order.data-provider';
import { OrderPaymentRequestModel } from '../models/order-payment.request.model';
import { PaymentResponseModel } from '../models/response/payment.response.model';
import { OrderStateService } from './order.state.service';
import { PaymentMethodTypeEnum } from '../pages/payment/enum/payment-method-type.enum';

@Injectable({
  providedIn: 'root',
})
export class PaymentService {
  private embeddedContent: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public embeddedContent$: Observable<string>;
  public env = environment;
  private deliveryAddressStatus: boolean;
  private deliveryAddressLabel: string;
  private invoiceStatus: boolean;
  private invoiceLabel: string;

  public readonly creditCard: string = PaymentMethodTypeEnum.CARD;
  public readonly cash: string = PaymentMethodTypeEnum.CASH;
  public readonly other: string = PaymentMethodTypeEnum.ONLINE;

  constructor(private orderStateService: OrderStateService, private orderDataProvider: OrderDataProvider) {
    this.embeddedContent$ = this.embeddedContent.asObservable();
    this.deliveryAddressStatus = false;
    this.invoiceStatus = false;
    this.deliveryAddressLabel = null;
    this.invoiceLabel = null;
  }

  public getPayment(cinemaId: string, orderId: string) {
    return this.orderDataProvider.getPayment(this.createPaymentRequest(cinemaId, orderId)).pipe(
      tap((res: PaymentResponseModel) => {
        this.orderStateService.setEmbededPaymentUrl(res.plainPayload);
        this.setEmbeddedContent(res.plainPayload);
      }),
    );
  }

  public createPaymentRequest(placeId, orderId): OrderPaymentRequestModel {
    const orderPaymentRequest: OrderPaymentRequestModel = new OrderPaymentRequestModel();
    orderPaymentRequest.placeId = placeId;
    orderPaymentRequest.orderId = orderId;
    orderPaymentRequest.paymentChannel = this.env.payment.channel;
    orderPaymentRequest.paymentProviderIdentifier = this.env.payment.provider;

    if (orderPaymentRequest.paymentProviderIdentifier === 'fake') {
      orderPaymentRequest.intPayMethodType = 'card';
      orderPaymentRequest.intPayMethodValue =
        'NDExMTExMTExMTExMTExMTsxMi8yMzsxMjM7YXNkYXNkbm1rb3BuaW9oaGlvaGlvMDs2MDAwMDthc2Q=';
      orderPaymentRequest.paymentProviderIdentifier = 'basys';
    }

    // orderPaymentRequest.paymentData = preInitPaymentEvent.paymentData;
    orderPaymentRequest.continueUrl = AppHelper.generateContinueUrl;

    return orderPaymentRequest;
  }

  public getEmbeddedContentUrl() {
    return this.orderStateService.getEmbededPaymentUrl();
  }
  public setEmbeddedContent(url: string = null) {
    this.embeddedContent.next(url ?? this.getEmbeddedContentUrl());
  }

  /*zmiany Pawel*/

  /*delivery*/
  setDeliveryAddressStatus(value: boolean): void {
    this.deliveryAddressStatus = value;
  }

  getDeliveryAddressStatus(): boolean {
    return this.deliveryAddressStatus;
  }

  deliveryAddressTrigger(): void {
    if (!this.getDeliveryAddressStatus()) {
      this.setDeliveryAddressStatus(true);
    } else {
      this.setDeliveryAddressStatus(false);
    }
  }

  getDeliveryAddressLabel(): string {
    return this.deliveryAddressLabel;
  }

  setDeliveryAddressLabel(value: string): void {
    this.deliveryAddressLabel = value;
  }

  /*invoice*/
  setInvoiceStatus(value: boolean): void {
    this.invoiceStatus = value;
  }

  getInvoiceStatus(): boolean {
    return this.invoiceStatus;
  }

  invoiceTrigger(): void {
    if (!this.getInvoiceStatus()) {
      this.setInvoiceStatus(true);
    } else {
      this.setInvoiceStatus(false);
    }
  }

  getInvoiceLabel(): string {
    return this.invoiceLabel;
  }

  setInvoiceLabel(value: string): void {
    this.invoiceLabel = value;
  }
}

<ng-container *ngIf="displayNoticeDialog">
  <p-dialog
    [(visible)]="displayNoticeDialog"
    [style]="{ 'max-width': '90%', width: '450px' }"
    [draggable]="false"
    [modal]="true"
    [showHeader]="false"
  >
    <div class="row py-5">
      <div class="col text-center">
        <h3>{{ 'tapp-order.abandonedBasket.title' | translate }}</h3>
      </div>
    </div>
    <div class="row">
      <div class="col d-flex justify-content-center">
        <div class="button mx-2" (click)="onContinue()">
          {{ 'tapp-order.abandonedBasket.actions.continue' | translate }}
        </div>
        <div class="button button-white mx-2" (click)="onStartAgain()">
          {{ 'tapp-order.abandonedBasket.actions.startAgain' | translate }}
        </div>
      </div>
    </div>
  </p-dialog>
</ng-container>

import { Exclude, Expose, Type } from 'class-transformer';
import { CalculateDiscountItemResponseApiModel } from './calculate-discount-item-response.api.model';
import { CalculateDiscountNotUsedResponseApiModel } from './calculate-discount-not-used-response.api.model';
import { CalculateDiscountUsedResponseApiModel } from './calculate-discount-used-response.api.model';

@Exclude()
export class CalculateDiscountResponseApiModel {
  @Expose()
  public placeId: string;

  @Expose()
  public onlyForcedDiscount: boolean;

  @Expose()
  public totalItemsPrice: number;

  @Expose()
  public totalItemsDiscount: number;

  @Expose()
  public itemsCount: number;

  @Expose()
  @Type(() => CalculateDiscountItemResponseApiModel)
  public items: CalculateDiscountItemResponseApiModel[];

  @Expose()
  @Type(() => CalculateDiscountUsedResponseApiModel)
  public discounts: CalculateDiscountUsedResponseApiModel[];

  @Expose()
  @Type(() => CalculateDiscountNotUsedResponseApiModel)
  public forcedDiscountsNotUsed: CalculateDiscountNotUsedResponseApiModel[];

  /**
   * @value 'active_used' - The promo code is active and has been successfully applied.
   * @value 'active_not_used' - The promo code is active but was not used because a better promotion was applied.
   * @value 'active_not_used_other_conditions_missed' - The promo code is active but was not used because other conditions were not met.
   * @value 'not_active' - No active promotion for the entered code.
   */
  @Expose()
  public promoCodeStatus:
    | 'active_used'
    | 'active_not_used'
    | 'not_active'
    | 'active_not_used_other_conditions_missed'
    | null;
}

<div class="content">
  <div class="title">
    <div class="title__background-text">
      {{ 'app.pages.terms-and-conditions.title' | translate }}
    </div>
    <h2 class="title__front-text">
      {{ 'app.pages.terms-and-conditions.title' | translate }}
    </h2>
  </div>

  <main class="main">
    <div [innerHTML]="html"></div>
  </main>
</div>

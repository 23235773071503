import { Exclude, Expose, Type } from 'class-transformer';

@Exclude()
export class CalculateDiscountNotUsedResponseApiModel {
  @Expose()
  public id: number;

  @Expose()
  public name: string;
}

<div class="payment-component payment-delivery-address-component" *ngIf="!isLoading">
  <div class="row">
    <ng-container
      *ngIf="
        ((isDeliveryZone?.getValue() && orderService.getOrderType() === deliveryOrderType) || isAccountPage) && !tableId
      "
    >
      <div
        class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 d-flex flex-column justify-content-between mb-4 mb-md-0"
      >
        <div class="row mb-3">
          <div class="col">
            <h2 class="header d-flex align-items-center">
              <span class="payment-delivery-address-title">{{
                'tapp-order.payment.delivery-adress.title' | translate
              }}</span>
            </h2>
          </div>
        </div>
        <div
          [ngClass]="paymentFormService.paymentAddressRejected && !isAccountPage ? 'border-red' : ''"
          class="address"
        >
          <div class="payment-delivery-address-card-full-address">
            <!-- Displayed on "User settings" page -->
            <ng-container *ngIf="context === 'account'">
              <span
                class="payment-delivery-address-card-content"
                *ngIf="user?.deliveryAddress?.city && user?.deliveryAddress?.street; else noData"
              >
                {{ user?.deliveryAddress?.city }},
                {{ user?.deliveryAddress?.street }}
                {{ user?.deliveryAddress?.buildingNo }}
              </span>
            </ng-container>

            <!-- Displayed on other pages -->
            <ng-container *ngIf="context === 'other'">
              <span
                class="payment-delivery-address-card-content"
                *ngIf="orderService.getDeliveryAddressCity() && orderService.getDeliveryAddressStreet(); else noData"
              >
                {{ orderService.getDeliveryAddressCity() }},
                {{ orderService.getDeliveryAddressStreet() }}
                {{ orderService.getDeliveryAddressBuildingNo() }}
              </span>
            </ng-container>

            <!-- Common part -->
            <ng-template #noData>
              <span class="payment-delivery-address-card-content font-italics">
                {{ 'tapp-order.payment.delivery-adress.no-data' | translate }}
              </span>
            </ng-template>
            <span (click)="changeAddress()" class="payment-delivery-address-card-change-button ui-clickable">{{
              'tapp-order.payment.delivery-adress.change-button-label' | translate
            }}</span>
          </div>
          <div class="payment-delivery-address-card-localNo">
            <span class="payment-delivery-address-card-content">
              {{ 'tapp-order.payment.delivery-adress.local-no' | translate }}:
              {{ context === 'account' ? user?.deliveryAddress?.localNo : orderService.getDeliveryAddressLocalNo() }}
            </span>
            <span (click)="changeAddress()" class="payment-delivery-address-card-change-button ui-clickable">{{
              'tapp-order.payment.delivery-adress.change-button-label' | translate
            }}</span>
          </div>
        </div>
      </div>
    </ng-container>

    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 d-flex justify-content-between flex-column">
      <div class="row mb-3">
        <div class="col">
          <h2 class="header d-flex align-items-center">
            <span class="payment-delivery-address-title">{{
              'tapp-order.payment.contact-data.title' | translate
            }}</span>
          </h2>
        </div>
      </div>
      <div class="customer-card-container">
        <div
          class="payment-customer-info-title"
          [ngClass]="paymentFormService.paymentCustomerRejected ? 'border-red' : ''"
        >
          <span
            class="payment-delivery-address-card-content"
            *ngIf="
              (orderService.getCustomerPhone() && orderService.getCustomerFirstName()) ||
              ((orderService.getCustomerPhone ||
                orderService.getCustomerFirstName() ||
                orderService.getCustomerEmail()) &&
                isAccountPage)
            "
          >
            <p class="delivery-customer-name">
              {{ orderService.getCustomerFirstName() }}
            </p>
            <p class="delivery-customer-phone">
              {{ orderService.getCustomerPhone() }}
            </p>
            <p class="delivery-customer-email">
              {{ orderService.getCustomerEmail() }}
            </p>
          </span>
          <span
            class="payment-delivery-address-card-content"
            *ngIf="
              (!orderService.getCustomerPhone() && !orderService.getCustomerFirstName()) ||
              ((!orderService.getCustomerPhone ||
                !orderService.getCustomerFirstName() ||
                !orderService.getCustomerEmail()) &&
                isAccountPage)
            "
          >
            <p class="delivery-customer-unknow">
              {{ 'tapp-order.payment.contact-data.no-data' | translate }}
            </p>
          </span>
          <span (click)="showCustomerForm()" class="payment-delivery-address-card-change-button ui-clickable">{{
            'tapp-order.payment.delivery-adress.change-button-label' | translate
          }}</span>
        </div>
      </div>
    </div>
  </div>

  <app-dialog-address-finder
    [displayDialog]="displayDialog"
    [redirectToPlace]="false"
    [checkCurrentPlace]="!isAccountPage"
    [message1]="
      isAccountPage ? null : ('tapp-order.select-adress-component.dialog.adress-change-warning-2' | translate)
    "
    [message2]="
      isAccountPage ? null : ('tapp-order.select-adress-component.dialog.adress-change-warning-3' | translate)
    "
    (onCloseDialog)="closeDialog()"
    [context]="isAccountPage ? 'account' : 'other'"
  ></app-dialog-address-finder>

  <ng-container *ngIf="displayCustomerForm">
    <p-dialog
      [(visible)]="displayCustomerForm"
      [draggable]="false"
      [modal]="true"
      [showHeader]="false"
      [style]="{ width: '490px', height: '500px' }"
      [resizable]="false"
    >
      <span
        class="dialog-customer-form-title"
        style="display: flex; align-content: center; align-items: center; justify-content: center"
        >{{ 'tapp-order.payment.contact-data.dialog.title' | translate }}</span
      >
      <form [formGroup]="customerForm">
        <div class="form-group">
          <label for="company" class="customer-form">{{
            'tapp-order.payment.contact-data.dialog.form.name.label' | translate
          }}</label>
          <input
            [ngClass]="{ 'invalid-input': customerForm.get('name').invalid && this.customerForm.get('name').touched }"
            type="text"
            formControlName="name"
            appInput
            pInputText
            maxlength="50"
            id="name"
            placeholder="{{ 'tapp-order.payment.contact-data.dialog.form.name.placeholder' | translate }}"
            style="min-width: 100%"
          />
        </div>
        <div class="form-group">
          <label for="phone" class="customer-form">{{
            'tapp-order.payment.contact-data.dialog.form.phone.label' | translate
          }}</label>
          <input
            [ngClass]="{ 'invalid-input': customerForm.get('phone').invalid && this.customerForm.get('phone').touched }"
            type="text"
            formControlName="phone"
            appInput
            pInputText
            maxlength="15"
            id="phone"
            placeholder="{{ 'tapp-order.payment.contact-data.dialog.form.phone.placeholder' | translate }}"
            style="min-width: 100%"
          />
        </div>
        <div class="form-group">
          <label for="email" class="customer-form">{{
            'tapp-order.payment.contact-data.dialog.form.email.label' | translate
          }}</label>
          <input
            [ngClass]="{ 'invalid-input': customerForm.get('email').invalid && this.customerForm.get('email').touched }"
            type="text"
            formControlName="email"
            appInput
            pInputText
            maxlength="100"
            id="email"
            placeholder="{{ 'tapp-order.payment.contact-data.dialog.form.email.placeholder' | translate }}"
            style="min-width: 100%"
          />
        </div>
      </form>
      <div class="buttons-container">
        <button class="save-button ui-clickable" (click)="saveCustomerInfo()">
          {{ 'tapp-order.payment.contact-data.dialog.form.save-button' | translate }}
        </button>
        <button class="cancel-button ui-clickable" (click)="cancelCustomerForm()">
          {{ 'tapp-order.payment.contact-data.dialog.form.cancel-button' | translate }}
        </button>
      </div>
    </p-dialog>
  </ng-container>
</div>

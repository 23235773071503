import { Component, NgZone, OnInit } from '@angular/core';
import { environment } from '@env/environment';
import { SocialMediaEnum } from '@core/services/enums/SocialMediaTypeEnum';
import { AuthService } from '@core/services/auth.service';
import { MatDialogRef } from '@angular/material/dialog';
import { LoginModalComponent } from '@shared/login-modal/login-modal.component';

declare const google: any;

@Component({
  selector: 'app-google-login-button',
  templateUrl: './google-login-button.component.html',
  styleUrls: ['./google-login-button.component.scss'],
})
export class GoogleLoginButtonComponent implements OnInit {
  constructor(
    private ngZone: NgZone,
    private authService: AuthService,
    public dialogRef: MatDialogRef<LoginModalComponent>,
  ) {}

  ngOnInit(): void {}

  loadGoogleAuth(event): void {
    event.preventDefault();
    const client = google.accounts.oauth2.initTokenClient({
      client_id: environment.googleClientID,
      callback: this.handleGoogleSignIn.bind(this),
      scope: 'profile email',
    });

    client.requestAccessToken();
  }

  async handleGoogleSignIn(response: any): Promise<void> {
    this.ngZone.run(() => {
      if (response.error) {
        console.error('Google Sign-In Error:', response.error);
      } else {
        const accessToken = response.access_token;
        this.authService.login(null, true, SocialMediaEnum.google, accessToken).subscribe(
          () => {
            this.dialogRef.close();
          },
          (e) => {
            console.log(e);
          },
          () => {},
        );
      }
    });
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { FbItemApiModel } from '../models/api-model/order/fb-item/fb-item.api.model';
import { OrderApiModel } from '../models/api-model/order/order.api.model';
import { OrderHttpService } from './order.http.service';

@Injectable({
  providedIn: 'root',
})
export class OrderFbItemHttpService {
  constructor(private http: HttpClient) {}

  private static cacheModify(key: string, responseData: Object): void {
    OrderHttpService.cacheModifier$.next((data: any[]) => {
      const oldCacheRow = data.find((p) => p.parameters[1] === key);

      if (!oldCacheRow) {
        return;
      }

      Object.assign(oldCacheRow.response, {
        ...responseData,
      });

      return data;
    });
  }

  patchItem(cinemaId: string, orderId: string, basketItemId: string, item: FbItemApiModel): Observable<OrderApiModel> {
    return this.http
      .patch<OrderApiModel>(`/cinema/${cinemaId}/order/${orderId}/fbitem/${basketItemId}`, item)
      .pipe(tap((res) => OrderFbItemHttpService.cacheModify(orderId, res)));
  }

  patchQuantityItem(
    cinemaId: string,
    orderId: string,
    basketItemId: string,
    quantity: number,
  ): Observable<OrderApiModel> {
    return this.http
      .patch<OrderApiModel>(`/cinema/${cinemaId}/order/${orderId}/fbitem/${basketItemId}/quantity`, { quantity })
      .pipe(tap((res) => OrderFbItemHttpService.cacheModify(orderId, res)));
  }

  put(cinemaId: string, orderId: string, items: Array<FbItemApiModel>): Observable<OrderApiModel> {
    return this.http
      .put<OrderApiModel>(`/cinema/${cinemaId}/order/${orderId}/fbitem`, items)
      .pipe(tap((res) => OrderFbItemHttpService.cacheModify(orderId, res)));
  }

  post(cinemaId: string, orderId: string, item: FbItemApiModel): Observable<OrderApiModel> {
    return this.http
      .post<OrderApiModel>(`/cinema/${cinemaId}/order/${orderId}/fbitem`, item)
      .pipe(tap((res) => OrderFbItemHttpService.cacheModify(orderId, res)));
  }

  delete(cinemaId: string, orderId: string, basketItemId: string): Observable<OrderApiModel> {
    return this.http
      .delete<OrderApiModel>(`/cinema/${cinemaId}/order/${orderId}/fbitem/${basketItemId}`)
      .pipe(tap((res) => OrderFbItemHttpService.cacheModify(orderId, res)));
  }
}

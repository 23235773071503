import { Component, OnInit } from '@angular/core';

import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-discount-delivery-type-info',
  templateUrl: './discount-delivery-type-info.component.html',
  styleUrls: ['./discount-delivery-type-info.component.scss'],
})
export class DiscountDeliveryTypeInfoComponent implements OnInit {
  constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig) {}

  ngOnInit(): void {}

  close() {
    if (this.ref) {
      this.ref.close();
    }
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { MenuApiModel } from '../../../models/tapp-order/api-model/menu/menu.api.model';
import { plainToClass, plainToClassFromExist } from 'class-transformer';
import { map } from 'rxjs/operators';
import { Cacheable, LocalStorageStrategy } from 'ts-cacheable';

const menuCacheBuster$ = new Subject<void>();

@Injectable({
  providedIn: 'root',
})
export class MenuHttpService {
  constructor(private http: HttpClient) {}

  @Cacheable({
    storageStrategy: LocalStorageStrategy,
    cacheBusterObserver: menuCacheBuster$,
  })
  getMenu(publicId): Observable<MenuApiModel> {
    return this.http
      .get<MenuApiModel>('/menu/' + publicId)
      .pipe(map((result) => plainToClass(MenuApiModel, result as object)));
  }
}

import { GroupDiscountApiModel } from '@api/model/discount/group-discount.api.model';
import { AbstractViewModel } from '@core/models/abstract.view.model';
import { ProductViewModel } from '@core/models/tapp-order/view-model/product/product.view.model';

export class GroupDiscountViewModel extends AbstractViewModel<GroupDiscountApiModel> {
  public value: number = null;
  public originalExternalGroupId: number = null;
  public products: ProductViewModel[] = [];

  public constructor(protected apiModel: GroupDiscountApiModel = new GroupDiscountApiModel()) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.value = this.apiModel.value || null;
    this.originalExternalGroupId = this.apiModel.originalExternalGroupId || null;
    this.products = this.apiModel.products ? this.apiModel.products.map((value) => new ProductViewModel(value)) : [];
  }

  public apply(value: any): GroupDiscountViewModel {
    Object.assign(this, value);
    return this;
  }

  toApiModel(): GroupDiscountApiModel {
    return Object.assign(this.apiModel, {
      value: this.value,
      products: this.products.map((i) => i.toApiModel()),
    });
  }
}

import { Exclude, Expose, Type } from 'class-transformer';
import { CalculateDiscountModifierResponseApiModel } from './calculate-discount-modifier-response.api.model';

@Exclude()
export class CalculateDiscountItemResponseApiModel {
  @Expose()
  public internalId: string;

  @Expose()
  public sizeId: number;

  @Expose()
  public doughId: number;

  @Expose()
  public discountId: number;

  @Expose()
  public productId: number;

  @Expose()
  public groupId: number;

  @Expose()
  public quantity: number;

  @Expose()
  public baseUnitPrice: number;

  @Expose()
  public baseUnitDiscount: number;

  @Expose()
  public id: number;

  @Expose()
  public unitPrice: number;

  @Expose()
  public unitDiscount: number;

  @Expose()
  public totalPrice: number;

  @Expose()
  public totalDiscount: number;

  @Expose()
  @Type(() => CalculateDiscountModifierResponseApiModel)
  public modifiers: CalculateDiscountModifierResponseApiModel[];

  @Expose()
  @Type(() => CalculateDiscountModifierResponseApiModel)
  public additionalIngredients: CalculateDiscountModifierResponseApiModel[];
}

<div class="payment-component payment-approve-component">
  <div class="row mb-3">
    <div class="col">
      <h2
        class="header"
        style="
          font-style: normal;
          font-weight: 500;
          font-size: 1rem;
          color: var(--font-primary);
          text-transform: uppercase;
        "
      >
        {{ 'tapp-order.payment.approve.title' | translate }}
      </h2>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <span class="clickable-text" (click)="showTermsAndCoditions()"
        >{{ 'tapp-order.payment.approve.privacy-policy' | translate }}
      </span>
    </div>
  </div>
  <div class="row">
    <ng-container *ngFor="let item of termsAndConditions$ | async">
      <div
        style="
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          align-content: center;
          flex-direction: row;
          padding: 16px;
        "
        class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12"
        [ngClass]="item.required && paymentFormService.paymentApproveRejected ? 'border-red' : ''"
      >
        <div class="d-flex">
          <p-checkbox
            (onChange)="sendSelectApprove(item)"
            [name]="item.content"
            [value]="item.id"
            [(ngModel)]="item.selected"
            [ngClass]="'checkbox'"
          ></p-checkbox>
          <label class="p-checkbox-label" [innerHTML]="item.content"></label>
        </div>
        <ng-container *ngIf="item.required">
          <span
            style="
              position: absolute;
              bottom: 0;
              right: 10px;
              font-weight: 400;
              font-size: 10px;
              line-height: 15px;
              color: var(--red);
            "
            >{{ 'tapp-order.payment.approve.regulations-acceptance-mandatory' | translate }}</span
          >
        </ng-container>
      </div>
    </ng-container>
  </div>
</div>

<app-dialog [(visible)]="termsVisible" [closable]="true" (onHide)="handleTermsHide()">
  <ng-template #header>
    <div class="dialog-terms__header">
      <span>{{ 'tapp-order.payment.approve.privacy-policy' | translate }}</span>
    </div>
  </ng-template>

  <div class="dialog-terms__content">
    <div [innerHTML]="html"></div>
  </div>
</app-dialog>

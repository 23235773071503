import { CreateOrderInvoiceRequestApiModel } from '@core/models/tapp-order/api-model/order/create-order-invoice-request.api.model';
import { Exclude, Expose, Type } from 'class-transformer';
import { CreateOrderItemRequestApiModel } from './create-order-item-request.api.model';
import { OrderDeliveryAdressApiModel } from './order-delivery-adress.api.model';

@Exclude()
export class CreateOrderRequestApiModel {
  @Expose()
  public customerName?: string;

  @Expose()
  public customerEmail?: string;

  @Expose()
  public customerPhone?: string;

  @Expose()
  public notes?: string;

  @Expose()
  public type: string;

  @Expose()
  public selectedPaymentMethod: string;

  @Expose()
  public selectedPaymentProvider: string;

  @Expose()
  public onlinePaymentMethodType: string;

  @Expose()
  public onlinePaymentMethodValue: string;

  @Expose()
  public paymentContinueUrl: string;

  @Expose()
  public paymentCancelUrl: string = null;

  @Expose()
  public placePublicId: string;

  @Expose()
  public tablePublicId?: string;

  @Expose()
  public deliveryAddress?: OrderDeliveryAdressApiModel;

  @Expose()
  public deliveryTime?: string;

  @Expose()
  public pickupTime?: string;

  @Expose()
  @Type(() => CreateOrderItemRequestApiModel)
  public orderItems: CreateOrderItemRequestApiModel[];

  @Expose()
  public promoCode: string | null;

  @Expose()
  @Type(() => CreateOrderInvoiceRequestApiModel)
  public invoice: CreateOrderInvoiceRequestApiModel;

  @Expose()
  public agreementIds?: number[];

  /**
   * Token for push notifications
   */
  @Expose()
  public deviceToken: string | null;
}

import { CardTypeItemMembershipPriceApiModel } from './card-type-item-membership-price.api.model';

export class CardTypeItemApiModel {
  id: string;

  itemId: string;

  typeId: string;

  templateId: string;

  chargeValue: number;

  email: string;

  message: string;

  promotionId: string;

  promotionName: string;

  name: string;

  quantity: number;

  taxRate: number;

  itemPrice: number;

  cardId: string;

  itemDefaultPriceLevelPrice: number;

  itemEarnedPoints: number;

  itemRedemptionPoints: number;

  membershipPrices: CardTypeItemMembershipPriceApiModel[] = [];
}

import { AbstractViewModel } from '../../../abstract.view.model';
import { ImageApiModel } from '../../api-model/product/image.api.model';

export class ImageViewModel extends AbstractViewModel<ImageApiModel> {
  public url: string;

  constructor(protected apiModel: ImageApiModel = new ImageApiModel()) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.url = this.apiModel.url;
  }

  toApiModel(): ImageApiModel {
    return undefined;
  }
}

import { Injectable } from '@angular/core';
import { DeliveryZonesHttpService } from '@core/http/tapp-order/delivery-zones/delivery-zones.http.service';
import { PlaceService } from './place.service';

@Injectable({
  providedIn: 'root',
})
export class DeliveryZoneService {
  constructor(private placeService: PlaceService, private deliveryZonesHttpService: DeliveryZonesHttpService) {
    this.isDeliveryZone();
  }

  _isDeliveryZone: boolean;

  isDeliveryZone(): void {
    this.placeService.getPlace().subscribe((place) => {
      this.deliveryZonesHttpService.getDeliveryZones(sessionStorage.getItem('placeId')).subscribe((deliveryZones) => {
        this._isDeliveryZone = !!deliveryZones.length;
      });
    });
  }
}

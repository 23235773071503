<div class="item-container">
  <div class="d-md-block d-none">
    <div class="item-container-top d-flex align-content-center align-items-center justify-content-between flex-wrap">
      <div class="d-flex align-content-center align-items-center justify-content-between flex-wrap">
        <span style="padding-right: 10px">
          {{ order.createdAt | date: 'dd.MM yyyy' }}
        </span>
        <span style="padding-right: 30px"> g. {{ order.createdAt | date: 'HH:mm':timezone }} </span>
        <div class="delivery-type d-flex align-content-center align-items-center">
          <ng-container [ngSwitch]="order.type">
            <div *ngSwitchCase="OrderTypeEnum.pick_up">
              <i class="ic ic-take-away"></i>
              <span style="font-weight: 500; text-transform: uppercase">{{
                'tapp-order.pages.logged-in-user.components.order-item.pick-up' | translate
              }}</span
              ><span *ngIf="order.pickupTime">: {{ order.pickupTime | date: 'HH:mm':timezone }}</span>
            </div>
            <div *ngSwitchCase="OrderTypeEnum.delivery">
              <i class="ic ic-delivery"></i>
              <span style="font-weight: 500; text-transform: uppercase">{{
                'tapp-order.pages.logged-in-user.components.order-item.delivery' | translate
              }}</span
              ><span *ngIf="order.pickupTime">: {{ order.deliveryTime | date: 'HH:mm':timezone }}</span>
            </div>
            <div *ngSwitchDefault></div>
          </ng-container>
        </div>
      </div>
      <div class="d-flex align-content-center align-items-center justify-content-between flex-wrap">
        <div class="status" style="margin-right: 15px">
          <ng-container [ngSwitch]="order.status">
            <div *ngSwitchCase="OrderStatusEnum.finished">
              <span [class]="OrderStatusEnum.finished">{{
                'tapp-order.pages.logged-in-user.components.order-item.finished' | translate
              }}</span>
            </div>
            <div *ngSwitchCase="OrderStatusEnum.accepted">
              <span [class]="OrderStatusEnum.accepted">{{
                'tapp-order.pages.logged-in-user.components.order-item.accepted' | translate
              }}</span>
            </div>
            <div *ngSwitchCase="OrderStatusEnum.canceled">
              <span [class]="OrderStatusEnum.canceled">{{
                'tapp-order.pages.logged-in-user.components.order-item.canceled' | translate
              }}</span>
            </div>
            <div *ngSwitchCase="OrderStatusEnum.new">
              <span [class]="OrderStatusEnum.new">{{
                'tapp-order.pages.logged-in-user.components.order-item.new' | translate
              }}</span>
            </div>
            <div *ngSwitchCase="OrderStatusEnum.in_delivery">
              <span [class]="OrderStatusEnum.new">{{
                'tapp-order.pages.logged-in-user.components.order-item.delivery' | translate
              }}</span>
            </div>
          </ng-container>
        </div>
        <span class="place-address-prefix" style="padding-right: 5px"
          >{{ 'tapp-order.pages.logged-in-user.components.order-item.local' | translate }}: </span
        ><span class="text-center"
          >{{ order.place.city + ' ' + order.place.street + ' ' + order.place.buildingNo
          }}{{ order.place.localNo ? '/' + order.place.localNo : '' }}</span
        >
      </div>
    </div>
    <div class="item-container-bottom">
      <div class="d-flex align-items-center">
        <div class="d-flex flex-column w-100">
          <div class="d-flex">
            <span class="item-amount w-100"
              >{{ order.orderItems.length }}
              {{ 'tapp-order.pages.logged-in-user.components.order-item.positions' | translate }}</span
            >
          </div>
        </div>
      </div>
      <div class="d-flex align-items-center">
        <div class="w-100 d-flex flex-wrap align-items-center align-content-center justify-content-between">
          <div class="d-flex flex-wrap align-items-center align-content-center justify-content-between mb-2 mr-3">
            <span class="order-price"
              >{{ 'tapp-order.pages.logged-in-user.components.order-item.summary' | translate }}:
              {{ order?.orderPayment.totalPrice | currency: 'PLN':'zł':'1.2-2':'pl-PL' }}</span
            >
            <div>
              <div
                *ngIf="order.status === OrderStatusEnum.finished && order.hasSurveyToComplete"
                class="ui-clickable ml-5"
                style="font-size: 0.75rem; color: #149ee9; font-weight: 500; line-height: 0.96875rem"
                (click)="openSurveyModal(order)"
                >{{ 'tapp-order.pages.logged-in-user.components.order-item.rate-order' | translate }}</div
              >
            </div>
          </div>

          <div class="buttons-wrapper mb-2">
            <div (click)="openDialog()" class="show-order-btn ui-clickable" style="margin-right: 17px">
              <span>{{ 'tapp-order.pages.logged-in-user.components.order-item.view-order' | translate }}</span>
            </div>
            <div (click)="repeatOrder()" class="repeat-order-btn ui-clickable">
              <span>{{ 'tapp-order.pages.logged-in-user.components.order-item.repeat' | translate }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row item-container">
  <div class="col d-md-none item-container-top item-container-bottom">
    <div class="d-flex justify-content-between align-items-center" style="padding: 13px 0 10px 0">
      <div class="d-flex align-items-center">
        <div>
          <span style="padding-right: 10px">
            {{ order.createdAt | date: 'dd.MM yyyy' }}
          </span>
          <span style="padding-right: 30px"> g. {{ order.createdAt | date: 'HH:mm' }} </span>
        </div>
      </div>
      <div class="d-flex align-items-center">
        <div class="status">
          <ng-container [ngSwitch]="order.status">
            <div *ngSwitchCase="OrderStatusEnum.finished">
              <span [class]="OrderStatusEnum.finished">{{
                'tapp-order.pages.logged-in-user.components.order-item.finished' | translate
              }}</span>
            </div>
            <div *ngSwitchCase="OrderStatusEnum.accepted">
              <span [class]="OrderStatusEnum.accepted">{{
                'tapp-order.pages.logged-in-user.components.order-item.accepted' | translate
              }}</span>
            </div>
            <div *ngSwitchCase="OrderStatusEnum.canceled">
              <span [class]="OrderStatusEnum.canceled">{{
                'tapp-order.pages.logged-in-user.components.order-item.canceled' | translate
              }}</span>
            </div>
            <div *ngSwitchCase="OrderStatusEnum.new">
              <span [class]="OrderStatusEnum.new">{{
                'tapp-order.pages.logged-in-user.components.order-item.new' | translate
              }}</span>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col justify-content-between d-flex">
        <ng-container [ngSwitch]="order.type">
          <div *ngSwitchCase="OrderTypeEnum.pick_up">
            <img src="../../../../../../assets/icons/ic-take-away.png" alt="" style="padding-right: 10px" />
            <span style="font-weight: 500"
              >{{ 'tapp-order.pages.logged-in-user.components.order-item.pick-up' | translate }} : </span
            ><span>{{ order.pickupTime | date: 'HH:mm' }}</span>
          </div>
          <div *ngSwitchCase="OrderTypeEnum.delivery">
            <img src="../../../../../../assets/icons/ic-delivery.png" alt="" style="padding-right: 10px" />
            <span style="font-weight: 500"
              >{{ 'tapp-order.pages.logged-in-user.components.order-item.delivery' | translate }} : </span
            ><span>{{ order.deliveryTime | date: 'HH:mm' }}</span>
          </div>
          <div *ngSwitchDefault></div>
        </ng-container>
        <div
          *ngIf="order.status === OrderStatusEnum.finished && order.hasSurveyToComplete"
          class="ui-clickable"
          style="font-size: 0.75rem; color: #149ee9; font-weight: 500; line-height: 0.96875rem"
          (click)="openSurveyModal(order)"
          >{{ 'tapp-order.pages.logged-in-user.components.order-item.rate-order' | translate }}</div
        >
      </div>
    </div>
    <div class="row" style="padding-bottom: 15px; border-bottom: #e0e0e0 1px solid">
      <div class="col">
        <span class="place-address-prefix" style="padding-right: 5px; font-weight: 500"
          >{{ 'tapp-order.pages.logged-in-user.components.order-item.local' | translate }} : </span
        ><span
          >{{ order.place.city + ' ' + order.place.street + ' ' + order.place.buildingNo
          }}{{ order.place.localNo ? '/' + order.place.localNo : '' }}</span
        >
      </div>
    </div>
    <div class="row" style="padding-top: 19px">
      <div class="col-12 d-flex align-items-center">
        <div class="d-flex flex-column">
          <span class="item-amount"
            >{{ order.orderItems.length }}
            {{ 'tapp-order.pages.logged-in-user.components.order-item.positions' | translate }}</span
          >
          <span class="order-price"
            >{{ 'tapp-order.pages.logged-in-user.components.order-item.summary' | translate }} :
            {{ order?.orderPayment.totalPrice | currency: 'PLN':'zł':'1.2-2':'pl-PL' }}</span
          >
        </div>
      </div>
    </div>
    <div class="row" style="padding: 40px 0 19px 0">
      <div class="col-12 d-flex align-items-center">
        <div class="d-flex w-100">
          <div (click)="openDialog()" class="show-order-btn ui-clickable" style="margin-right: 17px">
            <span>{{ 'tapp-order.pages.logged-in-user.components.order-item.view-order' | translate }}</span>
          </div>
          <div (click)="repeatOrder()" class="repeat-order-btn ui-clickable">
            <span>{{ 'tapp-order.pages.logged-in-user.components.order-item.repeat' | translate }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

import { Inject, Injectable, Optional } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { LoaderState } from './model/loader-state.model';
import { ILoadingConfig, LoadingConfig } from './model/loading-config';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  private loaderState: BehaviorSubject<LoaderState> = new BehaviorSubject<LoaderState>(null);
  public loaderState$: Observable<LoaderState>;

  public loadingConfig: ILoadingConfig;

  constructor(@Optional() @Inject('loadingConfig') private config: ILoadingConfig) {
    this.loadingConfig = this.config || new LoadingConfig();
    this.loaderState$ = this.loaderState.asObservable();
  }

  public showLoader(id = '') {
    this.loaderState.next(new LoaderState(id, true));
  }

  public hideLoader(id = '') {
    this.loaderState.next(new LoaderState(id, false));
  }
}

import { Injectable } from '@angular/core';
import { BasketItemViewModel } from '@core/models/tapp-order/view-model/basketItem/basket-item.view.model';
import { ModifierViewModel } from '@core/models/tapp-order/view-model/product/modifier.view.model';
import { ProductViewModel } from '@core/models/tapp-order/view-model/product/product.view.model';
import { ModifierTypeEnum } from '@shared/enum/modifier-type.enum';
import { ProductTypeEnum } from '@shared/enum/product-type.enum';
import * as _ from 'lodash';
import { cloneDeep } from 'lodash';
import { PlaceService } from './place.service';

interface IPizzaDoughFilter {
  availablePizzaDoughIds?: (number | string)[];
}

@Injectable()
export class ProductService {
  private _basketItem: BasketItemViewModel;
  private _product: ProductViewModel;

  private _selectedPizzaSize: ProductViewModel;
  private _selectedPizzaDough: ProductViewModel;
  private _pizzaDoughFilter: IPizzaDoughFilter = {};

  private hideMaxSize = false;

  public dividedPizza: boolean = false;

  constructor(placeService: PlaceService) {
    placeService.getPlace().subscribe((place) => {
      if (sessionStorage.getItem('api_subdomain') && placeService.getPlaceId()) {
        let apiSubdomain = sessionStorage.getItem('api_subdomain');
        if (apiSubdomain.includes('tapporderlhbpvqennm') && place.city == 'Bolesławiec') {
          this.hideMaxSize = true;
        }
      }
    });
  }

  set product(product: ProductViewModel) {
    this._product = product;
    this._product.CurrentQuantity = this.dividedPizza ? 0.5 : 1;
    this._basketItem = Object.assign(new BasketItemViewModel(), product);
  }

  public switchToHalfPizza() {
    this.dividedPizza = true;
    if (this.product) {
      this.product = this.product;
    }
  }

  public switchToFullPizza() {
    this.dividedPizza = false;
    if (this.product) {
      this.product = this.product;
    }
  }

  set basketItem(basketItem: BasketItemViewModel) {
    this._product = basketItem;
    //TODO: map selected modifiers to product modifiers
    //this.basketItem?.SelectedModifiers;
  }

  get product(): ProductViewModel {
    return this._product;
  }

  get basketItem(): BasketItemViewModel {
    let configuredModifiers = this.getAllConfiguredModifiers(this._product.modifiers);

    this._basketItem.SelectedModifiers = configuredModifiers[0];
    this._basketItem.unselectedModifiers = configuredModifiers[1];

    return this._basketItem;
  }

  get pizzaSizes(): ProductViewModel[] {
    let pizzaSizes = this._product?.modifiers.find(
      (modifier: ModifierViewModel) => modifier.modifierType == ModifierTypeEnum.PIZZA_SIZE,
    )?.products;

    if (this.hideMaxSize) {
      pizzaSizes = pizzaSizes.filter((size) => size.name != 'max');
    }

    pizzaSizes = pizzaSizes.filter((size) =>
      size.modifiers.find((sizeModifiers) => sizeModifiers.modifierType == ModifierTypeEnum.PIZZA_DOUGH),
    );

    return pizzaSizes;
  }

  get selectedPizzaSize(): ProductViewModel {
    if (!this._selectedPizzaSize && this.pizzaSizes) {
      this.selectedPizzaSize = this.pizzaSizes[0];
    }

    return this._selectedPizzaSize;
  }

  set selectedPizzaSize(pizzaSize: ProductViewModel) {
    if (this._selectedPizzaSize) {
      this._selectedPizzaSize.CurrentQuantity = 0;
      this._selectedPizzaSize.isChecked = false;
    }

    pizzaSize.CurrentQuantity = 1;
    pizzaSize.isChecked = false;

    this._selectedPizzaSize = pizzaSize;
  }

  get selectedPizzaDough(): ProductViewModel {
    if (!this._selectedPizzaDough) {
      this.selectedPizzaDough = this.pizzaDoughList[0];
    }

    return this._selectedPizzaDough;
  }

  set selectedPizzaDough(pizzaDough: ProductViewModel) {
    if (this._selectedPizzaDough) {
      this._selectedPizzaDough.CurrentQuantity = 0;
      this._selectedPizzaDough.isChecked = false;
    }

    if (pizzaDough) {
      pizzaDough.CurrentQuantity = 1;
      pizzaDough.isChecked = false;

      this._selectedPizzaDough = pizzaDough;
    } else {
      this.selectedPizzaDough = this.pizzaDoughList[0];
    }
  }

  get isPizza(): boolean {
    return this._product.productType === ProductTypeEnum.PRODUCT_PIZZA;
  }

  get pizzaDoughList(): ProductViewModel[] {
    let doughList = this.selectedPizzaSize?.modifiers.find(
      (modifier: ModifierViewModel) => modifier.modifierType == ModifierTypeEnum.PIZZA_DOUGH,
    )?.products;

    if (this._pizzaDoughFilter.availablePizzaDoughIds?.length) {
      doughList = doughList.filter((dough) =>
        this._pizzaDoughFilter.availablePizzaDoughIds.some(
          (element) => !dough.originalExternalDoughId || dough.originalExternalDoughId?.includes(element.toString()),
        ),
      );
    }

    return doughList;
  }

  get finalPrice(): number {
    if (this.dividedPizza) {
      console.error('When pizza is divided, for calculating price use another method');
      return;
    }

    this.compensationIngredient();

    return (
      this.calculatePizzaWithouModifiersPrice() +
      this.calculateTotalPizzaPriceOfModifiers(
        this.basketItem.SelectedModifiers,
        this.product.baseAdditionSwitchAllowed,
      )
    );
  }

  get pizzaIngredientsString(): string {
    let pizzaIngredients: string[] = [];

    this.pizzaIngredients.forEach((modifierGroup) => {
      modifierGroup.products.forEach((modifierProduct) => {
        if (modifierProduct.CurrentQuantity > 0) {
          pizzaIngredients.push(
            modifierProduct.CurrentQuantity > 1
              ? modifierProduct.CurrentQuantity +
                  'x ' +
                  modifierProduct.name.replace('...', '').replace('..', '').replace('.', '')
              : modifierProduct.name.replace('...', '').replace('..', '').replace('.', ''),
          );
        }
      });
    });

    return pizzaIngredients.join(', ');
  }

  //get sorted pizza ingredients
  get pizzaIngredients(): ModifierViewModel[] {
    let pizzaIngredients: ModifierViewModel[] = [];

    let freeIngredients = this.selectedPizzaSize.modifiers
      .filter((modifier: ModifierViewModel) => modifier.modifierType === ModifierTypeEnum.PIZZA_FREE_INGREDIENT)
      .map((modifier: ModifierViewModel) => {
        modifier.products = _.sortBy(modifier.products, 'name');

        return modifier;
      });

    freeIngredients = _.sortBy(freeIngredients, 'name');

    let paidIngredients = this.selectedPizzaSize.modifiers
      .filter((modifier: ModifierViewModel) => modifier.modifierType === ModifierTypeEnum.PIZZA_PAID_INGREDIENT)
      .map((modifier: ModifierViewModel) => {
        modifier.products = _.sortBy(modifier.products, 'name');

        return modifier;
      });

    paidIngredients = _.sortBy(paidIngredients, 'name');

    pizzaIngredients = [...pizzaIngredients, ...freeIngredients, ...paidIngredients];

    return pizzaIngredients;
  }

  //get sorted pizza ingredients
  get modifiers(): ModifierViewModel[] {
    if (this.isPizza) {
      return this.selectedPizzaSize.modifiers.filter(
        (modifier: ModifierViewModel) =>
          modifier.modifierType !== ModifierTypeEnum.PIZZA_DOUGH &&
          modifier.modifierType !== ModifierTypeEnum.PIZZA_PAID_INGREDIENT &&
          modifier.modifierType !== ModifierTypeEnum.PIZZA_FREE_INGREDIENT,
      );
    } else {
      return this.product.modifiers;
    }
  }

  public initPizzaDough() {
    this.selectedPizzaDough = this.pizzaDoughList[0];
  }

  public updatePizzaDoughFilter(opts: IPizzaDoughFilter): void {
    this._pizzaDoughFilter = {
      ...this._pizzaDoughFilter,
      ...opts,
    };
  }

  public getPizzaSizePrice(pizzaSize: ProductViewModel = this.selectedPizzaSize): number {
    const price = (this._product.price + pizzaSize.price) * this._product.CurrentQuantity;
    const formattedPrice = parseFloat(price.toFixed(2));
    return formattedPrice;
  }

  public getPizzaDoughPrice(pizzaDough: ProductViewModel = this.selectedPizzaDough): number {
    const price = pizzaDough.price * this._product.CurrentQuantity;
    const formattedPrice = parseFloat(price.toFixed(2));
    return formattedPrice;
  }

  public getPizzaPaidIngredientsPrice(): number {
    let totalPrice: number = 0;

    this.pizzaIngredients.forEach((modifierGroup) => {
      modifierGroup.products.forEach((modifierProduct) => {
        if (modifierProduct.modifierType === ModifierTypeEnum.PIZZA_PAID_INGREDIENT) {
          if (modifierProduct.CurrentQuantity > 0) {
            totalPrice += modifierProduct.totalPrice * this._product.CurrentQuantity;
          }
        }
      });
    });

    const formattedPrice = parseFloat(totalPrice.toFixed(2));
    return formattedPrice;
  }

  public getPizzaUnselectedFreeIngredientsPrice(): number {
    let totalPrice: number = 0;

    this.pizzaIngredients.forEach((modifierGroup) => {
      modifierGroup.products.forEach((modifierProduct) => {
        if (modifierProduct.originalExternalPrice) {
          if (modifierProduct.modifierType === ModifierTypeEnum.PIZZA_FREE_INGREDIENT) {
            if (modifierProduct.CurrentQuantity === 0) {
              const originExternaPriceEncoded = modifierProduct.originalExternalPriceEncoded.find((originalSize) => {
                return originalSize.sizeId.toString() === this.selectedPizzaSize.originalExternalSizeId;
              });
              totalPrice += originExternaPriceEncoded.price * this._product.CurrentQuantity;
            }
          }
        }
      });
    });

    const formattedPrice = parseFloat(totalPrice.toFixed(2));
    return formattedPrice;
  }

  public getAdditions(): number {
    return this.modifiers.reduce((accumulator: number, modifierGroup: ModifierViewModel) => {
      return (
        accumulator +
        modifierGroup.products.reduce((accumulator: number, modifierProduct: ProductViewModel) => {
          if (modifierProduct.CurrentQuantity > 0) {
            accumulator += modifierProduct.totalPrice;
          }
          return accumulator;
        }, 0)
      );
    }, 0);
  }

  public onPizzaSizeChange(pizzaSize: ProductViewModel) {
    pizzaSize.modifiers = pizzaSize.modifiers.map((modifierGroup: ModifierViewModel) => {
      modifierGroup.products = modifierGroup.products.map((modifierProduct: ProductViewModel) => {
        let oldModifierProduct = this.selectedPizzaSize.modifiers
          .find((oldModifierGroup) => oldModifierGroup.name === modifierGroup.name)
          ?.products.find(
            (oldModifierProduct) =>
              oldModifierProduct.name === modifierProduct.name &&
              oldModifierProduct.originalExternalId === modifierProduct.originalExternalId,
          );

        if (oldModifierProduct) {
          modifierProduct.CurrentQuantity = oldModifierProduct.CurrentQuantity;
          modifierProduct.isChecked = oldModifierProduct.isChecked;
        }

        return modifierProduct;
      });

      return modifierGroup;
    });

    this.selectedPizzaSize = pizzaSize;

    this.selectedPizzaDough = this.pizzaDoughList.find(
      (pizzaDough: ProductViewModel) =>
        pizzaDough.name == this.selectedPizzaDough.name &&
        pizzaDough.originalExternalDoughId === this.selectedPizzaDough.originalExternalDoughId,
    );
  }

  public setPizzaDoughByName(doughName: string) {
    this.selectedPizzaDough = this.pizzaDoughList.find((pizzaDough: ProductViewModel) => pizzaDough.name == doughName);
  }

  public getAllConfiguredModifiers(
    modifiers: ModifierViewModel[],
    selectedModifiers: ProductViewModel[] = [],
    unselectedModifiers: ProductViewModel[] = [],
  ): ProductViewModel[][] {
    modifiers.forEach((modifier) => {
      modifier.products.forEach((modifierProduct) => {
        if (modifierProduct.CurrentQuantity == 0 && modifierProduct.defaultQuantity > 0) {
          modifierProduct.groupName = modifier.name;
          modifierProduct.groupId = modifier.publicId;
          unselectedModifiers.push(modifierProduct);
        }

        if (modifierProduct.CurrentQuantity > 0) {
          modifierProduct.groupName = modifier.name;
          modifierProduct.groupId = modifier.publicId;
          selectedModifiers.push(modifierProduct);

          if (modifierProduct.hasModifiers) {
            return this.getAllConfiguredModifiers(modifierProduct.modifiers, selectedModifiers, unselectedModifiers);
          }
        }
      });
    });

    return [selectedModifiers, unselectedModifiers];
  }

  public compensationIngredient() {
    if (this.product.baseAdditionSwitchAllowed) {
      let configuredModifiers = this.getAllConfiguredModifiers(this.product.modifiers);

      let unselectedModifiers: ProductViewModel[] = configuredModifiers[1];
      let bufferPrice = 0;
      let index = 0;
      let copySelectedModifier = cloneDeep(this.basketItem.SelectedModifiers);

      if (unselectedModifiers.length > 0) {
        unselectedModifiers.forEach((deletedModifier) => {
          if (deletedModifier.originalExternalPrice) {
            deletedModifier.originalExternalPriceEncoded.forEach((originalSize) => {
              if (this.selectedPizzaSize.originalExternalSizeId == originalSize.sizeId.toString()) {
                bufferPrice += originalSize.price * this._product.CurrentQuantity;
              }
            });
          }
        });
      }

      copySelectedModifier.forEach((ingredient) => {
        if (ingredient.modifierType === ModifierTypeEnum.PIZZA_PAID_INGREDIENT) {
          ingredient.price = ingredient.originalInternalPrice;
          ingredient.totalPrice =
            ingredient.originalInternalPrice * ingredient.CurrentQuantity * this._product.CurrentQuantity;
        }
      });

      this.basketItem.SelectedModifiers.forEach((ingredient) => {
        if (ingredient.modifierType === ModifierTypeEnum.PIZZA_PAID_INGREDIENT) {
          if (bufferPrice > 0) {
            if (bufferPrice >= copySelectedModifier[index].totalPrice) {
              ingredient.totalPrice = 0;
              bufferPrice = bufferPrice - copySelectedModifier[index].totalPrice;
            } else {
              ingredient.totalPrice = copySelectedModifier[index].totalPrice - bufferPrice;
              bufferPrice = 0;
            }
          } else {
            copySelectedModifier.forEach((oldIngredient: ProductViewModel) => {
              if (oldIngredient.publicId == ingredient.publicId) {
                ingredient.totalPrice = oldIngredient.totalPrice;
              }
            });
          }
        }
        index++;
      });
    }
  }

  private calculateTotalPizzaPriceOfModifiers(
    modifiers: ProductViewModel[],
    baseAdditionSwitchAllowed: boolean,
  ): number {
    //nie chciałem pisać to gówno ale z jakiegoś powodu w przypadku
    // kompensacji skłądników totalPrice liczony poprawnie a bez kompensacji niepoprawnie
    if (baseAdditionSwitchAllowed) {
      return modifiers.reduce((accumulator: number, current: ProductViewModel) => accumulator + current.totalPrice, 0);
    }

    return modifiers.reduce(
      (accumulator: number, current: ProductViewModel) => accumulator + current.price * current.CurrentQuantity,
      0,
    );
  }

  public calculatePizzaWithouModifiersPrice(): number {
    return this._basketItem.price;
  }

  public calculatePartPizzaWithouModifiersPrice(): number {
    return this._basketItem.price * 0.5;
  }

  public prepareSelectBasicModifiers(product: ProductViewModel) {
    if (product.productType == ProductTypeEnum.PRODUCT_PIZZA) {
      product.modifiers.forEach((modifierGroup) => {
        modifierGroup.products.forEach((modifierProduct) => {
          modifierProduct.modifiers.forEach((modifierSubgroup) => {
            if (modifierSubgroup.products.length > 0 && modifierSubgroup.modifierType == ModifierTypeEnum.MODIFIER) {
              modifierSubgroup.products[0].defaultQuantity = 1;
              modifierSubgroup.products[0].CurrentQuantity = 1;
            }
          })
        })
      })
    } else {
      product.modifiers.forEach((modifierGroup) => {
        if (modifierGroup.products.length > 0 && modifierGroup.modifierType == ModifierTypeEnum.MODIFIER) {
          modifierGroup.products[0].defaultQuantity = 1;
          modifierGroup.products[0].CurrentQuantity = 1;
        }
      });
    }
  }
}

<div class="app-logged-in-user-page-container" *ngIf="!isLoading">
  <div class="col">
    <div class="row h-100 d-none d-md-flex justify-content-end" #desktop>
      <ng-container *ngIf="!isDisplaySetToNone(desktop)">
        <ng-container *ngTemplateOutlet="sidebar"></ng-container>
        <ng-container *ngTemplateOutlet="outlet"></ng-container>
      </ng-container>
    </div>
    <div class="row h-100 d-flex d-md-none" #mobile>
      <ng-container *ngIf="!isDisplaySetToNone(mobile)">
        <ng-container *ngIf="isOnRootPath; else outlet">
          <ng-container *ngTemplateOutlet="sidebar"></ng-container>
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>

<ng-template #outlet>
  <div class="content-container col-12">
    <router-outlet></router-outlet>
    <div class="row logout d-md-none d-flex">
      <div class="col">
        <span (click)="logout()" class="ui-clickable"
          >{{ 'tapp-order.pages.logged-in-user.logged-in-user.logout' | translate }}
          <i class="ic ic-logout"></i>
        </span>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #sidebar>
  <div class="col-md-4 sidebar col-12" style="height: calc(100vh - (100px + 72px))">
    <div class="row title">
      <div class="col">
        <span>{{ 'tapp-order.pages.logged-in-user.logged-in-user.your-account' | translate }}</span>
      </div>
    </div>
    <div class="row welcome">
      <div class="col">
        <span
          ><span class="primary-color">{{ 'tapp-order.pages.logged-in-user.logged-in-user.welcome' | translate }}</span
          >, {{ this.orderService.getCustomerFirstName() ? this.orderService.getCustomerFirstName() : user.name }}</span
        >

        <!-- https://jira.lsisoftware.pl/browse/OK-400745
          <div class="points">
            <span class="points__text"
              >{{
                "tapp-order.pages.logged-in-user.logged-in-user.your-points"
                  | translate
              }}:
            </span>
            <span class="points__number">{{
              pointsLoading ? "..." : points
            }}</span>
          </div>
        -->
      </div>
    </div>
    <ng-container *ngFor="let route of routes">
      <div class="row route">
        <div class="col d-flex align-items-center">
          <span class="ui-clickable" [routerLink]="[route.route]" [routerLinkActive]="'clicked'">{{ route.name }}</span>
          <i
            class="ic ic-arrow-right-2 img-right-arrow"
            [routerLink]="[route.route]"
            [routerLinkActive]="'img-right-arrow--visible'"
          ></i>
        </div>
      </div>
    </ng-container>
    <div class="row logout d-flex position-absolute">
      <div class="col">
        <span (click)="logout()" class="ui-clickable"
          >{{ 'tapp-order.pages.logged-in-user.logged-in-user.logout' | translate }} <i class="ic ic-logout"></i
        ></span>
      </div>
    </div>
  </div>
</ng-template>

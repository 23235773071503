import { Expose } from 'class-transformer';

@Expose()
export class OrderItemModifierApiModel {
  @Expose()
  public name: string;

  @Expose()
  public quantity: number;

  @Expose()
  public baseUnitPrice: object;

  @Expose()
  public productPublicId: string;

  @Expose()
  public unitPrice: object;

  @Expose()
  public totalPrice: object;

  @Expose()
  public modifierGroupPublicId: string;

  @Expose()
  public modifierType: string;
}

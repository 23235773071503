import { Expose } from 'class-transformer';
import { PaymentMethodEnum } from './PaymentMethodEnum';
import { PaymentProviderEnum } from './PaymentProviderEnum';
import { PaymentStatusEnum } from './PaymentStatusEnum';

@Expose()
export class OrderPaymentApiModel {
  @Expose()
  public createdAt: Date;

  @Expose()
  public totalPrice: number;

  @Expose()
  public foodTotalPrice: number;

  @Expose()
  public packageCost: number;

  @Expose()
  public deliveryCost: number;

  @Expose()
  public otherCost: number;

  @Expose()
  public currency: string;

  @Expose()
  public paymentMethod: PaymentMethodEnum;

  @Expose()
  public paymentProvider: PaymentProviderEnum;

  @Expose()
  public paymentStatus: PaymentStatusEnum;

  @Expose()
  public paymentContinueUrl: string;

  @Expose()
  public providerPaymentUrl: string;

  @Expose()
  public onlinePaymentMethodType: string;
}

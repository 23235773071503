import { Component, OnInit } from '@angular/core';
import { DeviceDetectorService } from '../../services/device-detector.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  public isDesktop: boolean = false;

  constructor(private deviceService: DeviceDetectorService) {
    this.deviceService.isDesktop.subscribe((isDesktop) => {
      this.isDesktop = isDesktop;
    });
  }

  ngOnInit(): void {}
}

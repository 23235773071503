<div class="list-of-places-container">
  <h2 class="title">{{ 'tapp-order.places-component.list.title' | translate }}</h2>
  <ul class="list list-of-places" [ngClass]="cities.length > 25 ? 'column-count-2' : ''">
    <!--    <ng-container *ngFor="let number of [0, 1, 2, 3, 4, 5]">-->
    <ng-container *ngFor="let city of cities">
      <li (click)="place(city)">{{ city }}</li>
    </ng-container>
    <!--    </ng-container>-->
  </ul>
</div>

import { Component, OnInit } from '@angular/core';
import { OrderApiModel } from '@api/model/user/order.api.model';
import { ResultListApiModel } from '@core/models/api-model/result-list.api.model';
import { OrderDataProvider } from '@core/pages/logged-in-user/data-providers/order.data-provider';

@Component({
  selector: 'app-order-history',
  templateUrl: './order-history.component.html',
  styleUrls: ['./order-history.component.scss'],
})
export class OrderHistoryComponent implements OnInit {
  public orders: ResultListApiModel<OrderApiModel>;
  public isLoading: boolean = true;

  constructor(private orderDataProvider: OrderDataProvider) {}

  ngOnInit(): void {
    this.orderDataProvider.getOrders().subscribe((value) => {
      value.then((val) => {
        this.orders = val;
        this.isLoading = false;
      });
    });
  }

  public onScrollEvent(event): void {
    this.orderDataProvider.getOrders(event).subscribe((value) => {
      value.then((val) => {
        this.orders = val;
      });
    });
  }
}

<div class="header header-rollup header-fixed">
  <nav class="navbar navbar-toggleable-md navbar-light bg-faded">
    <app-logo class="navbar-brand"></app-logo>
    <app-hamburger-button></app-hamburger-button>
  </nav>
  <div class="your-order-header">Your Order</div>
</div>

<div fxFlex fxLayout="column" fxLayoutGap="20px" class="container" style="height: 70vh; overflow-y: scroll">
  <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutGap="16px" fxLayoutAlign="flex-start" class="pt-3">
    <div
      [ngClass]="
        paymentMethod.selected == true ? 'payment-method-card payment-method-card-selected' : 'payment-method-card'
      "
      *ngFor="let paymentMethod of paymentMethods"
      (click)="toggleSelected(paymentMethod)"
    >
      {{ paymentMethod.name }}
    </div>
  </div>
</div>

<app-order-summary buttonText="Pay" [buttonLink]="['/order-success']"></app-order-summary>

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { environment } from '@env/environment';
import { Cacheable, LocalStorageStrategy } from 'ts-cacheable';
import { OrderApiModel } from '../models/api-model/order/order.api.model';
import { CreateNewOrderRequest } from '../models/create-new-order-request.model';
import { OrderPaymentRequestModel } from '../models/order-payment.request.model';
import { CateringResponseModel } from '../models/catering.response.model';
import { NutritionalInfoModel } from '../models/nutritional-info.response.model';
import { PaymentMethodResponseModel } from '../models/response/payment-method.response.model';
import { PaymentResponseModel } from '../models/response/payment.response.model';

export class TokenPaymentResponse {
  public jwt: string;
}

@Injectable({
  providedIn: 'root',
})
export class OrderHttpService {
  constructor(private http: HttpClient) {}
  public static cacheBuster$ = new Subject<void>();
  public static cacheModifier$ = new Subject<any>();

  public static cacheModify(key: string, responseData: Object): void {
    OrderHttpService.cacheModifier$.next((data: any[]) => {
      const oldCacheRow = data.find((p) => p.parameters[1] === key);

      if (!oldCacheRow) {
        return;
      }

      Object.assign(oldCacheRow.response, {
        ...responseData,
      });

      return data;
    });
  }

  @Cacheable({
    storageStrategy: LocalStorageStrategy,
    cacheBusterObserver: OrderHttpService.cacheBuster$,
    cacheModifier: OrderHttpService.cacheModifier$,
  })
  public findById(placeId: string, orderId: string): Observable<OrderApiModel> {
    return this.http.get<OrderApiModel>(`/place/${placeId}/order/${orderId}`);
  }

  public create(placeId: string, sourceOrderId: string = null): Observable<OrderApiModel> {
    const createNewOrderRequest: CreateNewOrderRequest = {
      sourceOrderId,
    };

    return this.http
      .post<OrderApiModel>(`/place/${placeId}/order`, createNewOrderRequest)
      .pipe(tap(() => OrderHttpService.cacheBuster$.next()));
  }

  public update(placeId: string, order: OrderApiModel) {
    return this.http
      .put<OrderApiModel>(`/place/${placeId}/order/${order.id}`, order)
      .pipe(tap((res) => OrderHttpService.cacheModify(res.id, res)));
  }

  silentDelete(placeId: string, orderId: string): boolean {
    let apiUrl: string = environment.apiUrl;
    if (apiUrl.endsWith('/') === false) {
      apiUrl = apiUrl + '/';
    }

    const endpoint = `place/${placeId}/order/${orderId}/delete`;
    return navigator.sendBeacon(apiUrl + endpoint);
  }

  public delete(placeId: string, orderId: string) {
    return this.http.delete(`/place/${placeId}/order/${orderId}`).pipe(tap(() => OrderHttpService.cacheBuster$.next()));
  }

  public closeBasket(placeId: string, orderId: string, forcePaymentConfirmed = true) {
    return this.http
      .post(`/place/${placeId}/order/${orderId}/close`, forcePaymentConfirmed ? { paymentConfirmed: true } : null)
      .pipe(tap(() => OrderHttpService.cacheBuster$.next()));
  }

  public getPaymentMethodList(placeId: string, orderId: string) {
    return this.http.get<PaymentMethodResponseModel[]>(`/place/${placeId}/order/${orderId}/payment/method`);
  }

  public getPayment(orderPaymentRequest: OrderPaymentRequestModel) {
    const options: any = {
      params: {},
    };

    if (orderPaymentRequest.paymentChannel) {
      options.params.channel = orderPaymentRequest.paymentChannel;
    }

    if (orderPaymentRequest.continueUrl) {
      options.params.continueUrl = orderPaymentRequest.continueUrl;
    }

    if (orderPaymentRequest.paymentData && typeof orderPaymentRequest.paymentData === 'object') {
      for (const key of Object.keys(orderPaymentRequest.paymentData)) {
        options.params['paymentData[' + key + ']'] = orderPaymentRequest.paymentData[key];
      }
    }

    if (orderPaymentRequest.intPayMethodType) {
      options.params.intPayMethodType = orderPaymentRequest.intPayMethodType;
    }

    if (orderPaymentRequest.intPayMethodValue) {
      options.params.intPayMethodValue = orderPaymentRequest.intPayMethodValue;
    }

    if (orderPaymentRequest.savePayment) {
      options.params.savePayment = 'true';
    }

    if (orderPaymentRequest.paymentToken) {
      options.params.paymentToken = orderPaymentRequest.paymentToken;
    }

    // return this.http.get<PaymentResponseModel>(
    //   `/place/${orderPaymentRequest.placeId}/order/${orderPaymentRequest.orderId}/payment${orderPaymentRequest.paymentProviderIdentifier ? '/' + orderPaymentRequest.paymentProviderIdentifier : ''}`
    // );

    return this.http.get<PaymentResponseModel>(
      `/place/${orderPaymentRequest.placeId}/order/${orderPaymentRequest.orderId}/payment${
        orderPaymentRequest.paymentProviderIdentifier ? '/' + orderPaymentRequest.paymentProviderIdentifier : ''
      }`,
      options as Object,
    );
  }

  public registerPayment(placeId: string, orderId: string, token: string = null) {
    const options: any = {
      context: {},
    };

    if (token === null) {
      options.context = { gen: true, orderId };
    } else {
      options.context = { data: token };
    }

    return this.http.get<TokenPaymentResponse>(`/place/${placeId}/payment`, options);
  }

  @Cacheable()
  public getCatering(
    placeId: string,
    orderId: string = null,
    screenGroupId: string = null,
  ): Observable<CateringResponseModel> {
    let params = new HttpParams();
    params = params.append('forceReload', 'true');

    if (screenGroupId !== null) {
      params = params.append('screenGroupId', screenGroupId);
    }

    const path = orderId ? `/place/${placeId}/order/${orderId}/fb` : `/place/${placeId}/fb`;

    return this.http.get<CateringResponseModel>(path, { params });
  }

  public removeItem(placeId: string, orderId: string, itemId: string): Observable<OrderApiModel> {
    return this.http
      .delete<OrderApiModel>(`/place/${placeId}/order/${orderId}/item/${itemId}`)
      .pipe(tap((res) => OrderHttpService.cacheModify(res.id, res)));
  }

  // /api/place/{placeId}/fb/nutritionalinfo
  @Cacheable()
  public getNutritionalInfo(placeId: string) {
    return this.http.get<NutritionalInfoModel[]>(`/place/${placeId}/fb/nutritionalinfo`);
  }
}

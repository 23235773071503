<form [formGroup]="passwordResetForm">
  <div class="col">
    <div class="row">
      <div class="col modal-title text-center">
        <span>{{ 'tapp-order.pages.logged-in-user.components.password-reset-modal.title' | translate }}</span>
      </div>
    </div>
    <ng-container *ngIf="!emailSent; else emailSentTemplate">
      <div class="row">
        <div class="col modal-subtitle text-center">
          <span>{{ 'tapp-order.pages.logged-in-user.components.password-reset-modal.subtitle' | translate }}</span>
        </div>
      </div>
      <div class="row">
        <div class="col modal-description text-center">
          <span>{{
            'tapp-order.pages.logged-in-user.components.password-reset-modal.modal-description' | translate
          }}</span>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="form-group">
            <input type="text" pInputText appInput formControlName="email" id="email" required="true" />
            <label for="email">{{ 'tapp-order.pages.logged-in-user.components.login-modal.email' | translate }}</label>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col modal-input-description text-center">
          <span>{{
            'tapp-order.pages.logged-in-user.components.password-reset-modal.modal-input-description' | translate
          }}</span>
        </div>
      </div>
      <div class="row my-1" *ngIf="error">
        <div class="error col text-center">
          <div>{{ error }}</div>
        </div>
      </div>
      <div class="row">
        <div class="col text-center">
          <button type="submit" (click)="onSubmit()" class="btn btn-primary" [disabled]="!isEmailCorrect">
            <span *ngIf="!loginLoading; else spinner">{{
              'tapp-order.pages.logged-in-user.components.password-reset-modal.submit-button' | translate
            }}</span>
            <ng-template #spinner>
              <span class="loader"></span>
            </ng-template>
          </button>
        </div>
      </div>
      <div class="row">
        <div class="col cancel text-center text-uppercase">
          <span class="ui-clickable" (click)="cancelPasswordResetting.emit()">{{
            'tapp-order.pages.logged-in-user.components.password-reset-modal.cancel-button' | translate
          }}</span>
        </div>
      </div>
    </ng-container>
  </div>
</form>

<ng-template #emailSentTemplate>
  <div class="row">
    <div class="col resend-message1">
      <span>{{
        'tapp-order.pages.logged-in-user.components.password-reset-modal.modal-instruction1' | translate
      }}</span>
    </div>
  </div>
  <div class="row">
    <div class="col resend-message2">
      <span>{{
        'tapp-order.pages.logged-in-user.components.password-reset-modal.modal-instruction2' | translate
      }}</span>
    </div>
  </div>
  <div class="row">
    <div class="col resend-question">
      <span>{{ 'tapp-order.pages.logged-in-user.components.password-reset-modal.not-delivered' | translate }}</span>
    </div>
  </div>
  <div class="row">
    <div class="col resend-btn d-flex justify-content-center">
      <button type="submit" (click)="onSubmit(true)" class="btn btn-primary" [disabled]="isResend">
        <span *ngIf="!loginLoading; else spinner">{{
          'tapp-order.pages.logged-in-user.components.password-reset-modal.send-again' | translate
        }}</span>
        <ng-template #spinner>
          <span class="loader"></span>
        </ng-template>
      </button>
    </div>
  </div>
</ng-template>

import { ImageApiModel } from './image.api.model';
import { Exclude, Expose, Type } from 'class-transformer';

@Exclude()
export class AllergenApiModel {
  @Expose()
  public name: string;

  @Expose()
  public publicId: string;
}

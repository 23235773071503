import { Component, OnInit } from '@angular/core';
import { PlaceHttpService } from '@core/http/tapp-order/place/place.http.service';
import { PlaceApiModel } from '@core/models/tapp-order/api-model/place/place.api.model';

@Component({
  selector: 'app-found-local',
  templateUrl: './found-local.component.html',
  styleUrls: ['./found-local.component.scss'],
})
export class FoundLocalComponent implements OnInit {
  public placeId: string;
  public placeData: PlaceApiModel;

  constructor(private placeHttpService: PlaceHttpService) {}

  ngOnInit(): void {
    this.placeId = sessionStorage.getItem('placeId');
    this.placeHttpService.getPlace(this.placeId).subscribe((placeData) => {
      this.placeData = placeData;
    });
  }
}

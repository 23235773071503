import { environment } from '@env/environment';
export abstract class ConfigService {
  public static setEnvironment(config) {
    if (!config) {
      return;
    }

    /*environment.apiUrl = config.apiUrl || environment.apiUrl;*/
    environment.baseImgOnError = config.baseImgOnError || environment.baseImgOnError;
    if (config.globalize) {
      environment.globalize.currency = config.globalize.currency || environment.globalize.currency;
    }
    environment.tips = config.tips || environment.tips;
    if (config.brand) {
      environment.brand.fullLogo = config.brand.fullLogo || environment.brand.fullLogo;
      environment.brand.subLogo = config.brand.subLogo || environment.brand.subLogo;
    }
    if (config.payment) {
      environment.payment.provider = config.payment.provider;
      environment.payment.giftcard = config.payment.giftcard;
      environment.payment.channel = config.payment.channel;
    }
    if (config.features) {
      environment.features = Object.assign(environment.features, config.features);
    }
  }
}

import { AbstractViewModel } from '../../../abstract.view.model';
import { SurveyAnswerViewModel } from '@core/models/tapp-order/view-model/survey/survey-answer.view.model';
import { SurveyAnswerItemApiModel } from '@core/models/tapp-order/api-model/survey/survey-answer-item.api.model';

export class SurveyAnswerItemViewModel extends AbstractViewModel<SurveyAnswerItemApiModel> {
  public orderPublicId?: number;
  public surveyId: number;
  public answers: SurveyAnswerViewModel[] = [];

  constructor(protected apiModel: SurveyAnswerItemApiModel = new SurveyAnswerItemApiModel()) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {}

  toApiModel(): SurveyAnswerItemApiModel {
    return Object.assign(this.apiModel, {
      orderPublicId: this.orderPublicId,
      surveyId: this.surveyId,
      answers: this.answers.map((answer) => answer.toApiModel()),
    });
  }

  public apply(formData: any) {
    Object.assign(this, formData, {
      answers: formData.answers.map((answerFormData) => {
        let viewModel = new SurveyAnswerViewModel();
        viewModel.apply(answerFormData);

        return viewModel;
      }),
    });
  }
}

import { ProductViewModel } from '../product/product.view.model';

export class BasketItemViewModel extends ProductViewModel {
  BasketItemID: string;
  CurrentQuantity: number;
  SelectedModifiers: ProductViewModel[] = [];
  unselectedModifiers?: ProductViewModel[] = [];
  modifiersToShow: ProductViewModel[] = [];
  product2?: ProductViewModel; // used for second part of divided pizza; if set, then this is divided pizza
  SelectedModifiers2: ProductViewModel[] = []; // used for second part of divided pizza
  unselectedModifiers2: ProductViewModel[] = []; //  used for second part of divided pizza
  modifiersToShow2: ProductViewModel[] = []; //  used for second part of divided pizza
  hidePizzaSize: boolean = false;
  hidePizzaDough: boolean = false;
}

<div *ngIf="(loadingService.loaderState$ | async).active === false" class="order-layout">
  <div class="content">
    <ng-container *ngIf="!showErrorPage">
      <ng-container *ngIf="orderStatus === PaymentOrderStatusEnum.orderAccepted">
        <app-order-accepted
          [restaurant]="restaurant"
          [order]="order"
          [orderType]="orderType"
          [pickupTime]="_pickupTime"
          [deliveryTime]="_deliveryTime"
          [localOrderIdentifier]="localOrderIdentifier"
        ></app-order-accepted>
      </ng-container>

      <ng-container *ngIf="orderStatus === PaymentOrderStatusEnum.orderInDelivery">
        <app-order-in-delivery
          [restaurant]="restaurant"
          [order]="order"
          [orderType]="orderType"
          [pickupTime]="_pickupTime"
          [deliveryTime]="_deliveryTime"
          [localOrderIdentifier]="localOrderIdentifier"
        ></app-order-in-delivery>
      </ng-container>

      <ng-container *ngIf="orderStatus === PaymentOrderStatusEnum.orderFinished">
        <app-order-finished
          [restaurant]="restaurant"
          [order]="order"
          [orderType]="orderType"
          [pickupTime]="_pickupTime"
          [deliveryTime]="_deliveryTime"
          [localOrderIdentifier]="localOrderIdentifier"
        ></app-order-finished>
      </ng-container>

      <ng-container *ngIf="orderStatus === PaymentOrderStatusEnum.orderRefused">
        <app-order-refused (newOrderEvent)="returnBackToPayments()"></app-order-refused>
      </ng-container>

      <ng-container *ngIf="orderStatus === PaymentOrderStatusEnum.orderPending">
        <app-order-pending [order]="order"></app-order-pending>
      </ng-container>

      <ng-container *ngIf="orderStatus === PaymentOrderStatusEnum.paymentPending">
        <app-payment-pending
          [showGoBackBtn]="true"
          [timerDateTo]="timerDateTo"
          (goBackEvent)="returnBackToPayments()"
        ></app-payment-pending>
      </ng-container>

      <ng-container *ngIf="orderStatus === PaymentOrderStatusEnum.paymentRefused">
        <app-payment-refused (newOrderEvent)="returnBackToPayments()"></app-payment-refused>
      </ng-container>

      <ng-container
        *ngIf="
          orderStatus === PaymentOrderStatusEnum.paymentRefunded ||
          orderStatus === PaymentOrderStatusEnum.paymentRefundedOwh
        "
      >
        <app-order-refunded [orderStatus]="orderStatus" (newOrderEvent)="returnBackToPayments()"></app-order-refunded>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="showErrorPage">
      <app-payment-refused (newOrderEvent)="returnBackToPayments()"></app-payment-refused>
    </ng-container>

    <app-sidebar
      class="basket"
      [basketItemsAmount]="basketService.calculateAllItemsInBasket()"
      [basketItems]="orderItems"
      [basketItemsPrice]="basketService.calculatePrice()"
      [paymentMode]="true"
      [isOrderButtonActive]="!!orderService.getCustomerEmail()"
      [isSummaryMode]="isSummaryMode"
      [sessionStorageNewOrder]="sessionStorageNewOrder"
    ></app-sidebar>

    <div
      *ngIf="
        orderStatus === PaymentOrderStatusEnum.orderPending ||
        orderStatus === PaymentOrderStatusEnum.orderAccepted ||
        orderStatus === PaymentOrderStatusEnum.orderInDelivery ||
        orderStatus === PaymentOrderStatusEnum.orderFinished
      "
      class="mt-2 mb-5 ui-clickable"
      style="
        background: var(--secondary-2);
        text-align: center;
        border-radius: var(--border-radius-input);
        padding: 7px 20px;
        margin: auto;
        width: 90%;
        max-width: 340px;
      "
      (click)="goToProducts()"
    >
      <span
        style="
          font-weight: 600;
          font-size: 14px;
          line-height: 24px;
          text-transform: uppercase;
          color: var(--font-white);
        "
        >Złóż nowe zamówienie</span
      >
    </div>
  </div>
</div>

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DiscountRestService } from '@api/rest/discount/discount.rest.service';
import { DiscountApiModel } from '@api/model/discount/discount.api.model';
import { DiscountViewModel } from '../model/discount.view.model';
import { BasketItemViewModel } from '@core/models/tapp-order/view-model/basketItem/basket-item.view.model';
import { CalculateDiscountResponseApiModel } from '@api/model/discount/calculate-discount-response.api.model';
import { CalculateDiscountResponseViewModel } from '../model/calculate-discount-response.view.model';

@Injectable()
export class DiscountDataProvider {
  public constructor(private discountRestService: DiscountRestService) {}

  public findAll(orderType: string): Observable<DiscountViewModel[]> {
    return this.discountRestService.findAll(orderType).pipe(
      map((apiModels: DiscountApiModel[]) => {
        return apiModels.map((apiModels: DiscountApiModel) => new DiscountViewModel(apiModels));
      }),
    );
  }

  public findById(id: string, orderType: string): Observable<DiscountViewModel> {
    return this.discountRestService
      .findById(id, orderType)
      .pipe(map((apiModel: DiscountApiModel) => new DiscountViewModel(apiModel)));
  }

  public calculate(basketItems: BasketItemViewModel[]): Observable<CalculateDiscountResponseViewModel> {
    return this.discountRestService
      .calculate(basketItems)
      .pipe(map((apiModel: CalculateDiscountResponseApiModel) => new CalculateDiscountResponseViewModel(apiModel)));
  }

  public calculateBasket(
    basketItems: BasketItemViewModel[],
    promoCode: string,
  ): Observable<CalculateDiscountResponseViewModel> {
    return this.discountRestService
      .calculateBasket(basketItems, promoCode)
      .pipe(map((apiModel: CalculateDiscountResponseApiModel) => new CalculateDiscountResponseViewModel(apiModel)));
  }
}

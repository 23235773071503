import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Cacheable, LocalStorageStrategy } from 'ts-cacheable';
import { PlaceApiModel } from '../models/tapp-order/api-model/place/place.api.model';

const placeCacheBuster$ = new Subject<void>();
const placesCacheBuster$ = new Subject<void>();

@Injectable({
  providedIn: 'root',
})
export class BaseHttpService {
  public readonly placeCacheBuster$ = placeCacheBuster$;

  constructor(private http: HttpClient) {}

  @Cacheable({
    storageStrategy: LocalStorageStrategy,
    cacheBusterObserver: placesCacheBuster$,
  })
  getPlaces(): Observable<PlaceApiModel[]> {
    return this.http.get<PlaceApiModel[]>('/place');
  }

  @Cacheable({
    storageStrategy: LocalStorageStrategy,
    cacheBusterObserver: placeCacheBuster$,
  })
  getPlace(id: string): Observable<PlaceApiModel> {
    return this.http.get<PlaceApiModel>(`/place/${id}`);
  }
}

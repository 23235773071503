import { TranslateLoader } from '@ngx-translate/core';
import { catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';

export class TranslateHttpLoader implements TranslateLoader {
  constructor(private http: HttpClient, public prefix: string = '/assets/i18n/', public suffix: string = '.json') {}

  public getTranslation(lang: string): Observable<any> {
    return this.http.get(`${this.prefix}${lang}${this.suffix}`).pipe(
      catchError((): Observable<any> => {
        return this.http.get(`${this.prefix}${environment.globalize.language.default}${this.suffix}`);
      }),
    );
  }
}

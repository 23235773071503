import { Expose, Type } from 'class-transformer';

export class CateringResponseModel {
  @Expose()
  // @Type(() => CateringArticleGroupResponseModel)
  groupList: Array<CateringArticleGroupResponseModel> = new Array<CateringArticleGroupResponseModel>();

  @Expose()
  // @Type(() => CateringArticleResponseModel)
  articleList: Array<CateringArticleResponseModel> = new Array<CateringArticleResponseModel>();
}

export class CateringArticleGroupResponseModel {
  @Expose()
  id: string;

  @Expose()
  name?: string;

  @Expose()
  description?: string;

  @Expose()
  parentGroupId?: string;

  @Expose()
  graphicUrl?: string;
}

export class CateringArticleResponseModel {
  @Expose()
  id: string;

  @Expose()
  groupId?: string;

  @Expose()
  name?: string;

  @Expose()
  price: number;

  @Expose()
  taxRate: number;

  @Expose()
  description?: string;

  @Expose()
  isLocked: boolean;

  @Expose()
  graphicUrl?: string;

  @Expose()
  // @Type(() => CateringArticleResponseModel)
  subArticleList: Array<CateringArticleResponseModel> = new Array<CateringArticleResponseModel>();

  @Expose()
  // @Type(() => CateringArticleResponseModel)
  replacementList: Array<CateringArticleResponseModel> = new Array<CateringArticleResponseModel>();

  @Expose()
  // @Type(() => CateringArticleModifierResponseModel)
  modifierList: Array<CateringArticleModifierResponseModel> = new Array<CateringArticleModifierResponseModel>();
}

export class CateringArticleModifierResponseModel {
  @Expose()
  id: string;

  @Expose()
  type?: string;

  @Expose()
  name?: string;

  @Expose()
  isRequired: boolean;

  @Expose()
  multiChoice: boolean;

  @Expose()
  multiChoiceMax: number;

  @Expose()
  multiChoiceMin: number;

  @Expose()
  separateItem: boolean;

  @Expose()
  // @Type(() => CateringArticleItemModifierResponseModel)
  itemList: Array<CateringArticleItemModifierResponseModel> = new Array<CateringArticleItemModifierResponseModel>();
}

export class CateringArticleItemModifierResponseModel {
  @Expose()
  id: string;

  @Expose()
  name?: string;

  @Expose()
  description?: string;

  @Expose()
  price: number;

  @Expose()
  quantity: number;

  @Expose()
  relatedItemList: Array<string> = new Array<string>();
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { plainToClass } from 'class-transformer';
import { Cacheable, LocalStorageStrategy } from 'ts-cacheable';
import { TableApiModel } from '@core/models/tapp-order/api-model/place/table.api.model';

const tableCacheBuster$ = new Subject<void>();

@Injectable({
  providedIn: 'root',
})
export class TableHttpService {
  constructor(private http: HttpClient) {}

  @Cacheable({
    storageStrategy: LocalStorageStrategy,
    cacheBusterObserver: tableCacheBuster$,
  })
  getTable(publicId: string): Observable<TableApiModel> {
    return this.http
      .get<TableApiModel>('/place/table/' + publicId)
      .pipe(map((result) => plainToClass(TableApiModel, result as object)));
  }
}

import { AbstractViewModel } from '../../../abstract.view.model';
import { GroupApiModel } from '../../api-model/menu/group.api.model';
import { ProductViewModel } from '../product/product.view.model';
import { ImageViewModel } from '@core/models/tapp-order/view-model/product/image.view.model';

export class GroupViewModel extends AbstractViewModel<GroupApiModel> {
  public publicId: string;
  public name: string;
  public active: boolean = false;
  public icon: ImageViewModel;
  public products: ProductViewModel[];
  public subgroups: GroupViewModel[];

  constructor(protected apiModel: GroupApiModel = new GroupApiModel()) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.publicId = this.apiModel.publicId;
    this.name = this.apiModel.name;
    this.products = this.apiModel.products
      ? this.apiModel.products.map((product) => new ProductViewModel(product))
      : [];
    this.subgroups = this.apiModel.subgroups ? this.apiModel.subgroups.map((group) => new GroupViewModel(group)) : [];
    this.icon = this.apiModel.icon ? new ImageViewModel(this.apiModel.icon) : new ImageViewModel();
  }

  toApiModel(): GroupApiModel {
    return undefined;
  }

  toString() {
    return this.name;
  }

  public subgroupsIsEmpty() {
    return !this.subgroups?.find((subgroup) => subgroup.products?.length > 0);
  }
}

import { Exclude, Expose } from 'class-transformer';

@Exclude()
export class PasswordChangeApiModel {
  @Expose()
  public resetPasswordToken: string;

  @Expose()
  public password: string;

  @Expose()
  public passwordRepeat: string;
}

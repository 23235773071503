import { Exclude, Expose } from 'class-transformer';

@Exclude()
export class TermsAndConditionsItemApiModel {
  @Expose()
  public id: number;

  @Expose()
  public content: string;

  @Expose()
  public required: boolean;

  @Expose()
  public categories: Array<'orders' | 'registration' | 'userSettings'>;
}

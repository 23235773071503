import { WeekDayType } from '@core/types/week-day.type';
import { Exclude, Expose } from 'class-transformer';

@Exclude()
export class WorkingHoursApiModel {
  @Expose()
  public id: number;

  @Expose()
  public placePublicId: string;

  @Expose()
  public externalId: string;

  @Expose()
  public startTime: string;

  @Expose()
  public endTime: string;

  @Expose()
  public weekDay: WeekDayType;
}

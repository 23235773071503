import { Component, Input, OnInit } from '@angular/core';
import { BasketService } from '../../../services/basket.service';
import { BottomNotificationComponent } from '../bottom-notification/bottom-notification.component';

@Component({
  selector: 'app-bottom-button',
  templateUrl: './bottom-button.component.html',
  styleUrls: ['./bottom-button.component.scss'],
})
export class BottomButtonComponent implements OnInit {
  @Input() leftText: string;
  @Input() centerText: string;
  @Input() rightText: string;
  @Input() routerLinkParam: string[];
  /**
   * Układ napisów na przycisku, domyślnie 2
   * 1 = left + center <br>
   * 2 = center <br>
   * 3 = right + center <br>
   * 4 = left + center + right
   */
  @Input() display: number = 2;

  @Input() buttonPosition: 'fixed' | 'static' = 'fixed';

  constructor(public basketService: BasketService, public bottomNotificationComponent: BottomNotificationComponent) {}

  ngOnInit(): void {}
}

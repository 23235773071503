import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';
import { TermsAndConditionsItemViewModel } from '@core/models/tapp-order/view-model/terms-and-conditions/terms-and-conditions-item.view.model';
import { PlaceService } from '../../../../../services/place.service';
import { DeliveryZonesHttpService } from '@core/http/tapp-order/delivery-zones/delivery-zones.http.service';
import { DeliveryZoneService } from '../../../../../services/deliveryZone.service';
import { DeviceDetectorService } from '../../../../../services/device-detector.service';
import { PlaceViewModel } from '@core/models/tapp-order/view-model/place/place.view.model';
import { UserRestService } from '@api/rest/user/user.rest.service';
import { UserDataApiModel } from '@api/model/user/user-data.api.model';
import { AuthService } from '@core/services/auth.service';
import { OrderService } from '@core/services/order.service';

@Component({
  selector: 'app-payment-form',
  templateUrl: './payment-form.component.html',
  styleUrls: ['./payment-form.component.scss'],
})
export class PaymentFormComponent implements OnInit {
  public isDesktop: boolean = false;
  public user: UserDataApiModel;
  public isLoading: boolean = false;

  @Output() public selectApproveEvent: EventEmitter<TermsAndConditionsItemViewModel[]> = new EventEmitter<
    TermsAndConditionsItemViewModel[]
  >();
  @Input() public formRejected: boolean;
  @Input() public isTermsAndConditionsAccepted: boolean;
  @Output() public termsAndConditionsNotAcceptedEvent: EventEmitter<null> = new EventEmitter<null>();
  @Input() public isOrderButtonActive: boolean;

  _isDeliveryZone: BehaviorSubject<boolean>;

  public place: PlaceViewModel;

  constructor(
    private deliveryZonesHttpService: DeliveryZonesHttpService,
    private placeService: PlaceService,
    private deviceService: DeviceDetectorService,
    private userRestService: UserRestService,
    private authService: AuthService,
    public orderService: OrderService,
  ) {
    this.deviceService.isDesktop.subscribe((isDesktop) => {
      this.isDesktop = isDesktop;
    });
  }

  ngOnInit(): void {
    this.isDeliveryZone();
    if (this.authService.isAuthenticated()) {
      this.isLoading = true;
      this.getUser();
    }
  }

  isDeliveryZone(): void {
    this.placeService.getPlace().subscribe((place) => {
      this.place = place;

      this.deliveryZonesHttpService.getDeliveryZones(sessionStorage.getItem('placeId')).subscribe((deliveryZones) => {
        this._isDeliveryZone = new BehaviorSubject<boolean>(!!deliveryZones.length);
      });
    });
  }

  public getUser(): void {
    this.userRestService.getUser().subscribe((value) => {
      this.user = value;
      this.isLoading = false;
    });
  }
}

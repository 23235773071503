import { Component, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Calendar, LocaleSettings } from 'primeng/calendar';
import { TranslateService } from '@ngx-translate/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { PrimeNGConfig } from 'primeng/api';

enum DateVariants {
  today = 'today',
  yesterday = 'yesterday',
  thisWeek = 'thisWeek',
  lastWeek = 'lastWeek',
  thisMonth = 'thisMonth',
  lastMonth = 'lastMonth',
}

const CALENDAR_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => AppCalendarComponent),
  multi: true,
};

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  providers: [CALENDAR_VALUE_ACCESSOR],
})
export class AppCalendarComponent implements OnInit, ControlValueAccessor {
  @ViewChild('calendar', { static: false }) calendar: Calendar;
  @Input() public selectionMode = 'single';
  @Input() public numberOfMonths = 1;
  @Input() public inline = false;
  @Input() public displayFooter = true;
  @Input() public dateFormat = 'dd.mm.yy';
  @Input() public dates: Date[];
  @Output() public onSelect: EventEmitter<Date[]> = new EventEmitter<Date[]>();
  @Output() public onClose: EventEmitter<Date[]> = new EventEmitter<Date[]>();
  public locale: LocaleSettings;

  value: any;
  disabled: any;
  onModelChange: Function = () => {};
  onModelTouched: Function = () => {};

  constructor(private translateService: TranslateService, private config: PrimeNGConfig) {}

  ngOnInit() {
    this.locale = {
      firstDayOfWeek: this.translateService.currentLang === 'pl' ? 1 : 0,
      dayNames: this.days,
      dayNamesShort: this.shortDays,
      dayNamesMin: this.minDays,
      dateFormat: 'dd.mm.yy',
      monthNames: this.months,
      monthNamesShort: this.shortMonths,
      today: this.today,
      clear: this.clear,
    };

    this.config.setTranslation(this.locale);
  }

  public onSelectEvent() {
    if (this.selectionMode === 'single') {
      this.onSelect.emit(this.dates);
    }
    if (this.selectionMode === 'range') {
      if (this.dates[1] !== null) {
        this.onSelect.emit(this.dates);
      }
    }
  }

  public changeDate(dateVariant: string): void {
    const firstDate = new Date();
    const secondDate = new Date();
    firstDate.setHours(0, 0, 0);
    secondDate.setHours(23, 59, 59);
    switch (dateVariant) {
      case DateVariants.today:
        this.dates = [firstDate, firstDate];
        break;
      case DateVariants.yesterday:
        firstDate.setDate(firstDate.getDate() - 1);
        this.dates = [firstDate, firstDate];
        break;
      case DateVariants.lastWeek:
        const lastStartWeek = firstDate.getDate() - ((6 + firstDate.getDay()) % 14);
        const lastEndWeek = firstDate.getDate() - (firstDate.getDay() % 14);
        firstDate.setDate(lastStartWeek);
        secondDate.setDate(lastEndWeek);
        this.dates = [firstDate, secondDate];
        break;
      case DateVariants.thisWeek:
        const thisStartWeek = firstDate.getDate() - firstDate.getDay() + 1;
        const thisEndWeek = firstDate.getDate() - firstDate.getDay() + 7;
        firstDate.setDate(thisStartWeek);
        secondDate.setDate(thisEndWeek);
        this.dates = [firstDate, secondDate];
        break;
      case DateVariants.lastMonth:
        const lastMonth = firstDate.getMonth() - 1;
        firstDate.setMonth(lastMonth);
        firstDate.setDate(1);
        secondDate.setDate(0);
        this.dates = [firstDate, secondDate];
        break;
      case DateVariants.thisMonth:
        firstDate.setDate(1);
        secondDate.setMonth(firstDate.getMonth() + 1);
        secondDate.setDate(0);
        this.dates = [firstDate, secondDate];
        break;
      default:
        return;
    }
    this.onSave();
  }

  public onInputEvent(inputEvent: { data }) {
    if (!inputEvent.data) {
      if (this.selectionMode === 'range') {
        this.calendar.hideOverlay();
      } else {
        this.onSelect.emit(this.dates);
      }
    }
  }

  onCloseEvent() {
    if (this.selectionMode === 'range') {
      if (this.dates) {
        if (this.dates[1] === null) {
          this.dates[1] = this.dates[1] === null ? this.dates[0] : this.dates[1];
          this.calendar.updateInputfield();
          this.onSelect.emit(this.dates);
        }
      }
    }
  }

  onSave() {
    this.onSelectEvent();
    this.calendar.hideOverlay();
  }

  get days(): string[] {
    return [
      this.translateService.instant('calendar.day.sunday'),
      this.translateService.instant('calendar.day.monday'),
      this.translateService.instant('calendar.day.tuesday'),
      this.translateService.instant('calendar.day.wednesday'),
      this.translateService.instant('calendar.day.thursday'),
      this.translateService.instant('calendar.day.friday'),
      this.translateService.instant('calendar.day.saturday'),
    ];
  }

  get shortDays(): string[] {
    return [
      this.translateService.instant('calendar.day.short.sunday'),
      this.translateService.instant('calendar.day.short.monday'),
      this.translateService.instant('calendar.day.short.tuesday'),
      this.translateService.instant('calendar.day.short.wednesday'),
      this.translateService.instant('calendar.day.short.thursday'),
      this.translateService.instant('calendar.day.short.friday'),
      this.translateService.instant('calendar.day.short.saturday'),
    ];
  }

  get minDays(): string[] {
    return [
      this.translateService.instant('calendar.day.min.sunday'),
      this.translateService.instant('calendar.day.min.monday'),
      this.translateService.instant('calendar.day.min.tuesday'),
      this.translateService.instant('calendar.day.min.wednesday'),
      this.translateService.instant('calendar.day.min.thursday'),
      this.translateService.instant('calendar.day.min.friday'),
      this.translateService.instant('calendar.day.min.saturday'),
    ];
  }

  get months(): string[] {
    return [
      this.translateService.instant('calendar.month.january'),
      this.translateService.instant('calendar.month.february'),
      this.translateService.instant('calendar.month.march'),
      this.translateService.instant('calendar.month.april'),
      this.translateService.instant('calendar.month.may'),
      this.translateService.instant('calendar.month.june'),
      this.translateService.instant('calendar.month.july'),
      this.translateService.instant('calendar.month.august'),
      this.translateService.instant('calendar.month.september'),
      this.translateService.instant('calendar.month.october'),
      this.translateService.instant('calendar.month.november'),
      this.translateService.instant('calendar.month.december'),
    ];
  }

  get shortMonths(): string[] {
    return [
      this.translateService.instant('calendar.month.short.january'),
      this.translateService.instant('calendar.month.short.february'),
      this.translateService.instant('calendar.month.short.march'),
      this.translateService.instant('calendar.month.short.april'),
      this.translateService.instant('calendar.month.short.may'),
      this.translateService.instant('calendar.month.short.june'),
      this.translateService.instant('calendar.month.short.july'),
    ];
  }

  get today(): string {
    return this.translateService.instant('calendar.today');
  }

  get clear(): string {
    return this.translateService.instant('calendar.clear');
  }

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: Function): void {
    this.onModelChange = fn;
  }

  registerOnTouched(fn: Function): void {
    this.onModelTouched = fn;
  }

  setDisabledState(val: boolean): void {
    this.disabled = val;
  }
}

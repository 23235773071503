import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TermsAndConditionsItemViewModel } from '@core/models/tapp-order/view-model/terms-and-conditions/terms-and-conditions-item.view.model';

@Component({
  selector: 'app-term-and-condition',
  templateUrl: './term-and-condition.component.html',
  styleUrls: ['./term-and-condition.component.scss'],
  animations: [
    trigger('borderRedAnimation', [
      state(
        'active',
        style({
          borderColor: '#e20816',
        }),
      ),
      transition('inactive => active', [
        animate(
          '250ms 250ms ease',
          keyframes([style({ borderColor: 'transparent', offset: 0 }), style({ borderColor: '#e20816', offset: 1 })]),
        ),
      ]),
      state(
        'inactive',
        style({
          borderColor: 'transparent',
          backgroundColor: 'transparent',
        }),
      ),
      transition('active => inactive', [
        animate(
          '250ms 0ms ease',
          keyframes([style({ borderColor: '#e20816', offset: 0 }), style({ borderColor: 'transparent', offset: 1 })]),
        ),
      ]),
    ]),
  ],
})
export class TermAndConditionComponent {
  @Input() termAndCondition: TermsAndConditionsItemViewModel;
  @Input() borderRed: boolean = false;
  @Output() public termAndConditionChangeEvent: EventEmitter<TermsAndConditionsItemViewModel> =
    new EventEmitter<TermsAndConditionsItemViewModel>();

  constructor() {}

  public onTermAndConditionChange(): void {
    this.termAndConditionChangeEvent.emit(this.termAndCondition);
  }
}

<ng-container *ngIf="tips">
  <div class="mb-3">
    <div class="d-flex align-content-start flex-wrap">
      <ng-container *ngFor="let tip of tips; let i = index">
        <button
          mat-raised-button
          [color]="selectedTip === tip ? 'primary' : ''"
          (click)="selectedTip = tip"
          class="mr-1 mb-1 px-0 py-1 tip"
          [disabled]="disabled"
        >
          <ng-container *ngIf="tip.type === tipType.manual; else valueTemplate">
            <input
              matInput
              class="tip-custom"
              [placeholder]="'app.components.tip.custom' | translate"
              value=""
              (change)="handleChange($event)"
              autocomplete="off"
              maxlength="4"
              digitOnly
              [min]="min"
              [max]="max"
              [readonly]="disabled"
            />
          </ng-container>
        </button>
        <ng-template #noTipTemplate>
          {{ 'app.components.tip.no-tip' | translate }}
        </ng-template>
        <ng-template #valueTemplate>
          {{ tip.type === tipType.percent ? tip.value + '%' : (tip.value | money) }}
        </ng-template>
      </ng-container>
    </div>
    <ng-container *ngIf="selectedTip && selectedTip.type === tipType.manual && mode === 'slider'">
      <div class="d-flex tip-slider my-3">
        <div class="thumb align-self-center">{{ min }}</div>
        <mat-slider
          [min]="min"
          [max]="max"
          step="1"
          [(ngModel)]="selectedTip.value"
          vertical="false"
          color="primary"
          class="w-100"
          (input)="onMove($event)"
          [disabled]="disabled"
        ></mat-slider>
        <div class="thumb align-self-center">{{ max }}</div>
      </div>
    </ng-container>
  </div>
</ng-container>

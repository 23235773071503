import { CalculateDiscountModifierResponseApiModel } from '@api/model/discount/calculate-discount-modifier-response.api.model';
import { AbstractViewModel } from '@core/models/abstract.view.model';

export class CalculateDiscountModifierResponseViewModel extends AbstractViewModel<CalculateDiscountModifierResponseApiModel> {
  public productId: number = null;
  public groupId: number = null;
  public quantity: number = null;
  public baseUnitPrice: number = null;
  public baseUnitDiscount: number = null;
  public unitPrice: number = null;
  public unitDiscount: number = null;
  public totalPrice: number = null;
  public totalDiscount: number = null;

  public constructor(
    protected apiModel: CalculateDiscountModifierResponseApiModel = new CalculateDiscountModifierResponseApiModel(),
  ) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.productId = this.apiModel.productId || null;
    this.groupId = this.apiModel.groupId || null;
    this.quantity = this.apiModel.quantity || null;
    this.baseUnitPrice = this.apiModel.baseUnitPrice || null;
    this.baseUnitDiscount = this.apiModel.baseUnitDiscount || null;
    this.unitPrice = this.apiModel.unitPrice || null;
    this.unitDiscount = this.apiModel.unitDiscount || null;
    this.totalPrice = this.apiModel.totalPrice || null;
    this.totalDiscount = this.apiModel.totalDiscount || null;
  }

  public apply(value: any): CalculateDiscountModifierResponseViewModel {
    Object.assign(this, value);
    return this;
  }

  toApiModel(): CalculateDiscountModifierResponseApiModel {
    return Object.assign(this.apiModel, {
      productId: this.productId,
      groupId: this.groupId,
      quantity: this.quantity,
      baseUnitPrice: this.baseUnitPrice,
      baseUnitDiscount: this.baseUnitDiscount,
      unitPrice: this.unitPrice,
      unitDiscount: this.unitDiscount,
      totalPrice: this.totalPrice,
      totalDiscount: this.totalDiscount,
    });
  }
}

<ng-container *ngFor="let group of modifierGroups; let lastGroup = last">
  <div class="modal-group-wrapper" [class]="lastGroup ? 'last' : ''" [ngClass]="{ 'toggle-mode': isToggleMode }">
    <div
      [attr.id]="'ingredient-dialog-group-' + group.publicId"
      [class.error]="group.hasError"
      class="modal-group-header"
    >
      <div (click)="toggleProducts(products)" class="modal-group-header-title">
        <span>
          {{ group.name }}
        </span>

        <img
          *ngIf="isToggleMode"
          [ngClass]="{ open: isToggleMode && isOpen(products) }"
          class="toggle-arrow"
          src="/assets/icons/arrow-down-toggle.svg"
          alt="Rozwiń/zwiń"
        />
      </div>

      <div *ngIf="group.minQuantity > 0" class="modal-group-header-requirements">
        <span>
          {{ 'tapp-order.product-modifiers-component.min-quantity-requirement-label' | translate }}&nbsp;
          {{ group.minQuantity }}
        </span>
      </div>
    </div>

    <div #products class="products-wrapper">
      <ng-container *ngFor="let modifier of group.products; let last = last">
        <ng-container *ngIf="group.maxQuantity > 1">
          <ng-container *ngIf="modifier.maxQuantity > 1">
            <div class="modal-group-content" [class]="last ? 'last' : ''">
              <div class="modal-group-content-quantity">
                <ng-container *ngIf="modifier.CurrentQuantity === 0">
                  <ng-container
                    *ngTemplateOutlet="
                      quantityEqualsZero;
                      context: {
                        modifier: modifier,
                        modifierGroup: group,
                        disableButton: countSelectedModifiersFromGroup(group) >= group.maxQuantity
                      }
                    "
                  ></ng-container>
                </ng-container>
                <ng-container *ngIf="modifier.CurrentQuantity === 1">
                  <ng-container
                    *ngTemplateOutlet="
                      quantityEqualsOne;
                      context: {
                        modifier: modifier,
                        modifierGroup: group,
                        disableButton: countSelectedModifiersFromGroup(group) >= group.maxQuantity
                      }
                    "
                  ></ng-container>
                </ng-container>
                <ng-container *ngIf="modifier.CurrentQuantity > 1">
                  <ng-container
                    *ngTemplateOutlet="
                      quantityLargerThanOne;
                      context: {
                        modifier: modifier,
                        modifierGroup: group,
                        disableButton: countSelectedModifiersFromGroup(group) >= group.maxQuantity
                      }
                    "
                  ></ng-container>
                </ng-container>
              </div>
              <div class="modal-group-content-name 1">
                <span>{{ modifier.name | removeDots }}</span>
              </div>
              <div class="modal-group-content-price">
                <span>{{ modifier.price | currency: 'PLN':'zł':'1.2-2':'pl-PL' }}</span>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="modifier.maxQuantity === 1">
            <div class="modal-group-content" [class]="last ? 'last' : ''">
              <div class="modal-group-content-quantity">
                <ng-container
                  *ngTemplateOutlet="
                    checkbox;
                    context: {
                      modifier: modifier,
                      modifierGroup: group,
                      checked: modifier.isChecked
                    }
                  "
                >
                </ng-container>
              </div>
              <div class="modal-group-content-name 2">
                <span>{{ modifier.name | removeDots | replace: '.':'' }}</span>
              </div>
              <div class="modal-group-content-price">
                <span>{{ modifier.price | currency: 'PLN':'zł':'1.2-2':'pl-PL' }}</span>
              </div>
            </div>
            <!-- <div class="modal-group-content-name 3">
              {{ modifier.name?.slice(0, 1) | uppercase | removeDots}}
              <span>{{ modifier.name?.slice(1, modifier.name?.length) | removeDots }}</span>
            </div> -->
            <!-- <div class="modal-group-content-price">
              <span>{{
                modifier.price | currency: "PLN":"zł":"1.2-2":"pl-PL"
              }}</span>
            </div> -->
          </ng-container>
        </ng-container>
        <ng-container *ngIf="group.maxQuantity === 1">
          <div class="modal-group-content" [class]="last ? 'last' : ''">
            <div class="modal-group-content-quantity">
              <ng-container *ngIf="group.modifierType != 'PIZZA_FREE_INGREDIENT'">
                <ng-container
                  *ngTemplateOutlet="
                    radio;
                    context: {
                      modifier: modifier,
                      modifierGroup: group,
                      checked: modifier.CurrentQuantity === 1
                    }
                  "
                >
                </ng-container>
              </ng-container>
              <ng-container *ngIf="group.modifierType == 'PIZZA_FREE_INGREDIENT'">
                <ng-container
                  *ngTemplateOutlet="
                    checkbox;
                    context: {
                      modifier: modifier,
                      modifierGroup: group,
                      checked: modifier.CurrentQuantity === 1,
                      disableButton: group.minQuantity > 0 || modifier.minQuantity > 0
                    }
                  "
                >
                </ng-container>
              </ng-container>
            </div>
            <div class="modal-group-content-name 4">
              <span class="text-capitalize">{{ modifier.name | removeDots }}</span>
            </div>
            <div class="modal-group-content-price">
              <span>{{ modifier.price | currency: 'PLN':'zł':'1.2-2':'pl-PL' }}</span>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </div>
</ng-container>

<ng-template
  #quantityEqualsZero
  let-disableButton="disableButton"
  let-modifier="modifier"
  let-modifierGroup="modifierGroup"
>
  <ng-container *ngIf="modifier; let modifier; let disableButton">
    <div class="modal-group-content-quantity-change">
      <div (click)="increaseModifierQuantity(modifier, modifierGroup)">
        <ng-container *ngTemplateOutlet="plus; context: { disableButton }"></ng-container>
      </div>
    </div>
  </ng-container>
</ng-template>
<ng-template
  #quantityEqualsOne
  let-disableButton="disableButton"
  let-modifier="modifier"
  let-modifierGroup="modifierGroup"
>
  <ng-container *ngIf="modifier; let modifier; let disableButton">
    <div class="modal-group-content-quantity-change">
      <div (click)="decreaseModifierQuantity(modifier, modifierGroup)">
        <ng-container *ngTemplateOutlet="minus"></ng-container>
      </div>
      {{ modifier.CurrentQuantity }}
      <div (click)="increaseModifierQuantity(modifier, modifierGroup)">
        <ng-container *ngTemplateOutlet="plus; context: { disableButton }"></ng-container>
      </div>
    </div>
  </ng-container>
</ng-template>
<ng-template
  #quantityLargerThanOne
  let-disableButton="disableButton"
  let-modifier="modifier"
  let-modifierGroup="modifierGroup"
>
  <ng-container *ngIf="modifier; let modifier; let disableButton">
    <div class="modal-group-content-quantity-change">
      <div (click)="decreaseModifierQuantity(modifier, modifierGroup)">
        <ng-container *ngTemplateOutlet="minus"></ng-container>
      </div>
      {{ modifier.CurrentQuantity }}
      <div (click)="increaseModifierQuantity(modifier, modifierGroup)">
        <ng-container *ngTemplateOutlet="plus; context: { disableButton }"></ng-container>
      </div>
    </div>
  </ng-container>
</ng-template>
<ng-template #radio let-checked="checked" let-modifier="modifier" let-modifierGroup="modifierGroup">
  <ng-container>
    <div class="modal-group-content-quantity-change">
      <mat-radio-button
        (change)="radioChange(modifier, modifierGroup)"
        [checked]="checked"
        [name]="modifier"
        class="example-radio-button"
      >
      </mat-radio-button>
    </div>
  </ng-container>
</ng-template>
<ng-template #checkbox let-checked="checked" let-modifier="modifier" let-modifierGroup="modifierGroup" let-disableButton="disableButton">
  <ng-container>
    <div class="modal-group-content-quantity-change">
      <mat-checkbox
        (change)="checkboxChange(modifier, modifierGroup)"
        [checked]="modifier.isChecked"
        [name]="modifier"
        [disabled]="modifier.minQuantity > 0 || disableButton === true"
      ></mat-checkbox>
    </div>
  </ng-container>
</ng-template>
<ng-template #trash>
  <div class="trash">
    <img src="assets/icons/recycle-bin-red.png" />
  </div>
</ng-template>
<ng-template #plus let-disableButton="disableButton">
  <div [ngClass]="{ disabled: disableButton }" class="plus"><span>+</span></div>
</ng-template>
<ng-template #minus>
  <div class="minus"><span>-</span></div>
</ng-template>

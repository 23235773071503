<ng-container *ngIf="loadingStatus === loadingStatusEnum.success">
  <div class="survey-modal-container row">
    <div class="col">
      <form [formGroup]="form" (submit)="onSubmit($event)">
        <div class="scroll-container">
          <ng-container formArrayName="surveys">
            <ng-container *ngFor="let surveyForm of form.get('surveys')['controls']">
              <ng-container [formGroup]="surveyForm">
                <div class="row">
                  <div class="col survey-title">
                    <span>{{ surveyForm.get('survey').value.name }}</span>
                  </div>
                </div>

                <ng-container formArrayName="answers">
                  <ng-container *ngFor="let answerForm of surveyForm.get('answers')['controls']; let i = index">
                    <ng-container [formGroup]="answerForm">
                      <div class="row question-wrapper">
                        <div class="col">
                          <div class="row">
                            <div class="col" style="font-size: 0.875rem; font-weight: 400; line-height: 1.0625rem">
                              <div class="question-title">
                                <span>{{ i + 1 + '. ' }}</span
                                >{{ answerForm.get('question').value.value }}
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col">
                              <div
                                class="stars-container"
                                *ngIf="answerForm.get('question').value.type === surveyQuestionTypeEnum.RATING"
                              >
                                <div class="star-item" [class.checked]="answerForm.get('rating').value == 5">
                                  <input
                                    formControlName="rating"
                                    type="radio"
                                    id="rating-5-{{ answerForm.get('question').value.id }}"
                                    class="star"
                                    value="5"
                                  />
                                  <label for="rating-5-{{ answerForm.get('question').value.id }}" class="star"></label>
                                </div>

                                <div class="star-item" [class.checked]="answerForm.get('rating').value == 4">
                                  <input
                                    formControlName="rating"
                                    type="radio"
                                    id="rating-4-{{ answerForm.get('question').value.id }}"
                                    class="star"
                                    value="4"
                                  />
                                  <label for="rating-4-{{ answerForm.get('question').value.id }}" class="star"></label>
                                </div>

                                <div class="star-item" [class.checked]="answerForm.get('rating').value == 3">
                                  <input
                                    formControlName="rating"
                                    type="radio"
                                    id="rating-3-{{ answerForm.get('question').value.id }}"
                                    class="star"
                                    value="3"
                                  />
                                  <label for="rating-3-{{ answerForm.get('question').value.id }}" class="star"></label>
                                </div>

                                <div class="star-item" [class.checked]="answerForm.get('rating').value == 2">
                                  <input
                                    formControlName="rating"
                                    type="radio"
                                    id="rating-2-{{ answerForm.get('question').value.id }}"
                                    class="star"
                                    value="2"
                                  />
                                  <label for="rating-2-{{ answerForm.get('question').value.id }}" class="star"></label>
                                </div>

                                <div class="star-item" [class.checked]="answerForm.get('rating').value == 1">
                                  <input
                                    formControlName="rating"
                                    type="radio"
                                    id="rating-1-{{ answerForm.get('question').value.id }}"
                                    class="star"
                                    value="1"
                                  />
                                  <label for="rating-1-{{ answerForm.get('question').value.id }}" class="star"></label>
                                </div>

                                <div
                                  *ngIf="
                                    answerForm.get('rating').invalid &&
                                    formSubmitted &&
                                    (answerForm.get('rating').dirty || answerForm.get('rating').touched)
                                  "
                                  class="invalid-feedback visible"
                                  >{{ 'common.form.required' | translate }}</div
                                >
                              </div>

                              <div
                                class="radiobox-container"
                                *ngIf="answerForm.get('question').value.type === surveyQuestionTypeEnum.SINGLE_CHOICE"
                              >
                                <label class="radiobox" *ngFor="let choice of answerForm.get('question').value.choices">
                                  <p-radioButton
                                    [value]="choice.id"
                                    formControlName="selectedChoiceId"
                                    [ngClass]="'radio'"
                                  ></p-radioButton>

                                  <span class="item-text">{{ choice.value }}</span>
                                </label>

                                <div
                                  *ngIf="
                                    answerForm.get('selectedChoiceId').invalid &&
                                    formSubmitted &&
                                    (answerForm.get('selectedChoiceId').dirty ||
                                      answerForm.get('selectedChoiceId').touched)
                                  "
                                  class="invalid-feedback visible"
                                  >{{ 'common.form.required' | translate }}</div
                                >
                              </div>

                              <div
                                class="checkbox-container"
                                *ngIf="answerForm.get('question').value.type === surveyQuestionTypeEnum.MULTIPLE_CHOICE"
                              >
                                <label class="checkbox" *ngFor="let choice of answerForm.get('question').value.choices">
                                  <p-checkbox
                                    [value]="choice.id"
                                    [formControl]="answerForm.controls['selectedChoicesIds']"
                                    [ngClass]="'checkbox'"
                                  ></p-checkbox>
                                  <span class="item-text">{{ choice.value }}</span>
                                </label>

                                <div
                                  *ngIf="
                                    answerForm.get('selectedChoicesIds').invalid &&
                                    formSubmitted &&
                                    (answerForm.get('selectedChoicesIds').dirty ||
                                      answerForm.get('selectedChoicesIds').touched)
                                  "
                                  class="invalid-feedback visible"
                                  >{{ 'common.form.required' | translate }}</div
                                >
                              </div>

                              <div
                                class="textarea-container"
                                *ngIf="answerForm.get('question').value.type === surveyQuestionTypeEnum.OPEN_ANSWER"
                              >
                                <textarea
                                  pInputTextarea
                                  [autoResize]="true"
                                  [rows]="2"
                                  [maxLength]="250"
                                  [appAutofocus]="false"
                                  [autofocus]="false"
                                  formControlName="ownAnswer"
                                  type="checkbox"
                                  class="textarea w-100"
                                ></textarea>

                                <div
                                  *ngIf="
                                    answerForm.get('ownAnswer').invalid &&
                                    formSubmitted &&
                                    (answerForm.get('ownAnswer').dirty || answerForm.get('ownAnswer').touched)
                                  "
                                  class="invalid-feedback visible"
                                  >{{ 'common.form.required' | translate }}</div
                                >
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ng-container></ng-container
                  >
                </ng-container>
              </ng-container>
            </ng-container>
          </ng-container>
        </div>
        <div class="row">
          <div class="col">
            <button class="action-button submit mx-0" mat-raised-button color="primary" type="submit">
              {{ 'buttons.submit-survey' | translate }}
            </button>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col">
            <button class="action-button cancel mx-0" mat-button type="reset" (click)="cancel()">
              {{ 'buttons.cancel' | translate }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</ng-container>

<div class="d-flex justify-content-center align-items-center">
  <div class="d-flex justify-content-between nav-bar-container">
    <div class="leftside d-flex nav-bar-inner-container">
      <div class="d-flex align-items-center justify-content-center">
        <app-logo [back_arrow]="false"></app-logo>
      </div>
      <div class="menu-items d-flex justify-content-start">
        <div
          class="d-flex align-items-center nav-bar-item ui-clickable"
          *ngIf="!tableId && !qrMenuMode && hasPlacesList"
        >
          <a [routerLink]="['places']">
            {{ 'tapp-order.shared.nav-bar.restaurants' | translate }}
          </a>
        </div>
        <ng-container *ngFor="let link of links">
          <div class="d-flex align-items-center nav-bar-item ui-clickable">
            <a href="{{ link.url }}">
              {{ link.name }}
            </a>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="rightside d-flex justify-content-end align-items-center" style="z-index: 1000">
      <ng-container *ngIf="!authService.isAuthenticated(); else loggedIn">
        <app-register-message-nav-bar style="padding-right: 5rem"></app-register-message-nav-bar>
        <span class="ui-clickable" style="font-weight: 500" (click)="openDialog()">
          {{ 'tapp-order.shared.nav-bar.login' | translate }}
        </span>
      </ng-container>
      <ng-template #loggedIn>
        <app-account-icon></app-account-icon>
      </ng-template>
    </div>
  </div>
</div>

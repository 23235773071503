import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OrderDeliveryAdressApiModel } from '@core/models/tapp-order/api-model/order/order-delivery-adress.api.model';
import { DiscountService } from '@core/pages/discounts/service/discount.service';
import { SelectedDeliveryHoursTypeEnum } from '@core/pages/order/enum/selected-delivery-hours-type.enum';
import { OrderService } from '@core/services/order.service';
import { PlatformService } from '@core/services/platform.service';
import { environment } from '@env/environment';
import { instanceToPlain, plainToClass } from 'class-transformer';
import { Observable } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { appUtils } from 'src/app/utils/app.utils';
import { PlaceService } from '../../../../services/place.service';
import { OrderTypeEnum } from '../../../models/tapp-order/api-model/order/OrderTypeEnum';
import { PaymentMethodEnum } from '../../../models/tapp-order/api-model/order/PaymentMethodEnum';
import { CreateOrderRequestApiModel } from '../../../models/tapp-order/api-model/order/create-order-request.api.model';
import { NewOrderApiModel } from '../../../models/tapp-order/api-model/order/new-order.api.model';
import { BasketItemViewModel } from '../../../models/tapp-order/view-model/basketItem/basket-item.view.model';

@Injectable({
  providedIn: 'root',
})
export class NewOrderService {
  public placeId = '';
  asapDeliveryHours: SelectedDeliveryHoursTypeEnum = SelectedDeliveryHoursTypeEnum.ASAP;
  specifyDeliveryHours: SelectedDeliveryHoursTypeEnum = SelectedDeliveryHoursTypeEnum.SPECIFY;
  private deliveryOrderType = OrderTypeEnum.delivery;
  private pickupOrderType = OrderTypeEnum.pick_up;

  constructor(
    private http: HttpClient,
    private platformService: PlatformService,
    private placeService: PlaceService,
    private orderService: OrderService,
    private discountService: DiscountService,
  ) {
    this.placeId = this.placeService.getPlaceId();
  }

  public create(basketItems: BasketItemViewModel[]): Observable<NewOrderApiModel> {
    const preparedOrderRequest: CreateOrderRequestApiModel = new CreateOrderRequestApiModel();
    preparedOrderRequest.type = OrderTypeEnum.delivery;
    preparedOrderRequest.selectedPaymentProvider = this.orderService.getPaymentProvider();
    preparedOrderRequest.selectedPaymentMethod = this.orderService.getPaymentMethod();

    if (this.orderService.getPaymentMethod() === PaymentMethodEnum.ONLINE) {
      preparedOrderRequest.onlinePaymentMethodType = this.orderService.getPaymentMethodType();
      preparedOrderRequest.onlinePaymentMethodValue = this.orderService.getPaymentMethodValue();
    }

    preparedOrderRequest.paymentContinueUrl = window.location.origin + '/order/{orderId}';

    preparedOrderRequest.paymentCancelUrl = window.location.origin + '/payments';
    preparedOrderRequest.placePublicId = this.placeId;
    preparedOrderRequest.tablePublicId = sessionStorage.getItem('tableId');
    preparedOrderRequest.orderItems =
      appUtils.transformBasketItemViewModelsToCreateOrderItemRequestApiModels(basketItems);
    preparedOrderRequest.promoCode = this.discountService.promoCode;
    preparedOrderRequest.type = this.orderService.getOrderType();
    preparedOrderRequest.customerPhone = this.orderService.getCustomerPhone();
    preparedOrderRequest.customerEmail = this.orderService.getCustomerEmail();
    preparedOrderRequest.customerName = this.orderService.getCustomerFirstName();
    preparedOrderRequest.notes = this.orderService.getCustomerNotes();

    if (this.orderService.getOrderType() === OrderTypeEnum.delivery) {
      preparedOrderRequest.deliveryAddress = this.prepareDeliveryAddressModel();
    }

    if (this.orderService.getSelectedDeliveryHoursType() !== this.asapDeliveryHours) {
      switch (this.orderService.getOrderType()) {
        case this.deliveryOrderType:
          preparedOrderRequest.deliveryTime = this.prepareDeliveryTime();
          break;
        case this.pickupOrderType:
          preparedOrderRequest.pickupTime = this.prepareDeliveryTime();
          break;
      }
    } else {
      preparedOrderRequest.deliveryTime = null;
      preparedOrderRequest.pickupTime = null;
    }

    if (this.orderService.getInvoiceSelected() === 'true') {
      preparedOrderRequest.invoice = {
        taxNumber: this.orderService.getInvoiceNip(),
        companyName: this.orderService.getInvoiceCompanyName(),
        street:
          this.orderService.getInvoiceCompanyStreet() != 'null' && this.orderService.getInvoiceCompanyStreet() != ''
            ? this.orderService.getInvoiceCompanyStreet()
            : null,
        buildingNo: this.orderService.getInvoiceCompanyStreetNo(),
        localNo:
          this.orderService.getInvoiceCompanyLocalNo() != 'null' && this.orderService.getInvoiceCompanyLocalNo() != ''
            ? this.orderService.getInvoiceCompanyLocalNo()
            : null,
        zipCode:
          this.orderService.getInvoicePostalCode() != 'null' && this.orderService.getInvoicePostalCode() != ''
            ? this.orderService.getInvoicePostalCode()
            : null,
        city: this.orderService.getInvoiceCompanyCity(),
        country: this.orderService.getInvoiceCountry(),
      };
    } else {
      preparedOrderRequest.invoice = null;
    }

    preparedOrderRequest.agreementIds = this.orderService.getSelectedTermsAndConditions().map((term) => term.id);

    this.orderService.setInvoiceSelected('false');

    return this.platformService.fetchPushNotificationToken().pipe(
      switchMap((token: string | null) => {
        preparedOrderRequest.deviceToken = token;

        return this.http.post<NewOrderApiModel>(`/order`, instanceToPlain(preparedOrderRequest)).pipe(
          tap((result) => this.trySaveSessionStorage()),
          map((result) => plainToClass(NewOrderApiModel, result as object)),
        );
      }),
    );
  }

  public get(id: string): Observable<NewOrderApiModel> {
    return this.http
      .get<NewOrderApiModel>(`/order/` + id)
      .pipe(map((result) => plainToClass(NewOrderApiModel, result as object)));
  }

  /**
   * This method is used exclusively for testing purposes.
   * It simulates the creation of a new order using mock data.
   *
   * WARNING: This method should not be used in production code.
   * Its existence is solely for testing and it may be deleted in the future without affecting the application's main functionality.
   *
   * @deprecated Use create() instead.
   */
  public testOrderEmptyStreet(): Observable<NewOrderApiModel> {
    if (environment.production) {
      throw new Error('Test order should not be used in production code.');
    }

    const json = {
      paymentCancelUrl: 'http://zamowienia.localhost:4200/payments',
      type: 'pick_up',
      selectedPaymentProvider: 'PayU',
      selectedPaymentMethod: 'CASH',
      paymentContinueUrl: 'http://zamowienia.localhost:4200/order/{orderId}',
      placePublicId: '4803-wwa-nugat-4',
      tablePublicId: null,
      orderItems: [
        {
          productPublicId: '3a09-8150-3da8-447d',
          name: 'PIZZA CAPRICIOSA',
          quantity: 1,
          notes: '',
          internalId: 'feb233d1-636c-4053-b968-8116d3692705',
          selectedModifiers: [
            {
              productPublicId: '3191-54d5-3adf-499f',
              modifierPublicId: 'a5da-0386-ce96-4813',
              internalId: '49e66658-57c4-452f-82f7-0102629d1c98',
              name: 'średnia',
              quantity: 1,
            },
            {
              productPublicId: '54ab-0641-5aa8-402f',
              modifierPublicId: 'a4c3-8eb2-50ad-4b62',
              internalId: '09f73ed8-550f-46cb-ab57-3c39835c14dc',
              name: '..SOS POMIDOROWY NA PIZZE',
              quantity: 1,
            },
            {
              productPublicId: 'e5b4-c414-fbd8-494d',
              modifierPublicId: 'a4c3-8eb2-50ad-4b62',
              internalId: '2b6e3dbd-55a9-4767-9aad-231976adc72c',
              name: 'PIECZARKI',
              quantity: 1,
            },
            {
              productPublicId: '1d8d-9761-0029-4f56',
              modifierPublicId: 'a4c3-8eb2-50ad-4b62',
              internalId: 'e3e29a18-2fa7-4ce8-99a9-04ffe902d888',
              name: 'SERR',
              quantity: 1,
            },
            {
              productPublicId: 'da7d-a5d3-5bf2-429d',
              modifierPublicId: 'ff60-2f5b-5a25-42c6',
              internalId: 'f6aa3ac2-a1dc-4ad8-8a91-f2149368d9f2',
              name: 'tradycyjne',
              quantity: 1,
            },
          ],
          unselectedModifiers: [],
        },
      ],
      promoCode: '',
      customerPhone: '666000999',
      customerEmail: 'vmambetov@lsisoftware.pl',
      customerName: 'Vlad',
      deliveryTime: null,
      pickupTime: null,
      invoice: {
        taxNumber: '1234567890',
        companyName: 'LSI-like',
        street: null,
        buildingNo: '404',
        localNo: null,
        zipCode: null,
        city: 'Łódź',
        country: 'Polska',
      },
      agreementIds: [2],
      deviceToken: null,
    };

    return this.http
      .post<NewOrderApiModel>(`/order`, json)
      .pipe(tap((result) => console.warn('NewOrderService -> testOrder [result]', result)))
      .pipe(map((result) => plainToClass(NewOrderApiModel, result as object)))
      .pipe(
        catchError((error) => {
          console.warn('NewOrderService -> testOrder [error]', error);
          throw error;
        }),
      );
  }

  /**
   * This method is used exclusively for testing purposes.
   * It simulates the creation of a new order using mock data.
   *
   * WARNING: This method should not be used in production code.
   * Its existence is solely for testing and it may be deleted in the future without affecting the application's main functionality.
   *
   * @deprecated Use create() instead.
   */
  public testOrderBadQuantityProducts(): Observable<NewOrderApiModel> {
    if (environment.production) {
      throw new Error('Test order should not be used in production code.');
    }

    const json = {
      paymentCancelUrl: 'http://zamowienia.localhost:4200/payments',
      type: 'pick_up',
      selectedPaymentProvider: 'PayU',
      selectedPaymentMethod: 'CASH',
      paymentContinueUrl: 'http://zamowienia.localhost:4200/order/{orderId}',
      placePublicId: '4803-wwa-nugat-4',
      tablePublicId: null,
      orderItems: [
        {
          productPublicId: '560f-862c-b059-41a5',
          name: 'Pepsi 0,5L',
          quantity: 1,
          notes: '',
          internalId: '28334098-21e7-4ae1-8d22-d35a2afc9157',
          selectedModifiers: [],
          unselectedModifiers: [],
        },
        {
          productPublicId: '560f-862c-b059-41a5',
          name: 'Pepsi 0,5L',
          quantity: 1,
          notes: '',
          internalId: '1ca19f2f-d0bd-4274-a7c4-889165828618',
          selectedModifiers: [],
          unselectedModifiers: [],
        },
        {
          productPublicId: '560f-862c-b059-41a5',
          name: 'Pepsi 0,5L',
          quantity: 1,
          notes: '',
          internalId: 'b7f7ddf4-adbe-422f-865d-53ab33602ae4',
          selectedModifiers: [],
          unselectedModifiers: [],
        },
        {
          productPublicId: '560f-862c-b059-41a5',
          name: 'Pepsi 0,5L',
          quantity: 1,
          notes: '',
          internalId: '549cafe2-804c-43d5-adfa-c6d973ea3e57',
          selectedModifiers: [],
          unselectedModifiers: [],
        },
        {
          productPublicId: '560f-862c-b059-41a5',
          name: 'Pepsi 0,5L',
          quantity: 1,
          notes: '',
          internalId: 'a2001fe5-0b4d-4864-8e8f-40eb76ba1aaa',
          selectedModifiers: [],
          unselectedModifiers: [],
        },
        {
          productPublicId: '560f-862c-b059-41a5',
          name: 'Pepsi 0,5L',
          quantity: 1,
          notes: '',
          internalId: '0c05d6f1-924c-4e19-80ac-f048b0a6deea',
          selectedModifiers: [],
          unselectedModifiers: [],
        },
        {
          productPublicId: '560f-862c-b059-41a5',
          name: 'Pepsi 0,5L',
          quantity: 1,
          notes: '',
          internalId: 'd1e54995-5353-4265-af88-289a1b97eec3',
          selectedModifiers: [],
          unselectedModifiers: [],
        },
        {
          productPublicId: '560f-862c-b059-41a5',
          name: 'Pepsi 0,5L',
          quantity: 1,
          notes: '',
          internalId: '42a1490b-fa4d-4b65-85af-22738b8b1cc5',
          selectedModifiers: [],
          unselectedModifiers: [],
        },
        {
          productPublicId: '560f-862c-b059-41a5',
          name: 'Pepsi 0,5L',
          quantity: 1,
          notes: '',
          internalId: 'ab3665e1-524e-4158-9499-c723d6fbf44e',
          selectedModifiers: [],
          unselectedModifiers: [],
        },
        {
          productPublicId: '560f-862c-b059-41a5',
          name: 'Pepsi 0,5L',
          quantity: 1,
          notes: '',
          internalId: '3c6d5239-c8c2-4a00-b5c2-3dcf2e2f88c4',
          selectedModifiers: [],
          unselectedModifiers: [],
        },
      ],
      promoCode: '',
      customerPhone: '45435345',
      customerEmail: 'test.auto-request@mail.com',
      customerName: 'Test Auto Request',
      deliveryTime: null,
      pickupTime: null,
      invoice: null,
      agreementIds: [2],
      deviceToken: null,
    };

    return this.http
      .post<NewOrderApiModel>(`/order`, json)
      .pipe(tap((result) => console.warn('NewOrderService -> testOrder [result]', result)))
      .pipe(map((result) => plainToClass(NewOrderApiModel, result as object)))
      .pipe(
        catchError((error) => {
          console.warn('NewOrderService -> testOrder [error]', error);
          throw error;
        }),
      );
  }

  /**
   * This method is used exclusively for testing purposes.
   * It simulates the creation of a new order using mock data.
   *
   * WARNING: This method should not be used in production code.
   * Its existence is solely for testing and it may be deleted in the future without affecting the application's main functionality.
   *
   * @deprecated Use create() instead.
   */
  public testOrderBadQuantityPaidModifiers(): Observable<NewOrderApiModel> {
    if (environment.production) {
      throw new Error('Test order should not be used in production code.');
    }

    const json = {
      paymentCancelUrl: 'http://zamowienia.localhost:4200/payments',
      type: 'pick_up',
      selectedPaymentProvider: 'PayU',
      selectedPaymentMethod: 'CASH',
      paymentContinueUrl: 'http://zamowienia.localhost:4200/order/{orderId}',
      placePublicId: '4803-wwa-nugat-4',
      tablePublicId: null,
      orderItems: [
        {
          productPublicId: 'd443-210b-4a65-4ce5',
          name: 'PIZZA KOMPOZYCJA WŁASNA',
          quantity: 0.5,
          notes: '',
          internalId: 'b665f110-9625-44b3-bebb-b4091694025f',
          selectedModifiers: [
            {
              productPublicId: 'a5d7-b8fc-1c83-4705',
              modifierPublicId: '555e-8e08-e385-49f2',
              internalId: '44568dd4-5328-429f-9cd5-8419fbad547a',
              name: 'średnia',
              quantity: 1,
            },
            {
              productPublicId: 'adb9-0001-01ca-40a7',
              modifierPublicId: 'b785-922d-e27c-42b2',
              internalId: 'bd28fe36-25a2-4697-a5fb-298629795d73',
              name: '..SOS POMIDOROWY NA PIZZE',
              quantity: 1,
            },
            {
              productPublicId: 'c874-3043-c31d-4396',
              modifierPublicId: 'b785-922d-e27c-42b2',
              internalId: '5c549352-f196-4105-8060-bb150c004734',
              name: 'SERR',
              quantity: 1,
            },
            {
              productPublicId: '0c05-bce6-d637-4957',
              modifierPublicId: 'b785-922d-e27c-42b2',
              internalId: 'c2390d16-cbca-4211-883f-4789b9f2e948',
              name: 'nowy dodatek',
              quantity: 1,
            },
            {
              productPublicId: '2b63-26ae-7cd0-4d65',
              modifierPublicId: '2362-a845-ba22-45fe',
              internalId: 'ecef0bb7-e3b1-4cfb-8507-7d2987c94c4b',
              name: 'tradycyjne',
              quantity: 1,
            },
            {
              productPublicId: '7999-0aea-28bf-4397',
              modifierPublicId: '66b8-cf7a-1629-4e99',
              internalId: '1c3c382f-af66-4a59-827f-87c3da341552',
              name: '..SOS POMIDOROWY NA PIZZE',
              quantity: 4,
            },
            {
              productPublicId: '56e3-9c23-4d93-43e9',
              modifierPublicId: '66b8-cf7a-1629-4e99',
              internalId: '3dd052be-7370-4e25-ad1d-b47610a656d5',
              name: '.SOS BBQ NA PIZZE',
              quantity: 3,
            },
            {
              productPublicId: '6213-1b91-a318-408f',
              modifierPublicId: '66b8-cf7a-1629-4e99',
              internalId: '862dbf16-f051-4850-94f2-d2649491ad2a',
              name: '.SOS BBQ PIKANTNY I ŚMIETANOWY NA PIZZE',
              quantity: 3,
            },
          ],
          unselectedModifiers: [],
          pizzaGroupId: 1,
          parentPizzaPublicId: null,
        },
        {
          pizzaGroupId: 1,
          parentPizzaPublicId: 'd443-210b-4a65-4ce5',
          productPublicId: '2f64-650a-14d6-440b',
          name: 'PIZZA MARGHARITA PROSCIUTTO CLASSICO PICANTE DUŻA',
          quantity: 0.5,
          notes: '',
          internalId: 'ef55a572-4d18-4342-8419-8057cf30a130',
          selectedModifiers: [
            {
              productPublicId: '765d-cd7a-6479-4421',
              modifierPublicId: '559c-80bf-1108-423d',
              internalId: '2bca7b8f-915a-4c64-ac55-73a8d8774862',
              name: 'średnia',
              quantity: 1,
            },
            {
              productPublicId: 'e0fe-0c03-da63-4730',
              modifierPublicId: '0a5d-4078-b819-46ab',
              internalId: 'c4f18b97-31d9-4341-8b8f-319d83207d21',
              name: 'tradycyjne',
              quantity: 1,
            },
            {
              productPublicId: '7999-0aea-28bf-4397',
              modifierPublicId: '66b8-cf7a-1629-4e99',
              internalId: '53096135-18de-4d88-b4f4-ea17cd3edd91',
              name: '..SOS POMIDOROWY NA PIZZE',
              quantity: 3,
            },
            {
              productPublicId: '56e3-9c23-4d93-43e9',
              modifierPublicId: '66b8-cf7a-1629-4e99',
              internalId: '02c444d2-b5d0-4bc3-ac87-17e162d57218',
              name: '.SOS BBQ NA PIZZE',
              quantity: 3,
            },
            {
              productPublicId: '6213-1b91-a318-408f',
              modifierPublicId: '66b8-cf7a-1629-4e99',
              internalId: 'a224ba44-d759-4307-aa29-0d728d6d6244',
              name: '.SOS BBQ PIKANTNY I ŚMIETANOWY NA PIZZE',
              quantity: 3,
            },
            {
              productPublicId: 'adb9-0001-01ca-40a7',
              modifierPublicId: 'b954-54c9-3fe8-4373',
              internalId: '3ab94ab6-194a-4f71-a233-a4ea4e9f4c2f',
              name: '..SOS POMIDOROWY NA PIZZE',
              quantity: 1,
            },
            {
              productPublicId: 'a460-fe70-f7cc-405b',
              modifierPublicId: 'b954-54c9-3fe8-4373',
              internalId: '194e04ab-8ae0-4698-8663-a44f0efd73c6',
              name: '.SOS BBQ NA PIZZE',
              quantity: 1,
            },
            {
              productPublicId: 'ee41-6e08-42d0-479d',
              modifierPublicId: 'b954-54c9-3fe8-4373',
              internalId: '73917c70-26ca-4dc5-80c7-a5ac90c08afe',
              name: '.SOS PIKANTNY ARABSKI I POMIDOROWY NA PIZZE',
              quantity: 1,
            },
            {
              productPublicId: '26d1-ca1c-f919-4835',
              modifierPublicId: 'b954-54c9-3fe8-4373',
              internalId: '690a93e5-5a15-4f9d-a211-bb8c85eec856',
              name: '.SOS POMIDOROWY (JALAPENO)',
              quantity: 1,
            },
            {
              productPublicId: '5f32-899a-f675-43a2',
              modifierPublicId: 'b954-54c9-3fe8-4373',
              internalId: '23cd1fae-9142-4f1e-b2da-c6d1c4f6933c',
              name: 'ANANAS',
              quantity: 1,
            },
            {
              productPublicId: 'b127-2338-09ee-4f4e',
              modifierPublicId: 'b954-54c9-3fe8-4373',
              internalId: '66a7d519-57c0-4e43-9c14-123debbc50ae',
              name: 'BOCZEK',
              quantity: 1,
            },
            {
              productPublicId: '5308-ef22-0529-4900',
              modifierPublicId: 'b954-54c9-3fe8-4373',
              internalId: '7f1d6613-9ed9-4a49-b222-f9b6ba638b09',
              name: 'GRAN GUSTO GALBANI',
              quantity: 1,
            },
            {
              productPublicId: 'f7f3-7a53-af91-4b7c',
              modifierPublicId: 'b954-54c9-3fe8-4373',
              internalId: '48b3108c-e0c4-4d0c-9482-ea1949cd54d5',
              name: 'KEBAB Z KURCZAKA',
              quantity: 1,
            },
            {
              productPublicId: 'c742-a2ae-a649-4522',
              modifierPublicId: 'b954-54c9-3fe8-4373',
              internalId: '84b8099f-e81a-47e0-a3e4-1597f5814a49',
              name: 'OGÓREK KONSERWOWY',
              quantity: 1,
            },
            {
              productPublicId: 'd63e-0511-d4fb-4cde',
              modifierPublicId: 'b954-54c9-3fe8-4373',
              internalId: 'd12aa4a0-d51d-4bc5-bfde-af53c7b890ec',
              name: 'POMIDOR',
              quantity: 1,
            },
            {
              productPublicId: '3c80-bec8-5947-4a02',
              modifierPublicId: 'b954-54c9-3fe8-4373',
              internalId: 'eddfb480-f3b8-4c0c-9988-58cfa3a98970',
              name: 'POMIDORY CHERRY',
              quantity: 1,
            },
            {
              productPublicId: 'd793-ce6f-78e8-4b61',
              modifierPublicId: 'b954-54c9-3fe8-4373',
              internalId: 'def12acb-fa49-4f1a-89ba-76f9a82402b8',
              name: 'RUCOLA',
              quantity: 1,
            },
            {
              productPublicId: 'eb9e-ad17-865e-45e3',
              modifierPublicId: 'b954-54c9-3fe8-4373',
              internalId: 'aa5c47aa-4e40-4797-a0c9-41adaa0e94ba',
              name: 'SER DOJRZEWAJĄCY GRANGUSTO',
              quantity: 1,
            },
            {
              productPublicId: 'b854-46a8-d21e-41f5',
              modifierPublicId: 'b954-54c9-3fe8-4373',
              internalId: 'd5cf909a-5f65-4ea0-9b2d-d3ae50e5043b',
              name: 'SER GOUDA WĘDZONA',
              quantity: 1,
            },
            {
              productPublicId: 'c874-3043-c31d-4396',
              modifierPublicId: 'b954-54c9-3fe8-4373',
              internalId: '5bc25f89-194e-4db6-a150-947bcf9c282a',
              name: 'SERR',
              quantity: 1,
            },
            {
              productPublicId: '7683-0aef-447f-44e4',
              modifierPublicId: 'b954-54c9-3fe8-4373',
              internalId: 'c568bd85-73e8-450a-98ea-421a0e034e80',
              name: 'dodatek blad',
              quantity: 1,
            },
          ],
          unselectedModifiers: [],
        },
      ],
      promoCode: '',
      customerPhone: '45435345',
      customerEmail: 'test.auto-request@mail.com',
      customerName: 'Test Auto Request',
      deliveryTime: null,
      pickupTime: null,
      invoice: null,
      agreementIds: [2],
      deviceToken: null,
    };

    return this.http
      .post<NewOrderApiModel>(`/order`, json)
      .pipe(tap((result) => console.warn('NewOrderService -> testOrder [result]', result)))
      .pipe(map((result) => plainToClass(NewOrderApiModel, result as object)))
      .pipe(
        catchError((error) => {
          console.warn('NewOrderService -> testOrder [error]', error);
          throw error;
        }),
      );
  }

  /**
   * This method is used exclusively for testing purposes.
   * It simulates the creation of a new order with a divided pizza that lacks required modifiers.
   *
   * WARNING: This method should not be used in production code.
   * Its existence is solely for testing and it may be deleted in the future without affecting the application's main functionality.
   *
   * @deprecated Use create() instead.
   */
  public testOrderDividedPizzaWithOutRequiredModifiers(): Observable<NewOrderApiModel> {
    if (environment.production) {
      throw new Error('Test order should not be used in production code.');
    }

    const json = {
      paymentCancelUrl: 'http://zamowienia.localhost:4200/payments',
      type: 'pick_up',
      selectedPaymentProvider: 'Tpay',
      selectedPaymentMethod: 'CASH',
      paymentContinueUrl: 'http://zamowienia.localhost:4200/order/{orderId}',
      placePublicId: '4844-kat-warszawska-36',
      tablePublicId: null,
      orderItems: [
        {
          productPublicId: 'ba50-ff29-03d2-4625',
          name: 'PIZZA KRÓLOWA 6 SERÓW',
          quantity: 0.5,
          notes: '',
          internalId: '7997d9e6-4fd4-444e-95b6-4bcfc10c09df',
          selectedModifiers: [
            {
              productPublicId: '683f-ede6-555a-438d',
              modifierPublicId: '17e1-07d9-0466-4130',
              internalId: '8fbe4c01-c4d8-4b29-9474-c1e0a1324a27',
              name: 'średnia',
              quantity: 1,
            },
            {
              productPublicId: '641c-2384-c072-41b1',
              modifierPublicId: '50de-1329-1b11-4111',
              internalId: 'dcbb51b2-128d-4719-8a2f-27279d83bbae',
              name: 'tradycyjne',
              quantity: 1,
            },
            {
              productPublicId: '0ecc-ef67-e4e2-449c',
              modifierPublicId: 'f8e9-ad88-5ae5-4ad4',
              internalId: 'bf0ea5d1-bbe8-44ef-b0a4-70ee774f60e8',
              name: 'SER DOJRZEWAJĄCY GRANGUSTO',
              quantity: 1,
            },
            {
              productPublicId: '0f63-d1e3-fcc0-4fe8',
              modifierPublicId: 'f8e9-ad88-5ae5-4ad4',
              internalId: '6e7cefb0-58cd-4987-bed8-2aace9915b64',
              name: 'SER GOUDA WĘDZONA',
              quantity: 1,
            },
            {
              productPublicId: '8bac-6d4c-3fb5-4cb1',
              modifierPublicId: 'f8e9-ad88-5ae5-4ad4',
              internalId: '65f17658-09b0-48fe-b035-0b7d674611fd',
              name: 'SER MOZARELLA GALBANI',
              quantity: 1,
            },
            {
              productPublicId: 'f417-ef0e-4272-4e8d',
              modifierPublicId: 'f8e9-ad88-5ae5-4ad4',
              internalId: 'ed4661e0-1916-4abb-9b80-0a45839bf3a8',
              name: 'SER PLEŚNIOWY',
              quantity: 1,
            },
            {
              productPublicId: '2243-73ef-2225-4103',
              modifierPublicId: 'f8e9-ad88-5ae5-4ad4',
              internalId: '5f1a9591-7d52-4cec-8fdc-64ccaacec284',
              name: 'SER ŚRÓDZIEMNOMORSKI',
              quantity: 1,
            },
            {
              productPublicId: '1d8d-9761-0029-4f56',
              modifierPublicId: 'f8e9-ad88-5ae5-4ad4',
              internalId: '061f0d0e-465f-4bc5-a6d7-98c1d739ff14',
              name: 'SERR',
              quantity: 1,
            },
          ],
          unselectedModifiers: [],
          pizzaGroupId: 1,
          parentPizzaPublicId: null,
        },
        {
          pizzaGroupId: 1,
          parentPizzaPublicId: 'ba50-ff29-03d2-4625',
          productPublicId: '40d7-15da-1222-4521',
          name: 'PIZZA BIG MEAT BBQ',
          quantity: 0.5,
          notes: '',
          internalId: '871160ba-e207-42cb-b303-39bf87fbe36c',
          selectedModifiers: [
            {
              productPublicId: '66c8-75ec-50a9-4065',
              modifierPublicId: '1ab8-ba8a-0fd6-4fdc',
              internalId: 'abba1066-4445-4175-8e43-89a7dba5da69',
              name: 'średnia',
              quantity: 1,
            },
            {
              productPublicId: 'c9db-b0c5-b4c9-4b52',
              modifierPublicId: 'd5af-8935-92b4-451f',
              internalId: 'ed7fdbe4-8e26-431f-ba22-9aadb94deb44',
              name: 'tradycyjne',
              quantity: 1,
            },
            {
              productPublicId: '2412-7611-b0b7-4625',
              modifierPublicId: '282b-e9a9-aef8-4485',
              internalId: '7367010e-75a6-4eee-be44-61acbd857d78',
              name: '.SOS BBQ PIKANTNY NA PIZZE',
              quantity: 1,
            },
            {
              productPublicId: '0e62-5ef7-400b-4178',
              modifierPublicId: '282b-e9a9-aef8-4485',
              internalId: 'e26b6893-6c87-47af-b7f9-e12eb08e16d1',
              name: 'CEBULA PRAŻONA',
              quantity: 1,
            },
            {
              productPublicId: '4f43-4b87-b124-494d',
              modifierPublicId: '282b-e9a9-aef8-4485',
              internalId: '3e0adf36-fbeb-4c3a-ac7d-ebfbe851f0e8',
              name: 'EKSTRA SER',
              quantity: 1,
            },
            {
              productPublicId: '1d8d-9761-0029-4f56',
              modifierPublicId: '282b-e9a9-aef8-4485',
              internalId: 'fe47148b-a506-4a03-9450-937c2c1e9846',
              name: 'SERR',
              quantity: 1,
            },
          ],
          unselectedModifiers: [],
        },
      ],
      promoCode: '',
      customerPhone: '666000999',
      customerEmail: 'vmambetov@lsisoftware.pl',
      customerName: 'Vlad',
      deliveryTime: null,
      pickupTime: null,
      invoice: null,
      agreementIds: [2],
      deviceToken: null,
    };

    return this.http
      .post<NewOrderApiModel>(`/order`, json)
      .pipe(tap((result) => console.warn('NewOrderService -> testOrder [result]', result)))
      .pipe(map((result) => plainToClass(NewOrderApiModel, result as object)))
      .pipe(
        catchError((error) => {
          console.warn('NewOrderService -> testOrder [error]', error);
          throw error;
        }),
      );
  }

  private prepareDeliveryAddressModel(): OrderDeliveryAdressApiModel {
    const deliveryAddress: OrderDeliveryAdressApiModel = new OrderDeliveryAdressApiModel();
    deliveryAddress.deliveryZoneId = this.orderService.getDeliveryZoneId();
    deliveryAddress.city = this.orderService.getDeliveryAddressCity();
    deliveryAddress.street =
      this.orderService.getDeliveryAddressStreet() != 'null' && this.orderService.getDeliveryAddressStreet() != ''
        ? this.orderService.getDeliveryAddressStreet()
        : null;
    deliveryAddress.buildingNo = this.orderService.getDeliveryAddressBuildingNo();
    deliveryAddress.localNo =
      this.orderService.getDeliveryAddressLocalNo() != 'null' && this.orderService.getDeliveryAddressLocalNo() != ''
        ? this.orderService.getDeliveryAddressLocalNo()
        : null;
    deliveryAddress.zipCode =
      this.orderService.getDeliveryAddressZipCode() != 'null' && this.orderService.getDeliveryAddressZipCode() != ''
        ? this.orderService.getDeliveryAddressZipCode()
        : null;

    return deliveryAddress;
  }

  private prepareDeliveryTime(): string {
    const selectedDeliveryTime = this.orderService.getDeliveryHours();
    const splitted = selectedDeliveryTime.split(':');
    const currentDate = new Date();
    currentDate.setHours(Number(splitted[0]), Number(splitted[1]), 0, 0);
    return currentDate.toISOString().split('.')[0];
  }

  /**
   * @deprecated Templrary solution for push notifications; Can be refactored after 07.08.2023
   */
  private trySaveSessionStorage(): void {
    const placeId = sessionStorage.getItem('placeId');

    if (placeId) {
      localStorage.setItem('placeId', placeId);
    } else {
      localStorage.removeItem('placeId');
    }
  }
}

import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { QueryParamsApiModel } from '@core/models/query-params-api.model';

@Injectable()
export class QueryParameterService {
  constructor() {}

  public applyParameters(queryParams: QueryParamsApiModel = new QueryParamsApiModel()): HttpParams {
    let params = new HttpParams();
    if (queryParams.filters) {
      let i = 0;
      for (const filter of queryParams.filters) {
        params = params.append(`filters[${i}][name]`, filter.name);
        params = params.append(`filters[${i}][operator]`, filter.operator);
        params = params.append(`filters[${i}][value]`, this.parseValue(filter.value));
        params = params.append(`filters[${i}][connector]`, filter.connector);
        i++;
      }
    }
    if (queryParams.orderBy) {
      params = params.append('orderBy', queryParams.orderBy);
    }
    if (queryParams.orderDirection) {
      if (typeof queryParams.orderDirection === 'number') {
        this.mapOrderDirection(queryParams);
      }
      params = params.append('orderDirection', queryParams.orderDirection);
    }
    if (queryParams.page) {
      params = params.append('page', String(queryParams.page));
    }
    if (queryParams.limit) {
      params = params.append('limit', String(queryParams.limit));
    }
    return params;
  }

  private mapOrderDirection(queryParams: QueryParamsApiModel): void {
    switch (queryParams.orderDirection) {
      case -1:
        queryParams.orderDirection = 'DESC';
        break;
      case 1:
        queryParams.orderDirection = 'ASC';
        break;
      default:
        queryParams.orderDirection = 'DESC';
    }
  }

  private parseValue(value: any): any {
    if (value instanceof Date) {
      const date = new Date(
        Date.UTC(
          value.getFullYear(),
          value.getMonth(),
          value.getDate(),
          value.getHours(),
          value.getMinutes(),
          value.getSeconds(),
          value.getMilliseconds(),
        ),
      );
      return JSON.stringify(date);
    } else if (typeof value !== 'string') {
      return JSON.stringify(value);
    }

    return value;
  }
}

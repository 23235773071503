import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { UserRestService } from '@api/rest/user/user.rest.service';
import { PasswordResetApiModel } from '@api/model/user/password-reset.api.model';
import { PlaceService } from '../../services/place.service';

const PAT_EMAIL = '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+[.][a-zA-Z]{2,4}$';

@Component({
  selector: 'app-reset-password-modal',
  templateUrl: './reset-password-modal.component.html',
  styleUrls: ['./reset-password-modal.component.scss'],
})
export class ResetPasswordModalComponent implements OnInit {
  @Output() cancelPasswordResetting = new EventEmitter<void>();
  public passwordResetForm: FormGroup;
  public loginLoading: boolean = false;
  public isEmailCorrect: boolean = false;
  public error: string;
  public emailSent: boolean = false;
  public isResend: boolean = false;

  constructor(private fb: FormBuilder, private userRestService: UserRestService, private placeService: PlaceService) {}

  ngOnInit(): void {
    this.passwordResetForm = this.fb.group({
      email: new FormControl(null, [Validators.required, Validators.pattern(PAT_EMAIL)]),
    });

    this.passwordResetForm.valueChanges.subscribe(() => {
      this.isEmailCorrect = this.passwordResetForm.controls.email.valid;
    });
  }

  public onSubmit(isResend = false): void {
    if (this.passwordResetForm.valid) {
      if (isResend) {
        this.isResend = true;
      }
      this.error = null;
      this.loginLoading = true;
      const apiModel = new PasswordResetApiModel();
      apiModel.email = this.passwordResetForm.get('email').value;
      apiModel.resetPasswordUrlTemplate = window.location.origin + '/reset-password/{resetPasswordToken}';
      apiModel.placePublicId = this.placeService.getPlaceId() ?? null;

      this.userRestService.postResetPassword(apiModel).subscribe(
        (x) => {
          this.loginLoading = false;
          this.emailSent = true;
        },
        (error) => {
          this.error = error.error.message;
          this.loginLoading = false;
        },
      );
    }
  }
}

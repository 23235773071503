import { Component, OnInit, OnDestroy, NgZone } from '@angular/core';
import { BasketService } from '../../../../services/basket.service';
import { cloneDeep } from 'lodash';
import { PlaceHttpService } from '../../../http/tapp-order/place/place.http.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PlaceService } from '../../../../services/place.service';
import { NewOrderService } from '../../../http/tapp-order/order/new-order.service';
import { interval, Observable, Subscription } from 'rxjs';
import { OrderStatusEnum } from '../../../models/tapp-order/order-status.enum';
import { OrderService } from '@core/services/order.service';
import { PlaceViewModel } from '@core/models/tapp-order/view-model/place/place.view.model';
import { OrderTypeEnum } from '@core/models/tapp-order/api-model/order/OrderTypeEnum';
import { DeviceDetectorService } from '../../../../services/device-detector.service';
import { NewOrderApiModel } from '@core/models/tapp-order/api-model/order/new-order.api.model';
import { environment } from '@env/environment';

@Component({
  selector: 'app-order-success',
  templateUrl: './order-success.component.html',
  styleUrls: ['./order-success.component.scss'],
})
export class OrderSuccessComponent implements OnInit, OnDestroy {
  public basketItemsClone = [];
  public logo: string = null;
  public sessionStorageNewOrder;
  public sessionStorageTableId;
  public tableName;
  public roomName;
  public isLoaded: boolean = false;
  public isDesktop: boolean = false;
  public isSummaryMode: boolean = true;
  public orderTypeEnum = OrderTypeEnum;

  public restaurant: PlaceViewModel;

  intervalSubscription: Subscription;
  orderInterval = interval(5000);

  constructor(
    public basketService: BasketService,
    public orderService: OrderService,
    private placeHttpService: PlaceHttpService,
    private router: Router,
    private placeService: PlaceService,
    private newOrderService: NewOrderService,
    private route: ActivatedRoute,
    private zone: NgZone,
    private deviceService: DeviceDetectorService,
  ) {
    this.deviceService.isDesktop.subscribe((isDesktop) => {
      this.isDesktop = isDesktop;
    });
  }

  ngOnInit(): void {
    //this.basketService.removeBasket();
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const code = urlParams.get('error');
    this.getRestaurant();

    if (code == '501') {
      this.isLoaded = true;
      this.router.navigate(['/order-failed']);
    } else {
      const id = this.route.snapshot.params.id;
      this.updateOrder(id);

      this.intervalSubscription = this.orderInterval.subscribe(() => {
        this.updateOrder(id);
      });
    }
  }

  ngOnDestroy(): void {
    if (this.intervalSubscription) {
      this.intervalSubscription.unsubscribe();
    }
  }

  getLogo(): string {
    return this.logo;
  }

  countItems(): number {
    let toReturn = 0;
    this.sessionStorageNewOrder.orderItems.forEach((thisOrderItem) => {
      toReturn += thisOrderItem.quantity;
    });
    return toReturn;
  }

  updateOrder(id: string) {
    this.newOrderService.get(id).subscribe(
      (order: NewOrderApiModel) => {
        this.basketService.setFoodTotalPriceBackendCalculation(order.orderPayment.foodTotalPrice);
        this.basketService.setDeliveryCostBackendCalculation(order.orderPayment.deliveryCost);
        this.basketService.setFinalPriceBackendCalculation(order.orderPayment.totalPrice);

        this.sessionStorageNewOrder = order;
        this.sessionStorageTableId = order.tablePublicId;

        if (order.localOrderIdentifier) {
          order.localOrderIdentifier = order.localOrderIdentifier.replace(/\(.*\)/, '').trim();
        }

        this.placeService.getPlace().subscribe((placeResponse) => {
          if (placeResponse.image && placeResponse.image.url) {
            this.logo = placeResponse.image.url;
          } else {
            this.logo = '../../../assets/images/' + environment.defaultLogoName;
          }

          if (this.sessionStorageTableId) {
            placeResponse.rooms.forEach((room) => {
              const table = room.tables.find((table) => table.publicId == this.sessionStorageTableId);
              if (table) {
                this.tableName = table.name;
                this.roomName = room.name;
              }
            });
          }
        });
        /* Kopiowanie zawartosci koszyka w celu wyświetlenia podsumowania zamówienia */
        if (sessionStorage.getItem('clearBasket') == null) {
          sessionStorage.setItem('clearBasket', 'cleared');
          this.basketItemsClone = cloneDeep(this.basketService.basketItems);
        }
        /* Czyszczenie koszyka po sfinalizowaniu zamówienia tylko jeżeli odpowiedź jest success */
        // this.basketService.basketItems = [];

        this.isLoaded = true;

        if (
          order.localOrderIdentifier ||
          order.status == OrderStatusEnum.canceled ||
          order.status == OrderStatusEnum.finished
        ) {
          this.intervalSubscription.unsubscribe();
        }
      },
      () => {
        this.intervalSubscription.unsubscribe();
        this.isLoaded = true;
        this.router.navigate(['/']);
      },
    );
  }

  getRestaurant() {
    this.placeService.getPlace().subscribe((result) => {
      this.restaurant = result;
    });
  }

  get orderType(): string {
    return this.orderService.getOrderType();
  }

  get deliveryTime(): string {
    return this.orderService.getDeliveryHours();
  }
}

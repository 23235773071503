import { AbstractViewModel } from '../../../abstract.view.model';
import { DeliveryZoneApiModel } from '@core/models/tapp-order/api-model/delivery-zones/delivery-zone.api.model';

export class DeliveryZoneViewModel extends AbstractViewModel<DeliveryZoneApiModel> {
  public id: number;
  public placePublicId: string;
  public name: string;
  public shapeType: string;
  public deliveryPrice: number;
  public minOrderPrice: number;
  public minOrderPriceForFreeDelivery: number;
  public color: string;
  public coordinates: Array<Array<number>[]>[] = [[]];
  public radius: number;

  constructor(protected apiModel: DeliveryZoneApiModel = new DeliveryZoneApiModel()) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.id = this.apiModel.id;
    this.name = this.apiModel.name;
    this.placePublicId = this.apiModel.placePublicId;
    this.name = this.apiModel.name;
    this.shapeType = this.apiModel.shapeType;
    this.deliveryPrice = this.apiModel.deliveryPrice;
    this.minOrderPrice = this.apiModel.minOrderPrice;
    this.minOrderPriceForFreeDelivery = this.apiModel.minOrderPriceForFreeDelivery;
    this.color = this.apiModel.color;
    this.coordinates = this.apiModel.coordinates;
    this.radius = this.apiModel.radius;
  }

  toApiModel(): DeliveryZoneApiModel {
    return undefined;
  }

  toString() {
    return this.name;
  }
}

<div class="payments-layout">
  <div class="content">
    <app-payment-form></app-payment-form>
  </div>

  <app-sidebar
    class="basket"
    [basketItemsAmount]="basketService.calculateAllItemsInBasket()"
    [basketItems]="this.basketService.basketItems"
    [basketItemsPrice]="basketService.calculatePrice()"
    [paymentMode]="true"
  ></app-sidebar>
</div>

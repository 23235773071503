import { Expose } from 'class-transformer';

@Expose()
export class CreateOrderItemModifierRequestApiModel {
  @Expose()
  public internalId: string;

  @Expose()
  public productPublicId: string;

  @Expose()
  public modifierPublicId: string;

  @Expose()
  public name: string;

  @Expose()
  public quantity: number;
}

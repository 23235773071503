import { TableApiModel } from './table.api.model';
import { Exclude, Expose, Type } from 'class-transformer';

@Exclude()
export class RoomApiModel {
  @Expose()
  public name: string;

  @Expose()
  @Type(() => TableApiModel)
  public tables: TableApiModel[];
}

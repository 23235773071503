import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CompanyApiModel } from '@api/model/user/company.api.model';
import { UserRestService } from '@api/rest/user/user.rest.service';
import { ConfirmationService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-company-tab',
  templateUrl: './company-tab.component.html',
  styleUrls: ['./company-tab.component.scss'],
})
export class CompanyTabComponent implements OnInit {
  public displayInvoiceForm: boolean = false;
  private _company: CompanyApiModel;
  private _isActive: boolean = false;

  get company(): CompanyApiModel {
    return this._company;
  }

  @Input() set company(value: CompanyApiModel) {
    this._company = value;
  }

  get isActive(): boolean {
    return this._isActive;
  }

  @Input() set isActive(value: boolean) {
    this._isActive = value;
  }

  @Output() changeDefaultCompanyEvent = new EventEmitter<CompanyApiModel>();
  @Output() deleteCompanyEvent = new EventEmitter<CompanyApiModel>();
  @Output() changeCompanyListEvent = new EventEmitter<CompanyApiModel>();

  constructor(
    private userRestService: UserRestService,
    private confirmationService: ConfirmationService,
    private translateService: TranslateService,
  ) {}

  public companyFormEventHandler(event): void {
    const companyApiModel = new CompanyApiModel();
    companyApiModel.taxNumber = event.controls['nip'].value;
    companyApiModel.companyName = event.controls['name'].value;
    companyApiModel.city = event.controls['city'].value;
    companyApiModel.street = event.controls['street'].value;
    companyApiModel.buildingNo = event.controls['streetNo'].value;
    companyApiModel.localNo = event.controls['localNo'].value;
    companyApiModel.zipCode = event.controls['postalCode'].value;
    companyApiModel.country = event.controls['country'].value;
    companyApiModel.isDefault = true;
    if (this.company) {
      companyApiModel.id = this.company.id;
      this.userRestService.putUserCompany(companyApiModel).subscribe((res) => {
        this.displayInvoiceForm = false;
        this.changeCompanyListEvent.emit(res);
      });
    } else {
      this.userRestService.postUserCompany(companyApiModel).subscribe((res) => {
        this.displayInvoiceForm = false;
        this.changeCompanyListEvent.emit(res);
      });
    }
  }

  public onDefaultCompanyChangeEvent(): void {
    this.company.isDefault = this.isActive;
    this.changeDefaultCompanyEvent.emit(this._company);
  }

  public openNewCompanyModal(): void {
    this.displayInvoiceForm = true;
    this.isActive = false;
  }

  ngOnInit(): void {}

  public confirmDeleteCompany(): void {
    this.confirmationService.confirm({
      header: this.translateService.instant('app.components.confirm-dialog-live-order.delete-company.header'),
      message: this.translateService.instant('app.components.confirm-dialog-live-order.delete-company.message'),
      acceptLabel: this.translateService.instant(
        'app.components.confirm-dialog-live-order.delete-company.accept-label',
      ),
      rejectLabel: this.translateService.instant(
        'app.components.confirm-dialog-live-order.delete-company.reject-label',
      ),
      accept: () => {
        this.deleteCompanyEvent.emit(this.company);
      },
      reject: () => {},
      key: 'confirm-dialog-live-order',
    });
  }
}

<div class="row" class="discount-dialog-container">
  <p class="discount-dialog-header-text">
    {{ 'tapp-order.pages.discount.dialog.delivery-change-title' | translate }}
  </p>

  <div class="buttons-container">
    <button class="save-button ui-clickable" (click)="close()">
      {{ 'tapp-order.pages.discount.dialog.buttons.close' | translate }}
    </button>
  </div>
</div>

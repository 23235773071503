import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TableViewModel } from '@core/models/tapp-order/view-model/place/table.view.model';
import { LoadingService } from 'src/app/shared/loading/loading.service';
import { PlaceService } from '../../../services/place.service';
import { BaseDataProvider } from '../../data-providers/base.data-provider';

@Component({
  selector: 'app-init-table-page',
  templateUrl: './init-table.component.html',
  styleUrls: ['./init-table.component.scss'],
})
export class InitTableComponent implements OnInit, OnDestroy {
  private subscriber = null;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private loadingService: LoadingService,
    private baseDataProvider: BaseDataProvider,
    private placeService: PlaceService,
  ) {}

  ngOnInit(): void {
    this.loadingService.showLoader();

    const placeId = this.route.snapshot.params.placeId;
    const tableId = this.route.snapshot.params.tableId;

    this.subscriber = this.baseDataProvider
      .getTable(tableId)
      .subscribe(
        (table: TableViewModel) => {
          if (table.placePublicId == placeId) {
            sessionStorage.setItem('tableId', tableId);
            sessionStorage.setItem('tableName', table.name);
            sessionStorage.setItem('roomName', table.roomName);
            this.placeService.use(placeId);

            this.router.navigate(['/', placeId]);
          } else {
            this.redirectToStart();
          }
        },
        () => {
          this.redirectToStart();
        },
      )
      .add(() => {
        this.loadingService.hideLoader();
      });
  }

  private redirectToStart() {
    sessionStorage.removeItem('tableId');
    sessionStorage.removeItem('tableName');
    sessionStorage.removeItem('roomName');

    this.router.navigate(['/start']);
  }

  ngOnDestroy(): void {
    if (this.subscriber) {
      this.subscriber.unsubscribe();
    }
  }
}

import { Exclude, Expose, Transform, Type } from 'class-transformer';
import { ProductApiModel } from '../product/product.api.model';
import { OrderReasonEnum } from './OrderReasonEnum';
import { OrderStatusEnum } from './OrderStatusEnum';
import { OrderTypeEnum } from './OrderTypeEnum';
import { OrderDeliveryAdressApiModel } from './order-delivery-adress.api.model';
import { OrderItemApiModel } from './order-item.api.model';
import { OrderPaymentApiModel } from './order-payment.api.model';

@Exclude()
export class NewOrderApiModel {
  @Expose()
  @Type(() => Date)
  @Transform(({ value }) => (value as Date).toISOString(), {
    toPlainOnly: true,
  })
  public createdAt: Date;

  @Expose()
  public publicId: string;

  @Expose()
  public customerName: string;

  @Expose()
  public customerEmail: string;

  @Expose()
  public notes: string;

  @Expose()
  @Type(() => Date)
  @Transform(({ value }) => (value as Date).toISOString(), {
    toPlainOnly: true,
  })
  public pickupTime: Date;

  @Expose()
  @Type(() => Date)
  @Transform(({ value }) => (value as Date).toISOString(), {
    toPlainOnly: true,
  })
  public deliveryTime: Date;

  @Expose()
  public type: OrderTypeEnum;

  @Expose()
  public status: OrderStatusEnum;

  @Expose()
  public reason: OrderReasonEnum;

  @Expose()
  public tablePublicId?: string;

  @Expose()
  public localOrderIdentifier?: string;

  @Expose()
  @Type(() => OrderItemApiModel)
  public orderItems: OrderItemApiModel[];

  @Expose()
  public orderPayment: OrderPaymentApiModel;

  @Expose()
  public deliveryAdress: OrderDeliveryAdressApiModel;

  @Expose()
  @Type(() => ProductApiModel)
  public place: ProductApiModel;
}

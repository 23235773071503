<div class="row">
  <div class="col">
    <div class="row">
      <div class="col d-flex justify-content-end">
        <div class="article-card-x-button">
          <img
            (click)="dialogRef.close()"
            class="ui-clickable"
            style="width: 14px; height: 14px; padding: 0"
            src="../../../assets/icons/tapp-order-x-button.png"
          />
        </div>
      </div>
    </div>
    <div class="row">
      <form [formGroup]="passwordResetForm" class="login-form form">
        <div class="col">
          <div class="row">
            <div class="col modal-title text-center">
              <span>{{ 'tapp-order.pages.logged-in-user.components.password-change-modal.title' | translate }}</span>
            </div>
          </div>
          <div class="row">
            <div class="col modal-description text-center">
              <span>{{
                'tapp-order.pages.logged-in-user.components.password-change-modal.modal-description1' | translate
              }}</span>
            </div>
          </div>
          <div class="row" style="margin-bottom: 2.31rem">
            <div class="col">
              <div class="form-group password" style="margin-bottom: 0">
                <input
                  type="password"
                  pInputText
                  appInput
                  formControlName="password"
                  id="password"
                  required="true"
                  #password
                />
                <label for="password">{{
                  'tapp-order.pages.logged-in-user.components.password-change-modal.modal-description1' | translate
                }}</label>
                <img
                  class="show-password ui-clickable"
                  [src]="
                    password.type == 'password'
                      ? '../../../../../../assets/icons/show-password.png'
                      : '../../../../../../assets/icons/show-password-1.png'
                  "
                  (click)="password.type = password.type == 'password' ? 'text' : 'password'"
                />
              </div>
              <div class="form-group" style="all: unset">
                <p
                  class="input-label"
                  [ngClass]="{
                    'input-label-error':
                      passwordResetForm.controls['password'].errors &&
                      passwordResetForm.controls['password'].touched &&
                      passwordResetForm.controls['password'].dirty
                  }"
                  >{{ 'tapp-order.pages.register.form.password-information' | translate }}</p
                >
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col modal-description text-center">
              <span>{{
                'tapp-order.pages.logged-in-user.components.password-change-modal.modal-description2' | translate
              }}</span>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="form-group">
                <input
                  type="password"
                  pInputText
                  appInput
                  formControlName="repeatPassword"
                  id="repeat-password"
                  required="true"
                  #repeatPassword
                />
                <label for="repeat-password">{{
                  'tapp-order.pages.logged-in-user.components.password-change-modal.modal-description2' | translate
                }}</label>
                <img
                  class="show-password ui-clickable"
                  [src]="
                    repeatPassword.type == 'password'
                      ? '../../../../../../assets/icons/show-password.png'
                      : '../../../../../../assets/icons/show-password-1.png'
                  "
                  (click)="repeatPassword.type = repeatPassword.type == 'password' ? 'text' : 'password'"
                />
              </div>
            </div>
          </div>
          <div class="row my-1" *ngIf="showError">
            <div class="error col text-center">
              <div>{{ 'tapp-order.pages.logged-in-user.components.login-modal.something-went-wrong' | translate }}</div>
            </div>
          </div>
          <div class="row">
            <div class="col text-center">
              <button type="submit" (click)="onSubmit()" class="btn btn-primary" [disabled]="!isFormCorrect">
                <span *ngIf="!loginLoading; else spinner">{{
                  'tapp-order.pages.logged-in-user.components.password-change-modal.submit-button' | translate
                }}</span>
                <ng-template #spinner>
                  <span class="loader"></span>
                </ng-template>
              </button>
            </div>
          </div>
          <div class="row">
            <div class="col cancel text-center">
              <span class="ui-clickable" (click)="dialogRef.close()">{{
                'tapp-order.pages.logged-in-user.components.password-change-modal.cancel-button' | translate
              }}</span>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

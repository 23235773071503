import { AbstractViewModel } from '../../../abstract.view.model';
import { PaymentProviderApiModel } from '@core/models/tapp-order/api-model/place/payment-provider.api.model';

export class PaymentProviderViewModel extends AbstractViewModel<PaymentProviderApiModel> {
  public paymentProvider: string;

  constructor(protected apiModel: PaymentProviderApiModel = new PaymentProviderApiModel()) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.paymentProvider = this.apiModel.paymentProvider;
  }

  toApiModel(): PaymentProviderApiModel {
    return new PaymentProviderApiModel();
  }
}

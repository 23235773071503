import { Directive, ElementRef, Input, Optional } from '@angular/core';
import { fromEvent } from 'rxjs';
import { shareReplay, tap } from 'rxjs/operators';
import { FormGroup } from '@angular/forms';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: 'form, div[formGroup]',
})
export class FormSubmitDirective {
  @Input() formGroup: FormGroup;
  submit$ = fromEvent(this.element, 'submit').pipe(
    tap(() => {
      this.markAsTouched(this.formGroup);
      if (this.element.classList.contains('ng-submitted') === false) {
        this.element.classList.add('ng-submitted');
      }
      const previousSibling = this.element.previousElementSibling;
      if (previousSibling !== null && previousSibling.nodeName.toLowerCase() === 'p-messages') {
        const previousSiblingList = previousSibling.querySelector('ul');
        if (previousSiblingList) {
          const childCount = previousSiblingList.childElementCount;
          if (childCount > 1) {
            for (let i = 0; i < childCount - 1; i++) {
              previousSiblingList.removeChild(previousSiblingList.querySelector('li'));
            }
          }
        }
      }
    }),
    shareReplay(1),
  );

  constructor(private host: ElementRef<HTMLFormElement>) {}

  get element() {
    return this.host.nativeElement;
  }

  public markAsTouched(formGroup: FormGroup): void {
    formGroup.markAsTouched();
    formGroup.updateValueAndValidity();
    (<any>Object).values(formGroup.controls).forEach((control) => {
      if (control.errors) {
        control.markAsTouched();
        control.updateValueAndValidity({ onlySelf: false, emitEvent: true });
      }
      if (control.controls) {
        this.markAsTouched(control);
      }
    });
  }
}

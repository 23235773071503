import { Injectable } from '@angular/core';
import { DeliveryZonesHttpService } from '@core/http/tapp-order/delivery-zones/delivery-zones.http.service';
import { GeoapiHttpService } from '@core/http/tapp-order/geoapi/geoapi.http.service';
import { TermsAndConditionsHttpService } from '@core/http/tapp-order/legal-and-marketing/terms-and-conditions.http.service';
import { TermsAndPrivacyPolicyHttpService } from '@core/http/tapp-order/legal-and-marketing/terms-and-privacy-policy.http.service';
import { DeliveryZoneApiModel } from '@core/models/tapp-order/api-model/delivery-zones/delivery-zone.api.model';
import { GeoapiApiModel } from '@core/models/tapp-order/api-model/geoapi/geoapi.api.model';
import { WorkingHoursApiModel } from '@core/models/tapp-order/api-model/working-hours/working-hours.api.model';
import { HolidayViewModel } from '@core/models/tapp-order/view-model/holiday/holiday.view.model';
import { TermsAndConditionsItemViewModel } from '@core/models/tapp-order/view-model/terms-and-conditions/terms-and-conditions-item.view.model';
import { TermsAndPrivacyPolicyItemViewModel } from '@core/models/tapp-order/view-model/terms-and-conditions/terms-and-privacy-policy-item.view.model';
import { OrderService } from '@core/services/order.service';
import { ShapeTypeEnum } from '@ui/address-finder/enum/shape-type.enum';
import * as L from 'leaflet';
import { Observable, of } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';
import { PlaceService } from 'src/app/services/place.service';
import { PlaceViewModel } from '../models/tapp-order/view-model/place/place.view.model';
import { BaseDataProvider } from './base.data-provider';

interface DeliveryAddress {
  postCode: string;
  city: string;
  street: string;
  buildingNo: string;
}

@Injectable({
  providedIn: 'root',
})
export class PlaceDataProvider {
  private _deliveryZones: DeliveryZoneApiModel[] = [];

  constructor(
    private placeService: PlaceService,
    private baseDataProvider: BaseDataProvider,
    private orderService: OrderService,
    private termsAndConditionsHttpService: TermsAndConditionsHttpService,
    private termsAndPrivacyPolicyHttpService: TermsAndPrivacyPolicyHttpService,
    private deliveryZonesHttpService: DeliveryZonesHttpService,
    private geoapiHttpService: GeoapiHttpService,
  ) {}

  private getPlaceId(): Observable<string> {
    const placeId = sessionStorage.getItem('placeId');

    if (placeId) {
      return of(placeId);
    } else {
      return this.placeService.getPlaces().pipe(
        switchMap((places) => {
          if (!places?.length) {
            throw of(null);
          } else {
            return of(places[0].publicId);
          }
        }),
      );
    }
  }

  private getFullDeliveryAddress(deliveryAddress: DeliveryAddress): string {
    const parts = [deliveryAddress.street, deliveryAddress.buildingNo, deliveryAddress.postCode, deliveryAddress.city];

    return parts.filter((part) => part && part != 'null').join(', ');
  }

  private findMatchingGeoItem(
    geoList: GeoapiApiModel[],
    deliveryAddress: DeliveryAddress,
    zones: DeliveryZoneApiModel[],
  ): DeliveryZoneApiModel | null {
    const geoItem = geoList.find(
      (item) =>
        item.city.toLowerCase() === deliveryAddress.city.toLowerCase() &&
        item.street.toLowerCase() === deliveryAddress.street.toLowerCase() &&
        item.houseNumber.toLowerCase() === deliveryAddress.buildingNo.toLowerCase() &&
        item.postCode.toLowerCase() === deliveryAddress.postCode.toLowerCase(),
    );

    if (!geoItem) {
      return null;
    }

    this.verifyGeo(geoItem, zones);

    const deliveryZone = this.getDeliveryZoneWithLowestDeliveryPrice();

    this._deliveryZones = [];

    return deliveryZone;
  }

  public getDeliveryZoneWithLowestDeliveryPrice(): DeliveryZoneApiModel | null {
    if (!this._deliveryZones || this._deliveryZones.length === 0) {
      return null;
    }

    return this._deliveryZones.reduce(
      (bestZone, currentZone) =>
        !bestZone || currentZone.deliveryPrice < bestZone.deliveryPrice ? currentZone : bestZone,
      null,
    );
  }

  private verifyGeo(geo: GeoapiApiModel, deliveryZones: DeliveryZoneApiModel[]): void {
    this._deliveryZones = [];

    deliveryZones.forEach((deliveryZone) => {
      switch (deliveryZone.shapeType) {
        case ShapeTypeEnum.polygon:
          this.findPointInPolygonDeliveryZone(deliveryZone, geo);
          break;

        case ShapeTypeEnum.circle:
          this.findPointInCircleDeliveryZone(deliveryZone, geo);
          break;
      }
    });
  }

  private findPointInPolygonDeliveryZone(deliveryZone, currentAddress: GeoapiApiModel): void {
    const polygon = L.polygon(deliveryZone.coordinates);

    if (this.isMarkerInsidePolygon(currentAddress.longitude, currentAddress.latitude, polygon)) {
      this._deliveryZones.push(deliveryZone);
    }
  }

  private findPointInCircleDeliveryZone(deliveryZone, currentAddress: GeoapiApiModel): void {
    var fromLatLng = L.latLng(currentAddress.latitude, currentAddress.longitude);

    let latitude;
    let longitude;

    if (Array.isArray(deliveryZone.coordinates[0])) {
      latitude = deliveryZone.coordinates[0][1];
      longitude = deliveryZone.coordinates[0][0];
    } else {
      latitude = deliveryZone.coordinates[1];
      longitude = deliveryZone.coordinates[0];
    }

    var toLatLng = L.latLng(latitude, longitude);

    if (fromLatLng.distanceTo(toLatLng) <= deliveryZone.radius) {
      this._deliveryZones.push(deliveryZone);
    }
  }

  // Ray Casting algorithm
  private isMarkerInsidePolygon(x, y, poly) {
    let inside = false;
    for (let ii = 0; ii < poly.getLatLngs().length; ii++) {
      let polyPoints = poly.getLatLngs()[ii];
      for (let i = 0, j = polyPoints.length - 1; i < polyPoints.length; j = i++) {
        let xi = polyPoints[i].lat,
          yi = polyPoints[i].lng;
        let xj = polyPoints[j].lat,
          yj = polyPoints[j].lng;

        let intersect = yi > y != yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
        if (intersect) {
          inside = !inside;
        }
      }
    }
    return inside;
  }

  public getCurrentOpeningStatus(): Observable<boolean> {
    return this.placeService.getPlace({ bypassCache: true }).pipe(
      switchMap((place: PlaceViewModel) => {
        return this.baseDataProvider.getHolidaysList().pipe(
          map((holidays: HolidayViewModel[]) => {
            return this.orderService.isPlaceOpen(place, holidays);
          }),
        );
      }),
    );
  }

  public getNextWorkingHours(): Observable<WorkingHoursApiModel> {
    return this.placeService.getPlace().pipe(
      switchMap((place: PlaceViewModel) => {
        return this.baseDataProvider.getHolidaysList().pipe(
          map((holidays: HolidayViewModel[]) => {
            return this.orderService.getNextWorkingHoursData(place, holidays);
          }),
        );
      }),
    );
  }

  public getTermsAndConditions(
    category: 'orders' | 'registration' | 'userSettings',
  ): Observable<TermsAndConditionsItemViewModel[]> {
    return this.getPlaceId().pipe(
      switchMap((placeId) => {
        return this.termsAndConditionsHttpService
          .get(placeId, category)
          .pipe(
            map((termsAndConditions) => termsAndConditions.map((item) => new TermsAndConditionsItemViewModel(item))),
          );
      }),
    );
  }

  public getTermsAndPrivacyPolicy(): Observable<TermsAndPrivacyPolicyItemViewModel[]> {
    return this.getPlaceId().pipe(
      switchMap((placeId) => {
        return this.termsAndPrivacyPolicyHttpService
          .get(placeId)
          .pipe(map((list) => list.map((item) => new TermsAndPrivacyPolicyItemViewModel(item))));
      }),
    );
  }

  public findDeliveryZoneByAddress(deliveryAddress: DeliveryAddress): Observable<DeliveryZoneApiModel | null> {
    return this.deliveryZonesHttpService.getDeliveryZoneList().pipe(
      first(),
      switchMap((zones) => {
        if (!zones?.length) {
          return of(null);
        }

        return this.geoapiHttpService.search(this.getFullDeliveryAddress(deliveryAddress)).pipe(
          first(),
          map((geoList) => this.findMatchingGeoItem(geoList, deliveryAddress, zones)),
        );
      }),
    );
  }
}

<form (submit)="onSubmit()" [formGroup]="loginForm" class="row register-form login-form">
  <button class="close" (click)="dialogRef.close()">X</button>
  <ng-container *ngIf="!isPasswordReset; else passwordReset">
    <div class="col">
      <div class="row">
        <div class="col modal-title text-center">
          <span>{{ 'tapp-order.pages.logged-in-user.components.login-modal.title' | translate }}</span>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="form-group">
            <input type="text" pInputText appInput formControlName="username" id="email" required="true" />
            <label for="email">{{ 'tapp-order.pages.logged-in-user.components.login-modal.email' | translate }}</label>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="form-group">
            <input
              type="password"
              pInputText
              appInput
              formControlName="password"
              id="password"
              required="true"
              #password
            />
            <label for="password">{{
              'tapp-order.pages.logged-in-user.components.login-modal.password' | translate
            }}</label>
            <img
              class="show-password ui-clickable"
              [src]="
                password.type == 'password'
                  ? '../../../assets/icons/show-password.png'
                  : '../../../assets/icons/show-password-1.png'
              "
              (click)="password.type = password.type == 'password' ? 'text' : 'password'"
            />
          </div>
          <a (click)="isPasswordReset = true" class="forgot-password ui-clickable">{{
            'tapp-order.pages.logged-in-user.components.login-modal.forgot-password' | translate
          }}</a>
        </div>
      </div>
      <div class="row my-1" *ngIf="error">
        <div class="error col text-center">
          <div>{{ error }}</div>
        </div>
      </div>
      <div class="row login-button-container">
        <div class="col text-center">
          <button type="submit" #submitButton class="btn btn-primary">
            <span *ngIf="!loginLoading; else spinner">{{
              'tapp-order.pages.logged-in-user.components.login-modal.login' | translate
            }}</span>
            <ng-template #spinner>
              <span class="loader"></span>
            </ng-template>
          </button>
        </div>
      </div>
      <ng-container *ngIf="socialMediaArray.length > 0">
        <div class="row another-login-options-header">
          <div class="col">
            <p>{{ 'tapp-order.pages.logged-in-user.components.login-modal.login-alternatives' | translate }}:</p>
          </div>
        </div>
        <div class="row another-login-options">
          <div class="col-6 d-flex justify-content-center" *ngIf="socialMediaArray.includes(SocialMediaEnum.fb)">
            <app-facebook-login-button style="width: 100%"></app-facebook-login-button>
          </div>
          <div class="col-6 d-flex justify-content-center" *ngIf="socialMediaArray.includes(SocialMediaEnum.google)">
            <app-google-login-button style="width: 100%"></app-google-login-button>
          </div>
        </div>
      </ng-container>
      <!--      <div class="row another-login-options">-->
      <!--        <div class="col-6 d-flex justify-content-center">-->
      <!--          <app-apple-login-button style="width: 100%"></app-apple-login-button>-->
      <!--        </div>-->
      <!--        <div class="col-6 d-flex justify-content-center">-->
      <!--          <app-instagram-login-button style="width: 100%"></app-instagram-login-button>-->
      <!--        </div>-->
      <!--      </div>-->
      <div class="row register-info">
        <div class="col">
          <span
            >{{ 'tapp-order.menu.register-text' | translate }}
            <a (click)="dialogRef.close()" [routerLink]="['register']" class="primary-color">{{
              'tapp-order.pages.logged-in-user.components.login-modal.register' | translate
            }}</a></span
          >
        </div>
      </div>
    </div>
  </ng-container>
  <ng-template #passwordReset>
    <app-reset-password-modal (cancelPasswordResetting)="isPasswordReset = false"></app-reset-password-modal>
  </ng-template>
</form>

import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { PaymentService } from '@core/services/payment.service';
import { OrderService } from '@core/services/order.service';
import { PaymentFormService } from '@core/pages/payment/payment-form.service';
import { InvoiceFormComponent } from './invoice-form/invoice-form.component';
import { AuthService } from '@core/services/auth.service';
import { UserRestService } from '@api/rest/user/user.rest.service';
import { UserSettngsComponent } from '@core/pages/logged-in-user/user-settings/user-settngs.component';

@Component({
  selector: 'app-payment-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [UserSettngsComponent],
})
export class InvoiceComponent implements OnInit {
  @ViewChild('invoiceForm') private invoiceForm: InvoiceFormComponent;

  checked: boolean;
  displayInvoiceForm = false;

  constructor(
    private paymentService: PaymentService,
    public orderService: OrderService,
    public paymentFormService: PaymentFormService,
    public authService: AuthService,
    private userRestService: UserRestService,
    private userSettngsComponent: UserSettngsComponent,
  ) {}

  ngOnInit(): void {
    if (this.authService.isAuthenticated()) {
      this.userRestService.getUser().subscribe((value) => {
        if (value.companies.find((val) => val.isDefault === true)) {
          const selectedCompany = value.companies.find((val) => val.isDefault === true);
          this.userSettngsComponent.turnOnInvoice(selectedCompany);
        }
        this.checked = this.orderService.getInvoiceSelected() === 'true';
      });
    }
  }

  trigger(): void {
    this.paymentService.invoiceTrigger();
    this.paymentService.getInvoiceStatus();
  }

  get invoiceLabel(): string {
    return this.paymentService.getInvoiceLabel();
  }

  changeInvoiceSwitch(event: any): void {
    if (event) {
      this.orderService.setInvoiceNip('');
      this.orderService.setInvoiceCountry('');
      this.orderService.setInvoicePostalCode('');
      this.orderService.setInvoiceCompanyStreet('');
      this.orderService.setInvoiceCompanyName('');
      this.orderService.setInvoiceCompanyLocalNo('');
      this.orderService.setInvoiceCompanyCity('');
      this.orderService.setInvoiceCompanyStreetNo('');
      this.showInvoiceForm();
    } else {
      this.orderService.setInvoiceSelected('false');
      this.paymentFormService.paymentInvoiceRejected = false;
    }
  }

  public showInvoiceForm(): void {
    this.displayInvoiceForm = true;
    this.invoiceForm.createInvoiceForm();
    setTimeout(() => {
      this.invoiceForm.focusOnNip();
    }, 0);
  }

  public onCloseDialog(): void {
    if (!this.orderService.getInvoiceNip()) {
      this.checked = false;
      this.orderService.setInvoiceSelected('false');
    } else {
      this.checked = true;
      this.orderService.setInvoiceSelected('true');
    }

    this.displayInvoiceForm = false;
  }
}

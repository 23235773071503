import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PlaceViewModel } from '@core/models/tapp-order/view-model/place/place.view.model';

@Component({
  selector: 'app-unexpected-issue-dialog',
  templateUrl: './unexpected-issue-dialog.component.html',
  styleUrls: ['./unexpected-issue-dialog.component.scss'],
})
export class UnexpectedIssueDialogComponent implements OnInit {
  @Input() visible = false;
  @Output() visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() place?: PlaceViewModel;

  constructor() {}

  ngOnInit(): void {}

  public close(): void {
    this.visibleChange.emit(false);
  }
}

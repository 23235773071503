import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ResponseValidatorService } from '../services/validators/response-validator.service';

@Injectable()
export class ResponseInterceptor implements HttpInterceptor {
  constructor(private responseValidatorService: ResponseValidatorService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.indexOf('.json') === -1) {
      return next.handle(req).pipe(
        tap(
          (event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
            }
          },
          (err: Error) => {
            if (err instanceof HttpErrorResponse) {
              console.error('INTERCEPTOR', err);
              this.responseValidatorService.handleGlobalErrors(err);
            }
          },
        ),
      );
    }

    return next.handle(req);
  }
}

import {
  Directive,
  ElementRef,
  HostBinding,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  Self,
  SimpleChanges,
} from '@angular/core';
import { AbstractControl, NgControl } from '@angular/forms';
import { Subject } from 'rxjs';

let nextUniqueId = 0;

@Directive({
  selector: 'input[appInput], textarea[appInput]',
  exportAs: 'appInput',
})
export class InputDirective implements OnInit, OnChanges, OnDestroy {
  protected _inputValueAccessor: { value: any };
  protected _uid = `app-input-${nextUniqueId++}`;
  protected _disabled = false;
  protected _id: string;
  protected _type = 'text';
  readonly stateChanges: Subject<void> = new Subject<void>();
  focused = false;

  @HostBinding('class.form-control') get isFormControl(): boolean {
    return true;
  }
  // @HostBinding('attr.placeholder') get isPlaceholder(): boolean {
  //   return false;
  // }
  @HostBinding('attr.required')
  get required() {
    return this.isRequired;
  }
  @HostBinding('class.is-required') get classRequired() {
    return this.isRequired;
  }

  get isRequired(): boolean {
    const control = this.ngControl.control;
    if (control.validator) {
      const validator = control.validator({} as AbstractControl);
      if (validator && validator.required) {
        return true;
      }
    }
    return false;
  }

  get empty(): boolean {
    return !this._elementRef.nativeElement.value;
  }

  @Input() labelTrans: string = null;
  @Input() placeholder: string;

  @Input()
  get disabled(): boolean {
    if (this.ngControl && this.ngControl.disabled !== null) {
      return this.ngControl.disabled;
    }
    return this._disabled;
  }
  set disabled(value: boolean) {
    this._disabled = value;

    if (this.focused) {
      this.focused = false;
      this.stateChanges.next();
    }
  }

  @Input()
  get id(): string {
    return this._id;
  }
  set id(value: string) {
    this._id = value || this._uid;
  }

  @Input()
  get type(): string {
    return this._type;
  }
  set type(value: string) {
    this._type = value;
    // this.validateType(value);
  }

  @Input()
  get value(): string {
    return this._inputValueAccessor.value;
  }
  set value(value: string) {
    value = value === '' ? null : value;
    if (value !== this.value) {
      this._inputValueAccessor.value = value;
      this.stateChanges.next();
    }
  }

  @Input()
  get readonly(): boolean {
    return this._readonly;
  }
  set readonly(value: boolean) {
    this._readonly = value;
  }
  private _readonly = false;

  @HostListener('focus', ['$event.target'])
  onFocus(e): void {
    this._focusChanged(true);
  }

  @HostListener('blur', ['$event.target'])
  onBlur(e): void {
    this._focusChanged(false);
  }

  constructor(
    protected _elementRef: ElementRef<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>,
    @Optional() @Self() public ngControl: NgControl,
  ) {
    this._inputValueAccessor = this._elementRef.nativeElement;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.stateChanges.next();
  }

  ngOnInit(): void {
    this.stateChanges.next();
  }

  ngOnDestroy(): void {
    this.stateChanges.complete();
  }

  _focusChanged(isFocused: boolean): void {
    if (isFocused !== this.focused && (!this.readonly || !isFocused)) {
      this.focused = isFocused;
      this.stateChanges.next();
    }
  }

  clear(): void {
    this._inputValueAccessor.value = null;
    this.stateChanges.next();
  }
}

import { FilterParameterApiModel } from '@core/models/filter-parameter.api.model';

export class QueryParamsApiModel {
  public filters: FilterParameterApiModel[] = [];
  public limit: number;
  public page: number;
  public orderBy: string;
  public orderDirection: any;

  public constructor() {
    this.filters = [];
    this.limit = null;
    this.page = null;
    this.orderBy = null;
  }
}

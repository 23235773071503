import { AbstractViewModel } from '../../../abstract.view.model';
import { SurveyApiModel } from '@core/models/tapp-order/api-model/survey/survey.api.model';
import { SurveyCategoryEnum } from '@shared/enum/survey-category.enum';
import { SurveyQuestionViewModel } from '@core/models/tapp-order/view-model/survey/survey-question.view.model';

export class SurveyViewModel extends AbstractViewModel<SurveyApiModel> {
  public id: number;
  public name: string;
  public active: boolean;
  public category: SurveyCategoryEnum;
  public questions: SurveyQuestionViewModel[] = [];

  constructor(protected apiModel: SurveyApiModel = new SurveyApiModel()) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.id = this.apiModel.id;
    this.name = this.apiModel.name;
    this.active = this.apiModel.active;
    this.category = this.apiModel.category;
    this.questions =
      this.apiModel.questions.length > 0
        ? this.apiModel.questions.map((apiModel) => new SurveyQuestionViewModel(apiModel))
        : [];
  }

  toApiModel(): SurveyApiModel {
    return undefined;
  }

  toString() {
    return this.name;
  }
}

import { Injectable } from '@angular/core';
import { DeliveryZoneApiModel } from '@core/models/tapp-order/api-model/delivery-zones/delivery-zone.api.model';
import { GeoapiApiModel } from '@core/models/tapp-order/api-model/geoapi/geoapi.api.model';
import { OrderTypeEnum } from '@core/models/tapp-order/api-model/order/OrderTypeEnum';
import { PaymentProviderEnum } from '@core/models/tapp-order/api-model/order/PaymentProviderEnum';
import { WorkingHoursApiModel } from '@core/models/tapp-order/api-model/working-hours/working-hours.api.model';
import { HolidayViewModel } from '@core/models/tapp-order/view-model/holiday/holiday.view.model';
import { TermsAndConditionsItemViewModel } from '@core/models/tapp-order/view-model/terms-and-conditions/terms-and-conditions-item.view.model';
import { MenuTypeEnum } from '@shared/enum/menu-type.enum';
import { BehaviorSubject, Observable } from 'rxjs';
import { PlaceViewModel } from '@core/models/tapp-order/view-model/place/place.view.model';

interface DeliveryData {
  zone: DeliveryZoneApiModel;
  zipCode: string;
  city: string;
  street: string;
  buildingNo: string;
  localNo: string;
}

@Injectable({
  providedIn: 'root',
})
export class OrderService {
  private orderTypeKey = 'orderType';
  private customerFirstNameKey = 'customerFirstName';
  private customerPhoneKey = 'customerPhone';
  private customerEmailKey = 'customerEmail';
  private customerNotesKey = 'customerNotes';
  private deliveryZoneIdKey = 'deliveryZoneId';
  private deliveryZoneKey = 'deliveryZone';
  private deliveryAddressCityKey = 'deliveryAddressCity';
  private deliveryAddressZipCodeKey = 'deliveryAddressZipCode';
  private deliveryAddressStreetKey = 'deliveryAddressStreet';
  private deliveryAddressBuildingNoKey = 'deliveryAddressBuildingNo';
  private deliveryAddressLocalNoKey = 'deliveryAddressLocalNo';
  private deliveryHoursKey = 'deliveryTime';
  private paymentProviderKey = 'paymentProvider';
  private paymentMethodKey = 'paymentMethod';
  private paymentMethodTypeKey = 'paymentMethodType';
  private paymentMethodValueKey = 'paymentMethodValue';
  private selectedDeliveryHoursTypeKey = 'selectedDeliveryHoursType';
  private invoiceNipKey = 'invoiceNip';
  private invoiceCompanyNameKey = 'invoiceCompanyName';
  private invoiceCompanyCityKey = 'invoiceCompanyCity';
  private invoiceCompanyStreetKey = 'invoiceCompanyStreet';
  private invoiceCompanyStreetNoKey = 'invoiceCompanyStreetNo';
  private invoiceCompanyLocalNoKey = 'invoiceCompanyLocalNo';
  private invoicePostalCodeKey = 'invoicePostalCode';
  private invoiceCountryKey = 'invoiceCountry';
  private invoiceSelectedKey = 'invoiceSelected';

  private _orderType: string = OrderTypeEnum.delivery;

  private _customerFirstName: string;
  private _customerPhone: string;
  private _customerEmail: string;
  private _customerNotes: string;

  private _deliveryZoneId: number;
  private _deliveryZone: DeliveryZoneApiModel;

  private _deliveryAddressCity: string;
  private _deliveryAddressZipCode: string;
  private _deliveryAddressStreet: string;
  private _deliveryAddressBuildingNo: string;
  private _deliveryAddressLocalNo: string;
  private _deliveryHours: string;
  private _selectedDeliveryHoursType: string;

  private _paymentProvider: string = PaymentProviderEnum.PayU;
  private _paymentMethod: string;
  private _paymentMethodType: string;
  private _paymentMethodValue: string;

  private _invoiceNip: string;
  private _invoiceCompanyName: string;
  private _invoiceCompanyCity: string;
  private _invoiceCompanyStreet: string = '';
  private _invoiceCompanyStreetNo: string;
  private _invoiceCompanyLocalNo: string = '';
  private _invoicePostalCode: string;
  private _invoiceCountry: string;
  private _invoiceSelected: string;

  private _termsAndConditions: TermsAndConditionsItemViewModel[];

  private _orderId: string | null = null;

  private deliveryZoneSubject: BehaviorSubject<DeliveryZoneApiModel> = new BehaviorSubject<DeliveryZoneApiModel>(null);
  public deliveryZoneSubject$: Observable<DeliveryZoneApiModel>;

  private orderTypeSubject: BehaviorSubject<String> = new BehaviorSubject<String>(null);
  public orderTypeSubject$: Observable<String>;

  constructor() {
    this.deliveryZoneSubject$ = this.deliveryZoneSubject.asObservable();
    this.orderTypeSubject$ = this.orderTypeSubject.asObservable();
  }

  public getInvoiceNip(): string {
    return localStorage.getItem(this.invoiceNipKey) ? localStorage.getItem(this.invoiceNipKey) : this._invoiceNip;
  }

  public setInvoiceNip(value: string): void {
    this._invoiceNip = value;
    localStorage.setItem(this.invoiceNipKey, value);
  }

  public getInvoiceCompanyName(): string {
    return localStorage.getItem(this.invoiceCompanyNameKey)
      ? localStorage.getItem(this.invoiceCompanyNameKey)
      : this._invoiceCompanyName;
  }

  public setInvoiceCompanyName(value: string): void {
    this._invoiceCompanyName = value;
    localStorage.setItem(this.invoiceCompanyNameKey, value);
  }

  public getInvoiceCompanyCity(): string {
    return localStorage.getItem(this.invoiceCompanyCityKey)
      ? localStorage.getItem(this.invoiceCompanyCityKey)
      : this._invoiceCompanyCity;
  }

  public setInvoiceCompanyCity(value: string): void {
    this._invoiceCompanyCity = value;
    localStorage.setItem(this.invoiceCompanyCityKey, value);
  }

  public getInvoiceCompanyStreet(): string {
    return localStorage.getItem(this.invoiceCompanyStreetKey) || this._invoiceCompanyStreet;
  }

  public setInvoiceCompanyStreet(value: string): void {
    this._invoiceCompanyStreet = value;
    localStorage.setItem(this.invoiceCompanyStreetKey, value);
  }

  public getInvoiceCompanyStreetNo(): string {
    return localStorage.getItem(this.invoiceCompanyStreetNoKey)
      ? localStorage.getItem(this.invoiceCompanyStreetNoKey)
      : this._invoiceCompanyStreetNo;
  }

  public setInvoiceCompanyStreetNo(value: string): void {
    this._invoiceCompanyStreetNo = value;
    localStorage.setItem(this.invoiceCompanyStreetNoKey, value);
  }

  public getInvoiceCompanyLocalNo(): string {
    return localStorage.getItem(this.invoiceCompanyLocalNoKey) || this._invoiceCompanyLocalNo;
  }

  public setInvoiceCompanyLocalNo(value: string): void {
    this._invoiceCompanyLocalNo = value;
    localStorage.setItem(this.invoiceCompanyLocalNoKey, value);
  }

  public getInvoicePostalCode(): string {
    return localStorage.getItem(this.invoicePostalCodeKey)
      ? localStorage.getItem(this.invoicePostalCodeKey)
      : this._invoicePostalCode;
  }

  public setInvoicePostalCode(value: string): void {
    this._invoicePostalCode = value;
    localStorage.setItem(this.invoicePostalCodeKey, value);
  }

  public getInvoiceCountry(): string {
    return localStorage.getItem(this.invoiceCountryKey)
      ? localStorage.getItem(this.invoiceCountryKey)
      : this._invoiceCountry;
  }

  public setInvoiceCountry(value: string): void {
    this._invoiceCountry = value;
    localStorage.setItem(this.invoiceCountryKey, value);
  }

  public getInvoiceSelected(): string {
    return localStorage.getItem(this.invoiceSelectedKey)
      ? localStorage.getItem(this.invoiceSelectedKey)
      : this._invoiceSelected;
  }

  public setInvoiceSelected(value: string): void {
    this._invoiceSelected = value;
    localStorage.setItem(this.invoiceSelectedKey, value);
  }

  public getOrderType(): OrderTypeEnum {
    return (
      localStorage.getItem(this.orderTypeKey) ? localStorage.getItem(this.orderTypeKey) : this._orderType
    ) as OrderTypeEnum;
  }

  public getMenuType(): MenuTypeEnum {
    if (this.tableId) {
      return MenuTypeEnum.ONSITE;
    }

    switch (this.getOrderType()) {
      case OrderTypeEnum.pick_up:
        return MenuTypeEnum.PICK_UP;
      case OrderTypeEnum.delivery:
        return MenuTypeEnum.DELIVERY;
      case OrderTypeEnum.at_location:
        return MenuTypeEnum.ONSITE;
    }
  }

  public setOrderType(orderType: string): void {
    this._orderType = orderType;
    localStorage.setItem(this.orderTypeKey, orderType);
    this.orderTypeSubject.next(orderType);
  }

  public getCustomerFirstName(): string {
    return localStorage.getItem(this.customerFirstNameKey)
      ? localStorage.getItem(this.customerFirstNameKey)
      : this._customerFirstName;
  }

  public setCustomerFirstName(firstName: string): void {
    this._customerFirstName = firstName;
    localStorage.setItem(this.customerFirstNameKey, firstName);
  }

  public getCustomerPhone(): string {
    return localStorage.getItem(this.customerPhoneKey)
      ? localStorage.getItem(this.customerPhoneKey)
      : this._customerPhone;
  }

  public setCustomerPhone(phone: string): void {
    this._customerPhone = phone;
    localStorage.setItem(this.customerPhoneKey, phone);
  }

  public getCustomerEmail(): string {
    return localStorage.getItem(this.customerEmailKey)
      ? localStorage.getItem(this.customerEmailKey)
      : this._customerEmail;
  }

  public setCustomerEmail(email: string): void {
    this._customerEmail = email;
    localStorage.setItem(this.customerEmailKey, email);
  }

  public getCustomerNotes(): string {
    return localStorage.getItem(this.customerNotesKey)
      ? localStorage.getItem(this.customerNotesKey)
      : this._customerNotes;
  }

  public setCustomerNotes(notes: string): void {
    this._customerNotes = notes;
    localStorage.setItem(this.customerNotesKey, notes);
  }

  public removeCustomerNotes(): void {
    localStorage.removeItem(this.customerNotesKey);
  }

  public setDeliveryData(deliveryData: DeliveryData): void {
    this.setDeliveryZoneId(deliveryData.zone.id);
    this.setDeliveryZone(deliveryData.zone);
    this.setDeliveryAddressCity(deliveryData.city);
    this.setDeliveryAddressStreet(deliveryData.street);
    this.setDeliveryAddressBuildingNo(deliveryData.buildingNo);
    this.setDeliveryAddressZipCode(deliveryData.zipCode);
    this.setDeliveryAddressLocalNo(deliveryData.localNo);
  }

  /**
   *
   * @deprecated Use setDeliveryData instead
   */
  public saveDeliveryAddress(address: GeoapiApiModel, localNo: string): void {
    if (address) {
      this.setDeliveryAddressCity(address.city);
      this.setDeliveryAddressStreet(address.street);
      this.setDeliveryAddressBuildingNo(address.houseNumber);
      this.setDeliveryAddressZipCode(address.postCode);
      this.setDeliveryAddressLocalNo(localNo);
    }
  }

  public getDeliveryZoneId(): number {
    return localStorage.getItem(this.deliveryZoneIdKey)
      ? Number(localStorage.getItem(this.deliveryZoneIdKey))
      : this._deliveryZoneId;
  }

  public setDeliveryZoneId(deliveryZoneId: number): void {
    this._deliveryZoneId = deliveryZoneId;
    localStorage.setItem(this.deliveryZoneIdKey, deliveryZoneId.toString());
  }

  public removeDeliveryZoneId(): void {
    this._deliveryZoneId = null;
    localStorage.removeItem(this.deliveryZoneIdKey);
  }

  public getDeliveryZone(): DeliveryZoneApiModel {
    let model: DeliveryZoneApiModel;
    if (localStorage.getItem(this.deliveryZoneKey)) {
      model = JSON.parse(localStorage.getItem(this.deliveryZoneKey));
    } else {
      model = this._deliveryZone;
    }
    return model;
  }

  public setDeliveryZone(deliveryZone: DeliveryZoneApiModel): void {
    this._deliveryZone = deliveryZone;
    localStorage.setItem(this.deliveryZoneKey, JSON.stringify(deliveryZone));
    this.deliveryZoneSubject.next(deliveryZone);
  }

  public getDeliveryAddressCity(): string {
    return localStorage.getItem(this.deliveryAddressCityKey)
      ? localStorage.getItem(this.deliveryAddressCityKey)
      : this._deliveryAddressCity;
  }

  public setDeliveryAddressCity(city: string): void {
    this._deliveryAddressCity = city;
    localStorage.setItem(this.deliveryAddressCityKey, city);
  }

  public removeDeliveryAddressCity(): void {
    localStorage.removeItem(this.deliveryAddressCityKey);
  }

  public getDeliveryAddressZipCode(): string {
    return localStorage.getItem(this.deliveryAddressZipCodeKey)
      ? localStorage.getItem(this.deliveryAddressZipCodeKey)
      : this._deliveryAddressZipCode;
  }

  public setDeliveryAddressZipCode(zipCode: string): void {
    this._deliveryAddressZipCode = zipCode;
    localStorage.setItem(this.deliveryAddressZipCodeKey, zipCode);
  }

  public removeDeliveryAddressZipCode(): void {
    localStorage.removeItem(this.deliveryAddressZipCodeKey);
  }

  public getDeliveryAddressStreet(): string {
    return localStorage.getItem(this.deliveryAddressStreetKey)
      ? localStorage.getItem(this.deliveryAddressStreetKey)
      : this._deliveryAddressStreet;
  }

  public setDeliveryAddressStreet(street: string): void {
    this._deliveryAddressStreet = street;
    localStorage.setItem(this.deliveryAddressStreetKey, street);
  }

  public removeDeliveryAddressStreet(): void {
    localStorage.removeItem(this.deliveryAddressStreetKey);
  }

  public getDeliveryAddressBuildingNo(): string {
    return localStorage.getItem(this.deliveryAddressBuildingNoKey)
      ? localStorage.getItem(this.deliveryAddressBuildingNoKey)
      : this._deliveryAddressBuildingNo;
  }

  public setDeliveryAddressBuildingNo(buildingNo: string): void {
    this._deliveryAddressBuildingNo = buildingNo;
    localStorage.setItem(this.deliveryAddressBuildingNoKey, buildingNo);
  }

  public removeDeliveryAddressBuildingNo(): void {
    localStorage.removeItem(this.deliveryAddressBuildingNoKey);
  }

  public getDeliveryAddressLocalNo(): string {
    return localStorage.getItem(this.deliveryAddressLocalNoKey)
      ? localStorage.getItem(this.deliveryAddressLocalNoKey)
      : this._deliveryAddressLocalNo;
  }

  public setDeliveryAddressLocalNo(localNo: string): void {
    this._deliveryAddressLocalNo = localNo;
    localStorage.setItem(this.deliveryAddressLocalNoKey, localNo);
  }

  public removeDeliveryAddressLocalNo(): void {
    localStorage.removeItem(this.deliveryAddressLocalNoKey);
  }

  public getDeliveryHours(): string {
    return localStorage.getItem(this.deliveryHoursKey)
      ? localStorage.getItem(this.deliveryHoursKey)
      : this._deliveryHours;
  }

  public setDeliveryHours(time: string): void {
    this._deliveryHours = time;
    localStorage.setItem(this.deliveryHoursKey, time);
  }

  public removeDeliveryHours(): void {
    localStorage.removeItem(this.deliveryHoursKey);
  }

  public getPaymentProvider(): string {
    return this._paymentProvider;
  }

  public setPaymentProvider(paymentProvider: string): void {
    this._paymentProvider = paymentProvider;
  }

  public getPaymentMethod(): string {
    return localStorage.getItem(this.paymentMethodKey);
  }

  public setPaymentMethod(paymentMethod: string): void {
    this._paymentMethod = paymentMethod;
    localStorage.setItem(this.paymentMethodKey, paymentMethod);
  }

  public getPaymentMethodType(): string {
    return localStorage.getItem(this.paymentMethodTypeKey);
  }

  public setPaymentMethodType(paymentMethodType: string): void {
    this._paymentMethodType = paymentMethodType;
    localStorage.setItem(this.paymentMethodTypeKey, paymentMethodType);
  }

  public getPaymentMethodValue(): string {
    return localStorage.getItem(this.paymentMethodValueKey);
  }

  public setPaymentMethodValue(paymentMethodValue: string): void {
    this._paymentMethodValue = paymentMethodValue;
    localStorage.setItem(this.paymentMethodValueKey, paymentMethodValue);
  }

  public getSelectedDeliveryHoursType(): string {
    return localStorage.getItem(this.selectedDeliveryHoursTypeKey);
  }

  public setSelectedDeliveryHoursType(type: string): void {
    this._selectedDeliveryHoursType = type;
    localStorage.setItem(this.selectedDeliveryHoursTypeKey, type);
  }

  private getHolidayByDate(holidays: HolidayViewModel[], date: Date) {
    holidays.forEach((holiday) => {
      let convertedDate = new Date(holiday.date).toString();
      holiday.date = new Date(convertedDate);
    });

    return holidays.find(
      (holiday) =>
        new Intl.DateTimeFormat('en', { day: 'numeric' }).format(date) ===
          new Intl.DateTimeFormat('en', { day: 'numeric' }).format(holiday.date) &&
        new Intl.DateTimeFormat('en', { month: 'numeric' }).format(date) ===
          new Intl.DateTimeFormat('en', { month: 'numeric' }).format(holiday.date),
    );
  }

  public isPlaceOpen(place: PlaceViewModel, holidays: HolidayViewModel[], qrMenuMode: boolean = false): boolean {
    if (qrMenuMode && !place.qrMenuActive) {
      return false;
    }

    if (!place.qrMenuActive && !place.tableOrderActive && !place.onlineOrderActive) {
      return false;
    }

    let workingHours: WorkingHoursApiModel[] = [];
    if (this.getOrderType() == OrderTypeEnum.delivery) {
      workingHours = place.deliveryWorkingHours;
    } else if (this.getOrderType() == OrderTypeEnum.pick_up) {
      workingHours = place.pickupWorkingHours;
    } else {
      workingHours = place.workingHours;
    }

    if (!workingHours || workingHours.length == 0) {
      return false;
    }

    let isOpen: boolean = false;
    const currDate: Date = new Date();
    const currTime: string = currDate.toLocaleTimeString('pl-Pl', {
      hour: '2-digit',
      minute: '2-digit',
    });

    const currWeekDay: string = currDate.toLocaleDateString('en-Us', { weekday: 'long' }).toLocaleLowerCase();

    workingHours
      .filter((wh) => wh.weekDay === currWeekDay)
      .forEach((wh) => {
        if (wh.startTime <= currTime && wh.endTime >= currTime) {
          isOpen = true;
        }
      });

    let holiday = this.getHolidayByDate(holidays, new Date());
    if (holiday) {
      isOpen = false;
    }

    return isOpen;
  }

  public getNextWorkingHoursData(
    place: PlaceViewModel,
    holidays: HolidayViewModel[],
    qrMenuMode: boolean = false,
  ): WorkingHoursApiModel {
    if (qrMenuMode && !place.qrMenuActive) {
      return null;
    }

    if (!place.qrMenuActive && !place.tableOrderActive && !place.onlineOrderActive) {
      return null;
    }

    let workingHours: WorkingHoursApiModel[] = [];
    if (this.getOrderType() == OrderTypeEnum.delivery && !qrMenuMode) {
      workingHours = place.deliveryWorkingHours;
    } else if (this.getOrderType() == OrderTypeEnum.pick_up && !qrMenuMode) {
      workingHours = place.pickupWorkingHours;
    } else {
      workingHours = place.workingHours;
    }

    if (!workingHours) {
      return null;
    }

    let nextWorkingHours = null;
    let currDate: Date = new Date();
    let currTime: string = currDate.toLocaleTimeString('pl-Pl', {
      hour: '2-digit',
      minute: '2-digit',
    });
    let currWeekDay: string = currDate.toLocaleDateString('en-Us', { weekday: 'long' }).toLocaleLowerCase();
    let dayWorkingHours = [];

    workingHours
      .filter((wh) => wh.weekDay === currWeekDay)
      .sort((a, b) => (a.startTime > b.startTime ? 1 : -1))
      .forEach((wh) => {
        if (wh.startTime > currTime) {
          nextWorkingHours = wh;
        }
      });

    if (workingHours && workingHours.length > 0) {
      while (nextWorkingHours === null) {
        currDate.setDate(currDate.getDate() + 1);
        currWeekDay = currDate.toLocaleDateString('en-Us', { weekday: 'long' }).toLocaleLowerCase();

        dayWorkingHours = workingHours
          .filter((wh) => wh.weekDay === currWeekDay)
          .sort((a, b) => (a.startTime > b.startTime ? 1 : -1));

        let holiday = this.getHolidayByDate(holidays, currDate);
        if (dayWorkingHours.length > 0 && !holiday) {
          nextWorkingHours = JSON.parse(JSON.stringify(dayWorkingHours[0]));
          nextWorkingHours.startTime = nextWorkingHours.startTime.replace(/^0/, '');
        }
      }
    }
    return nextWorkingHours;
  }

  public getOrderId(): string {
    return this._orderId;
  }

  public setOrderId(value: string): void {
    this._orderId = value;
  }

  public removeOrderId(): void {
    this._orderId = null;
  }

  get roomName() {
    return sessionStorage.getItem('roomName');
  }

  get tableName() {
    return sessionStorage.getItem('tableName');
  }

  get tableId() {
    return sessionStorage.getItem('tableId');
  }

  set roomName(value: string) {
    sessionStorage.setItem('roomName', value);
  }

  set tableName(value: string) {
    sessionStorage.setItem('tableName', value);
  }

  set tableId(value: string) {
    sessionStorage.setItem('tableId', value);
  }

  setSelectedTermsAndConditions(termsAndConditions: TermsAndConditionsItemViewModel[]) {
    this._termsAndConditions = termsAndConditions;
  }

  getSelectedTermsAndConditions(): TermsAndConditionsItemViewModel[] {
    return this._termsAndConditions.filter((term) => term.selected);
  }
}

import { CreateOrderItemRequestApiModel } from '@core/models/tapp-order/api-model/order/create-order-item-request.api.model';
import { Exclude, Expose, Type } from 'class-transformer';

@Exclude()
export class CalculateDiscountRequestApiModel {
  @Expose()
  public type: string;

  @Expose()
  @Type(() => CreateOrderItemRequestApiModel)
  public orderItems: CreateOrderItemRequestApiModel[];

  @Expose()
  public promoCode: string;
}

export class CardItemShippingAddressApiModel {
  firstName: string;

  lastName: string;

  city: string;

  street: string;

  streetNumber: string;

  postalCode: string;

  address1: string;

  address2: string;
}

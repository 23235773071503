<mat-card class="card card-article" id="{{ item.id }}" [class.hidden]="hide">
  <div class="product-list-item">
    <ng-container *ngIf="!disabled; else disabledTemplate">
      <div class="product-list-detail d-flex" (click)="navigateToDetails()">
        <ng-container [ngTemplateOutlet]="dataTemplate"></ng-container>
      </div>
    </ng-container>
    <ng-template #disabledTemplate>
      <div class="product-list-detail d-flex">
        <ng-container [ngTemplateOutlet]="dataTemplate"></ng-container>
      </div>
    </ng-template>
    <ng-template #dataTemplate>
      <div class="d-inline-block quantity" *ngIf="totalQuantity && !hasAnyMods">
        {{ totalQuantity | quantity }}
      </div>
      <img *ngIf="item.graphicUrl" [src]="item.graphicUrl" />
      <div class="d-inline-block">
        <div class="product-name">{{ item.name }}</div>
        <div class="product-description">
          {{ item.description }}
        </div>
      </div>
    </ng-template>
    <div class="product-list-action">
      <span class="product-price">
        {{ item.price | currency: 'PLN':'':'1.2-2' }}
      </span>
      <button mat-raised-button color="primary" *ngIf="!disabled && (hasAnyMods || !totalQuantity)" (click)="add()">
        +
      </button>
    </div>
  </div>

  <mat-card-footer *ngIf="totalQuantity > 0">
    <ng-container *ngFor="let article of item.orderArticles; let ind = index">
      <div
        *ngIf="hasAnyMods"
        class="product-list-item product-list-subitem px-3 py-1 product-list-detail d-flex align-items-center"
      >
        <!-- <h5 class="d-inline-block group-label">{{modifier.name}}</h5> -->
        <div class="d-flex align-items-center">
          <div>
            {{ article.selectedQuantity | quantity }}
          </div>
          <div class="mx-3">
            <div class="product-name-small">{{ item.name }}</div>
            <div *ngIf="article.modifierArticleList && article.modifierArticleList.length" class="product-description">
              {{ modifiers(article) }}
            </div>
            <div *ngIf="article.subArticleList && article.subArticleList.length" class="product-description">
              {{ subarticles(article) }}
            </div>
          </div>
        </div>
        <div>
          <span class="product-price">
            {{ article.price | money }}
          </span>
        </div>
      </div>

      <div class="quantity-picker d-flex justify-content-between" *ngIf="!disabled">
        <button mat-button (click)="decrement(article)">-</button>
        <button mat-button (click)="increment(article)">+</button>
      </div>
    </ng-container>
  </mat-card-footer>
</mat-card>

<ng-template #itemInCart>
  <mat-icon class="mat-icon product-in-cart" color="primary">check</mat-icon>
</ng-template>

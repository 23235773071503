<div class="logo" [class.hidden]="hide">
  <div class="back-arrow" *ngIf="back_arrow == true">
    <img src="../../assets/icons/arrow-back.png" style="height: 16px" />
  </div>
  <a href="{{ redirectUrl }}">
    <img
      *ngIf="defaultPlaceLogo && defaultPlaceLogo.length && (!logo || logo.length == 0)"
      class="full-logo"
      [src]="defaultPlaceLogo"
    />
    <img *ngIf="logo && logo.length > 0" class="full-logo" [src]="logo" />
  </a>
</div>

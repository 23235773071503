import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { TranslateModule } from '@ngx-translate/core';
import { InputDirective } from './input.directive';
import { FormFieldModule } from '../form-field/form-field.module';
import { AutofocusDirective } from './autofocus.directive';

@NgModule({
  imports: [CommonModule, TooltipModule, ConfirmDialogModule, TranslateModule, FormFieldModule],
  declarations: [InputDirective, AutofocusDirective],
  exports: [InputDirective, FormFieldModule, AutofocusDirective],
  providers: [],
})
export class InputModule {}

<div class="row">
  <div class="col-12">
    <section class="container">
      <div class="card-container">
        <aside>
          {{ 'cardNumber' | translate }}
        </aside>
        <div class="payu-card-form" id="payu-card-number"></div>

        <div class="card-details clearfix">
          <div class="expiration">
            <aside>
              {{ 'expirationDate' | translate }}
            </aside>
            <div class="payu-card-form" id="payu-card-date"></div>
          </div>

          <div class="cvv">
            <aside>
              {{ 'cvv' | translate }}
            </aside>
            <div class="payu-card-form" id="payu-card-cvv"></div>
          </div>
        </div>
      </div>
    </section>
  </div>
</div>

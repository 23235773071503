import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbandonedBasketService } from '@ui/abandoned-basket/abandoned-basket.service';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BasketService } from '../../services/basket.service';
import { environment } from '@env/environment';

@Component({
  selector: 'app-abandoned-basket',
  templateUrl: './abandoned-basket.component.html',
  styleUrls: ['./abandoned-basket.component.scss'],
})
export class AbandonedBasketComponent implements OnInit, OnDestroy {
  private _subscription: Subscription = null;
  public displayNoticeDialog: boolean = false;

  constructor(
    private abandonedBasketService: AbandonedBasketService,
    private translateService: TranslateService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public basketService: BasketService,
  ) {}

  ngOnInit(): void {
    if (!('Notification' in window)) {
      console.warn('This browser does not support desktop notification');
    } else {
      if (Notification.permission !== 'denied' && Notification.permission !== 'granted') {
        Notification.requestPermission().then();
      }
    }

    if (environment.abandonedBasketFeatureEnabled) {
      this._subscription = this.abandonedBasketService.notice.subscribe((notice: boolean) => {
        if (notice) {
          this.showNotice();
        }
      });
    }

    this.activatedRoute.queryParams.subscribe((params) => {
      if (params?.notice) {
        this.displayNoticeDialog = false;
      }
      if (params?.removeBasket) {
        this.basketService.removeBasket();
      }
    });
  }

  ngOnDestroy(): void {
    this._subscription?.unsubscribe();
  }

  private showNotice(): void {
    this.showPushNotice();
    this.displayNoticeDialog = true;
  }

  private showPushNotice(): void {
    if ('Notification' in window && Notification.permission === 'granted') {
      navigator.serviceWorker.ready.then((registration) => {
        let redirectUrl = sessionStorage.getItem('placeId')
          ? '/' + sessionStorage.getItem('placeId') + '?notice=true'
          : '/payments?notice=true';

        registration.showNotification(this.translateService.instant('tapp-order.abandonedBasket.title'), {
          actions: [
            {
              action: 'continue',
              title: this.translateService.instant('tapp-order.abandonedBasket.actions.continue'),
            },
            {
              action: 'startAgain',
              title: this.translateService.instant('tapp-order.abandonedBasket.actions.startAgain'),
            },
          ],
          data: {
            onActionClick: {
              continue: {
                operation: 'openWindow',
                url: redirectUrl,
              },
              startAgain: {
                operation: 'openWindow',
                url: redirectUrl + '&removeBasket=true',
              },
            },
          },
        });
      });
    }
  }

  public onContinue() {
    this.redirectToProducts();
  }

  public onStartAgain() {
    this.basketService.removeBasket();
    this.redirectToProducts();
  }

  public redirectToProducts(): void {
    this.displayNoticeDialog = false;
    if (sessionStorage.getItem('placeId')) {
      this.router.navigate(['/', sessionStorage.getItem('placeId')]);
    } else {
      this.router.navigate(['/']);
    }
  }
}

import { AbstractViewModel } from '../../../abstract.view.model';
import { AllergenApiModel } from '@core/models/tapp-order/api-model/product/allergen.api.model';

export class AllergenViewModel extends AbstractViewModel<AllergenApiModel> {
  public name: string;
  public publicId: string;

  public checked: boolean = false;

  constructor(public apiModel: AllergenApiModel = new AllergenApiModel()) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.name = this.apiModel.name;
    this.publicId = this.apiModel.publicId;
  }

  toApiModel(): AllergenApiModel {
    return undefined;
  }

  toString() {
    return this.name;
  }
}

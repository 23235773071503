import { Exclude, Expose, Type } from 'class-transformer';
import { SurveyCategoryEnum } from '@shared/enum/survey-category.enum';
import { SurveyQuestionApiModel } from '@core/models/tapp-order/api-model/survey/survey-question.api.model';

@Exclude()
export class SurveyApiModel {
  @Expose()
  public id: number;

  @Expose()
  public name: string;

  @Expose()
  public active: boolean;

  @Expose()
  public category: SurveyCategoryEnum;

  @Expose()
  @Type(() => SurveyQuestionApiModel)
  public questions: SurveyQuestionApiModel[] = [];
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { TranslateModule } from '@ngx-translate/core';
import { FormFieldComponent } from '../form-field/form-field.component';

@NgModule({
  imports: [CommonModule, TooltipModule, ConfirmDialogModule, TranslateModule],
  declarations: [FormFieldComponent],
  exports: [FormFieldComponent],
  providers: [],
})
export class FormFieldModule {}

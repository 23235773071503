import { Exclude, Expose } from 'class-transformer';

@Exclude()
export class GeoapiApiModel {
  @Expose()
  public latitude: number;

  @Expose()
  public longitude: number;

  @Expose()
  public country: string;

  @Expose()
  public city: string;

  @Expose()
  public street: string;

  @Expose()
  public houseNumber: string;

  @Expose()
  public postCode: string;

  @Expose()
  public fullAddress: string;
}
